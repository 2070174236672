import React, { Component, useContext } from 'react';
import Modal from 'react-responsive-modal';
import { Button, Col, FormGroup, Row, Label, Form } from 'reactstrap';
import Datetime from 'react-datetime';
import Datepicker from 'react-datepicker';
import Keyboard from 'react-simple-keyboard';

import SweetAlert from 'react-bootstrap-sweetalert';
// koneksi
import { Post, Get, Delete, cekLogin, JENIS_DESA, JENIS_DUSUN } from '../function/Koneksi';

const frmDef = {
	uuid: '',
	id_jenis: '',
	id_pengaju: '',
	tanggal_surat: new Date(),
	nik: '',
	nama_pemohon: '',
	tempat_lahir: '',
	tanggal_lahir: '',
	pekerjaan: '',
	pekerjaan: '',
	agama: '',
	jenis_kelamin: '',
	status_perkawinan: '',
	alamat: '',
	kewarganegaraan: 'Indonesia',
	tujuan_pembuatan: '',
	lokasi: '',
	kk: '',
	agama: '',
	status_ttd: '',
	jabatan_ttd: '',
	jabatan_pengganti_ttd: '',
	nama_ttd: '',
	nip_ttd: '',

	//detail
	tgl_mulai: '',
	tgl_selesai: '',
	waktu_mulai: '',
	waktu_selesai: '',
	tempat: '',
	hiburan: ''
};

const dateFormat = require('dateformat');

export default class SuratIzinKeramainan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: {
				form: false,
				btnForm: true,
				btnAksi: []
			},
			// keyboard
			layoutName: 'default',
			inputName: '',
			input: {},
			inputPattern: /^\d+$/,
			display: 'block',
			focus: false,
			hasFocus: false,

			Basic: false,
			longContent: false,
			Vertically: false,
			Tooltips: false,
			Grid: false,
			VaryingMdo: false,
			VaryingFat: false,
			VaryingBootstrap: false,
			sizeLarge: false,
			sizeSmall: false,

			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			successAlert: false,
			tanggal_mulai: null,
			tanggal_selesai: null,
			isDown: false,
			startY: null,
			scrollUp: null
		};
		this.showKeyboard = this.showKeyboard.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
	}

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	onChangeAll = (inputObj) => {
		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputObj[this.state.inputName]
		};
		this.setState({
			// input: inputObj
			input: updatedInputObj
		});
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				console.log('Active input', inputName);
			}
		);
	};

	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		console.log(inputVal);
		console.log(updatedInputObj);

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);
	};

	onKeyPress = (button) => {
		// console.log('Button pressed', button);

		if (button === '{shift}' || button === '{lock}') {
			this.handleShift();
		}
	};

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}
		this.forceUpdate();
	};

	onOpenModal = (key) => {
		this.setState({ [key]: true });
	};

	onCloseModal = (key) => {
		this.setState({ [key]: false });
	};

	handleClick = (event) => {
		alert(event);
	};

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_mulai':
				// this.setState({ tanggal_mulai: date.format('YYYY-MM-DD HH:mm:ss') });
				// this.setState({ tanggal_mulai: date });
				if (typeof date._isValid != 'undefined') {
					let tgl = date.format('YYYY-MM-DD HH:mm:ss');
					this.setState({ tanggal_mulai: new Date(tgl) });
				}
				break;
			case 'tanggal_selesai':
				// this.setState({ tanggal_selesai: date.format('YYYY-MM-DD HH:mm:ss') });
				// this.setState({ tanggal_selesai: date });
				if (typeof date._isValid != 'undefined') {
					let tgl = date.format('YYYY-MM-DD HH:mm:ss');
					this.setState({ tanggal_selesai: new Date(tgl) });
				}
				break;
		}
	};

	simpan = (e) => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();

		let alamat = '';
		if (JENIS_DESA.toLowerCase() == 'kelurahan') {
			alamat =
				' RT. ' +
				this.props.data.results.data_kk.data_rt.nama +
				'/RW. ' +
				this.props.data.results.data_kk.data_rt.data_rw.nama +
				', ' +
				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
				', Kec. ' +
				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
				', ' +
				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
		} else {
			// alamat =
			// 	' RT. ' +
			// 	this.props.data.results.data_kk.data_rt.nama +
			// 	'/RW. ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 	', ' +
			// 	JENIS_DUSUN +
			// 	' ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 	', ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 	', Kec. ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
			// 	', ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

				if (this.props.data.results.data_kk.id_rt == '0') {
					alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
					", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
					", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
					", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
				} else {
					alamat =
					" RT. " + 
					this.props.data.results.data_kk.data_rt.nama +
					"/RW. " +
					this.props.data.results.data_kk.data_rt.data_rw.nama +
					", " + JENIS_DUSUN + " " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
					", " + JENIS_DESA + " " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
					", Kec. " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.nama +
					", " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.data_kabkota.nama; 
				}
		}

		let formDetail = [
			{
				nama_item: 'tgl_mulai',
				isi_item: dateFormat(this.state.tanggal_mulai, 'yyyy-mm-dd HH:MM:ss')
			},
			{
				nama_item: 'tgl_selesai',
				isi_item: dateFormat(this.state.tanggal_selesai, 'yyyy-mm-dd HH:MM:ss')
			},
			{
				nama_item: 'tempat',
				isi_item: document.getElementById('tempat').value
			},
			{
				nama_item: 'hiburan',
				isi_item: document.getElementById('hiburan').value
			}
		];

		let addData = {
			id_jenis: this.props.id_jenis,

			nik: this.props.data.results.id,
			nama_pemohon: this.props.data.results.nama,
			tempat_lahir: this.props.data.results.tempat_lahir,
			tanggal_lahir: this.props.data.results.tanggal_lahir,
			pendidikan_terakhir: this.props.data.results.pendidikan_terakhir,
			pekerjaan: this.props.data.results.data_pekerjaan.nama,
			agama: this.props.data.results.agama,
			status_perkawinan: this.props.data.results.status_perkawinan,
			alamat: alamat,
			jenis_kelamin: this.props.data.results.jk,

			kewarganegaraan: 'Indonesia',
			tujuan_pembuatan: document.getElementById('tujuan_pembuatan').value,
			kk: this.props.data.results.id_kk,

			detail: formDetail
		};

		// console.log(addData);

		let psn = '';
		let resstat = 201;
		let metode = 'create';

		Post('surat-keterangan/grup/' + metode + '/with-detail', null, addData, (res) => {
			this.onCloseModal('Basic');

			let dataAntrian = {
				nama: this.props.data.results.nama,
				nik: this.props.data.results.id,
				keterangan: '',
				uuid_surat:res.data.results,
			};

			if (res.status === resstat) {
				Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
					console.log("Hasil Update Status")
					console.log(res2)
					if (res2.code === 201) {
						console.log("Berhasil Ganti Status Menjadi Selesai")

						this.props.getAntrian('Surat Izin Keramaian', 'berhasil', false, dataAntrian);
					}
					else{
						console.log("Gagal Ganti Status Menjadi Selesai")

						this.props.getAntrian('Surat Izin Keramaian', 'gagal');
					}
				});
				// this.props.getAntrian('Surat Izin Keramaian', 'berhasil', true, dataAntrian);
			} else {
				this.props.getAntrian('Surat Izin Keramaian', 'gagal');
			}
			// this.componentDidMount();
			// reset data
			this.state.tanggal_mulai = null;
			this.state.tanggal_selesai = null;
			this.state.input = {};
			this.forceUpdate();
			document.getElementById('tempat').value = '';
			document.getElementById('hiburan').value = '';
			document.getElementById('tujuan_pembuatan').value = '';
		});
	};

	componentDidMount() {}

	render() {
		const { Basic } = this.state;

		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		let frmDef = {
			uuid: '',
			id_jenis: '',
			id_pengaju: '',
			tanggal_surat: '',
			nik: '',
			nama_pemohon: '',
			tempat_lahir: '',
			tanggal_lahir: '',
			pekerjaan: '',
			pekerjaan: '',
			agama: '',
			status_perkawinan: '',
			alamat: '',
			kewarganegaraan: 'Indonesia',
			tujuan_pembuatan: '',
			lokasi: '',
			kk: '',
			detail: [],
			agama: '',
			jenis_kelamin: '',
			status_ttd: '',
			jabatan_ttd: '',
			jabatan_pengganti_ttd: '',
			nama_ttd: '',
			nip_ttd: '',
			//detail
			//detail
			tgl_mulai: '',
			tgl_selesai: '',
			waktu_mulai: '',
			waktu_selesai: '',
			tempat: '',
			hiburan: ''
		};

		const columns = [
			{
				dataField: 'tanggal_surat',
				text: 'Tanggal',
				formatter: (cell, obj) => {
					return obj.tanggal_surat == null ? '-' : new Date(obj.tanggal_surat).toLocaleDateString('id-ID');
				},
				isDummyField: true,
				sort: true
			},
			{
				dataField: 'nomor',
				text: 'Nomor surat',
				formatter: (cell, obj) => {
					return obj.nomor == '' ? '-' : obj.nomor;
				},
				sort: true
			},
			{
				dataField: 'tujuan_pembuatan',
				text: 'Tujuan',
				formatter: (cell, obj) => {
					let tujuan = obj.tujuan_pembuatan.split(',');
					return tujuan[0];
				},
				isDummyField: true,
				sort: true
			},
			{
				dataField: 'status',
				text: 'Status',
				formatter: (cell, obj) => {
					let status = '-';
					obj.data_status.map((dt) => {
						status = dt.status;
					});
					if (status == 'Diajukan') {
						this.state.status.btnAksi[cell] = true;
					} else {
						this.state.status.btnAksi[cell] = false;
					}
					this.forceUpdate();
					return status;
				},
				isDummyField: true,
				sort: true
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi
			}
		];

		return (
			<div>
				<SweetAlert
					show={this.state.show}
					// show={true}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					cancelBtnBsStyle="info"
					onCancel={this.closeAlert}
					showConfirm={false}
				/>
				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div
							// className="card-scrollable"
							// style={{ marginBottom: this.state.display == 'block' ? '200px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								// console.log(e);
								this.state.isDown = true;
								this.state.startY = e.pageY - slider.offsetTop;
								this.state.scrollUp = slider.scrollTop;
								this.forceUpdate();
								// console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title" id="exampleModalLongTitle">
									Surat Izin Keramaian
								</h5>
							</div>
							<div className="modal-body">
								<input
									className="form-control"
									id="jenis_kelamin"
									readOnly
									type="hidden"
									placeholder="jenis_kelamin"
									value={this.props.data.results.jk}
								/>

								<input
									className="form-control"
									readOnly
									id="pekerjaan"
									type="hidden"
									placeholder="Pekerjaan"
									value={this.props.data.results.pekerjaan}
								/>

								<input
									className="form-control"
									id="pendidikan_terakhir"
									readOnly
									type="hidden"
									placeholder="Pendidikan Terakhir"
									value={this.props.data.results.pendidikan_terakhir}
								/>

								<input
									className="form-control"
									id="agama"
									readOnly
									type="hidden"
									placeholder="Agama"
									value={this.props.data.results.agama}
								/>

								<input
									className="form-control"
									id="status_perkawinan"
									type="hidden"
									readOnly
									placeholder="Status Perkawinan"
									value={this.props.data.results.status_perkawinan}
								/>

								<input
									className="form-control"
									id="alamat"
									type="hidden"
									readOnly
									placeholder="Alamat"
									value={this.props.data.results.alamat}
								/>

								<input
									className="form-control"
									id="kewarganegaraan"
									type="hidden"
									readOnly={true}
									placeholder="Kewarganegaraan"
									value={this.props.data.results.kewarganegaraan}
								/>
								<input
									className="form-control"
									id="kk"
									type="hidden"
									placeholder="no kk"
									value={this.props.data.results.id_kk}
								/>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											placeholder="NIK"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Lengkap</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pemohon"
											disabled
											type="text"
											placeholder=" Nama Lengkap"
											value={this.props.data.results.nama}
										/>
									</Col>
								</FormGroup>

								<input
									className="form-control"
									readOnly
									id="tempat_lahir"
									type="hidden"
									placeholder="Tempat lahir"
									value={this.props.data.results.tempat_lahir}
								/>

								<input
									className="form-control"
									id="tanggal_lahir"
									type="hidden"
									placeholder="Tanggal Lahir"
									value={this.props.data.results.tanggal_lahir}
								/>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Tujuan pembuatan</Label>
									<Col sm="9">
										<input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												// console.log(e.target);
												this.setActiveInput('tujuan_pembuatan');
											}}
											value={this.state.input['tujuan_pembuatan'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="tujuan_pembuatan"
											type="text"
											required
											placeholder="Tujuan pembuatan"
											autoComplete="off"
											// autoFocus
											// defaultValue={this.state.dt.tujuan_pembuatan}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Waktu Acara</Label>
									<Col sm="4">
										<Datetime
											inputProps={{
												required: true,
												placeholder: 'Waktu Mulai',
												onFocus: (e) => {
													// this.setState({ display: 'none' });
												}
											}}
											initialValue={new Date(this.state.tanggal_mulai)}
											onChange={(e) => {
												this.gantiTgl(e, 'tanggal_mulai');
											}}
											locale="id-ID"
											timeFormat="HH:mm"
											dateFormat="DD/MM/yyyy"
										/>
									</Col>
									<Label className="col-sm-1 col-form-label text-center">s/d</Label>
									<Col sm="4">
										<Datetime
											locale="id-ID"
											dateFormat="DD/MM/yyyy"
											timeFormat="HH:mm"
											inputProps={{
												required: true,
												placeholder: 'Waktu Selesai',
												onFocus: (e) => {
													// this.setState({ display: 'none' });
												}
											}}
											initialValue={this.state.tanggal_selesai}
											onChange={(e) => {
												this.gantiTgl(e, 'tanggal_selesai');
											}}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Tempat</Label>
									<Col sm="9">
										<input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												// console.log(e.target);
												this.setActiveInput('tempat');
											}}
											value={this.state.input['tempat'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="tempat"
											type="text"
											required
											placeholder="Tempat"
											autoComplete="off"
											// defaultValue={this.state.dt.tempat}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Hiburan</Label>
									<Col sm="9">
										<input
											onFocus={() => {
												this.setState({ display: 'block' });
												this.setActiveInput('hiburan');
											}}
											value={this.state.input['hiburan'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="hiburan"
											type="text"
											required
											placeholder="Hiburan"
											autoComplete="off"
											// defaultValue={this.state.dt.hiburan}
										/>
									</Col>
								</FormGroup>
							</div>
							<div
								className="modal-footer"
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
							>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-primary">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>
					{/* <div
						style={{
							position: 'fixed',
							bottom: 0,
							left: 0,
							width: '100%',
							zIndex: 4,
							display: this.state.display
						}}
					>
						<Keyboard
							style={{ marginTop: '40px', zIndex: 3 }}
							keyboardRef={(r) => (this.keyboard = r)}
							inputName={this.state.inputName}
							layoutName={this.state.layoutName}
							onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
							onKeyPress={(button) => this.onKeyPress(button)}
						/>
					</div> */}
				</Modal>
				<div
					className="card kiosk-card"
					onClick={() => {
						this.setState({
							judul: 'Tambah Surat Keramaian',
							dt: frmDef,
							tanggal_mulai: new Date(),
							tanggal_selesai: null,
							pendStat: []
						});
						this.onOpenModal(`Basic`);
					}}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
						// background: 'linear-gradient(135deg, #339ce8, #4ec573)'
						// background: 'linear-gradient(135deg, #99ff12, #40b523)'
						// background: 'linear-gradient(135deg, #097df9, #2988ec)'
						// background: 'linear-gradient(135deg, #484848, #000000)'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/keramaian.png')}
								alt="logo"
								// width="60"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p className="mb-0" style={{ fontSize: '20px', fontWeight: '600', color: '#000000' }}>
								Izin Keramaian
							</p>
						</div>
					</div>
				</div>

				{/* <div className="row" style={{ marginTop: '200px', position: 'absolute' }}>
					<div
						className="container-fluid"
						style={{ zIndex: 3, width: '100vw', position: 'fixed', bottom: 0 }}
					>
						<div className="row">
							<Keyboard
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
								inputPattern={this.state.inputPattern}
							/>
						</div>
					</div>
				</div> */}
			</div>
		);
	}
}
