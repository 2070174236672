import React, { Component } from "react";
import { Col, Row, Button } from "reactstrap";
import { Link, Redirect } from "react-router-dom";
import Slider from "react-slick";
import "../assets/css/kiosk.css";

export default class Beranda7 extends Component {
  state = {
    navigate: false
  };

  logOut(e) {
    e.preventDefault();
    sessionStorage.removeItem("access_token");
    this.setState({ navigate: true });
  }

  render() {
    const { navigate } = this.state;
    // console.log(akun);
    if (navigate) {
      return <Redirect to="/" push={true} />;
    }

    const sliderStyle = {
      margin: "0px auto",
      width: "95%",
      // maxWidth: '1280px',
      maxWidth: "1440px",
      // height: '357px',
      borderRadius: "21px",
      objectFit: "cover",
      objectPosition: "50% 50%"
    };

    var Merge_settings = {
      dots: true,
      infinite: true,
      speed: 100,
      slidesToShow: 1,
      slidesToScroll: 1,
      margin: 0,
      padding: 0
      // centerMode: true,
      // variableWidth: true,
      // adaptiveHeight:true,
    };

    return (
      <>
        <div className="bg-beranda-kiosk7">
          <div className="lingkaran">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <div className="selamat-datang">
                      <img
                        src={require("../assets/images/kiosk/kota-pekanbaru.png")}
                        alt=""
                        style={{
                          maxHeight: 90,
                          width: 60
                        }}
                      />
                      <div>
                        <span>
                          <b>SELAMAT DATANG DI</b>
                        </span>
                      </div>
                      <div>
                        <span>
                          <b> ANJUNGAN PELAYANAN MANDIRI </b>
                        </span>
                      </div>
                      <div>
                        <span>
                          <b>{localStorage.getItem('Nama_DESA').toUpperCase()}</b>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <Link to="/login-masyarakat">
                      <div className="masuk">
                        <img
                          src={require("../assets/images/kiosk/masuk.png")}
                          alt=""
                          style={{
                            height: "118px",
                            width: "118px",
                            borderRadius: "10px"
                          }}
                        />
                        <div>
                          <span>MULAI</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-md-6">
                    {/* <Link to="/login-masyarakat"> */}
                    <div className="keluar">
                      <img
                        src={require("../assets/images/kiosk/panduan.png")}
                        alt=""
                        style={{
                          height: "118px",
                          width: "118px",
                          borderRadius: "10px"
                        }}
                        onClick={this.logOut.bind(this)}
                      />
                      <div>
                        <span>PANDUAN</span>
                      </div>
                    </div>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="gambar-laptop">
                  <img
                    src={require("../assets/images/kiosk/bg-kiosk-beranda.png")}
                    alt=""
                    style={{
                      height: 520,
                      width: "100%",
                      borderRadius: "10px"
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

