import React, { Component, useEffect, useState } from "react";

// import Custom Componenets
import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col
} from "reactstrap";
// koneksi
import { Post, Get, Delete, cekLogin, ID_DESA } from "../function/Koneksi";

import { Link } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroller";

class Penduduk extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total: 0,
      pagination: {
        total: 0
      },
      pesan: "Memuat notifikasi...."
    };
  }

  componentDidMount() {
    Get("list-notif", null, res => {
      
      let data = res.results.data.data;
      let total = res.results.total;
      let results = res.results;
      console.log(data)
      if(data.length==0){
      this.setState({ data, pagination: results.data, total ,pesan:'Belum ada Notifikasi'});
        
      }else {
        if(res.results.data.current_page==res.results.data.last_page){
          this.setState({ data, pagination: results.data, total ,pesan:''});

        } else {
          this.setState({ data, pagination: results.data, total });

        }

      }

    });
  }
  cekNotifikasi = dt => {
    console.log(dt);
    Get("list-notif/update/" + dt.uuid, null, res => {
      console.log(res);
      // this.props.history.push(this.props.location.pathname);
      // window.location.reload(false);
      this.componentDidMount();
    });
  };
  cekAllNotifikasi = dt => {
    console.log(dt);
    Get("list-notif/all", null, res => {
      console.log(res);
      // this.props.history.push(this.props.location.pathname);
      // window.location.reload(false);
      this.componentDidMount();
    });
  };
  // cekNotifikasi = () => {
  //   Get("list-notif/all", null, res => {
  //     console.log(res);
  //     this.componentDidMount();
  //   });
  // };
  fetchMoreData = () => {
    // console.log(this.state.pagination)
    if (this.state.pagination.current_page < this.state.pagination.last_page) {
      let link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
      // console.log(link)
      this.setState({ pesan: "Memuat notifikasi...." });

      Get("list-notif" + link, null, res => {
        let data = res.results.data.data;
        let results = res.results;
        this.setState({
          data: [...this.state.data, ...res.results.data.data],
          pagination: results.data
        });
        // console.log(this.state.data)
      });
    } else {
      this.setState({ pesan: "" });
    }
  };
  renderItem = (index, key) => {
    let data = this.state.data;
    return data.length != 0 ? (
      <li key={key}>
        <div className="media">
          <div className="media-body">
            <h6 className="mt-0">{data[index].konten}</h6>
            <span>
              <i className="icofont icofont-clock-time p-r-5"></i>Just Now
            </span>
          </div>
        </div>
      </li>
    ) : null;
  };

  render() {
    return (
      <div>
        {/* <Breadcrumb title="Data Penduduk" parent="Admin" /> */}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-lg-12 text-right">
                      <span>
                        <a href="#" onClick={this.cekAllNotifikasi}>
                          Tandai telah dibaca
                        </a>
                      </span>
                    </div>
                    {/* <h5>
                          
                      </h5> */}
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <div className="row">
                    <div className="col-lg-12">
                      <div
                        style={{ overflow: "auto", maxHeight: 200 }}
                        ref={ref => (this.scrollParentRef = ref)}
                      >
                        <InfiniteScroll
                          initialLoad={false}
                          loadMore={this.fetchMoreData}
                          hasMore={true}
                          useWindow={false}
                          getScrollParent={() => this.scrollParentRef}
                          loader={
                            this.state.pesan != "" ? (
                              <div key={0}>
                                <h6 className="mt-0">{this.state.pesan}.</h6>
                              </div>
                            ) : (
                              <></>
                            )
                          }
                        >
                          {this.state.data.map((dt, idx) => {
                            let cek =
                              dt.konten.indexOf("setujui") !== -1
                                ? {
                                    pathname: "/admin/disposisi",
                                    state: { tab: "2" }
                                  }
                                : {
                                    pathname: "/admin/disposisi",
                                    state: { tab: "1" }
                                  };
                            return (
                              <div key={idx}>
                                <Link to={cek}>
                                  <div className="col-lg-12">
                                    <h6
                                      className={
                                        dt.status == 0
                                          ? "mt-0 txt-danger "
                                          : "mt-0"
                                      }
                                    >
                                      {dt.konten}
                                    </h6>
                                  </div>
                                </Link>

                                <div className="col-lg-12">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <span>
                                        <i className="icofont icofont-clock-time p-r-5"></i>
                                        {new Date(
                                          dt.created_at
                                        ).toLocaleDateString("id-ID")}
                                      </span>
                                    </div>
                                    <div className="col-lg-6 text-right">
                                      {dt.status == 0 ? (
                                        <span>
                                          <a
                                            href="#"
                                            onClick={() =>
                                              this.cekNotifikasi(dt)
                                            }
                                          >
                                            Tandai telah dibaca
                                          </a>
                                        </span>
                                      ) : (
                                        <span>Telah dibaca</span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <br />
                              </div>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Penduduk;
