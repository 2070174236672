import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Col, FormGroup, Label, Row, Form } from 'reactstrap';
import Select from 'react-select';
import { Link, Redirect } from 'react-router-dom';
import Datetime from 'react-datetime';
import { Get, JENIS_DESA, JENIS_DUSUN, Post } from '../function/Koneksi';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

let frmDef = {
	uuid: '',
	id_jenis: '',
	nomor: '',
	tanggal_surat: null,
	nik: '',
	nama_pemohon: '',
	tempat_lahir: '',
	tanggal_lahir: '',
	jk: '',
	pekerjaan: '',
	agama: '',
	alamat: '',
	status_perkawinan: '',
	pendidikan_terakhir: '',

	nik_pasangan: '',
	nama_pasangan: '',
	tempat_lahir_pasangan: '',
	tanggal_lahir_pasangan: '',
	jk_pasangan: '',
	pekerjaan_pasangan: '',
	agama_pasangan: '',
	alamat_pasangan: '',
	status_perkawinan_pasangan: '',

	tanggal_menikah: null,
	tempat_menikah: '',
	wali_nikah: '',
	petugas_nikah: '',
	mas_kawin: '',
	nik_pelapor: '',
	nama_pelapor: '',

	tempat_lahir_pelapor: '',
	tanggal_lahir_pelapor: '',
	jk_pelapor: '',
	pekerjaan_pelapor: '',
	agama_pelapor: '',
	alamat_pelapor: '',
	status_perkawinan_pelapor: '',

	status_ttd: '',
	jabatan_ttd: '',
	jabatan_pengganti_ttd: '',
	nama_ttd: '',
	nip_ttd: ''
};
const dateFormat = require('dateformat');

export default class SuratPernahMenikah extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Basic: false,
			status: {
				form: false,
				dataPenduduk: 'none',
				dataPenduduk2: 'none',
				dataPenduduk3: 'none'
			},
			//keyboard
			focus: false,
			display: 'none',
			layoutName: 'default',
			inputName: 'input1',
			input: {},
			inputPattern: /^\d+$/,
			//end keyboard
			frmNik: [],
			nikStat: null,
			frmNik3: [],
			nik3Stat: null,
			dt: frmDef,
			tanggal_surat: new Date(),
			// tanggal_lahir: new Date(),
			tanggal_menikah: null,
			formPasangan: 'none',
			// Dropdown
			frmAgm: [],
			agmStat: null,
			frmPkr: [],
			pkrStat: null,
			frmSpk: [],
			spkStat: null,
		};
	}

	onOpenModal = (key) => {
		console.log(key);
		this.setState({ [key]: true });
	};

	onCloseModal = (key) => {
		this.setState({ [key]: false, input: '', display: 'none' });
		this.state.nikStat = null;
		this.state.dt.nama_pemohon = '';
		// this.state.dt.tanggal_lahir = '';
		this.state.dt.tanggal_lahir = null;
		this.state.dt.tempat_lahir = '';
		this.state.dt.agama = '';
		this.state.dt.pekerjaan = '';
		this.state.dt.alamat = '';
		this.state.dt.jk = '';
		this.state.dt.status_perkawinan = '';
		this.state.status.dataPenduduk = 'none';
		this.state.nik3Stat = null;
		this.state.dt.nik_pasangan = '';
		this.state.dt.nama_pasangan = '';
		// this.state.dt.tanggal_lahir_pasangan = '';
		this.state.dt.tanggal_lahir_pasangan = null;
		this.state.dt.tempat_lahir_pasangan = '';
		this.state.dt.agama_pasangan = '';
		this.state.agmStat = null;
		this.state.dt.pekerjaan_pasangan = '';
		this.state.pkrStat = null;
		this.state.dt.alamat_pasangan = '';
		this.state.dt.jk_pasangan = '';
		this.state.dt.status_perkawinan_pasangan = '';
		this.state.spkStat = null;
		this.state.status.dataPenduduk3 = 'none';
		this.state.formPasangan = 'none';
		this.state.tanggal_menikah = null
		this.forceUpdate();
	};

	handleClick = (event) => {
		alert(event);
	};

	changeInputText = (e, fld) => {
		switch (fld) {
			case 'nik_pasangan':
				// console.log(e.target.value);
				this.state.dt.nik_pasangan = e.target.value;
				this.forceUpdate();
				break;
			case 'alamat_pasangan':
				// console.log(e.target);
				this.state.dt.alamat_pasangan = e.target.value;
				this.forceUpdate();
				break;
			case 'nama_pasangan':
				// console.log(e.target.value);
				this.state.dt.nama_pasangan = e.target.value;
				this.forceUpdate();
				break;
			case 'tempat_lahir_pasangan':
				// console.log(e.target.value);
				this.state.dt.tempat_lahir_pasangan = e.target.value;
				this.forceUpdate();
				break;
			case 'pekerjaan_pasangan':
				// console.log(e.target.value);

				this.state.dt.pekerjaan_pasangan = e.target.value;
				this.forceUpdate();
				break;
			case 'agama_pasangan':
				// console.log(e.target.value);
				this.state.dt.agama_pasangan = e.target.value;
				this.forceUpdate();
				break;
			case 'status_perkawinan_pasangan':
				// console.log(e.target.value);
				this.state.dt.status_perkawinan_pasangan = e.target.value;
				this.forceUpdate();
				break;
			case 'tanggal_lahir_pasangan':
				// console.log(e.target.value);
				this.state.dt.tanggal_lahir_pasangan = e.target.value;
				this.forceUpdate();
				break;
		}
	};

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld == 'nik') {
				let nikStat = { value: e, label: e };
				let param = {
					nik: e
				};

				let keyword = e;
				let datanya = this.state.frmNik;

				let filtered = datanya.filter((entry) =>
					Object.values(entry).some((val) => typeof val === 'string' && val.includes(keyword))
				);

				// let nikStat = { value: e, label: e };
				// let param = {
				// 	nik: e
				// };
				// Post('penduduk/kk/' + this.props.data.results.id_kk, null, param, (datanik) => {
				// 	let frmNik = [];
				// 	datanik.data.results.map((dt) => {
				// 		frmNik.push({ value: dt.id, label: dt.id });
				// 	});
				// 	// this.state.frmNik = frmNik;
				// 	// this.state.nikStat = nikStat;
				// 	// this.state.status.dataPenduduk = 'block';
				// 	// this.forceUpdate();
				// 	this.setState({ frmNik, nikStat });
				// });
			} else {
				if (fld == 'nik3') {
					let nik3Stat = { value: e, label: e };
					let param = {
						nik: e
					};
					Post('penduduk', null, param, (datanik) => {
						let frmNik3 = [];
						datanik.data.results.data.map((dt) => {
							frmNik3.push({ value: dt.id, label: dt.id });
						});
						this.setState({ frmNik3, nik3Stat });
					});
				}
			}
		}
		// else {
		// 	if (fld == 'nik') {
		// 		this.state.nikStat = '';
		// 		this.state.status.dataPenduduk = 'none';
		// 	}
		// }
	};

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'jam':
					this.state.dt.jam = e.value;
					this.setState({ jamStat: e });
					break;
				case 'pekerjaan_pasangan':
					this.state.dt.pekerjaan_pasangan = e.label;
					this.setState({ pkrStat: e });
					break;
				case 'agama':
					this.state.dt.agama = e.label;
					this.setState({ agmStat: e });
					break;
				case 'agama_pasangan':
					this.state.dt.agama_pasangan = e.label;
					this.setState({ agmStat: e });
					break;
				case 'status_perkawinan_pasangan':
					this.state.dt.status_perkawinan_pasangan = e.label;
					this.setState({ spkStat: e });
					break;
				case 'nik':
					this.state.dt.nik = e.value;
					this.setState({ nikStat: e });
					break;
				case 'nik2':
					this.state.dt.nik_pelapor = e.value;
					this.setState({ nik3Stat: e });
					break;
				case 'nik3':
					this.state.dt.nik_pasangan = e.value;
					this.setState({ nik3Stat: e });
					break;
			}
		} else {
			switch (sel) {
				case 'jam':
					this.state.dt.jam = 0;
					this.setState({ jamStat: e });
					break;
				case 'pekerjaan_pasangan':
					this.state.dt.pekerjaan_pasangan = '';
					this.setState({ pkrStat: e });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = '';
					this.setState({ pkrStat: e });
					break;
				case 'agama':
					this.state.dt.agama = '';
					this.setState({ agmStat: e });
					break;
				case 'agama_pasangan':
					this.state.dt.agama_pasangan = '';
					this.setState({ agmStat: e });
					break;
				case 'status_perkawinan_pasangan':
					this.state.dt.status_perkawinan_pasangan = '';
					this.setState({ spkStat: e });
					break;
				case 'nik':
					this.state.dt.nik = 0;
					this.setState({ nikStat: e });
					break;
				case 'nik2':
					this.state.dt.nik_pelapor = 0;
					this.setState({ nik3Stat: e });
					break;
				case 'nik3':
					this.state.dt.nik_pasangan = 0;
					this.setState({ nik3Stat: e });
					break;
			}
		}

		this.forceUpdate();
	};

	getPenduduk = (e, sel, jns) => {
		if (e) {
			this.changeSelectValue(e, sel);
			let link_getnya = '';
			if (this.props.data.results.data_kk == null) {
				Get('temp/penduduk/find', e.value, (data) => {
					// console.log(data.results);
					const data_rt = data.results.data_kk_pend_temp.data_rt;
					const data_rw = data_rt.data_rw;
					const data_dusun = data_rw.data_dusun;
					const data_desa = data_dusun.data_keldes;
					const data_kecamatan = data_desa.data_kecamatan;
					const data_kabkota = data_kecamatan.data_kabkota;
					let alamat =
						' RT. ' +
						data.results.data_kk_pend_temp.data_rt.nama +
						'/RW. ' +
						data.results.data_kk_pend_temp.data_rt.data_rw.nama +
						', Dusun ' +
						data.results.data_kk_pend_temp.data_rt.data_rw.data_dusun.nama +
						', ' +
						data.results.data_kk_pend_temp.data_rt.data_rw.data_dusun.data_keldes.nama +
						', Kec. ' +
						data.results.data_kk_pend_temp.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
						', ' +
						data.results.data_kk_pend_temp.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan
							.data_kabkota.nama;

					if (jns == 'pasangan') {
						this.state.tanggal_lahir_pasangan = data.results.tanggal_lahir;
						this.state.dt.nama_pasangan = data.results.nama;
						this.state.dt.nik_pasangan = data.results.id;
						this.state.dt.tanggal_lahir_pasangan = data.results.tanggal_lahir;
						this.state.dt.tempat_lahir_pasangan = data.results.tempat_lahir;
						this.state.dt.jk_pasangan = data.results.jk;
						this.state.dt.status_perkawinan_pasangan = data.results.status_perkawinan;
						this.state.dt.agama_pasangan = data.results.data_agama.nama;
						this.state.dt.pekerjaan_pasangan = data.results.data_pekerjaan.nama;
						this.state.dt.alamat_pasangan = alamat;
						this.state.status.dataPenduduk3 = 'block';
						this.forceUpdate();
					}
					// this.forceUpdate();

					if (data.results) {
						// this.state.tanggal_lahir = data.results.tanggal_lahir;
						this.state.dt.nama_pemohon = data.results.nama;
						// this.state.dt.tanggal_lahir = new Date(data.results.tanggal_lahir).toLocaleDateString('id-ID');
						this.state.dt.tanggal_lahir = new Date(data.results.tanggal_lahir);
						this.state.dt.tempat_lahir = data.results.tempat_lahir;
						this.state.dt.jk = data.results.jk;
						this.state.dt.status_perkawinan = data.results.status_perkawinan;
						this.state.dt.agama = data.results.data_agama.nama;
						this.state.dt.pekerjaan = data.results.data_pekerjaan.nama;
						this.state.dt.pendidikan_terakhir = data.results.data_pendidikan_terakhir.nama;
						this.state.dt.alamat = alamat;
						this.state.status.dataPenduduk = 'block';
						// this.state.status.btnForm = false;
						this.forceUpdate();
						// console.log(this.state.dt.tanggal_lahir);
					} else {
						this.state.nikStat = null;
						this.state.frmNik = [];
						this.state.dt.nama_pemohon = '';
						this.state.dt.tanggal_lahir = '';
						this.state.dt.tempat_lahir = '';
						this.state.dt.agama = '';
						this.state.dt.pekerjaan = '';
						this.state.dt.alamat = '';
						this.state.dt.jk = '';
						this.state.dt.status_perkawinan = '';
						this.state.status.dataPenduduk = 'none';
						this.forceUpdate();
						// this.setState({
						// 	show: true,
						// 	basicType: 'danger',
						// 	basicTitle: 'Peringatan Catatan Warga',
						// 	pesanAlert:
						// 		'Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r' +
						// 		catatan
						// });
						this.props.isComplete(false, true);
					}
				});
			} else {
				Get('penduduk/find', e.value, (data) => {
					// console.log(data.results);
					const data_rt = data.results.data_kk.data_rt;
					const data_rw = data_rt.data_rw;
					const data_dusun = data_rw.data_dusun;
					const data_desa = data_dusun.data_keldes;
					const data_kecamatan = data_desa.data_kecamatan;
					const data_kabkota = data_kecamatan.data_kabkota;
					let alamat =
						' RT. ' +
						data.results.data_kk.data_rt.nama +
						'/RW. ' +
						data.results.data_kk.data_rt.data_rw.nama +
						', Dusun ' +
						data.results.data_kk.data_rt.data_rw.data_dusun.nama +
						', ' +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
						', Kec. ' +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
						', ' +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

					if (jns == 'pasangan') {
						this.state.tanggal_lahir_pasangan = data.results.tanggal_lahir;
						this.state.dt.nama_pasangan = data.results.nama;
						this.state.dt.nik_pasangan = data.results.id;
						this.state.dt.tanggal_lahir_pasangan = data.results.tanggal_lahir;
						this.state.dt.tempat_lahir_pasangan = data.results.tempat_lahir;
						this.state.dt.jk_pasangan = data.results.jk;
						this.state.dt.status_perkawinan_pasangan = data.results.status_perkawinan;
						this.state.dt.agama_pasangan = data.results.data_agama.nama;
						this.state.dt.pekerjaan_pasangan = data.results.data_pekerjaan.nama;
						this.state.dt.alamat_pasangan = alamat;
						this.state.status.dataPenduduk3 = 'block';
						this.forceUpdate();
					} else {
						Get('catatan-penduduk/nik', data.results.id, (dpddk) => {
							let pctt = 0;
							let catatan = '';
							if (dpddk.results.data) {
								dpddk.results.data.map((dtp) => {
									if (dtp.status == 0 && dtp.penalti == 1) {
										pctt = 1;
										catatan = dtp.catatan;
									}
								});
							}
							if (pctt == 0) {
								// this.state.tanggal_lahir = data.results.tanggal_lahir;
								this.state.dt.nama_pemohon = data.results.nama;
								// this.state.dt.tanggal_lahir = new Date(data.results.tanggal_lahir).toLocaleDateString(
								// 	'id-ID'
								// );
								this.state.dt.tanggal_lahir = new Date(data.results.tanggal_lahir);
								this.state.dt.tempat_lahir = data.results.tempat_lahir;
								this.state.dt.jk = data.results.jk;
								this.state.dt.status_perkawinan = data.results.status_perkawinan;
								this.state.dt.agama = data.results.data_agama.nama;
								this.state.dt.pekerjaan = data.results.data_pekerjaan.nama;
								this.state.dt.pendidikan_terakhir = data.results.data_pendidikan_terakhir.nama;
								this.state.dt.alamat = alamat;
								this.state.status.dataPenduduk = 'block';
								// this.state.status.btnForm = false;
								this.forceUpdate();
								// console.log(this.state.dt.tanggal_lahir);
							} else {
								this.state.nikStat = null;
								this.state.frmNik = [];
								this.state.dt.nama_pemohon = '';
								this.state.dt.tanggal_lahir = '';
								this.state.dt.tempat_lahir = '';
								this.state.dt.agama = '';
								this.state.dt.pekerjaan = '';
								this.state.dt.alamat = '';
								this.state.dt.jk = '';
								this.state.dt.status_perkawinan = '';
								this.state.status.dataPenduduk = 'none';
								this.forceUpdate();
								// this.setState({
								// 	show: true,
								// 	basicType: 'danger',
								// 	basicTitle: 'Peringatan Catatan Warga',
								// 	pesanAlert:
								// 		'Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r' +
								// 		catatan
								// });
								this.props.isComplete(false, true);
							}
						});
					}

					// this.forceUpdate();
				});
			}
		} else {
			if (jns == 'pelapor') {
				this.state.nik3Stat = null;
				this.state.dt.nama_pelapor = '';
				this.state.dt.tanggal_lahir_pelapor = '';
				this.state.dt.tempat_lahir_pelapor = '';
				this.state.dt.agama_pelapor = '';
				this.state.dt.pekerjaan_pelapor = '';
				this.state.dt.alamat_pelapor = '';
				this.state.dt.jk_pelapor = '';
				this.state.dt.status_perkawinan_pelapor = '';
				this.state.status.dataPenduduk2 = 'none';
			} else {
				if (jns == 'pasangan') {
					this.state.nik3Stat = null;
					this.state.dt.nik_pasangan = '';
					this.state.dt.nama_pasangan = '';
					this.state.dt.tanggal_lahir_pasangan = '';
					this.state.dt.tempat_lahir_pasangan = '';
					this.state.dt.agama_pasangan = '';
					this.state.dt.pekerjaan_pasangan = '';
					this.state.dt.alamat_pasangan = '';
					this.state.dt.jk_pasangan = '';
					this.state.dt.status_perkawinan_pasangan = '';
					this.state.status.dataPenduduk3 = 'none';
				} else {
					this.state.nikStat = null;
					this.state.dt.nama_pemohon = '';
					this.state.dt.tanggal_lahir = '';
					this.state.dt.tempat_lahir = '';
					this.state.dt.agama = '';
					this.state.dt.pekerjaan = '';
					this.state.dt.alamat = '';
					this.state.dt.jk = '';
					this.state.dt.status_perkawinan = '';
					this.state.status.dataPenduduk = 'none';
				}
			}
			this.state.status.btnForm = true;

			this.forceUpdate();
		}
	};

	dataPasangan = () => {
		if (this.state.nik3Stat != null) {
			this.state.showBio2 = true;
		} else {
			this.state.showBio2 = this.state.showBio2 == false ? true : false;
		}

		this.state.status.dataPenduduk3 = this.state.status.dataPenduduk3 == '' ? 'none' : '';
		this.state.nik3Stat = null;
		this.state.formPasangan = this.state.formPasangan == '' ? 'none' : '';
		this.state.dt.nama_nasab_p2 = '';
		this.state.dt.nama_pasangan = '';
		this.state.dt.jenis_kelamin_pasangan = '';
		this.state.dt.jk_pasangan = '';
		this.state.dt.tempat_lahir_pasangan = '';
		this.state.dt.tanggal_lahir_pasangan = '';
		this.state.dt.pekerjaan_pasangan = '';
		this.state.dt.agama_pasangan = '';
		this.state.dt.nik_pasangan = '';
		this.state.dt.alamat_pasangan = '';
		this.state.dt.status_perkawinan_pasangan = '';
		this.forceUpdate();
	};

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_surat':
				this.setState({ tanggal_surat: date });
				break;
			case 'tanggal_menikah':
				console.log(date.format('YYYY-MM-DD'));
				// this.setState({ tanggal_menikah: date.format('YYYY-MM-DD') });
				if (typeof date._isValid != 'undefined') {
          // this.setState({ tanggal_menikah: date.format("YYYY-MM-DD") });
          this.setState({ tanggal_menikah: new Date(date.format("YYYY-MM-DD")) });
        }
				break;
			case 'tanggal_lahir_pasangan':
				// this.state.dt.tanggal_lahir_pasangan = date.format('YYYY-MM-DD');
				if (typeof date._isValid != 'undefined') {
					// this.state.dt.tanggal_lahir_pasangan = date.format('YYYY-MM-DD');
					this.state.dt.tanggal_lahir_pasangan = new Date(date.format('YYYY-MM-DD'));
					this.forceUpdate();
        }
				break;
		}
	};

	simpan = (e) => {
		e.preventDefault();

		const nikPasangan = document.getElementById("nik_pasangan").value

		console.log(nikPasangan)
		console.log(this.state.status.dataPenduduk)
		console.log(this.state.status.dataPenduduk3)
		console.log(this.state.dt.jk_pasangan)

		if (nikPasangan.trim() == '') {
			this.props.getAntrian('Surat Keterangan Pernah Menikah', 'gagal', true);
		}
		else if (nikPasangan.length != 16) {
			this.props.getAntrian('Surat Keterangan Pernah Menikah', 'cek digit nik');
		}
		else if (this.state.status.dataPenduduk != "none" && this.state.status.dataPenduduk3 != "none" && this.state.dt.jk_pasangan != '' && this.state.dt.agama_pasangan!='' && this.state.dt.pekerjaan_pasangan!='' && this.state.dt.status_perkawinan_pasangan!='') {
			let alamat = '';

			if (JENIS_DESA.toLowerCase() == 'kelurahan') {
				alamat =
					' RT. ' +
					this.props.data.results.data_kk.data_rt.nama +
					'/RW. ' +
					this.props.data.results.data_kk.data_rt.data_rw.nama +
					', ' +
					this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
					', Kec. ' +
					this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
					', ' +
					this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
			} else {
				if (this.props.data.results.data_kk.id_rt == '0') {
					alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
					", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
					", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
					", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
				} else {
					alamat =
					" RT. " + 
					this.props.data.results.data_kk.data_rt.nama +
					"/RW. " +
					this.props.data.results.data_kk.data_rt.data_rw.nama +
					", " + JENIS_DUSUN + " " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
					", " + JENIS_DESA + " " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
					", Kec. " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.nama +
					", " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.data_kabkota.nama; 
				}
			}

			let addSuratKeteranganPernahMenikah = {
				id_jenis: this.props.id_jenis,
				
				nik: this.state.nikStat.value,
				nama_pemohon: this.state.dt.nama_pemohon,
				tempat_lahir: this.state.dt.tempat_lahir,
				tanggal_lahir: dateFormat(this.state.dt.tanggal_lahir, 'yyyy-mm-dd'),
				jenis_kelamin: this.state.dt.jk,
				status_perkawinan: this.state.dt.status_perkawinan,
				pendidikan_terakhir: this.state.dt.pendidikan_terakhir,
				pekerjaan: this.state.dt.pekerjaan,
				agama: this.state.dt.agama,
				alamat: this.state.dt.alamat,

				// nik_pasangan: this.state.dt.nik_pasangan,
				nik_pasangan: nikPasangan,
				nama_pasangan: document.getElementById('nama_pasangan').value,
				tempat_lahir_pasangan: document.getElementById('tempat_lahir_pasangan').value,
				tanggal_lahir_pasangan: dateFormat(this.state.dt.tanggal_lahir_pasangan, 'yyyy-mm-dd'),
				jenis_kelamin_pasangan: this.state.dt.jk_pasangan,
				status_perkawinan_pasangan: this.state.dt.status_perkawinan_pasangan,
				pekerjaan_pasangan: this.state.dt.pekerjaan_pasangan,
				// agama_pasangan: document.getElementById('agama_pasangan').value,
				agama_pasangan: this.state.dt.agama_pasangan,
				alamat_pasangan: document.getElementById('alamat_pasangan').value,

				tanggal_menikah: dateFormat(this.state.tanggal_menikah, 'yyyy-mm-dd'),
				tempat_menikah: document.getElementById('tempat_menikah').value,
				petugas_nikah: document.getElementById('petugas_nikah').value,
				wali_nikah: document.getElementById('wali_nikah').value,
				mas_kawin: document.getElementById('mas_kawin').value,
				saksi_nikah: document.getElementById('saksi_nikah').value,

				nik_pelapor: this.props.data.results.id,
				nama_pelapor: this.props.data.results.nama,
				tempat_lahir_pelapor: this.props.data.results.tempat_lahir,
				tanggal_lahir_pelapor: this.props.data.results.tanggal_lahir,
				jenis_kelamin_pelapor: this.props.data.results.jk,
				status_perkawinan_pelapor: this.props.data.results.status_perkawinan,
				pekerjaan_pelapor: this.props.data.results.data_pekerjaan.nama,
				agama_pelapor: this.props.data.results.data_agama.nama,
				alamat_pelapor: alamat,

				status_ttd: null
			};

			console.log(addSuratKeteranganPernahMenikah);

			let psn = '';
			let resstat = 201;
			let metode = 'create';

			Post('surat-keterangan/pernah-menikah/' + metode, null, addSuratKeteranganPernahMenikah, (res) => {
				// this.tutupForm();
				// this.state.status.btnForm = false;
				// this.state.status.form = false;
				// this.forceUpdate();
				this.onCloseModal('Basic');

				let dataAntrian = {
					nama: this.props.data.results.nama,
					nik: this.props.data.results.id,
					keterangan: '',
					uuid_surat:res.data.results,
				};
	
				if (res.status === resstat) {
					Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
						console.log("Hasil Update Status")
						console.log(res2)
						if (res2.code === 201) {
							console.log("Berhasil Ganti Status Menjadi Selesai")

							this.props.getAntrian('Surat Keterangan Pernah Menikah', 'berhasil', false, dataAntrian);
						}
						else{
							console.log("Gagal Ganti Status Menjadi Selesai")

							this.props.getAntrian('Surat Keterangan Pernah Menikah', 'gagal');
						}
					});
					
					this.state.agmStat = null;
					this.state.pkrStat = null;
					this.state.spkStat = null;
					// this.props.getAntrian('Surat Keterangan Pernah Menikah', 'berhasil', false, dataAntrian);
					this.forceUpdate();
				} else {
					// this.setState({
					// 	show: true,
					// 	basicType: 'danger',
					// 	basicTitle: 'Data Surat Keterangan Pernah Menikah',
					// 	pesanAlert: 'Gagal ' + psn + ' Data'
					// });
					this.props.getAntrian('Surat Keterangan Pernah Menikah', 'gagal');
				}
				this.componentDidMount();
			});
		} else {
			this.props.getAntrian('Surat Keterangan Pernah Menikah', 'gagal', true);
		}

		// LAMA

		// if (this.state.input['cari_nik1']) {
		// 	this.state.dt.nik_pasangan = this.state.input['cari_nik1'];
		// 	this.forceUpdate();
		// }

		// if (this.state.dt.jk_pasangan == '') {
		// 	this.forceUpdate();
		// 	this.props.getAntrian('Surat Keterangan Pernah Menikah', 'gagal', true);
		// } else {
		// 	if (this.state.nikStat && this.state.dt.nik_pasangan != '') {
		// 		if (this.state.dt.nik_pasangan.length != 16) {
		// 			this.forceUpdate();
		// 			// console.log('Panjang NIK adalah 16 digit');
		// 			this.props.getAntrian('Surat Keterangan Pernah Menikah', 'cek digit nik');
		// 		} else {
		// 			// this.state.status.btnForm = true;
		// 			// this.forceUpdate();

		// 			// let tgl_lhr = this.state.dt.tanggal_lahir.split('/');
		// 			// let tanggal_lahir = tgl_lhr[2] + '-' + tgl_lhr[1] + '-' + tgl_lhr[0];
		// 			// let tgl_lhr_ps = this.state.dt.tanggal_lahir_pasangan.split('/');

		// 			let tanggal_lahir_pasangan;
		// 			if (this.state.formPasangan == 'none') {
		// 				tanggal_lahir_pasangan = this.state.dt.tanggal_lahir_pasangan;
		// 			} else {
		// 				tanggal_lahir_pasangan = this.state.dt.tanggal_lahir_pasangan;
		// 			}

		// 			let tgl_lhr_pl = this.state.dt.tanggal_lahir.split('/');
		// 			let tanggal_lahir_pelapor = tgl_lhr_pl[2] + '-' + tgl_lhr_pl[1] + '-' + tgl_lhr_pl[0];

		// 			// let nama_pasangan;
		// 			// let tempat_lahir_pasangan;
		// 			// let pekerjaan_pasangan;
		// 			// let agama_pasangan;
		// 			// let alamat_pasangan;
		// 			// let status_perkawinan_pasangan;

		// 			// if (this.state.formPasangan == 'none') {
		// 			// 	nama_pasangan = this.state.dt.nama_pasangan;
		// 			// 	tempat_lahir_pasangan = this.state.dt.tempat_lahir_pasangan;
		// 			// 	pekerjaan_pasangan = this.state.dt.pekerjaan_pasangan;
		// 			// 	agama_pasangan = this.state.dt.agama_pasangan;
		// 			// 	alamat_pasangan = this.state.dt.alamat_pasangan;
		// 			// 	status_perkawinan_pasangan = this.state.dt.status_perkawinan_pasangan;
		// 			// } else {
		// 			// 	nama_pasangan = this.state.input['nama_pasangan'];
		// 			// 	tempat_lahir_pasangan = this.state.input['tempat_lahir_pasangan'];
		// 			// 	pekerjaan_pasangan = this.state.input['pekerjaan_pasangan'];
		// 			// 	agama_pasangan = this.state.input['agama_pasangan'];
		// 			// 	alamat_pasangan = this.state.input['alamat_pasangan'];
		// 			// 	status_perkawinan_pasangan = this.state.input['status_perkawinan_pasangan'];
		// 			// }

		// 			let alamat = '';
		// 		// if (JENIS_DESA.toLowerCase() == 'kelurahan') {
		// 		// 	alamat =
		// 		// 		' RT. ' +
		// 		// 		this.props.data.results.data_kk.data_rt.nama +
		// 		// 		'/RW. ' +
		// 		// 		this.props.data.results.data_kk.data_rt.data_rw.nama +
		// 		// 		', ' +
		// 		// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
		// 		// 		', Kec. ' +
		// 		// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
		// 		// 		', ' +
		// 		// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
		// 		// } else {
		// 		// 	alamat =
		// 		// 		' RT. ' +
		// 		// 		this.props.data.results.data_kk.data_rt.nama +
		// 		// 		'/RW. ' +
		// 		// 		this.props.data.results.data_kk.data_rt.data_rw.nama +
		// 		// 		', ' +
		// 		// 		JENIS_DUSUN +
		// 		// 		' ' +
		// 		// 		this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
		// 		// 		', ' +
		// 		// 		this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
		// 		// 		', Kec. ' +
		// 		// 		this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
		// 		// 		', ' +
		// 		// 		this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;
		// 		// }
		// 		if (JENIS_DESA.toLowerCase() == 'kelurahan') {
		// 			alamat =
		// 				' RT. ' +
		// 				this.props.data.results.data_kk.data_rt.nama +
		// 				'/RW. ' +
		// 				this.props.data.results.data_kk.data_rt.data_rw.nama +
		// 				', ' +
		// 				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
		// 				', Kec. ' +
		// 				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
		// 				', ' +
		// 				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
		// 		} else {
		// 			// alamat =
		// 			// 	' RT. ' +
		// 			// 	this.props.data.results.data_kk.data_rt.nama +
		// 			// 	'/RW. ' +
		// 			// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
		// 			// 	', ' +
		// 			// 	JENIS_DUSUN +
		// 			// 	' ' +
		// 			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
		// 			// 	', ' +
		// 			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
		// 			// 	', Kec. ' +
		// 			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
		// 			// 	', ' +
		// 			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;
		
		// 				if (this.props.data.results.data_kk.id_rt == '0') {
		// 					alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
		// 					", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
		// 					", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
		// 					", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
		// 				} else {
		// 					alamat =
		// 					" RT. " + 
		// 					this.props.data.results.data_kk.data_rt.nama +
		// 					"/RW. " +
		// 					this.props.data.results.data_kk.data_rt.data_rw.nama +
		// 					", " + JENIS_DUSUN + " " +
		// 					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
		// 					", " + JENIS_DESA + " " +
		// 					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
		// 					", Kec. " +
		// 					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
		// 						.data_kecamatan.nama +
		// 					", " +
		// 					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
		// 						.data_kecamatan.data_kabkota.nama; 
		// 				}
		// 		}

		// 			let addSuratKeteranganPernahMenikah = {
		// 				// uuid: document.getElementById('uuid').value,
		// 				id_jenis: this.props.id_jenis,
		// 				// nomor : document.getElementById("nomor").value,

		// 				// nik: this.props.data.results.id,
		// 				// nama_pemohon: this.props.data.results.nama,
		// 				// tempat_lahir: this.props.data.results.tempat_lahir,
		// 				// tanggal_lahir: this.props.data.results.tanggal_lahir,
		// 				// pendidikan_terakhir: this.props.data.results.pendidikan_terakhir,
		// 				// pekerjaan: this.props.data.results.pekerjaan,
		// 				// agama: this.props.data.results.agama,
		// 				// status_perkawinan: this.props.data.results.status_perkawinan,
		// 				// alamat: this.props.data.results.alamat,
		// 				// jenis_kelamin: this.props.data.results.jk,
		// 				nik: this.state.nikStat.value,
		// 				nama_pemohon: this.state.dt.nama_pemohon,
		// 				tempat_lahir: this.state.dt.tempat_lahir,
		// 				tanggal_lahir: tanggal_lahir_pelapor,
		// 				jenis_kelamin: this.state.dt.jk,
		// 				status_perkawinan: this.state.dt.status_perkawinan,
		// 				pendidikan_terakhir: this.state.dt.pendidikan_terakhir,
		// 				pekerjaan: this.state.dt.pekerjaan,
		// 				agama: this.state.dt.agama,
		// 				alamat: this.state.dt.alamat,

		// 				nik_pasangan: this.state.dt.nik_pasangan,
		// 				// nama_pasangan: this.state.dt.nama_pasangan,
		// 				// nama_pasangan: nama_pasangan,
		// 				nama_pasangan: document.getElementById('nama_pasangan').value,
		// 				// nama_pasangan: this.state.input['nama_pasangan'],
		// 				// tempat_lahir_pasangan: this.state.dt.tempat_lahir_pasangan,
		// 				// tempat_lahir_pasangan: tempat_lahir_pasangan,
		// 				tempat_lahir_pasangan: document.getElementById('tempat_lahir_pasangan').value,
		// 				// tempat_lahir_pasangan: this.state.input['tempat_lahir_pasangan'],
		// 				// tanggal_lahir_pasangan: tanggal_lahir_pasangan,
		// 				tanggal_lahir_pasangan: dateFormat(this.state.dt.tanggal_lahir_pasangan, 'yyyy-mm-dd'),
		// 				jenis_kelamin_pasangan: this.state.dt.jk_pasangan,
		// 				// status_perkawinan_pasangan: this.state.dt.status_perkawinan_pasangan,
		// 				// status_perkawinan_pasangan: status_perkawinan_pasangan,
		// 				status_perkawinan_pasangan: document.getElementById('status_perkawinan_pasangan').value,
		// 				// status_perkawinan_pasangan: this.state.input['status_perkawinan_pasangan'],
		// 				// pekerjaan_pasangan: this.state.dt.pekerjaan_pasangan,
		// 				pekerjaan_pasangan: document.getElementById('pekerjaan_pasangan').value,
		// 				// pekerjaan_pasangan: this.state.input['pekerjaan_pasangan'],
		// 				// agama_pasangan: this.state.dt.agama_pasangan,
		// 				agama_pasangan: document.getElementById('agama_pasangan').value,
		// 				// agama_pasangan: this.state.input['agama_pasangan'],
		// 				// alamat_pasangan: this.state.dt.alamat_pasangan,
		// 				alamat_pasangan: document.getElementById('alamat_pasangan').value,
		// 				// alamat_pasangan: this.state.input['alamat_pasangan'],

		// 				tanggal_menikah: this.state.tanggal_menikah,
		// 				tempat_menikah: document.getElementById('tempat_menikah').value,
		// 				petugas_nikah: document.getElementById('petugas_nikah').value,
		// 				wali_nikah: document.getElementById('wali_nikah').value,
		// 				mas_kawin: document.getElementById('mas_kawin').value,
		// 				saksi_nikah: document.getElementById('saksi_nikah').value,

		// 				nik_pelapor: this.props.data.results.id,
		// 				nama_pelapor: this.props.data.results.nama,
		// 				tempat_lahir_pelapor: this.props.data.results.tempat_lahir,
		// 				tanggal_lahir_pelapor: this.props.data.results.tanggal_lahir,
		// 				// pendidikan_terakhir_pelapor: this.props.data.results.pendidikan_terakhir,
		// 				jenis_kelamin_pelapor: this.props.data.results.jk,
		// 				status_perkawinan_pelapor: this.props.data.results.status_perkawinan,
		// 				pekerjaan_pelapor: this.props.data.results.data_pekerjaan.nama,
		// 				agama_pelapor: this.props.data.results.data_agama.nama,
		// 				alamat_pelapor: alamat,
		// 				// hubungan_pelapor : document.getElementById("hubungan_pelapor").value,

		// 				status_ttd: null
		// 			};

		// 			// console.log(addSuratKeteranganPernahMenikah);

		// 			let psn = '';
		// 			let resstat = 201;
		// 			let metode = 'create';

		// 			Post('surat-keterangan/pernah-menikah/' + metode, null, addSuratKeteranganPernahMenikah, (res) => {
		// 				// this.tutupForm();
		// 				// this.state.status.btnForm = false;
		// 				// this.state.status.form = false;
		// 				// this.forceUpdate();
		// 				this.onCloseModal('Basic');

		// 				let dataAntrian = {
		// 					nama: this.props.data.results.nama,
		// 					nik: this.props.data.results.id,
		// 					keterangan: ''
		// 				};

		// 				if (res.status === resstat) {
		// 					// this.setState({
		// 					// 	show: true,
		// 					// 	basicType: 'success',
		// 					// 	basicTitle: 'Data Surat Keterangan Pernah Menikah',
		// 					// 	pesanAlert: 'Berhasil ' + psn + ' Data',
		// 					// 	successAlert: true
		// 					// });
		// 					this.props.getAntrian('Surat Keterangan Pernah Menikah', 'berhasil', false, dataAntrian);
		// 				} else {
		// 					// this.setState({
		// 					// 	show: true,
		// 					// 	basicType: 'danger',
		// 					// 	basicTitle: 'Data Surat Keterangan Pernah Menikah',
		// 					// 	pesanAlert: 'Gagal ' + psn + ' Data'
		// 					// });
		// 					this.props.getAntrian('Surat Keterangan Pernah Menikah', 'gagal');
		// 				}
		// 				this.componentDidMount();
		// 			});
		// 		}
		// 	} else {
		// 		this.forceUpdate();
		// 		this.props.getAntrian('Surat Keterangan Pernah Menikah', 'gagal', true);
		// 		// this.props.isComplete(false);
		// 	}
		// }
	};

	componentDidMount() {
		if (this.props.data.results.data_kk == null) {
			let frmNik = [];

			this.props.data.results.data_kk_pend_temp.data_keluarga.map((dt) => {
				frmNik.push({ value: dt.id, label: dt.id });
			});
			this.setState({ frmNik });
		} else {
			Get('penduduk/kk/' + this.props.data.results.id_kk, null, (data) => {
				// console.log(data);
				let frmNik = [];
				// let frmNik3 = [];
				if(data.results){
					data.results.map((dt) => {
						frmNik.push({ value: dt.id, label: dt.id });
						// frmNik3.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmNik });
				}
			});
		}
		Get('penduduk', null, (data) => {
			// let frmNik = [];
			let frmNik3 = [];
			if(data.results){
				data.results.data.map((dt) => {
					// frmNik.push({ value: dt.id, label: dt.id });
					frmNik3.push({ value: dt.id, label: dt.id });
				});
				this.setState({ frmNik3 });
			}
		});

		// Get list agama
		Get('agama/list', null, (data) => {
			let frmAgm = [];
			if(data.results){
				data.results.map((dt) => {
					frmAgm.push({ value: dt.uuid, label: dt.nama });
				});
				this.setState({ frmAgm });
			}
		});

		// Get List Pekerjaan
		Get('pekerjaan/list', null, (data) => {
			let frmPkr = [];
			if(data.results){
				data.results.map((dt) => {
					frmPkr.push({ value: dt.uuid, label: dt.nama });
				});
				this.setState({ frmPkr });
			}
		});

		// Get List Status Perkawinan
		Get('select-enum/penduduk/status_perkawinan', null, (data) => {
			let frmSpk = [];
			if(data.results){
				data.map((dt) => {
					frmSpk.push({ value: dt, label: dt });
				});
				this.setState({ frmSpk });
			}
		});
	}

	// Cari NIK
	handleChange = (e, cek) => {
		console.log('handle');
		switch (cek) {
			case 'cari_nik1':
				this.setState({
					input: e.target.value
				});
				this.forceUpdate();
				break;
		}
	};

	cariNIK = (e, sel) => {
		e.preventDefault();

		let id_cari = '';
		if (sel == 'cari_nik1') {
			// id_cari = '1111222200000002';
			id_cari = this.state.dt.nik_pasangan;
			// id_cari = this.state.nik3Stat;
			// console.log('id cari', id_cari);
		}

		if (id_cari.length != 16) {
			this.forceUpdate();
			// console.log('Panjang NIK adalah 16 digit');
			this.props.getAntrian('Surat Keterangan Pernah Menikah', 'cek digit nik');
		} else {
			// console.log('Mantap bos');

			Get('penduduk/find', id_cari, (data) => {
				if (data.results) {
					// console.log('Berhasil Tampilkan Penduduk');
					// console.log(data.results);
					this.state.status.dataPenduduk3 = 'block';

					const data_rt = data.results.data_kk.data_rt;
					const data_rw = data_rt.data_rw;
					const data_dusun = data_rw.data_dusun;
					const data_desa = data_dusun.data_keldes;
					const data_kecamatan = data_desa.data_kecamatan;
					const data_kabkota = data_kecamatan.data_kabkota;
					let alamat =
						' RT. ' +
						data.results.data_kk.data_rt.nama +
						'/RW. ' +
						data.results.data_kk.data_rt.data_rw.nama +
						', Dusun ' +
						data.results.data_kk.data_rt.data_rw.data_dusun.nama +
						', ' +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
						', Kec. ' +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
						', ' +
						data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

					this.state.tanggal_lahir_pasangan = data.results.tanggal_lahir;
					this.state.dt.nama_pasangan = data.results.nama;
					this.state.dt.nik_pasangan = data.results.id;
					// this.state.dt.tanggal_lahir_pasangan = data.results.tanggal_lahir;
					this.state.dt.tanggal_lahir_pasangan = new Date(data.results.tanggal_lahir);
					this.state.dt.tempat_lahir_pasangan = data.results.tempat_lahir;
					this.state.dt.jk_pasangan = data.results.jk;
					this.state.dt.status_perkawinan_pasangan = data.results.status_perkawinan;
					this.state.spkStat = { value: data.results.status_perkawinan, label: data.results.status_perkawinan };
					this.state.dt.agama_pasangan = data.results.data_agama.nama;
					this.state.agmStat = { value: data.results.data_agama.uuid, label: data.results.data_agama.nama };
					this.state.dt.pekerjaan_pasangan = data.results.data_pekerjaan.nama;
					this.state.pkrStat = { value: data.results.data_pekerjaan.uuid, label: data.results.data_pekerjaan.nama };
					this.state.dt.alamat_pasangan = alamat;

					this.forceUpdate();
				} else {
					this.state.nik3Stat = null;
					this.state.dt.nik_pasangan = '';
					this.state.dt.nama_pasangan = '';
					this.state.dt.tanggal_lahir_pasangan = '';
					this.state.dt.tempat_lahir_pasangan = '';
					this.state.dt.agama_pasangan = '';
					this.state.dt.pekerjaan_pasangan = '';
					this.state.dt.alamat_pasangan = '';
					this.state.dt.jk_pasangan = '';
					this.state.dt.status_perkawinan_pasangan = '';
					this.state.status.dataPenduduk3 = 'none';

					this.forceUpdate();
					this.props.getAntrian('Surat Keterangan Pernah Menikah', 'cari nik');
				}
			});
		}
	};

	ubahJk = (e) => {
		this.state.dt.jk_pasangan = document.getElementById('jk_pasangan').value;
		this.forceUpdate();
	};

	// ====================keyboard=============================

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}

		this.forceUpdate();
	};

	onChange = (input) => {
		this.setState({ input });
		console.log('Input changed', input);
	};

	onKeyPress = (button) => {
		console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	onChangeInput = (event) => {
		console.log('===onchange===');
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);
	};

	onInputChanged = (data) => {
		this.setState({ input: data });
	};

	onInputSubmitted = (data) => {
		console.log('Input submitted:', data);
	};

	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		console.log('Input changed', inputObj);
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				console.log('Active input', inputName);
			}
		);
	};

	//====================end keyboard========================

	render() {
		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const { Basic } = this.state;

		return (
			<div>
				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div
							// style={{ marginBottom: this.state.display == '' ? '200px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								// console.log(e);
								this.state.isDown = true;
								this.state.startY = e.pageY - slider.offsetTop;
								this.state.scrollUp = slider.scrollTop;
								this.forceUpdate();
								// console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title" align="center">
									Surat Keterangan Pernah Menikah
								</h5>
							</div>

							<div className="modal-body">
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK Pelapor</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											placeholder="NIK"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Pelapor</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pemohon"
											disabled
											type="text"
											placeholder=" Nama Lengkap"
											value={this.props.data.results.nama}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										NIK yang Menikah
									</Label>
									<Col sm="9">
										<Select
											classNamePrefix="select"
											onChange={(e) => {
												this.getPenduduk(e, 'nik', 'pemohon');
											}}
											value={this.state.nikStat}
											name="nik_menikah"
											options={this.state.frmNik}
											placeholder="Pilih NIK"
											onInputChange={(e) => this.handleInputChangeNIK(e, 'nik')}
											isClearable
										/>
									</Col>
								</FormGroup>
								<div style={{ display: this.state.status.dataPenduduk }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama yang Menikah
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="nama"
												type="text"
												placeholder="Nama Pemohon"
												value={this.state.dt.nama_pemohon}
												readOnly
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tempat Lahir
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="tempat_lahir"
												type="text"
												placeholder="Tempat Lahir"
												value={this.state.dt.tempat_lahir}
												readOnly
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tanggal Lahir
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="tanggal_lahir"
												type="text"
												placeholder="Tanggal Lahir"
												// value={this.state.dt.tanggal_lahir}
												value={dateFormat(this.state.dt.tanggal_lahir, 'dd/mm/yyyy')}
												readOnly
											/>
										</Col>
									</FormGroup>
								</div>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										NIK Pasangan
									</Label>

									{this.state.formPasangan == 'none' ? (
										<>
											<div className="col-sm-7">
												<input
													id="nik_pasangan"
													name="nik3Stat"
													// onChange={(e) => this.onChangeInput(e)}
													style={{ marginBottom: '10px' }}
													className="form-control"
													placeholder="Masukkan NIK Pasangan"
													minLength="16"
													type="number"
													required
													// onFocus={(e) => {
													// 	this.setState({ display: '' });
													// 	console.log(e.target);
													// 	this.setActiveInput('cari_nik1');
													// }}
													// value={this.state.input['cari_nik1'] || ''}
													onChange={(e) => this.changeInputText(e, 'nik_pasangan')}

													// onFocus={() => this.setActiveInput('nik_pasangan')}
													// value={this.state.input['nik_pasangan'] || ''}
													// onChange={(e) => this.onChangeInput(e, 'cari_nik1')}
												/>
											</div>
											<div className="col-sm-2" style={{ textAlign: 'center' }}>
												<button
													type="button"
													className="btn btn-warning"
													onClick={(e) => this.cariNIK(e, 'cari_nik1')}
												>
													Cari
												</button>
											</div>
										</>
									) : (
										// <Select
										// 	classNamePrefix="select"
										// 	onChange={(e) => {
										// 		this.getPenduduk(e, 'nik3', 'pasangan');
										// 	}}
										// 	// defaultValue={this.state.nik3Stat}
										// 	value={this.state.nik3Stat}
										// 	name="NIK3"
										// 	options={this.state.frmNik3}
										// 	placeholder="Pilih NIK Pasangan"
										// 	onInputChange={(e) => this.handleInputChangeNIK(e, 'nik3')}
										// 	isClearable
										// />
										<Col sm="9">
											<input
												className="form-control"
												id="nik_pasangan"
												type={this.state.formPasangan == 'none' ? 'hidden' : 'number'}
												required
												placeholder="NIK Pasangan"
												defaultValue={this.state.dt.nik_pasangan}
												value={this.state.dt.nik_pasangan}
												onChange={(e) => this.changeInputText(e, 'nik_pasangan')}
												readOnly={this.state.formPasangan == '' ? false : true}
												minLength="16"
												type="number"
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	console.log(e.target);
												// 	this.setActiveInput('cari_nik1');
												// }}
												// value={this.state.input['cari_nik1'] || ''}
												// onChange={(e) => this.onChangeInput(e)}
												// onChange={(e) => this.handleChange(e)}
											/>
										</Col>
									)}

									<Col sm="3"> &nbsp; </Col>
									<Col sm="6" align="left">
										<small id="emailHelp" className="form-text text-muted">
											Data NIK {this.state.formPasangan == 'none' ? 'tidak' : ''} tersedia?{' '}
											<Link
												to={{
													pathname: this.props.myroute,
													state: this.props.data
												}}
												onClick={this.dataPasangan}
											>
												{' '}
												Klik disini
											</Link>
										</small>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.status.dataPenduduk3 }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama Pasangan
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="nama_pasangan"
												type="text"
												placeholder="Nama Pasangan"
												required
												readOnly={this.state.formPasangan == '' ? false : true}
												value={
													this.state.formPasangan == '' ? (
														// this.state.input['nama_pasangan'] || ''
														this.state.dt.nama_pasangan
													) : (
														this.state.dt.nama_pasangan
													)
												}
												onChange={(e) => this.changeInputText(e, 'nama_pasangan')}
												
												
												// defaultValue={this.state.dt.nama_pasangan}

												// onChange={(e) => this.changeInputText(e, 'nama_pasangan')}
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	console.log(e.target);
												// 	this.setActiveInput('nama_pasangan');
												// }}
												// onChange={(e) => this.onChangeInput(e)}
												// onChange={(e) => this.handleChange(e)}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Jenis Kelamin
										</Label>
										<Col sm="9">
											{this.state.formPasangan == 'none' ? (
												<input
													className="form-control"
													id="jenis_kelamin_pasangan"
													type="text"
													readOnly={this.state.formPasangan == '' ? false : true}
													placeholder="Jenis Kelamin Pasangan"
													value={this.state.dt.jk_pasangan}
													// defaultValue={this.state.dt.jenis_kelamin_pasangan}
													onChange={(e) => this.changeInputText(e, 'jenis_kelamin_pasangan')}
												/>
											) : (
												<select
													className="form-control"
													id="jk_pasangan"
													placeholder="jk_pasangan"
													defaultValue={this.state.dt.jk_pasangan}
													onChange={this.ubahJk}
												>
													<option value="">Pilih Jenis Kelamin</option>
													<option value="Laki-Laki">Laki-Laki</option>
													<option value="Perempuan">Perempuan</option>
												</select>
											)}
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tempat Lahir Pasangan
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="tempat_lahir_pasangan"
												type=""
												placeholder="Tempat Lahir"
												required
												readOnly={this.state.formPasangan == '' ? false : true}
												value={
													this.state.formPasangan == '' ? (
														// this.state.input['tempat_lahir_pasangan'] || ''
														this.state.dt.tempat_lahir_pasangan
													) : (
														this.state.dt.tempat_lahir_pasangan
													)
												}
												onChange={(e) => this.changeInputText(e, 'tempat_lahir_pasangan')}


												// defaultValue={this.state.dt.tempat_lahir_pasangan}
												// value={this.state.dt.tempat_lahir_pasangan}
												// onChange={(e) => this.changeInputText(e, 'tempat_lahir_pasangan')}
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	console.log(e.target);
												// 	this.setActiveInput('tempat_lahir_pasangan');
												// }}
												// onChange={(e) => this.onChangeInput(e)}
												// onChange={(e) => this.handleChange(e)}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tanggal Lahir Pasangan
										</Label>
										<Col sm="9">
											{this.state.formPasangan == 'none' ? (
												<input
													className="form-control"
													id="tanggal_lahir_pasangan"
													type="text"
													readOnly
													required
													placeholder="Tanggal Lahir"
													value={dateFormat(this.state.dt.tanggal_lahir_pasangan, 'dd/mm/yyyy') }
												/>
											) : (
												<Datetime
													locale="id-ID"
													dateFormat="DD/MM/yyyy"
													closeOnSelect={true}
													input={true}
													inputProps={{
														required: true,
														placeholder: 'Tanggal Lahir Pasangan'
													}}
													closeOnTab={true}
													timeFormat={false}
													initialValue={this.state.dt.tanggal_lahir_pasangan}
													onChange={(e) => {
														this.gantiTgl(e, 'tanggal_lahir_pasangan');
														// this.setState({ tanggal_lahirkk:})
														// console.log(e.format('YYYY-MM-DD'))
													}}
												/>
											)}
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Pekerjaan Pasangan
										</Label>
										<Col sm="9">
											{this.state.formPasangan == 'none'
												?
													<input
														className="form-control"
														id="pekerjaan_pasangan"
														type="text"
														placeholder="Pekerjaan"
														required
														value={this.state.dt.pekerjaan_pasangan}
														readOnly
													/>
												:
													<Select
														isDisabled={this.state.status.select}
														classNamePrefix="select"
														onChange={(e) => {
															this.changeSelectValue(e, 'pekerjaan_pasangan');
														}}
														defaultValue={this.state.pkrStat}
														value={this.state.pkrStat}
														name="Pekerjaan"
														options={this.state.frmPkr}
														placeholder="Pilih Pekerjaan"
														isClearable
													/>
											}
											{/* <input
												className="form-control"
												id="pekerjaan_pasangan"
												type="text"
												placeholder="Pekerjaan"
												required
												// value={this.state.dt.pekerjaan_pasangan}
												// defaultValue={this.state.dt.pekerjaan_pasangan}
												onChange={(e) => this.changeInputText(e, 'pekerjaan_pasangan')}
												readOnly={this.state.formPasangan == '' ? false : true}
												onFocus={(e) => {
													this.setState({ display: '' });
													console.log(e.target);
													this.setActiveInput('pekerjaan_pasangan');
												}}
												value={
													this.state.formPasangan == '' ? (
														this.state.input['pekerjaan_pasangan'] || ''
													) : (
														this.state.dt.pekerjaan_pasangan
													)
												}
												onChange={(e) => this.onChangeInput(e)}
												// onChange={(e) => this.handleChange(e)}
											/> */}
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Agama Pasangan
										</Label>
										<Col sm="9">
										{this.state.formPasangan == 'none'
											?
												<input
													className="form-control"
													id="agama_pasangan"
													type="text"
													placeholder="Agama"
													value={this.state.dt.agama_pasangan}
													required
													readOnly
												/>
											:
												<Select
													isDisabled={this.state.status.select}
													classNamePrefix="select"
													onChange={(e) => {
														this.changeSelectValue(e, 'agama_pasangan');
													}}
													defaultValue={this.state.agmStat}
													value={this.state.agmStat}
													name="Agama"
													options={this.state.frmAgm}
													placeholder="Pilih Agama"
													isClearable
												/>
												
										}
											{/* <input
												className="form-control"
												id="agama_pasangan"
												type="text"
												placeholder="Agama"
												value={this.state.dt.agama_pasangan}
												required
												// defaultValue={this.state.dt.agama_pasangan}
												onChange={(e) => this.changeInputText(e, 'agama_pasangan')}
												readOnly={this.state.formPasangan == '' ? false : true}
												onFocus={(e) => {
													this.setState({ display: '' });
													console.log(e.target);
													this.setActiveInput('agama_pasangan');
												}}
												value={
													this.state.formPasangan == '' ? (
														this.state.input['agama_pasangan'] || ''
													) : (
														this.state.dt.agama_pasangan
													)
												}
												onChange={(e) => this.onChangeInput(e)}
											/> */}
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Alamat Pasangan
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="alamat_pasangan"
												type="text"
												placeholder="Alamat"
												required
												readOnly={this.state.formPasangan == '' ? false : true}
												// defaultValue={this.state.dt.alamat_pasangan}
												// value={this.state.dt.alamat_pasangan}
												// onChange={(e) => this.changeInputText(e, 'alamat_pasangan')}
												// onFocus={(e) => {
												// 	this.setState({ display: '' });
												// 	console.log(e.target);
												// 	this.setActiveInput('alamat_pasangan');
												// }}
												value={
													this.state.formPasangan == '' ? (
														this.state.dt.alamat_pasangan
														// this.state.input['alamat_pasangan'] || ''
													) : (
														this.state.dt.alamat_pasangan
													)
												}
												onChange={(e) => this.changeInputText(e, 'alamat_pasangan')}
												// onChange={(e) => this.handleChange(e)}
											/>
											{/* <textarea className="form-control" id="alamat" placeholder="Alamat" value={this.state.dt.alamat}></textarea> */}
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Status Perkawinan Pasangan
										</Label>
										<Col sm="9">
											{this.state.formPasangan == 'none'
												?
													<input
														className="form-control"
														id="status_perkawinan_pasangan"
														type="text"
														placeholder="Status Perkawinan Pasangan"
														required
														value={this.state.dt.status_perkawinan_pasangan}
														readOnly
													/>
												:
													<Select
														isDisabled={this.state.status.select}
														classNamePrefix="select"
														onChange={(e) => {
															this.changeSelectValue(e, 'status_perkawinan_pasangan');
														}}
														defaultValue={this.state.spkStat}
														value={this.state.spkStat}
														name="Status Perkawinan"
														options={this.state.frmSpk}
														placeholder="Pilih Status Perkawinan"
														isClearable
													/>
											}

											{/* <input
												className="form-control"
												id="status_perkawinan_pasangan"
												type="text"
												placeholder="Status Perkawinan Pasangan"
												required
												// defaultValue={this.state.dt.status_perkawinan_pasangan}
												// value={this.state.dt.status_perkawinan_pasangan}
												onChange={(e) => this.changeInputText(e, 'status_perkawinan_pasangan')}
												readOnly={this.state.formPasangan == '' ? false : true}
												onFocus={(e) => {
													this.setState({ display: '' });
													console.log(e.target);
													this.setActiveInput('status_perkawinan_pasangan');
												}}
												value={
													this.state.formPasangan == '' ? (
														this.state.input['status_perkawinan_pasangan'] || ''
													) : (
														this.state.dt.status_perkawinan_pasangan
													)
												}
												onChange={(e) => this.onChangeInput(e)}
												// onChange={(e) => this.handleChange(e)}
											/> */}
										</Col>
									</FormGroup>
								</div>

								{/* <div style={{ display: this.state.formPasangan }}> */}

								{/* </div> */}

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tanggal Menikah
									</Label>
									<Col sm="9">
										<Datetime
											locale="id-ID"
											dateFormat="DD/MM/yyyy"
											closeOnSelect={true}
											input={true}
											inputProps={{ required: true, placeholder: 'Tanggal Menikah' }}
											closeOnTab={true}
											timeFormat={false}
											initialValue={this.state.tanggal_menikah}
											value={this.state.tanggal_menikah}
											onChange={(e) => {
												this.gantiTgl(e, 'tanggal_menikah');
												// this.setState({ tanggal_lahirkk:})
												// console.log(e.format('YYYY-MM-DD'))
											}}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tempat Menikah
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tempat_menikah"
											type="text"
											placeholder="Tempat Menikah"
											required
											// defaultValue={this.state.dt.tempat_menikah}
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	console.log(e.target);
											// 	this.setActiveInput('tempat_menikah');
											// }}
											// value={this.state.input['tempat_menikah'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Petugas Nikah
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="petugas_nikah"
											type="text"
											placeholder="Petugas Menikah"
											required
											// defaultValue={this.state.dt.petugas_nikah}
											// onClick={() => this.showKeyboard()}
											// onFocus={() => this.setActiveInput('petugas_nikah')}
											// value={this.state.input['petugas_nikah'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Wali Nikah
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="wali_nikah"
											type="text"
											placeholder="Wali Menikah"
											required
											// defaultValue={this.state.dt.wali_nikah}
											// onClick={() => this.showKeyboard()}
											// onFocus={() => this.setActiveInput('wali_nikah')}
											// value={this.state.input['wali_nikah'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Mas Kawin
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="mas_kawin"
											type="text"
											placeholder="Mas Kawin"
											required
											// defaultValue={this.state.dt.mas_kawin}
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	console.log(e.target);
											// 	this.setActiveInput('mas_kawin');
											// }}
											// value={this.state.input['mas_kawin'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Saksi Nikah
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="saksi_nikah"
											type="text"
											placeholder="Saksi Nikah"
											required
											// defaultValue={this.state.dt.saksi_nikah}
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	console.log(e.target);
											// 	this.setActiveInput('saksi_nikah');
											// }}
											// value={this.state.input['saksi_nikah'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
							</div>

							<div
								className="modal-footer"
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-primary">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>

					{/* {this.state.display == '' ? (
						<div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 4 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
						''
					)} */}
				</Modal>
				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/pernah-menikah.png')}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p
								className="mb-0"
								style={{
									fontSize: '20px',
									fontWeight: '600',
									color: '#000000'
								}}
							>
								Pernah Menikah
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
