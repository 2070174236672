import React, { useContext } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	ButtonGroup,
	CustomInput,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';
import Datepicker from 'react-datepicker';
import Select from 'react-select';
import { Tanggal, Hari, HariTanggal, HariTanggalJam } from '../../function/Format';
import Datetime from 'react-datetime';

function EditAhliWaris({ detail_surat_context }) {
	const detailSuratProps = useContext(detail_surat_context);

	let no = 0;
	const ahliwaris = detailSuratProps.detailSurat.detail.map((dt, i) => {
		no++;
		return (
			<tr key={i}>
				<td>{no}</td>
				<td>{dt.nama}</td>
				<td>
					{dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
				</td>
				<td>{dt.pekerjaan}</td>
				<td>{dt.shdk}</td>
				<td>
					<Button size="xs" onClick={() => detailSuratProps.hapusAw(i)} color="danger" className="btn-icon">
						<i className="fa fa-trash" />
					</Button>
				</td>
			</tr>
		);
	});

	return (
		<div>
			<Modal
				open={detailSuratProps.status.formUpdateSurat}
				onClose={detailSuratProps.tutupForm}
				closeOnEsc={false}
				closeOnOverlayClick={false}
			>
				<Form
					className="theme-form"
					onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}
				>
					<div className="modal-header">
						<h5 className="modal-title">Ubah {detailSuratProps.detailSurat.jenis_surat}</h5>
					</div>
					<div className="modal-body">
						<p>
							Data identitas akan terisi secara otomatis saat NIK dipilih. Jika ada ketidak sesuaian data,
							silakan ubah di data master penduduk.
						</p>
						<input
							className="form-control"
							id="uuid"
							type="hidden"
							placeholder=""
							defaultValue={detailSuratProps.detailSurat.uuid}
						/>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								NIK
							</Label>
							<Col sm="9">
								<Select
									classNamePrefix="select"
									onChange={(e) => {
										detailSuratProps.getPenduduk(
											e,
											detailSuratProps.detailSurat.jenis_surat,
											'nik'
										);
									}}
									defaultValue={detailSuratProps.dataNikStat.nikStat}
									value={detailSuratProps.dataNikStat.nikStat}
									name="NIK"
									options={detailSuratProps.dataNik.frmNik}
									placeholder="Pilih NIK"
									onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik')}
									isClearable
								/>
							</Col>
						</FormGroup>
						<div style={{ display: detailSuratProps.status.dataPenduduk }}>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Pemohon
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama Pemohon"
										value={detailSuratProps.detailSurat.nama}
										readOnly
										style={{ backgroundColor: "#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tempat Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tempat_lahir"
										type="text"
										placeholder="Tempat Lahir"
										value={detailSuratProps.detailSurat.tempat_lahir}
										readOnly
										style={{ backgroundColor: "#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tanggal Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tanggal_lahir"
										type="text"
										placeholder="Tanggal Lahir"
										value={detailSuratProps.detailSurat.tanggal_lahir}
										readOnly
										style={{ backgroundColor: "#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
						</div>
						<div style={{ display: 'none' }}>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Jenis Kelamin
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="jenis_kelamin"
										type="text"
										placeholder="Jenis Kelamin"
										value={detailSuratProps.detailSurat.jenis_kelamin}
										readOnly
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Pekerjaan
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="pekerjaan"
										type="text"
										placeholder="Pekerjaan"
										value={detailSuratProps.detailSurat.pekerjaan}
										readOnly
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Pekerjaan
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="kewarganegaraan"
										type="text"
										placeholder="Pekerjaan"
										value={detailSuratProps.detailSurat.kewarganegaraan}
										readOnly
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Agama
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="agama"
										type="text"
										placeholder="Agama"
										value={detailSuratProps.detailSurat.agama}
										readOnly
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Alamat
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="alamat"
										type="text"
										placeholder="Alamat"
										value={detailSuratProps.detailSurat.alamat}
										readOnly
									/>
								</Col>
							</FormGroup>
						</div>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Waktu Meninggal
							</Label>
							<Col sm="9">
								{/* <Datepicker
                  className="form-control"
                  selected={detailSuratProps.detailSurat.waktu_meninggal}
                  onChange={e => detailSuratProps.gantiTgl(e, "waktu_meninggal")}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={1}
                  timeCaption="waktu"
                  dateFormat="dd MMMM yyyy h:mm aa"
                /> */}
								<Datetime
									locale="id-ID"
									timeFormat="HH:mm"
									dateFormat="DD/MM/yyyy"
									input={true}
									closeOnTab={true}
									initialValue={new Date(detailSuratProps.detailSurat.waktu_meninggal)}
									onChange={(e) => detailSuratProps.gantiTgl(e, 'waktu_meninggal')}
									inputProps={{
										required: true,
										placeholder: 'Waktu Meninggal'
									}}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Tempat Meninggal
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tempat_meninggal"
									type="text"
									placeholder="Tempat Meninggal"
									defaultValue={detailSuratProps.detailSurat.tempat_meninggal}
									required
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Sebab Meninggal
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="sebab_meninggal"
									type="text"
									placeholder="Sebab Meninggal"
									defaultValue={detailSuratProps.detailSurat.sebab_meninggal}
									required
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Col sm="12">
								<a
									href="#"
									onClick={detailSuratProps.tambahMeninggal}
									className={
										detailSuratProps.detailSurat.meninggal2 == 'none' ? (
											'btn btn-sm btn-success'
										) : (
											'btn btn-sm btn-danger'
										)
									}
								>
									{detailSuratProps.detailSurat.meninggal2 == 'none' ? (
										'Tambah Data Meninggal'
									) : (
										'Hapus Data Meninggal'
									)}
								</a>
							</Col>
						</FormGroup>
						<div style={{ display: detailSuratProps.detailSurat.meninggal2 }}>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									NIK
								</Label>
								<Col sm="9">
									<Select
										classNamePrefix="select"
										onChange={(e) => {
											detailSuratProps.getPenduduk(
												e,
												detailSuratProps.detailSurat.jenis_surat,
												'nik2'
											);
										}}
										defaultValue={detailSuratProps.dataNikStat.nik2Stat}
										value={detailSuratProps.dataNikStat.nik2Stat}
										name="NIK2"
										options={detailSuratProps.dataNik.frmNik2}
										placeholder="Pilih NIK"
										onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik2')}
										isClearable
									/>
								</Col>
							</FormGroup>
							<div style={{ display: detailSuratProps.dataNikStat.nik2Stat == null ? "none" : '' }}>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Pemohon
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama2"
											type="text"
											placeholder="Nama Pemohon"
											value={detailSuratProps.detailSurat.nama2}
											readOnly
											style={{ backgroundColor: "#c9d1d8" }}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tempat_lahir2"
											type="text"
											placeholder="Tempat Lahir"
											value={detailSuratProps.detailSurat.tempat_lahir2}
											readOnly
											style={{ backgroundColor: "#c9d1d8" }}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tanggal_lahir2"
											type="text"
											placeholder="Tanggal Lahir"
											value={detailSuratProps.detailSurat.tanggal_lahir2}
											readOnly
											style={{ backgroundColor: "#c9d1d8" }}
										/>
									</Col>
								</FormGroup>
							</div>
							<div style={{ display: 'none' }}>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Jenis Kelamin
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="jenis_kelamin2"
											type="text"
											placeholder="Jenis Kelamin"
											value={detailSuratProps.detailSurat.jenis_kelamin2}
											readOnly
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Pekerjaan
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="pekerjaan2"
											type="text"
											placeholder="Pekerjaan"
											value={detailSuratProps.detailSurat.pekerjaan2}
											readOnly
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Kewarganegaraan
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="kewarganegaraan2"
											type="text"
											placeholder="Pekerjaan"
											value={detailSuratProps.detailSurat.kewarganegaraan2}
											readOnly
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Agama
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="agama2"
											type="text"
											placeholder="Agama"
											value={detailSuratProps.detailSurat.agama2}
											readOnly
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Alamat
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="alamat2"
											type="text"
											placeholder="Alamat"
											value={detailSuratProps.detailSurat.alamat2}
											readOnly
										/>
									</Col>
								</FormGroup>
							</div>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Waktu Meninggal
								</Label>
								<Col sm="9">
									{/* <Datepicker
                    className="form-control"
                    selected={detailSuratProps.detailSurat.waktu_meninggal2}
                    onChange={e => detailSuratProps.gantiTgl(e, "waktu_meninggal2")}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={1}
                    timeCaption="waktu"
                    dateFormat="dd MMMM yyyy h:mm aa"
                  /> */}
									<Datetime
										locale="id-ID"
										timeFormat="HH:mm"
										dateFormat="DD/MM/yyyy"
										input={true}
										closeOnTab={true}
										initialValue={new Date(detailSuratProps.detailSurat.waktu_meninggal2)}
										onChange={(e) => detailSuratProps.gantiTgl(e, 'waktu_meninggal2')}
										inputProps={{
											required: detailSuratProps.detailSurat.meninggal2 != 'none' ? true : false,
											placeholder: 'Waktu Meninggal'
										}}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tempat Meninggal
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tempat_meninggal2"
										type="text"
										placeholder="Tempat Meninggal"
										defaultValue={detailSuratProps.detailSurat.tempat_meninggal2}
										required={detailSuratProps.detailSurat.meninggal2 != 'none' ? true : false}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Sebab Meninggal
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="sebab_meninggal2"
										type="text"
										placeholder="Sebab Meninggal"
										defaultValue={detailSuratProps.detailSurat.sebab_meninggal2}
										required={detailSuratProps.detailSurat.meninggal2 != 'none' ? true : false}
									/>
								</Col>
							</FormGroup>
						</div>
						<div style={{ display: detailSuratProps.status.dataPenduduk }}>
							<FormGroup className="row">
								<Label className="col-sm-12 col-form-label">Daftar Ahli Waris</Label>
								<Col sm="5">
									<Select
										classNamePrefix="select"
										onChange={(e) => {
											detailSuratProps.getPenduduk(
												e,
												detailSuratProps.detailSurat.jenis_surat,
												'nik3'
											);
										}}
										defaultValue={detailSuratProps.dataNikStat.nik3Stat}
										value={detailSuratProps.dataNikStat.nik3Stat}
										name="NIK3"
										options={detailSuratProps.dataNik.frmNik3}
										placeholder="Pilih NIK Ahli Waris"
										onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik3')}
										isClearable
									/>
								</Col>
								<Col sm="4">
									{/* <input
										className="form-control"
										id="shdk"
										type="text"
										placeholder="SHDK"
										value={detailSuratProps.detailSurat.shdk}
										onChange={(e) => detailSuratProps.addShdk(e, 'shdk')}
									/> */}
									<Select
										classNamePrefix="select"
										onChange={(e) => {
											detailSuratProps.changeSelectValue2(e, 'shdk_aw');
										}}
										value={detailSuratProps.detailSurat.shdk}
										id="shdk"
										options={[
											{ value: 'Suami', label: 'Suami' },
											{ value: 'Isteri', label: 'Isteri' },
											{ value: 'Orang Tua', label: 'Orang Tua' },
											{ value: 'Anak', label: 'Anak' },
											{ value: 'Kakak', label: 'Kakak' },
											{ value: 'Adik', label: 'Adik' },
											{ value: 'Mertua', label: 'Mertua' },
											{ value: 'Menantu', label: 'Menantu' },
											{ value: 'Cucu', label: 'Cucu' },
											{ value: 'Pembantu', label: 'Pembantu' },
											{ value: 'Famili Lain', label: 'Famili Lain' }
										]}
										placeholder="Hubungan Keluarga"
									/>
								</Col>
								<Col sm="3">
									<button
										type="button"
										className="btn btn-primary"
										onClick={(e) => detailSuratProps.tambahAw(e, 'ahli-waris')}
									>
										Tambah
									</button>
								</Col>
								<Col sm="12">
									<table className="table table-bordered table-stripes table-hovered">
										<thead>
											<tr>
												<th>No</th>
												<th>Nama</th>
												<th>Tempat/Tanggal Lahir</th>
												<th>Pekerjaan</th>
												<th>Hubungan Keluarga</th>
												<th>
													<i className="fa fa-trash" />
												</th>
											</tr>
										</thead>
										<tbody>{ahliwaris}</tbody>
									</table>
								</Col>
							</FormGroup>
						</div>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-warning" onClick={detailSuratProps.tutupForm}>
							Tutup
						</button>
						<button
							type="submit"
							className="btn btn-success"
							// disabled={detailSuratProps.status.btnForm}
						>
							Simpan
						</button>
					</div>
				</Form>
			</Modal>
		</div>
	);
}

export default EditAhliWaris;
