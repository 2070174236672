import React, { useContext } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	ButtonGroup,
	CustomInput,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';
import Datepicker from 'react-datepicker';
import Select from 'react-select';
import { Link } from 'react-router-dom';

function EditDomisiliUsaha({ detail_surat_context }) {
	const detailSuratProps = useContext(detail_surat_context);

	const dateFormat = require('dateformat');

	// const usaha = detailSuratProps.detailSurat.usaha.map((dt, index) => (
	// 	<div className="shareholder">
	// 		<Label className="col-sm-2 col-form-label" htmlFor="inputEmail3">
	// 			Usaha {index + 1}
	// 		</Label>

	// 		<FormGroup className="row">
	// 			<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
	// 				Jenis
	// 			</Label>
	// 			<Col sm="9">
	// 				<input
	// 					className="form-control"
	// 					id={'jenis_usaha' + index}
	// 					type="text"
	// 					required
	// 					placeholder="Jenis"
	// 					defaultValue={dt.jenis_usaha}
	// 				/>
	// 			</Col>
	// 		</FormGroup>
	// 		<FormGroup className="row">
	// 			<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
	// 				Merk
	// 			</Label>
	// 			<Col sm="9">
	// 				<input
	// 					className="form-control"
	// 					id={'merk' + index}
	// 					type="text"
	// 					required
	// 					placeholder=" Merk"
	// 					defaultValue={dt.nama_tempat_usaha}
	// 				/>
	// 			</Col>
	// 		</FormGroup>
	// 		<FormGroup className="row">
	// 			<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
	// 				Lokasi
	// 			</Label>
	// 			<Col sm="9">
	// 				<input
	// 					className="form-control"
	// 					id={'lokasi' + index}
	// 					type="text"
	// 					required
	// 					placeholder="Lokasi"
	// 					defaultValue={dt.lokasi}
	// 				/>
	// 			</Col>
	// 		</FormGroup>
	// 		<FormGroup className="row">
	// 			<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
	// 				Lama Usaha
	// 			</Label>
	// 			<Col sm="9">
	// 				<input
	// 					className="form-control"
	// 					id={'umur' + index}
	// 					type="text"
	// 					required
	// 					placeholder=" Keterangan"
	// 					defaultValue={dt.umur}
	// 				/>
	// 			</Col>
	// 		</FormGroup>
	// 		<FormGroup className="row">
	// 			<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
	// 				Luas
	// 			</Label>
	// 			<Col sm="9">
	// 				<input
	// 					className="form-control"
	// 					id={'luas_kebun' + index}
	// 					type="text"
	// 					required
	// 					placeholder=" Luas"
	// 					defaultValue={dt.luas_kebun}
	// 				/>
	// 			</Col>
	// 		</FormGroup>

	// 		<div className="float-right">
	// 			<button
	// 				type="button"
	// 				className="btn btn-danger small"
	// 				onClick={detailSuratProps.handleRemoveShareholder(index)}
	// 			>
	// 				-
	// 			</button>
	// 		</div>
	// 	</div>
	// ));

	return (
		<div>
			<Modal
				open={detailSuratProps.status.formUpdateSurat}
				onClose={detailSuratProps.tutupForm}
				styles={{ modal: { width: "80%" } }}
				closeOnEsc={false}
				closeOnOverlayClick={false}
			>
				<Form className="theme-form" onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}>
					<div className="modal-header">
						<h5 className="modal-title">Detail {detailSuratProps.detailSurat.jenis_surat}</h5>
					</div>
					<div className="modal-body">
						<input
							className="form-control"
							id="uuid"
							type="hidden"
							placeholder=""
							defaultValue={detailSuratProps.detailSurat.uuid}
						/>

						<FormGroup className="row">
							<Label
								className="col-sm-3 col-form-label"
								htmlFor="inputEmail3"
							>
								NIK{" "}
							</Label>
							<Col sm="9">
								<Select
									// isDisabled={this.state.status.select}
									classNamePrefix="select"
									onChange={e => {
										detailSuratProps.getPenduduk(
											e,
											detailSuratProps.detailSurat.jenis_surat,
											'nik'
										);
									}}
									defaultValue={detailSuratProps.detailSurat.pendStat}
									value={detailSuratProps.detailSurat.pendStat}
									name="penduduk"
									options={detailSuratProps.dataNik.frmNik}
									placeholder="Pilih NIK"
									onInputChange={e => detailSuratProps.handleInputChangeNIK(e, "nik")}
									isClearable
								/>
							</Col>
						</FormGroup>

						<hr />
						<div
							style={{ display: detailSuratProps.status.showBio == false ? "none" : "" }}
						>
							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									NIK
								</Label>
								<Col sm="9"> */}
							<input
								className="form-control"
								id="nik"
								type="hidden"
								readOnly
								placeholder="NIK"
								value={detailSuratProps.detailSurat.nik}
							/>
							{/* </Col>
							</FormGroup> */}
							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									KK
								</Label>
								<Col sm="9"> */}
							<input
								className="form-control"
								id="kk"
								type="hidden"
								readOnly
								placeholder="kk"
								value={detailSuratProps.detailSurat.kk}
							/>
							{/* </Col>
							</FormGroup> */}

							<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Nama Pemohon
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_pemohon"
										readOnly
										type="text"
										placeholder="nama_pemohon"
										value={detailSuratProps.detailSurat.nama_pemohon}
										style={{ backgroundColor:"#c9d1d8" }}
										// disabled
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Tempat Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										readOnly
										id="tempat_lahir"
										type="text"
										placeholder="Tempat lahir"
										value={detailSuratProps.detailSurat.tempat_lahir}
										style={{ backgroundColor:"#c9d1d8" }}
										// disabled
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Tanggal Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tanggal_lahir"
										readOnly
										type="text"
										placeholder="Tanggal Lahir"
										value={dateFormat(detailSuratProps.detailSurat.tanggal_lahir, 'dd/mm/yyyy')}
										style={{ backgroundColor:"#c9d1d8" }}
										// disabled
									/>
								</Col>
							</FormGroup>
							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Jenis Kelamin
								</Label>
								<Col sm="9"> */}
							<input
								className="form-control"
								id="jenis_kelamin"
								readOnly
								type="hidden"
								placeholder="jenis_kelamin"
								value={detailSuratProps.detailSurat.jenis_kelamin}
							/>
							{/* </Col>
							</FormGroup> */}
							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Pekerjaan
								</Label>
								<Col sm="9"> */}
							<input
								className="form-control"
								readOnly
								id="pekerjaan"
								type="hidden"
								placeholder="Pekerjaan"
								value={detailSuratProps.detailSurat.pekerjaan}
							/>
							{/* </Col>
							</FormGroup> */}
							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Pendidikan Terakhir
								</Label>
								<Col sm="9"> */}
							<input
								className="form-control"
								id="pendidikan_terakhir"
								readOnly
								type="hidden"
								placeholder="Pendidikan Terakhir"
								value={detailSuratProps.detailSurat.pendidikan_terakhir}
							/>
							{/* </Col>
							</FormGroup> */}
							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Agama
								</Label>
								<Col sm="9"> */}
							<input
								className="form-control"
								id="agama"
								readOnly
								type="hidden"
								placeholder="Agama"
								value={detailSuratProps.detailSurat.agama}
							/>
							{/* </Col>
							</FormGroup> */}
							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Status Perkawinan
								</Label>
								<Col sm="9"> */}
							<input
								className="form-control"
								id="status_perkawinan"
								type="hidden"
								readOnly
								placeholder="Status Perkawinan"
								value={detailSuratProps.detailSurat.status_perkawinan}
							/>
							{/* </Col>
							</FormGroup> */}
							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Alamat
								</Label>
								<Col sm="9"> */}
							<input
								className="form-control"
								id="alamat"
								type="hidden"
								readOnly
								placeholder="Alamat"
								value={detailSuratProps.detailSurat.alamat}
							/>
							{/* </Col>
							</FormGroup> */}
							{/* <FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Kewarganegaraan
								</Label>
								<Col sm="9"> */}
							<input
								className="form-control"
								id="kewarganegaraan"
								type="hidden"
								readOnly={true}
								placeholder="Kewarganegaraan"
								value={detailSuratProps.detailSurat.kewarganegaraan}
							/>
							{/* </Col>
							</FormGroup> */}
						</div>
						
            
            <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Jenis Usaha
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="jenis_usaha"
                      type="text"
                      placeholder="Jenis Usaha"
                      defaultValue={detailSuratProps.detailSurat.jenis_usaha}
                      required
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Merk Usaha
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_tempat_usaha"
                      type="text"
                      placeholder=" Merk Usaha"
                      defaultValue={detailSuratProps.detailSurat.merk_usaha}
                      required
                    />
                  </Col>
                </FormGroup>

                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Lokasi Usaha
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="lokasi"
                      type="text"
                      placeholder="Lokasi Usaha"
                      defaultValue={detailSuratProps.detailSurat.lokasi_usaha}
                      required
                    />
                  </Col>
                </FormGroup>


                <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputEmail3"
                      >
                        Dusun
                      </Label>
                      <Col sm="9">
                        <input
                          className="form-control"
                          id="dusun"
                          type="text"
                          placeholder="Contoh: Sungai Petai"
                          defaultValue={detailSuratProps.detailSurat.dusStat}
                          // onChange={(e) => {
                          //   this.changeInputValue(e, 'dusun');
                          // }}
                          required
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputEmail3"
                      >
                        Rw
                      </Label>
                      <Col sm="9">
                        <input
                          className="form-control"
                          id="rw"
                          type="text"
                          placeholder="Contoh: 04"
                          defaultValue={detailSuratProps.detailSurat.rwStat}
                          // onChange={(e) => {
                          //   this.changeInputValue(e, 'rw');
                          // }}
                          required
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup className="row">
                      <Label
                        className="col-sm-3 col-form-label"
                        htmlFor="inputEmail3"
                      >
                        Rt
                      </Label>
                      <Col sm="9">
                        <input
                          className="form-control"
                          id="rt"
                          type="text"
                          placeholder="Contoh: 02"
                          defaultValue={detailSuratProps.detailSurat.rtStat}
                          // onChange={(e) => {
                          //   this.changeInputValue(e, 'rt');
                          // }}
                          required
                        />
                      </Col>
                    </FormGroup>
						
						

					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-warning"
							// disabled={this.state.status.btnForm}
							onClick={detailSuratProps.tutupForm}
						>
							Tutup
						</button>
						<button
							type="submit"
							className="btn btn-success"
							// disabled={this.state.status.btnForm}
						>
							Simpan
						</button>
					</div>
				</Form>
			</Modal>
			
		</div>
	);
}

export default EditDomisiliUsaha;