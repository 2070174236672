import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Col, FormGroup, Label, Row, Form } from 'reactstrap';
import { JENIS_DESA, JENIS_DUSUN, Post, Get } from '../function/Koneksi';
import NumberFormat from 'react-number-format';
// import Keyboard from 'react-simple-keyboard';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';


import { Tanggal, Hari, HariTanggal, HariTanggalJam, DataToken } from '../function/Format';
const dateFormat = require('dateformat');

export default class SuratPenghasilan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			rencana_berangkat: new Date(),
			Basic: false,
			nominal: '',

			status: {
				view: false,
				form: false,
				btnForm: false,
				btnAksi: false,
				keyboard: false
			},

			//keyboard
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: { penghasilan: '' },
			inputPattern: /^\d+$/,
			display: 'none',
			focus: false,
			hasFocus: false,
			style: {}
		};

		this.showKeyboard = this.showKeyboard.bind(this);
	}

	// onOpenModal = (key) => {
	// 	console.log(key);
	// 	this.setState({ [key]: true });
	// };

	// onCloseModal = (key) => {
	// 	this.setState({ [key]: false });
	// };

	handleClick = (event) => {
		alert(event);
	};

	simpan = (e) => {
		e.preventDefault();

		if (this.state.input.penghasilan != '') {
			let alamat = '';
			if (JENIS_DESA.toLowerCase() == 'kelurahan') {
				alamat =
					' RT. ' +
					this.props.data.results.data_kk.data_rt.nama +
					'/RW. ' +
					this.props.data.results.data_kk.data_rt.data_rw.nama +
					', ' +
					this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
					', Kec. ' +
					this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
					', ' +
					this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
			} else {
				// alamat =
				// 	' RT. ' +
				// 	this.props.data.results.data_kk.data_rt.nama +
				// 	'/RW. ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
				// 	', ' +
				// 	JENIS_DUSUN +
				// 	' ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
				// 	', ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
				// 	', Kec. ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
				// 	', ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;
	
					console.log(this.props.data.results)
					if (this.props.data.results.data_kk == null || this.props.data.results.data_kk.id_rt == '0') {
						alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
						", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
						", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
						", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
					} else {
						alamat =
						" RT. " + 
						this.props.data.results.data_kk.data_rt.nama +
						"/RW. " +
						this.props.data.results.data_kk.data_rt.data_rw.nama +
						", " + JENIS_DUSUN + " " +
						this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
						", " + JENIS_DESA + " " +
						this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
						", Kec. " +
						this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
							.data_kecamatan.nama +
						", " +
						this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
							.data_kecamatan.data_kabkota.nama; 
					}
			}
	
			let addSuratKeteranganPenghasilan = {
				id_jenis: this.props.id_jenis,
				// uuid: document.getElementById('uuid').value,
				// nomor : document.getElementById("nomor").value,
	
				nik: this.props.data.results.id,
				nama_pemohon: this.props.data.results.nama,
				tempat_lahir: this.props.data.results.tempat_lahir,
				tanggal_lahir: this.props.data.results.tanggal_lahir,
				pekerjaan: this.props.data.results.data_pekerjaan.nama,
				agama: this.props.data.results.data_agama.nama,
				status_perkawinan: this.props.data.results.status_perkawinan,
				alamat: alamat,
				jenis_kelamin: this.props.data.results.jk,
	
				// penghasilan: document.getElementById('penghasilan').value,
				penghasilan: this.state.input.penghasilan.replace(/[^\d]/g, ''),
				status_ttd: null
			};
			// console.log(this.state.input.penghasilan);
			// console.log(addSuratKeteranganPenghasilan);
	
			let psn = '';
			let resstat = 201;
			let metode = 'create';
	
			Post('surat-keterangan/penghasilan/' + metode, null, addSuratKeteranganPenghasilan, (res) => {
				console.log("Tampil Respon Simpan")
				console.log(res)
				this.onCloseModal('Basic');
	
				let dataAntrian = {
					nama: this.props.data.results.nama,
					nik: this.props.data.results.id,
					keterangan: '',
					uuid_surat:res.data.results,
				};
	
				if (res.status === resstat) {
					Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
						console.log("Hasil Update Status")
						console.log(res2)
						if (res2.code === 201) {
							console.log("Berhasil Ganti Status Menjadi Selesai")

							this.props.getAntrian('Surat Keterangan Penghasilan', 'berhasil', false, dataAntrian);
						}
						else{
							console.log("Gagal Ganti Status Menjadi Selesai")

							this.props.getAntrian('Surat Keterangan Penghasilan', 'gagal');
						}
					});
					// this.props.getAntrian('Surat Keterangan Penghasilan', 'berhasil', false, dataAntrian);
				} else {
					this.props.getAntrian('Surat Keterangan Penghasilan', 'gagal');
				}
				// reset cache
				// document.getElementById('penghasilan').value = '';
				// this.state.input.penghasilan = '';
				this.setState({input : { penghasilan : '' }})
			});
		} else {
			this.props.getAntrian('Surat Keterangan Penghasilan', 'gagal', true);
		}
		
	};

	// EMBED KEyBOARD

	onOpenModal = (key) => {
		console.log(key);
		console.log(this.props.data.results);
		this.setState({
			[key]: true,
			regis: true
		});
	};

	onCloseModal = (key) => {
		this.setState({
			[key]: false,
			display: 'none',
			regis: 'false',
			input: {}
		});
	};

	// on focus
	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				console.log('Active input', inputName);
			}
		);
	};

	// end on focus

	// ketika Klik Keyboard atau mengisi data
	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		console.log('Input changed', inputObj);
	};

	onKeyPress = (button) => {
		console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	// End ketika Klik Keyboard atau mengisi data

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}
		// this.setState({
		// 	display: '',
		// 	// onFocus:true

		//   });
		this.forceUpdate();
	};

	showKeyboard1 = () => {
		this.setState({
			display: 'none',
			regis: 'false'
		});
		this.forceUpdate();
	};

	// Input keyboard
	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				} else {
				}
			}
		);
	};

	onChangeInput1 = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			}
		);
	};

	// end input keyboard

	render() {
		const footerStyle = {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		};

		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const { Basic } = this.state;

		return (
			<div>
				<Modal
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					// styles={{ modal: { marginBottom: '200px', width: '80%' } }}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title" align="center">
								Surat Keterangan Penghasilan
							</h5>
						</div>

						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder=""
								// defaultValue={this.state.dt.uuid}
							/>

							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label">No Antrian Anda</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="no_antrian_anda"
										defaultValue="12"
										disabled
										type="text"
										placeholder="No Antrian Anda"
									/>
								</Col>
							</FormGroup> */}

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label">NIK Pemohon</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nik"
										disabled
										type="text"
										placeholder="NIK"
										value={this.props.data.results.id}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label">Nama Lengkap Pemohon</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_pemohon"
										disabled
										type="text"
										placeholder=" Nama Lengkap"
										value={this.props.data.results.nama}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Penghasilan (Rp)
								</Label>
								<Col sm="9">
									<NumberFormat
										id="penghasilan"
										placeholder="Contoh : 1.500.000"
										thousandSeparator={true}
										prefix={'Rp. '}
										className="form-control"
										onFocus={() => this.setActiveInput('penghasilan')}
										value={this.state.input['penghasilan']}
										onChange={(e) => this.onChangeInput(e)}
									/>
								</Col>
							</FormGroup>

							<div style={{ display: 'none' }}>
								<FormGroup className="row">
									<Label>Tempat Lahir</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tempat_lahir"
											type="text"
											placeholder="Tempat Lahir"
											value={this.props.data.results.tempat_lahir}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label> Tanggal Lahir</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tanggal_lahir"
											type="text"
											placeholder="Tanggal Lahir"
											value={this.props.data.results.tanggal_lahir}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label>Jenis Kelamin</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="jenis_kelamin"
											type="text"
											placeholder="Jenis Kelamin"
											value={this.props.data.results.jk}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label>Pekerjaan</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="pekerjaan"
											type="text"
											placeholder="Pekerjaan"
											value={this.props.data.results.pekerjaan}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label>Agama</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="agama"
											type="text"
											placeholder="Agama"
											value={this.props.data.results.agama}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Alamat
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="alamat"
											type="text"
											placeholder="Alamat"
											value={this.props.data.results.alamat}
											readOnly
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Status Perkawinan
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="status_perkawinan"
											type="text"
											placeholder="Status Perkawinan"
											value={this.props.data.results.status_perkawinan}
											readOnly
										/>
									</Col>
								</FormGroup>
							</div>

							<div className="modal-footer" style={footerStyle}>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-primary">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>

					{/* {this.state.regis ? (
						<div style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 4, left: 0 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
						<div />
					)} */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/penghasilan.png')}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p
								className="mb-0"
								style={{
									fontSize: '20px',
									fontWeight: '600',
									color: '#000000'
								}}
							>
								Penghasilan
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}