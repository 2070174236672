import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";

// koneksi
import { STATUS_LAYANAN_KUA, STATUS_LAYANAN_CAPIL } from "../../../function/Koneksi";

class MenuAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="mb-2 menuu"
        style={{
          display: "flex",
          justifyContent: "space-around",
          borderRadius: "10px",
          width: "100%"
        }}
      >
        <div className="dropdown-basic">
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-success"
                  style={{ borderRadius: "10px"}}
                >
                  <i className="fa fa-home"></i> Dashboard
                </button>
              </div>
            </Link>
          </div>
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-success"
                style={{ borderRadius: "10px"}}
              >
                <i className="fa fa-envelope"></i> Administrasi
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                <Link
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/verifikasi",
                    state: { title: "Verifikasi Data" }
                  }}
                >
                  <i className="icon-check-box"></i> Verifikasi Data
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/disposisi"}>
                  <i className="icon-arrow-right"></i> Disposisi Surat
                </Link>
                <div className="liyu">
                  <a>
                    <i className="icon-pencil-alt"></i> Perubahan Data
                    <span style={{ float: "right" }}>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  </a>
                  <div className="liyu1">
                    <Link
                      to={
                        process.env.PUBLIC_URL + "/admin/perubahan-data/nik-kk"
                      }
                    >
                      <i className="fa fa-user"></i> Nik & KK
                    </Link>
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        "/admin/perubahan-data/pindah-kk"
                      }
                    >
                      <i className="fa fa-user"></i> Pindah KK
                    </Link>
                    <Link
                      to={
                        process.env.PUBLIC_URL +
                        "/admin/perubahan-data/pengajuan"
                      }
                    >
                      <i className="fa fa-user"></i> Pengajuan Perubahan Data
                    </Link>
                  </div>
                </div>
                <Link to={process.env.PUBLIC_URL + "/admin/laporan"}>
                  <i className="fa fa-files-o"></i> Laporan
                </Link>
              </div>
            </div>
          </div>
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-success"
                style={{ borderRadius: "10px"}}
              >
                <i className="icofont icofont-users-alt-4"></i> Kependudukan
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                <Link to={process.env.PUBLIC_URL + "/admin/penduduk"}>
                  <i className="fa fa-user"></i> Penduduk
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/kartu-keluarga"}>
                  <i className="fa fa-users"></i> Kartu Keluarga
                </Link>
              </div>
            </div>
          </div>
          

          {
            localStorage.getItem('STATUS_LAYANAN_KUA') == '1'  && localStorage.getItem('STATUS_LAYANAN_CAPIL') =='1'
            ?
              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-success"
                    style={{ borderRadius: "10px"}}
                  >
                    <i className="fa fa-desktop"></i> Layanan
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <Link
                      to={
                        process.env.PUBLIC_URL + "/admin/layanan/capil/disdukcapil"
                      }
                    >
                      <i className="fa fa-university"></i> Disdukcapil
                    </Link>
                    <Link to={process.env.PUBLIC_URL + "/admin/layanan/kua/kua"}>
                      <i className="fa fa-university"></i> KUA
                    </Link>
                  </div>
                </div>
              </div>
            : localStorage.getItem('STATUS_LAYANAN_KUA') == '1'
            ?
                <div className="dropdown">
                  <div className="btn-group m-1">
                    <button
                      type="button"
                      className="dropbtn btn-success"
                      style={{ borderRadius: "10px"}}
                    >
                      <i className="fa fa-desktop"></i> Layanan
                      <span>
                        <i className="icofont icofont-arrow-down"></i>
                      </span>
                    </button>
                    <div className="dropdown-content">
                      <Link to={process.env.PUBLIC_URL + "/admin/layanan/kua/kua"}>
                        <i className="fa fa-university"></i> KUA
                      </Link>
                    </div>
                  </div>
                </div>
              : localStorage.getItem('STATUS_LAYANAN_CAPIL') == '1'
              ?
                <div className="dropdown">
                  <div className="btn-group m-1">
                    <button
                      type="button"
                      className="dropbtn btn-success"
                      style={{ borderRadius: "10px"}}
                    >
                      <i className="fa fa-desktop"></i> Layanan
                      <span>
                        <i className="icofont icofont-arrow-down"></i>
                      </span>
                    </button>
                    <div className="dropdown-content">
                      <Link
                        to={
                          process.env.PUBLIC_URL + "/admin/layanan/capil/disdukcapil"
                        }
                      >
                        <i className="fa fa-university"></i> Disdukcapil
                      </Link>
                    </div>
                  </div>
                </div>
              :
            <>
            </>
          }


          
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-success"
                style={{ borderRadius: "10px"}}
              >
                <i className="icofont icofont-ui-note"></i> Informasi
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                <Link to={process.env.PUBLIC_URL + "/admin/berita"}>
                  <i className="icon-rss-alt"></i> Berita / Info
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/banner"}>
                  <i className="icon-gallery"></i> Banner
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/catatan"}>
                  <i className="fa fa-edit"></i> Catatan
                </Link>
              </div>
            </div>
          </div>
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-success"
                style={{ borderRadius: "10px"}}
              >
                <i className="fa fa-bar-chart"></i> Data Potensi
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                <Link to={process.env.PUBLIC_URL + "/admin/data-potensi"}>
                  <i className="icofont icofont-growth"></i> Grafik
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/laporan-potensi"}>
                  <i className="fa fa-files-o"></i> Laporan
                </Link>
              </div>
            </div>
          </div>
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/admin/perangkat"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-success"
                  style={{ borderRadius: "10px"}}
                >
                  <i className="fa fa-building"></i> Perangkat Desa
                </button>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuAdmin;
