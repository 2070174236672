import React from 'react';
import { logo } from './asset/base64img';
import { Tanggal, Hari, HariTanggal, HariTanggalJam } from '../function/Format';
import '../assets/css/site.css';
import { ALAMAT_KANTOR_DESA, DESA, NAMA_KABUPATEN, KECAMATAN, Nama_DESA, PROVINSI, JENIS_DESA, JABATAN_TERTINGGI } from '../function/Koneksi';

function CetakSurat({ cetak, jenis }) {
	const terbilang = (a) => {
		// pastikan kita hanya berususan dengan tipe data numeric
		let angka = parseFloat(a);
		let hasil_bagi = 0;
		let hasil_mod = 0;
		// array bilangan
		// sepuluh dan sebelas merupakan special karena awalan 'se'
		const bilangan = [
			'',
			'Satu',
			'Dua',
			'Tiga',
			'Empat',
			'Lima',
			'Enam',
			'Tujuh',
			'Delapan',
			'Sembilan',
			'Sepuluh',
			'Sebelas'
		];

		// pencocokan dimulai dari satuan angka terkecil
		if (angka < 12) {
			// mapping angka ke index array bilangan
			return bilangan[angka];
		} else if (angka < 20) {
			// bilangan 'belasan'
			// misal 18 maka 18 - 10 = 8
			return bilangan[angka - 10] + ' Belas';
		} else if (angka < 100) {
			// bilangan 'puluhan'
			// misal 27 maka 27 / 10 = 2.7 (integer => 2) 'Dua'
			// untuk mendapatkan sisa bagi gunakan modulus
			// 27 mod 10 = 7 'Tujuh'
			hasil_bagi = parseInt(angka / 10);
			hasil_mod = angka % 10;
			return bilangan[hasil_bagi] + ' Puluh ' + bilangan[hasil_mod];
		} else if (angka < 200) {
			// bilangan 'seratusan' (itulah indonesia knp tidak satu ratus saja? :))
			// misal 151 maka 151 = 100 = 51 (hasil berupa 'puluhan')
			// daripada menulis ulang rutin kode puluhan maka gunakan
			// saja fungsi rekursif dengan memanggil fungsi terbilang(51)
			return 'Seratus ' + terbilang(angka - 100);
		} else if (angka < 1000) {
			// bilangan 'ratusan'
			// misal 467 maka 467 / 100 = 4,67 (integer => 4) 'Empat'
			// sisanya 467 mod 100 = 67 (berupa puluhan jadi gunakan rekursif terbilang(67))
			hasil_bagi = parseInt(angka / 100);
			hasil_mod = angka % 100;
			return bilangan[hasil_bagi] + ' Ratus ' + terbilang(hasil_mod);
		} else if (angka < 2000) {
			// bilangan 'seribuan'
			// misal 1250 maka 1250 - 1000 = 250 (ratusan)
			// gunakan rekursif terbilang(250)
			return 'Seribu ' + terbilang(angka - 100);
		} else if (angka < 1000000) {
			// bilangan 'ribuan' (sampai ratusan ribu
			hasil_bagi = parseInt(angka / 1000); // karena hasilnya bisa ratusan jadi langsung digunakan rekursif
			hasil_mod = angka % 1000;
			return terbilang(hasil_bagi) + ' Ribu ' + terbilang(hasil_mod);
		} else if (angka < 1000000000) {
			// bilangan 'jutaan' (sampai ratusan juta)
			// 'satu puluh' => SALAH
			// 'satu ratus' => SALAH
			// 'satu juta' => BENAR
			// @#$%^ WT*

			// hasil bagi bisa satuan, belasan, ratusan jadi langsung kita gunakan rekursif
			hasil_bagi = parseInt(angka / 1000000);
			hasil_mod = angka % 1000000;
			return terbilang(hasil_bagi) + ' Juta ' + terbilang(hasil_mod);
		} else if (angka < 1000000000000) {
			// bilangan 'milyaran'
			hasil_bagi = parseInt(angka / 1000000000);
			// karena batas maksimum integer untuk 32bit sistem adalah 2147483647
			// maka kita gunakan fmod agar dapat menghandle angka yang lebih besar
			hasil_mod = angka % 1000000000;
			return terbilang(hasil_bagi) + ' Milyar ' + terbilang(hasil_mod);
		} else if (angka < 1000000000000000) {
			// bilangan 'triliun'
			hasil_bagi = angka / 1000000000000;
			hasil_mod = angka % 1000000000000;
			return terbilang(hasil_bagi) + ' Triliun ' + terbilang(hasil_mod);
		} else {
			return 'Wow...';
		}
	};

	const ttd = () => {
		let text_ttd = '';
		if (cetak.jabatan_ttd.toLowerCase() == JABATAN_TERTINGGI.toLowerCase()) {
			text_ttd = cetak.jabatan_ttd + ' ' + cetak.nama_desa
		} else {
			// text_ttd = 'KEPALA ' + cetak.nama_desa
		}
		return text_ttd
	}

	switch (jenis) {
		case 'Surat Keterangan Tidak Mampu':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat keterangan tidak mampu
									</span>
									<br />
									{cetak.nomor}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													fontWeight: 'bold',
													textTransform: 'uppercase'
												}}
											>
												{cetak.nama_pemohon}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir}, {Tanggal(new Date(cetak.tanggal_lahir))}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Jenis Kelamin
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.jenis_kelamin}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.agama}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Status Perkawinan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.status_perkawinan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pendidikan Terakhir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pendidikan_terakhir}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Kewarganegaraan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.kewarganegaraan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nomor KTP
											</td>
											<td>:</td>
											<td>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nomor KK
											</td>
											<td>:</td>
											<td>{cetak.kk}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									{cetak.detail.length == 0 ? (
										<p style={{ textIndent: '50px' }}>
											Adalah benar nama tersebut di atas adalah berdomisili/bertempat tinggal{' '}
											{cetak.keluarga} di{' '}
											<span style={{ textTransform: 'capitalize' }}>{cetak.alamat}</span>
											. Sepanjang sepengetahuan kami yang bersangkutan termasuk golongan{' '}
											<b>TIDAK MAMPU/MISKIN</b>.
										</p>
									) : (
											<div>
												<p style={{ textIndent: '50px' }}>
													Adalah benar nama tersebut di atas adalah berdomisili/bertempat tinggal{' '}
													{cetak.keluarga} di {cetak.alamat}. Sepanjang sepengetahuan kami yang
												bersangkutan termasuk golongan <b>TIDAK MAMPU/MISKIN</b>
												, dan mempunyai tanggungan keluarga sebanyak {cetak.detail.length}{' '}
												orang, yaitu :
											</p>
												<table
													border="1"
													width="100%"
													cellpadding="0"
													cellspacing="0"
													style={{ fontSize: '14px', pageBreakAfter: 'auto' }}
												>
													<thead>
														<tr>
															<th>No</th>
															<th>Nama</th>
															<th>L/P</th>
															<th>Tempat/Tanggal Lahir</th>
															<th>Pekerjaan</th>
															<th>Pendidikan</th>
															<th>SHDK</th>
														</tr>
													</thead>
													<tbody>
														{cetak.detail.map((dt, i) => {
															console.log(dt)
															return (
																<tr
																	style={{
																		textTransform: 'capitalize',
																		pageBreakInside: 'avoid',
																		pageBreakAfter: 'auto'
																	}}
																>
																	<td
																		style={{
																			padding: '5px 5px 5px 5px',
																			pageBreakInside: 'avoid',
																			pageBreakAfter: 'auto'
																		}}
																	>
																		{i + 1}
																	</td>
																	<td
																		style={{
																			padding: '5px 5px 5px 5px',
																			pageBreakInside: 'avoid',
																			pageBreakAfter: 'auto'
																		}}
																	>
																		{dt.nama.toLowerCase()}
																	</td>
																	<td
																		style={{
																			padding: '5px 5px 5px 5px',
																			pageBreakInside: 'avoid',
																			pageBreakAfter: 'auto'
																		}}
																	>
																		{/* {dt.jenis_kelamin.toLowerCase() == 'laki-laki' ? 'L' : 'P'} */}
																		{dt.jk.toLowerCase() == 'laki-laki' ? 'L' : 'P'}
																	</td>
																	<td
																		style={{
																			padding: '5px 5px 5px 5px',
																			pageBreakInside: 'avoid',
																			pageBreakAfter: 'auto'
																		}}
																	>
																		{dt.tempat_lahir.toLowerCase()},{' '}
																		{Tanggal(new Date(dt.tanggal_lahir))}
																	</td>
																	<td
																		style={{
																			padding: '5px 5px 5px 5px',
																			pageBreakInside: 'avoid',
																			pageBreakAfter: 'auto'
																		}}
																	>
																		{dt.pekerjaan.toLowerCase()}
																	</td>
																	<td
																		style={{
																			padding: '5px 5px 5px 5px',
																			pageBreakInside: 'avoid',
																			pageBreakAfter: 'auto'
																		}}
																	>
																		{dt.pendidikan}
																	</td>
																	<td
																		style={{
																			padding: '5px 5px 5px 5px',
																			pageBreakInside: 'avoid',
																			pageBreakAfter: 'auto'
																		}}
																	>
																		{dt.shdk.toLowerCase()}
																	</td>
																</tr>
															);
														})}
													</tbody>
												</table>
											</div>
										)}
								</td>
							</tr>
							<tr style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
								<td
									style={{
										textAlign: 'justify',
										pageBreakInside: 'avoid',
										pageBreakAfter: 'auto'
									}}
								>
									<p style={{ textIndent: '50px' }}>
										Demikianlah surat keterangan tidak mampu ini dibuat dengan sebenarnya untuk
										dapat dipergunakan seperlunya
									</p>
									<table border="0" width="100%" style={{ marginTop: '10px' }}>
										<tr>
											<td width="55%">&nbsp;</td>
											<td width="45%">
												<div style={{ width: 'fit-content' }}>
													<div style={{ marginBottom: '20px' }}>
														<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
															<tr>
																<td width="auto">Dikeluarkan di</td>
																<td style={{ width: '21px', textAlign: 'center' }}>:</td>
																<td style={{ textTransform: 'capitalize' }}>
																	{cetak.nama_desa}
																</td>
															</tr>
															<tr>
																<td width="auto" style={{ borderBottom: '1px solid black' }}>
																	Pada tanggal
																</td>
																<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
																<td style={{ borderBottom: '1px solid black' }}>
																	{Tanggal(new Date(cetak.tanggal_surat))}
																</td>
															</tr>
														</table>
													</div>
													<div style={{ textAlign: 'center' }}>
														<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
															{cetak.status_ttd}{' '}
															<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
																{ttd()}
															</span>
														</div>
														{cetak.jabatan_pengganti_ttd ? (
															<div style={{ textAlign: 'center' }}>
																{/* <br /> */}
																<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
																	{cetak.jabatan_pengganti_ttd}
																</span>
															</div>
														) : (
																''
															)}
														<div style={{ height: '50px' }}>{''}</div>
														<div style={{ textAlign: 'center' }}>
															<span
																style={{
																	// textTransform: 'uppercase',
																	fontWeight: 'bold',
																	borderBottom: '1px solid black'
																}}
															>
																{cetak.nama_ttd}
															</span>
															<br />
															{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
														</div>
													</div>
												</div>
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Keterangan Domisili':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat keterangan domisili
									</span>
									<br />
									{cetak.nomor}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													fontWeight: 'bold',
													textTransform: 'uppercase'
												}}
											>
												{cetak.nama_pemohon}
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td>
												{cetak.tempat_lahir}, {Tanggal(new Date(cetak.tanggal_lahir))}
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												No Kartu Keluarga
											</td>
											<td>:</td>
											<td>{cetak.kk}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td>{cetak.pekerjaan}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Status Perkawinan
											</td>
											<td>:</td>
											<td>{cetak.status_perkawinan}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td>{cetak.agama}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pendidikan Terakhir
											</td>
											<td>:</td>
											<td>{cetak.pendidikan_terakhir}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Adalah benar nama tersebut di atas adalah berdomisili/bertempat tinggal{' '}
										{cetak.keluarga} di {cetak.alamat} sejak {cetak.tujuan_pembuatan} yang lalu.
									</p>{' '}
									{cetak.detailkeluarga}
								</td>
							</tr>
							<tr style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
								<td
									style={{
										textAlign: 'justify',
										pageBreakInside: 'avoid',
										pageBreakAfter: 'auto'
									}}
								>
									<p style={{ textIndent: '50px' }}>
										Demikianlah surat keterangan domisili ini dibuat dengan sebenarnya untuk dapat
										dipergunakan seperlunya
									</p>
									<table border="0" width="100%" style={{ marginTop: '10px' }}>
										<tr>
											<td width="55%">&nbsp;</td>
											<td width="45%">
												<div style={{ width: 'fit-content' }}>
													<div style={{ marginBottom: '20px' }}>
														<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
															<tr>
																<td width="auto">Dikeluarkan di</td>
																<td style={{ width: '21px', textAlign: 'center' }}>:</td>
																<td style={{ textTransform: 'capitalize' }}>
																	{cetak.nama_desa}
																</td>
															</tr>
															<tr>
																<td width="auto" style={{ borderBottom: '1px solid black' }}>
																	Pada tanggal
																</td>
																<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
																<td style={{ borderBottom: '1px solid black' }}>
																	{Tanggal(new Date(cetak.tanggal_surat))}
																</td>
															</tr>
														</table>
													</div>
													<div style={{ textAlign: 'center' }}>
														<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
															{cetak.status_ttd}{' '}
															<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
																{ttd()}
															</span>
														</div>
														{cetak.jabatan_pengganti_ttd ? (
															<div style={{ textAlign: 'center' }}>
																{/* <br /> */}
																<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
																	{cetak.jabatan_pengganti_ttd}
																</span>
															</div>
														) : (
																''
															)}
														<div style={{ height: '50px' }}>{''}</div>
														<div style={{ textAlign: 'center' }}>
															<span
																style={{
																	// textTransform: 'uppercase',
																	fontWeight: 'bold',
																	borderBottom: '1px solid black'
																}}
															>
																{cetak.nama_ttd}
															</span>
															<br />
															{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
														</div>
													</div>
												</div>
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Keterangan Usaha':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat keterangan Usaha
									</span>
									<br />
									{cetak.nomor}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama_pemohon}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir},{Tanggal(cetak.tanggal_lahir)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Status Perkawinan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.status_perkawinan}</td>
										</tr>

										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.agama}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Selanjutnya kami terangkan bahwa yang bersangkutan benar-benar memiliki usaha
										yakni:
									</span>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify', paddingLeft: '60px' }}>
									<table
										border="0"
										width="100%"
										style={{
											pageBreakAfter: 'auto'
										}}
									>
										{cetak.detail.map((dt, idx) => {
											return (
												<div>
													{/* <li> */}
													<tr
														style={{
															pageBreakInside: 'avoid',
															pageBreakAfter: 'auto'
														}}
													>
														<td>{idx + 1}</td>
														<td>
															<table
																border="0"
																width="100%"
																style={{
																	marginTop: '0',
																	marginLeft: '0',
																	marginRight: '0',
																	verticalAlign: 'top'
																}}
															>
																{typeof dt.jenis_usaha !=
																	'undefined' ? dt.jenis_usaha != '' ? (
																		<tr style={{ textTransform: 'capitalize' }}>
																			<td width="35%" style={{ paddingLeft: '20px' }}>
																				Jenis
																		</td>
																			<td width="5%">:</td>
																			<td
																				width="60%"
																				style={{
																					textTransform: 'capitalize'
																				}}
																			>
																				{dt.jenis_usaha.toLowerCase()}
																			</td>
																		</tr>
																	) : (
																			<>f </>
																		) : (
																		<> </>
																	)}

																{typeof dt.lokasi != 'undefined' ? dt.lokasi != '' ? (
																	<tr>
																		<td width="35%" style={{ paddingLeft: '20px' }}>
																			Lokasi
																		</td>
																		<td width="5%">:</td>
																		<td
																			width="60%"
																			style={{
																				textTransform: 'capitalize'
																			}}
																		>
																			{dt.lokasi.toLowerCase()}
																		</td>
																	</tr>
																) : (
																		<> </>
																	) : (
																		<> </>
																	)}
																{typeof dt.luas_kebun != 'undefined' ? dt.luas_kebun !=
																	'' ? (
																		<tr>
																			<td width="35%" style={{ paddingLeft: '20px' }}>
																				Lama Usaha
																		</td>
																			<td width="5%">:</td>
																			<td
																				width="60%"
																				style={{
																					textTransform: 'capitalize'
																				}}
																			>
																				{dt.umur.toLowerCase()}
																			</td>
																		</tr>
																	) : (
																		<> </>
																	) : (
																		<> </>
																	)}
																{typeof dt.luas_kebun != 'undefined' ? dt.luas_kebun !=
																	'' ? (
																		<tr>
																			<td width="35%" style={{ paddingLeft: '20px' }}>
																				Luas Kebun
																		</td>
																			<td width="5%">:</td>
																			<td
																				width="60%"
																				style={{
																					textTransform: 'capitalize'
																				}}
																			>
																				{dt.luas_kebun.toLowerCase()}
																			</td>
																		</tr>
																	) : (
																		<div />
																	) : (
																		<div />
																	)}
																{typeof dt.nama_tempat_usaha !=
																	'undefined' ? dt.nama_tempat_usaha != '' ? (
																		<tr>
																			<td width="35%" style={{ paddingLeft: '20px' }}>
																				Nama Tempat Usaha
																		</td>
																			<td width="5%">:</td>
																			<td
																				width="60%"
																				style={{
																					textTransform: 'capitalize'
																				}}
																			>
																				{dt.nama_tempat_usaha.toLowerCase()}
																			</td>
																		</tr>
																	) : (
																			<div />
																		) : (
																		<div />
																	)}
															</table>
														</td>
													</tr>
													{/* </li> */}
												</div>
											);
										})}
									</table>
								</td>
							</tr>
							<tr style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
								<td
									style={{
										textAlign: 'justify',
										pageBreakInside: 'avoid',
										pageBreakAfter: 'auto'
									}}
								>
									<p style={{ textIndent: '50px' }}>
										Demikianlah Surat Keterangan Usaha ini kami buat berdasarkan keterangan yang
										bersangkutan untuk dapat dipergunakan sebagaimana mestinya
									</p>
									<table border="0" width="100%" style={{ marginTop: '10px' }}>
										<tr>
											<td width="55%">&nbsp;</td>
											<td width="45%">
												<div style={{ width: 'fit-content' }}>
													<div style={{ marginBottom: '20px' }}>
														<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
															<tr>
																<td width="auto">Dikeluarkan di</td>
																<td style={{ width: '21px', textAlign: 'center' }}>:</td>
																<td style={{ textTransform: 'capitalize' }}>
																	{cetak.nama_desa}
																</td>
															</tr>
															<tr>
																<td width="auto" style={{ borderBottom: '1px solid black' }}>
																	Pada tanggal
																</td>
																<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
																<td style={{ borderBottom: '1px solid black' }}>
																	{Tanggal(new Date(cetak.tanggal_surat))}
																</td>
															</tr>
														</table>
													</div>
													<div style={{ textAlign: 'center' }}>
														<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
															{cetak.status_ttd}{' '}
															<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
																{ttd()}
															</span>
														</div>
														{cetak.jabatan_pengganti_ttd ? (
															<div style={{ textAlign: 'center' }}>
																{/* <br /> */}
																<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
																	{cetak.jabatan_pengganti_ttd}
																</span>
															</div>
														) : (
																''
															)}
														<div style={{ height: '50px' }}>{''}</div>
														<div style={{ textAlign: 'center' }}>
															<span
																style={{
																	// textTransform: 'uppercase',
																	fontWeight: 'bold',
																	borderBottom: '1px solid black'
																}}
															>
																{cetak.nama_ttd}
															</span>
															<br />
															{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
														</div>
													</div>
												</div>
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
						{/* <table border="0" width="100%">
              <tr>
                <td style={{ textAlign: "justify" }} width="50%">
                  &nbsp;
                </td>
                <td style={{ textAlign: "justify" }} width="50%">
                  <table border="0" width="100%" cellpadding="0" cellspacing="0">
                    <tr>
                      <td width="35%">Dikeluarkan di</td>
                      <td>:</td>
                      <td style={{ textTransform: "capitalize" }} >{cetak.nama_desa}</td>
                    </tr>
                    <tr>
                      <td width="35%" style={{ borderBottom: "1px solid black" }}>
                        Pada tanggal
                    </td>
                      <td style={{ borderBottom: "1px solid black" }}>:</td>
                      <td style={{ borderBottom: "1px solid black" }}>
                        {Tanggal(new Date(cetak.tanggal_surat))}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table> */}
						{/* <table border="0" width="100%" style={{ marginTop: "20px" }}>
              <tr>
                <td style={{ textAlign: "justify" }} width="50%">
                  &nbsp;
                </td>
                <td style={{ textAlign: "justify" }} width="50%">
                  <table border="0" width="100%" cellpadding="0" cellspacing="0">
                    <tr>
                      <td width="35%">Dikeluarkan di</td>
                      <td>:</td>
                      <td style={{ textTransform: "capitalize" }} >{cetak.nama_desa}</td>
                    </tr>
                    <tr>
                      <td width="35%" style={{ borderBottom: "1px solid black" }}>
                        Pada tanggal
                    </td>
                      <td style={{ borderBottom: "1px solid black" }}>:</td>
                      <td style={{ borderBottom: "1px solid black" }}>
                        {Tanggal(new Date(cetak.tanggal_surat))}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr style={{ marginTop: "20px" }}>
                <td width="50%">&nbsp;</td>
                <td
                  width="50%"
                  style={{ paddingLeft: "20px", textAlign: "center" }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {cetak.status_ttd}{" "}
                    <span style={{ textTransform: "uppercase" }}>
                      {(cetak.jabatan_ttd.toLowerCase()!= 'kepala ' + JENIS_DESA.toLowerCase()) ? cetak.jabatan_ttd + ' ' + cetak.nama_desa : 'KEPALA ' + cetak.nama_desa}
                    </span>
                    {cetak.jabatan_pengganti_ttd ? (
                      <div>
                        <br />
                        <span style={{ textTransform: 'uppercase' }} >{cetak.jabatan_pengganti_ttd}</span>
                      </div>
                    ) : (
                        ""
                      )}
                  </span>
                </td>
              </tr>
              <tr>
                <td height="50" colspan="2">
                  &nbsp;
              </td>
              </tr>
              <tr>
                <td align="center">&nbsp;</td>
                <td align="center" style={{ fontWeight: 'bold', paddingLeft: "20px", textAlign: "center" }}>
                  <span
                    style={{
                      
                      fontWeight: "bold",
                      borderBottom: "1px solid black"
                    }}
                  >
                    ( {cetak.nama_ttd} )
                  </span>
                  <br />
                  {(cetak.nip_ttd != '') ? 'NIP.: ' + cetak.nip_ttd : ''}
                </td>
              </tr>
            </table> */}
					</div>
				</div>
			);
			break;

		case 'Surat Keterangan Penghasilan':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat keterangan penghasilan
									</span>
									<br />
									{cetak.nomor}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama_pemohon}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir}, {Tanggal(new Date(cetak.tanggal_lahir))}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Jenis Kelamin
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.jenis_kelamin}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Status Perkawinan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.status_perkawinan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.agama}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Adalah benar nama tersebut di atas adalah penduduk{' '}
										<span style={{ textTransform: 'capitalize' }}>{cetak.nama_desa}</span> menetap
										dan berdomisili di{' '}
										<span style={{ textTransform: 'capitalize' }}>{cetak.alamat}</span> beserta
										dengan keluarganya. Menurut pengakuan yang bersangkutan, penghasilan dari usaha
										yang bersangkutan berkisar Rp.{' '}
										{cetak.penghasilan.toLocaleString('id-ID', {
											minimumFractionDigits: 0
										})}
										,- ({terbilang(cetak.penghasilan)} Rupiah) per bulannya
									</p>
									<p style={{ textIndent: '50px' }}>
										Demikianlah surat keterangan ini dibuat dengan sebenarnya untuk dapat
										dipergunakan seperlunya.
									</p>
								</td>
							</tr>
						</table>
						<table border="0" width="100%" style={{ marginTop: '10px' }}>
							<tr>
								<td width="55%">&nbsp;</td>
								<td width="45%">
									<div style={{ width: 'fit-content' }}>
										<div style={{ marginBottom: '20px' }}>
											<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
												<tr>
													<td width="auto">Dikeluarkan di</td>
													<td style={{ width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.nama_desa}
													</td>
												</tr>
												<tr>
													<td width="auto" style={{ borderBottom: '1px solid black' }}>
														Pada tanggal
													</td>
													<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ borderBottom: '1px solid black' }}>
														{Tanggal(new Date(cetak.tanggal_surat))}
													</td>
												</tr>
											</table>
										</div>
										<div style={{ textAlign: 'center' }}>
											<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
												{cetak.status_ttd}{' '}
												<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
													{ttd()}
												</span>
											</div>
											{cetak.jabatan_pengganti_ttd ? (
												<div style={{ textAlign: 'center' }}>
													{/* <br /> */}
													<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
														{cetak.jabatan_pengganti_ttd}
													</span>
												</div>
											) : (
													''
												)}
											<div style={{ height: '50px' }}>{''}</div>
											<div style={{ textAlign: 'center' }}>
												<span
													style={{
														// textTransform: 'uppercase',
														fontWeight: 'bold',
														borderBottom: '1px solid black'
													}}
												>
													{cetak.nama_ttd}
												</span>
												<br />
												{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
											</div>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Pengantar SKCK':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table border="0" width="100%">
							<tr>
								<td style={{ textAlign: 'justify' }} width="50%">
									&nbsp;
								</td>
								<td
									style={{
										textAlign: 'justify',
										textTransform: 'capitalize',
										paddingLeft: '53px'
									}}
									width="50%"
								>
									{cetak.nama_desa}, {Tanggal(new Date(cetak.tanggal_surat))}
								</td>
							</tr>
						</table>
						<table style={{ width: '100%' }}>
							<tr>
								<td style={{ width: '50%' }}>
									<table style={{ width: '100%' }}>
										<tbody>
											<tr>
												<td style={{ width: '17.7628%' }}>Nomor</td>
												<td style={{ width: '8.0701%' }}>:</td>
												<td style={{ width: '74.1108%' }}>{cetak.nomor}</td>
											</tr>
											<tr>
												<td style={{ width: '17.7628%' }}>Lampiran</td>
												<td style={{ width: '8.0701%' }}>: </td>
												<td style={{ width: '74.1108%' }}>Fotocopy KTP</td>
											</tr>
											<tr>
												<td style={{ width: '17.7628%' }}>Perihal</td>
												<td style={{ width: '8.0701%' }}>:</td>
												<td style={{ width: '74.1108%' }}>
													<strong>Permohonan &nbsp;SKCK</strong>
												</td>
											</tr>
										</tbody>
									</table>
								</td>
								<td style={{ width: '50%', paddingLeft: '53px' }}>
									<table border="0" width="100%">
										<tr>
											<td style={{ textAlign: 'justify' }} />
										</tr>
										<tr>
											<td style={{ textAlign: 'justify' }}>Kepada Yth.:</td>
										</tr>
										<tr>
											<td style={{ textAlign: 'justify' }}>
												Bapak Kapolres{' '}
												<span style={{ textTransform: 'uppercase' }}>{NAMA_KABUPATEN}</span></td>
										</tr>
										<tr>
											<td style={{ textAlign: 'justify' }}>
												Di-{' '}
												<span
													style={{
														textTransform: 'uppercase',
														fontWeight: 'bold',
														textDecoration: 'underline'
													}}
												>
													{NAMA_KABUPATEN}
												</span>
											</td>
										</tr>
										{/* <tr>
                        <td style={{ textAlign: "center" }}>
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              textDecoration: "underline"
                            }}
                          >
                            {KECAMATAN}
                        </span>
                        </td>
                      </tr> */}
									</table>
								</td>
							</tr>
						</table>

						<table
							border="0"
							width="90%"
							style={{
								marginTop: '40px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								paddingLeft: '50px',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '0px' }}>Assalamu'alaikum Wr. Wb.</p>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama_pemohon}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tgl. Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir},{Tanggal(cetak.tanggal_lahir)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Jenis Kelamin
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.jenis_kelamin}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.agama}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pendidikan Terakhir
											</td>
											<td>:</td>
											<td>{cetak.pendidikan_terakhir}</td>
										</tr>

										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Kewarganegaraan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.kewarganegaraan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Maksud dan Tujuan
											</td>
											<td>:</td>
											<td>Mengurus Surat Keterangan Catatan Kepolisian (SKCK)</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Keperluan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.tujuan_pembuatan}</td>
										</tr>
									</table>
								</td>
							</tr>
							{/* <tr>
                <td style={{ textAlign: "justify" }}>
                  <span style={{ paddingLeft: "50px" }}>
                    Demikianlah permohonan rekomendasi ini kami sampaikan, atas
                    bantuan dan perhatiannya kami ucapkan terima kasih.
                </span>
                </td>
              </tr> */}
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Adalah benar nama tersebut diatas penduduk {Nama_DESA}, Kec. {KECAMATAN}.
										Sepanjang sepengetahuan kami yang bersangkutan berkelakuan baik, tidak pernah
										terlibat dalam tindakan kriminal di wilayah kami, serta tidak terlibat dalam
										organisasi yang dilarang Pemerintah.
									</span>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Demikianlah rekomendasi ini kami buat dengan harapan semoga Bapak dapat
										menerbitkan <strong>SKCK</strong> untuk yang bersangkutan. Atas perhatian dan
										terkabulnya permohonan ini kami ucapkan terima kasih.
									</span>
									<table border="0" width="100%" style={{ marginTop: '10px' }}>
										<tr>
											<td width="55%">&nbsp;</td>
											<td width="45%">
												<div style={{ width: 'fit-content' }}>
													<div style={{ marginBottom: '20px' }}>
														<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
															<tr>
																<td width="auto">Dikeluarkan di</td>
																<td style={{ width: '21px', textAlign: 'center' }}>:</td>
																<td style={{ textTransform: 'capitalize' }}>
																	{cetak.nama_desa}
																</td>
															</tr>
															<tr>
																<td width="auto" style={{ borderBottom: '1px solid black' }}>
																	Pada tanggal
																</td>
																<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
																<td style={{ borderBottom: '1px solid black' }}>
																	{Tanggal(new Date(cetak.tanggal_surat))}
																</td>
															</tr>
														</table>
													</div>
													<div style={{ textAlign: 'center' }}>
														<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
															{cetak.status_ttd}{' '}
															<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
																{ttd()}
															</span>
														</div>
														{cetak.jabatan_pengganti_ttd ? (
															<div style={{ textAlign: 'center' }}>
																{/* <br /> */}
																<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
																	{cetak.jabatan_pengganti_ttd}
																</span>
															</div>
														) : (
																''
															)}
														<div style={{ height: '50px' }}>{''}</div>
														<div style={{ textAlign: 'center' }}>
															<span
																style={{
																	// textTransform: 'uppercase',
																	fontWeight: 'bold',
																	borderBottom: '1px solid black'
																}}
															>
																{cetak.nama_ttd}
															</span>
															<br />
															{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
														</div>
													</div>
												</div>
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>

						{/* <table border="0" width="100%" style={{ marginTop: "20px" }}>
              <tr>
                <td width="50%">&nbsp;</td>
                <td
                  width="50%"
                  style={{ paddingLeft: "20px", textAlign: "center" }}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {cetak.status_ttd}{" "}
                    <span style={{ textTransform: "uppercase" }}>
                      {(cetak.jabatan_ttd.toLowerCase()!= 'kepala ' + JENIS_DESA.toLowerCase()) ? cetak.jabatan_ttd + ' ' + cetak.nama_desa : 'KEPALA ' + cetak.nama_desa}
                    </span>
                    {cetak.jabatan_pengganti_ttd ? (
                      <div>
                        <br />
                        <span style={{ textTransform: 'uppercase' }} >{cetak.jabatan_pengganti_ttd}</span>
                      </div>
                    ) : (
                        ""
                      )}
                  </span>
                </td>
              </tr>
              <tr>
                <td height="50" colspan="2">
                  &nbsp;
              </td>
              </tr>
              <tr>
                <td align="center">&nbsp;</td>
                <td align="center" style={{ paddingLeft: '20px', fontWeight: 'bold' }}>
                  <span
                    style={{
                      
                      fontWeight: "bold",
                      borderBottom: "1px solid black"
                    }}
                  >
                    ( {cetak.nama_ttd} )
                  </span>
                  <br />
                  {(cetak.nip_ttd != '') ? 'NIP.: ' + cetak.nip_ttd : ''}
                </td>
              </tr>
            </table> */}
					</div>
				</div>
			);
			break;

		case 'Surat Keterangan Belum Memiliki Rumah':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<title />
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n            td{\n                vertical-align: top;\n            }\n        '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat keterangan belum memiliki rumah
									</span>
									<br />
									{cetak.nomor}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>

											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama_pemohon}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir}, {Tanggal(cetak.tanggal_lahir)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Jenis Kelamin
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.jenis_kelamin}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.agama}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												No. KK
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.kk}</td>
										</tr>

										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Adalah benar nama tersebut diatas &nbsp;saat ini berdomisili di{' '}
										<span style={{ textTransform: 'capitalize' }}> {cetak.alamat} </span>
										. Selanjutnya kami terangkan bahwa sepanjang sepengetahuan kami yang
										bersangkutan &nbsp;sampai saat ini &nbsp;
										<strong>Belum Memiliki Rumah Sendiri</strong> di {Nama_DESA} Kecamatan{' '}
										{KECAMATAN} ({cetak.lokasi}
										).
									</span>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Surat Keterangan ini kami berikan kepada yang bersangkutan guna :&nbsp;
										<strong>
											<u>‘{cetak.tujuan_pembuatan.toUpperCase()}’.</u>
										</strong>
									</span>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Demikianlah surat keterangan &nbsp;ini dibuat dengan sebenarnya untuk dapat
										dipergunakan seperlunya.
									</span>
								</td>
							</tr>
						</table>

						<table border="0" width="100%" style={{ marginTop: '10px' }}>
							<tr>
								<td width="55%">&nbsp;</td>
								<td width="45%">
									<div style={{ width: 'fit-content' }}>
										<div style={{ marginBottom: '20px' }}>
											<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
												<tr>
													<td width="auto">Dikeluarkan di</td>
													<td style={{ width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.nama_desa}
													</td>
												</tr>
												<tr>
													<td width="auto" style={{ borderBottom: '1px solid black' }}>
														Pada tanggal
													</td>
													<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ borderBottom: '1px solid black' }}>
														{Tanggal(new Date(cetak.tanggal_surat))}
													</td>
												</tr>
											</table>
										</div>
										<div style={{ textAlign: 'center' }}>
											<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
												{cetak.status_ttd}{' '}
												<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
													{ttd()}
												</span>
											</div>
											{cetak.jabatan_pengganti_ttd ? (
												<div style={{ textAlign: 'center' }}>
													{/* <br /> */}
													<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
														{cetak.jabatan_pengganti_ttd}
													</span>
												</div>
											) : (
													''
												)}
											<div style={{ height: '50px' }}>{''}</div>
											<div style={{ textAlign: 'center' }}>
												<span
													style={{
														// textTransform: 'uppercase',
														fontWeight: 'bold',
														borderBottom: '1px solid black'
													}}
												>
													{cetak.nama_ttd}
												</span>
												<br />
												{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
											</div>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Pernyataan Kehilangan':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<title />
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tbody>
								<tr>
									<td width="100" align="center">
										<img src={logo} width="80px" />
									</td>
									<td align="center">
										<div style={{ fontWeight: 'bold' }}>
											<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
												pemerintah {NAMA_KABUPATEN}
											</span>
											<br />
											<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
												kecamatan {KECAMATAN}
											</span>
											<br />
											<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
												kantor {JABATAN_TERTINGGI} {DESA}
											</span>
											<br />
											<span
												style={{
													fontSize: '16px',
													textTransform: 'capitalize'
												}}
											>
												{ALAMAT_KANTOR_DESA}
											</span>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '20px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tbody>
								<tr>
									<td align="center" style={{ paddingBottom: '20px' }}>
										{' '}
										<span
											style={{
												textTransform: 'uppercase',
												fontWeight: 'bold',
												textDecoration: 'underline'
											}}
										>
											surat keterangan kehilangan
										</span>
										<br />
										{cetak.nomor}
									</td>
								</tr>
								<tr>
									<td style={{ textAlign: 'justify' }}>
										<p style={{ textIndent: '50px' }}>
											Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
											{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
										</p>
									</td>
								</tr>
								<tr>
									<td style={{ textAlign: 'justify' }}>
										<table border="0" width="100%" style={{ marginTop: '0px' }}>
											<tbody>
												<tr>
													<td width="35%" style={{ paddingLeft: '50px' }}>
														Nama Lengkap
													</td>
													<td>:</td>
													<td
														style={{
															textTransform: 'uppercase',
															fontWeight: 'bold'
														}}
													>
														{' '}
														{cetak.nama_pemohon}
													</td>
												</tr>
												<tr>
													<td width="35%" style={{ paddingLeft: '50px' }}>
														Tempat / Tanggal Lahir
													</td>
													<td>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.tempat_lahir},{Tanggal(cetak.tanggal_lahir)}
													</td>
												</tr>
												<tr>
													<td width="35%" style={{ paddingLeft: '50px' }}>
														Jenis Kelamin
													</td>
													<td>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.jenis_kelamin}
													</td>
												</tr>
												<tr>
													<td width="35%" style={{ paddingLeft: '50px' }}>
														Pekerjaan
													</td>
													<td>:</td>
													<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
												</tr>
												<tr>
													<td width="35%" style={{ paddingLeft: '50px' }}>
														Agama
													</td>
													<td>:</td>
													<td style={{ textTransform: 'capitalize' }}>{cetak.agama}</td>
												</tr>
												<tr>
													<td width="35%" style={{ paddingLeft: '50px' }}>
														Status Perkawinan
													</td>
													<td>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.status_perkawinan}
													</td>
												</tr>
												<tr>
													<td width="35%" style={{ paddingLeft: '50px' }}>
														NIK
													</td>
													<td>:</td>
													<td>{cetak.nik}</td>
												</tr>
												<tr>
													<td width="35%" style={{ paddingLeft: '50px' }}>
														Alamat
													</td>
													<td>:</td>
													<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
								<tr>
									<td style={{ textAlign: 'justify' }}>
										<span style={{ paddingLeft: '50px' }}>
											Adalah benar nama tersebut adalah warga {Nama_DESA}, selanjutnya kami
											terangkan bahwa yang bersangkutan telah kehilangan barang berupa :
										</span>
									</td>
								</tr>
								<tr>
									<td style={{ textAlign: 'justify' }}>
										<table border="0" width="100%" style={{ marginTop: '0px' }}>
											<tbody>
												<tr>
													<td width="35%" style={{ paddingLeft: '50px' }}>
														Nama Barang
													</td>
													<td>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.tujuan_pembuatan}
														{/* <table
                          border="0"
                          style={{
                            margin: "0px 0px 0px 0px",
                            padding: "0px 0px 0px 0px"
                          }}
                        >
                          {cetak.tujuan_pembuatan
                            .split(",")
                            .map((dt, idn) => {
                              return (
                                <tr>
                                  <td style={{ width: "2%" }}>{idn + 1}.</td>
                                  <td>{dt}</td>
                                </tr>
                              );
                            })}
                        </table> */}
													</td>
												</tr>
												<tr>
													<td width="35%" style={{ paddingLeft: '50px' }}>
														Atas Nama
													</td>
													<td>:</td>
													<td
														style={{
															textTransform: 'uppercase',
															fontWeight: 'bold'
														}}
													>
														{cetak.nama_pemohon}
													</td>
												</tr>
												<tr>
													<td width="35%" style={{ paddingLeft: '50px' }}>
														Tempat Hilang
													</td>
													<td>:</td>
													<td style={{ textTransform: 'capitalize' }}>{cetak.lokasi}</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
								<tr style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
									<td
										style={{
											textAlign: 'justify',
											pageBreakInside: 'avoid',
											pageBreakAfter: 'auto'
										}}
									>
										<span style={{ paddingLeft: '50px' }}>
											Demikianlah Surat Keterangan ini kami berikan kepada yang bersangkutan untuk
											dapat dipergunakan sebagaimana perlunya.
											{/* Surat Keterangan ini dibuat atas laporan dari : */}
										</span>
										<table border="0" width="100%" style={{ marginTop: '10px' }}>
											<tr>
												<td width="55%">&nbsp;</td>
												<td width="45%">
													<div style={{ width: 'fit-content' }}>
														<div style={{ marginBottom: '20px' }}>
															<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
																<tr>
																	<td width="auto">Dikeluarkan di</td>
																	<td style={{ width: '21px', textAlign: 'center' }}>:</td>
																	<td style={{ textTransform: 'capitalize' }}>
																		{cetak.nama_desa}
																	</td>
																</tr>
																<tr>
																	<td width="auto" style={{ borderBottom: '1px solid black' }}>
																		Pada tanggal
																	</td>
																	<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
																	<td style={{ borderBottom: '1px solid black' }}>
																		{Tanggal(new Date(cetak.tanggal_surat))}
																	</td>
																</tr>
															</table>
														</div>
														<div style={{ textAlign: 'center' }}>
															<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
																{cetak.status_ttd}{' '}
																<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
																	{ttd()}
																</span>
															</div>
															{cetak.jabatan_pengganti_ttd ? (
																<div style={{ textAlign: 'center' }}>
																	{/* <br /> */}
																	<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
																		{cetak.jabatan_pengganti_ttd}
																	</span>
																</div>
															) : (
																	''
																)}
															<div style={{ height: '50px' }}>{''}</div>
															<div style={{ textAlign: 'center' }}>
																<span
																	style={{
																		// textTransform: 'uppercase',
																		fontWeight: 'bold',
																		borderBottom: '1px solid black'
																	}}
																>
																	{cetak.nama_ttd}
																</span>
																<br />
																{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
															</div>
														</div>
													</div>
												</td>
											</tr>
										</table>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Keterangan Kepemilikan Rumah':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat keterangan kepemilikan rumah
									</span>
									<br />
									{cetak.nomor}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama_pemohon}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir}, {Tanggal(new Date(cetak.tanggal_lahir))}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.agama}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Status Perkawinan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.status_perkawinan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nomor NIK
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Adalah benar nama tersebut di atas adalah penduduk{' '}
										<span style={{ textTransform: 'capitalize' }}>{cetak.nama_desa}</span> menetap
										dan berdomisili di{' '}
										<span style={{ textTransform: 'capitalize' }}>{cetak.alamat}</span>
										. Selanjutnya kami terangkan bahwa yang bersangkutan benar - benar telah
										memiliki rumah pribadi yang terletak di {cetak.tujuan_pembuatan} yang saat ini
										ditempati oleh nama tersebut beserta keluarganya
									</p>
									<p style={{ textIndent: '50px' }}>
										Demikianlah surat ini dibuat dengan sebenarnya untuk dapat dipergunakan
										seperlunya.
									</p>
								</td>
							</tr>
						</table>
						<table border="0" width="100%" style={{ marginTop: '10px' }}>
							<tr>
								<td width="55%">&nbsp;</td>
								<td width="45%">
									<div style={{ width: 'fit-content' }}>
										<div style={{ marginBottom: '20px' }}>
											<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
												<tr>
													<td width="auto">Dikeluarkan di</td>
													<td style={{ width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.nama_desa}
													</td>
												</tr>
												<tr>
													<td width="auto" style={{ borderBottom: '1px solid black' }}>
														Pada tanggal
													</td>
													<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ borderBottom: '1px solid black' }}>
														{Tanggal(new Date(cetak.tanggal_surat))}
													</td>
												</tr>
											</table>
										</div>
										<div style={{ textAlign: 'center' }}>
											<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
												{cetak.status_ttd}{' '}
												<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
													{ttd()}
												</span>
											</div>
											{cetak.jabatan_pengganti_ttd ? (
												<div style={{ textAlign: 'center' }}>
													{/* <br /> */}
													<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
														{cetak.jabatan_pengganti_ttd}
													</span>
												</div>
											) : (
													''
												)}
											<div style={{ height: '50px' }}>{''}</div>
											<div style={{ textAlign: 'center' }}>
												<span
													style={{
														// textTransform: 'uppercase',
														fontWeight: 'bold',
														borderBottom: '1px solid black'
													}}
												>
													{cetak.nama_ttd}
												</span>
												<br />
												{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
											</div>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Keterangan Jalan':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<title />
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n            td {\n                vertical-align: top;\n            }\n\n            ol {\n                margin-top: 0px;\n                margin-bottom: 0px;\n                padding-left: 14px;\n            }\n\n            li {\n                font-size: 12px;\n            }\n        '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '20px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat keterangan jalan
									</span>
									<br />
									{cetak.nomor}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini memberikan Surat Keterangan Jalan kepada
										:
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama_pemohon}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir},{Tanggal(cetak.tanggal_lahir)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Jenis Kelamin
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.jenis_kelamin}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.agama}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Status Perkawinan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.status_perkawinan}</td>
										</tr>

										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Adalah benar nama tersebut warga {Nama_DESA} dan berdomisili di {cetak.alamat}{' '}
										beserta dengan seluruh anggota keluarganya. Adapun yang bersangkutan mengadakan
										perjalanan ke :
									</span>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tujuan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.detail.tujuan_perjalanan}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Rencana Berangkat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{HariTanggal(new Date(cetak.detail.rencana_berangkat))}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alat Transportasi
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.detail.transportasi}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												No. Pol
											</td>
											<td>:</td>
											<td>{cetak.detail.no_pol}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Supir
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.detail.nama_supir}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/ Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.detail.tempat_lahir_supir},
												{Tanggal(cetak.detail.tanggal_lahir_supir)}
											</td>
										</tr>

										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Barang Bawaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.detail.barang}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pengikut
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.detail.pengikut}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Maksud dan Tujuan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.tujuan_pembuatan}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
								<td
									style={{
										textAlign: 'justify',
										pageBreakInside: 'avoid',
										pageBreakAfter: 'auto'
									}}
								>
									<span style={{ paddingLeft: '50px' }}>
										Demikianlah Surat Keterangan Jalan ini kami berikan kepada yang bersangkutan,
										untuk dapat dipergunakan seperlunya.
									</span>
									<table border="0" width="100%" style={{ marginTop: '10px' }}>
										<tr>
											<td width="55%">&nbsp;</td>
											<td width="45%">
												<div style={{ width: 'fit-content' }}>
													<div style={{ marginBottom: '20px' }}>
														<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
															<tr>
																<td width="auto">Dikeluarkan di</td>
																<td style={{ width: '21px', textAlign: 'center' }}>:</td>
																<td style={{ textTransform: 'capitalize' }}>
																	{cetak.nama_desa}
																</td>
															</tr>
															<tr>
																<td width="auto" style={{ borderBottom: '1px solid black' }}>
																	Pada tanggal
																</td>
																<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
																<td style={{ borderBottom: '1px solid black' }}>
																	{Tanggal(new Date(cetak.tanggal_surat))}
																</td>
															</tr>
														</table>
													</div>
													<div style={{ textAlign: 'center' }}>
														<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
															{cetak.status_ttd}{' '}
															<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
																{ttd()}
															</span>
														</div>
														{cetak.jabatan_pengganti_ttd ? (
															<div style={{ textAlign: 'center' }}>
																{/* <br /> */}
																<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
																	{cetak.jabatan_pengganti_ttd}
																</span>
															</div>
														) : (
																''
															)}
														<div style={{ height: '50px' }}>{''}</div>
														<div style={{ textAlign: 'center' }}>
															<span
																style={{
																	// textTransform: 'uppercase',
																	fontWeight: 'bold',
																	borderBottom: '1px solid black'
																}}
															>
																{cetak.nama_ttd}
															</span>
															<br />
															{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
														</div>
													</div>
												</div>
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Keterangan Kematian':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat keterangan kematian
									</span>
									<br />
									{cetak.nomor}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													fontWeight: 'bold',
													textTransform: 'uppercase'
												}}
											>
												{cetak.nama}
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td>
												{cetak.tempat_lahir}, {Tanggal(new Date(cetak.tanggal_lahir))}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td>{cetak.detail.alamat_pelapor}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td colspan="3" width="35%" style={{ paddingLeft: '50px' }}>
												Telah meninggal dunia pada
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Hari/Tanggal
											</td>
											<td>:</td>
											<td>{HariTanggal(new Date(cetak.detail.waktu_meninggal))}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Jam
											</td>
											<td>:</td>
											<td>
												{new Date(cetak.detail.waktu_meninggal).toLocaleTimeString('id-ID', {
													hour: '2-digit',
													minute: '2-digit'
												})}{' '}
												WIB
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Disebabkan karena
											</td>
											<td>:</td>
											<td>{cetak.detail.sebab_meninggal}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Dimakamkan di
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.detail.tempat_makam}</td>
										</tr>
										<tr>
											<td colspan="3" width="35%" style={{ paddingLeft: '50px' }}>
												Demikianlah surat keterangan ini dibuat berdasarkan laporan dari :
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.detail.nama_pelapor}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.detail.tempat_lahir_pelapor},{' '}
												{Tanggal(new Date(cetak.detail.tanggal_lahir_pelapor))}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.detail.nik_pelapor}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.detail.agama_pelapor}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.detail.pekerjaan_pelapor}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.detail.alamat_pelapor}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Hubungan Pelapor
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.detail.hubungan_pelapor}
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
						<table border="0" width="100%" style={{ marginTop: '10px' }}>
							<tr>
								<td width="55%">&nbsp;</td>
								<td width="45%">
									<div style={{ width: 'fit-content' }}>
										<div style={{ marginBottom: '20px' }}>
											<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
												<tr>
													<td width="auto">Dikeluarkan di</td>
													<td style={{ width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.nama_desa}
													</td>
												</tr>
												<tr>
													<td width="auto" style={{ borderBottom: '1px solid black' }}>
														Pada tanggal
													</td>
													<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ borderBottom: '1px solid black' }}>
														{Tanggal(new Date(cetak.tanggal_surat))}
													</td>
												</tr>
											</table>
										</div>
										<div style={{ textAlign: 'center' }}>
											<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
												{cetak.status_ttd}{' '}
												<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
													{ttd()}
												</span>
											</div>
											{cetak.jabatan_pengganti_ttd ? (
												<div style={{ textAlign: 'center' }}>
													{/* <br /> */}
													<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
														{cetak.jabatan_pengganti_ttd}
													</span>
												</div>
											) : (
													''
												)}
											<div style={{ height: '50px' }}>{''}</div>
											<div style={{ textAlign: 'center' }}>
												<span
													style={{
														// textTransform: 'uppercase',
														fontWeight: 'bold',
														borderBottom: '1px solid black'
													}}
												>
													{cetak.nama_ttd}
												</span>
												<br />
												{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
											</div>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Keterangan Pernah Menikah':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat keterangan pernah menikah
									</span>
									<br />
									{cetak.nomor}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%" style={{ marginTop: '0px' }}>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama
											</td>
											<td>:</td>
											<td>
												<span
													style={{
														textTransform: 'uppercase',
														fontWeight: 'bold'
													}}
												>
													{cetak.nama_pemohon}
												</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tangal Lahir
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.tempat_lahir}
												</span>
												, {Tanggal(new Date(cetak.tanggal_lahir))}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>{cetak.agama}</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Status Perkawinan
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.status_perkawinan}
												</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>{cetak.alamat}</span>
											</td>
										</tr>
										<tr>
											<td
												colspan="3"
												// width="35%"
												// colspan="2"
												style={{ paddingLeft: '50px' }}
											>
												Telah melaksanakan akad nikah dengan
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td>
												<span
													style={{
														textTransform: 'uppercase',
														fontWeight: 'bold'
													}}
												>
													{cetak.nama_pasangan}
												</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.tempat_lahir_pasangan},{' '}
													{Tanggal(new Date(cetak.tanggal_lahir_pasangan))}
												</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.pekerjaan_pasangan}
												</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Status Perkawinan
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.status_perkawinan_pasangan}
												</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik_pasangan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat Terakhir
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.alamat_pasangan}
												</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat Menikah
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.tempat_menikah}
												</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pada
											</td>
											<td>:</td>
											<td>{Tanggal(new Date(cetak.tanggal_menikah))}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Mas Kawin
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>{cetak.mas_kawin}</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Wali Nikah
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>{cetak.wali_nikah}</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Saksi Nikah
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>{cetak.saksi_nikah}</span>
											</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '52px' }}>
										Demikianlah surat keterangan ini dibuat berdasarkan laporan dari :
									</span>
									<table border="0" width="100%" style={{ marginTop: '0px' }}>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td>
												<span
													style={{
														textTransform: 'uppercase',
														fontWeight: 'bold'
													}}
												>
													{cetak.nama_pelapor}
												</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.tempat_lahir_pelapor},{' '}
													{Tanggal(new Date(cetak.tanggal_lahir_pelapor))}
												</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik_pelapor}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.agama_pelapor}
												</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.pekerjaan_pelapor}
												</span>
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td>
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.alamat_pelapor}
												</span>
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
						<table border="0" width="100%" style={{ marginTop: '10px' }}>
							<tr>
								<td width="55%">&nbsp;</td>
								<td width="45%">
									<div style={{ width: 'fit-content' }}>
										<div style={{ marginBottom: '20px' }}>
											<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
												<tr>
													<td width="auto">Dikeluarkan di</td>
													<td style={{ width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.nama_desa}
													</td>
												</tr>
												<tr>
													<td width="auto" style={{ borderBottom: '1px solid black' }}>
														Pada tanggal
													</td>
													<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ borderBottom: '1px solid black' }}>
														{Tanggal(new Date(cetak.tanggal_surat))}
													</td>
												</tr>
											</table>
										</div>
										<div style={{ textAlign: 'center' }}>
											<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
												{cetak.status_ttd}{' '}
												<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
													{ttd()}
												</span>
											</div>
											{cetak.jabatan_pengganti_ttd ? (
												<div style={{ textAlign: 'center' }}>
													{/* <br /> */}
													<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
														{cetak.jabatan_pengganti_ttd}
													</span>
												</div>
											) : (
													''
												)}
											<div style={{ height: '50px' }}>{''}</div>
											<div style={{ textAlign: 'center' }}>
												<span
													style={{
														// textTransform: 'uppercase',
														fontWeight: 'bold',
														borderBottom: '1px solid black'
													}}
												>
													{cetak.nama_ttd}
												</span>
												<br />
												{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
											</div>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Pengantar Pindah Nikah':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<title />
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n            td {\n                vertical-align: top;\n            }\n        '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table border="0" width="100%">
							<tr>
								<td style={{ textAlign: 'justify' }} width="50%">
									&nbsp;
								</td>
								<td width="50%" style={{ textTransform: 'capitalize' }}>
									{cetak.nama_desa}, {Tanggal(new Date(cetak.tanggal_surat))}
								</td>
							</tr>
						</table>

						<table style={{ width: '100%' }}>
							<tbody>
								<tr>
									<td style={{ width: '50%' }}>
										<table style={{ width: '100%' }}>
											<tbody>
												<tr>
													<td style={{ width: '17.7628%' }}>Nomor</td>
													<td style={{ width: '8.0701%' }}>:</td>
													<td style={{ width: '74.1108%' }}>{cetak.nomor}</td>
												</tr>
												<tr>
													<td style={{ width: '17.7628%' }}>Lampiran</td>
													<td style={{ width: '8.0701%' }}>: </td>
													<td style={{ width: '74.1108%' }}>Satu berkas N.A </td>
												</tr>
												<tr>
													<td style={{ width: '17.7628%' }}>Perihal</td>
													<td style={{ width: '8.0701%' }}>:</td>
													<td style={{ width: '74.1108%' }}>
														<strong>Rekomendasi Pindah Nikah</strong>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
									<td style={{ width: '50%' }}>
										<table border="0" width="100%">
											<tr>
												<td style={{ textAlign: 'justify' }} />
											</tr>
											<tr>
												<td style={{ textAlign: 'justify' }}>Kepada Yth.:</td>
											</tr>
											<tr>
												<td style={{ textAlign: 'justify' }}>Bapak KUA Kec. {KECAMATAN}</td>
											</tr>
											<tr>
												<td style={{ textAlign: 'justify' }}>
													Di-{' '}
													<span
														style={{
															textTransform: 'uppercase',
															fontWeight: 'bold',
															textDecoration: 'underline'
														}}
													>
														{DESA}
													</span>
												</td>
											</tr>
											{/* <tr>
                        <td style={{ textAlign: "center" }}>
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontWeight: "bold",
                              textDecoration: "underline"
                            }}
                          >
                            {DESA}
                        </span>
                        </td>
                      </tr> */}
										</table>
									</td>
								</tr>
							</tbody>
						</table>
						<table
							border="0"
							width="90%"
							style={{
								marginTop: 'auto',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p>Assalamu'alaikum Wr. Wb.</p>

									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, dengan ini memohon rekomendasi pindah nikah untuk :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													fontWeight: 'bold',
													textTransform: 'uppercase'
												}}
											>
												{cetak.nama_pemohon}
												&nbsp;
												{cetak.jenis_kelamin.toLowerCase() == 'laki-laki' ? 'bin' : 'binti'}{' '}
												{cetak.nama_nasab_p1}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir},{Tanggal(cetak.tanggal_lahir)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.agama}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Status Perkawinan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.status_perkawinan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>

							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>Untuk melaksanakan pernikahan dengan</span>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													fontWeight: 'bold',
													textTransform: 'uppercase'
												}}
											>
												{cetak.nama_pasangan}
												&nbsp;
												{cetak.jenis_kelamin_pasangan.toLowerCase() == 'laki-laki' ? 'bin' : 'binti'}{' '}
												{cetak.nama_nasab_p2}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir_pasangan},
												{Tanggal(cetak.tanggal_lahir_pasangan)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan_pasangan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.agama_pasangan}</td>
										</tr>

										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik_pasangan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat_pasangan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat Nikah di
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.tempat_nikah}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Demikianlah permohonan rekomendasi ini kami sampaikan, atas bantuan dan
										perhatiannya kami ucapkan terima kasih.
									</span>
								</td>
							</tr>
						</table>

						<table border="0" width="100%" style={{ marginTop: '10px' }}>
							<tr>
								<td width="55%">&nbsp;</td>
								<td width="45%">
									<div style={{ width: 'fit-content' }}>
										<div style={{ marginBottom: '20px' }}>
											<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
												<tr>
													<td width="auto">Dikeluarkan di</td>
													<td style={{ width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.nama_desa}
													</td>
												</tr>
												<tr>
													<td width="auto" style={{ borderBottom: '1px solid black' }}>
														Pada tanggal
													</td>
													<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ borderBottom: '1px solid black' }}>
														{Tanggal(new Date(cetak.tanggal_surat))}
													</td>
												</tr>
											</table>
										</div>
										<div style={{ textAlign: 'center' }}>
											<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
												{cetak.status_ttd}{' '}
												<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
													{ttd()}
												</span>
											</div>
											{cetak.jabatan_pengganti_ttd ? (
												<div style={{ textAlign: 'center' }}>
													{/* <br /> */}
													<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
														{cetak.jabatan_pengganti_ttd}
													</span>
												</div>
											) : (
													''
												)}
											<div style={{ height: '50px' }}>{''}</div>
											<div style={{ textAlign: 'center' }}>
												<span
													style={{
														// textTransform: 'uppercase',
														fontWeight: 'bold',
														borderBottom: '1px solid black'
													}}
												>
													{cetak.nama_ttd}
												</span>
												<br />
												{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
											</div>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Pernyataan Belum Menikah':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat pernyataan belum pernah nikah
									</span>
									{/* <br />
                {cetak.nomor_surat} */}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '0px' }}>Yang bertanda tangan dibawah ini yakni : </p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama_pemohon}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												{cetak.jenis_kelamin.toLowerCase() == 'laki-laki' ? 'Bin' : 'Binti'}
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama_ayah_kandung}
											</td>
										</tr>

										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir},{Tanggal(cetak.tanggal_lahir)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Status Perkawinan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.status_perkawinan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Dengan ini menyatakan sesungguhnya bahwa saya sampai saat ini belum pernah
										menikah / kawin dengan siapapun juga dalam arti kata masih "
										{cetak.jenis_kelamin.toLowerCase() == 'laki-laki' ? 'Jejaka' : 'Gadis'}" serta belum pernah
										tercatat pada buku nikah P3NTR dalam wilayah Republik Indonesia, apabila
										dikemudian hari ternyata tidak benar atau palsu maka saya bersedia di tuntut
										secara hukum/undang-undang yang berlaku dan tidak melibatkan pejabat yang ikut
										bertanda tangan pada surat pernyataan ini.
									</span>
								</td>
							</tr>

							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Demikianlah surat keterangan pernyataan ini saya buat dengan benar dan
										sungguh-sungguh dan dapat dipergunakan seperlunya.
									</span>
								</td>
							</tr>
						</table>
						<table border="0" width="100%">
							<tr>
								<td style={{ textAlign: 'justify' }} width="50%">
									&nbsp;
								</td>
								<td style={{ textAlign: 'center', textTransform: 'capitalize' }} width="50%">
									{cetak.nama_desa}, {Tanggal(new Date(cetak.tanggal_surat))}{' '}
								</td>
							</tr>
						</table>
						<table border="0" width="100%" style={{ marginTop: '20px' }}>
							<tr>
								<td width="50%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span style={{ fontWeight: 'bold' }}>Saksi keluarga,</span>
								</td>
								<td width="50%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span style={{ fontWeight: 'bold' }}>Saya yang menyatakan,</span>
								</td>
							</tr>
							<tr>
								<td width="50%" align="center" style={{ fontWeight: 'bold' }}>
									{cetak.hubungan_saksi2 != '' ? (
										<div>
											{' '}
											<table>
												<tr style={{ textTransform: 'capitalize' }} >
													<td
														width="50%"
														style={{ paddingLeft: '20px', textAlign: 'center' }}
													>
														{cetak.hubungan_saksi1}
													</td>
													<td
														width="50%"
														style={{ paddingLeft: '20px', textAlign: 'center' }}
													>
														{cetak.hubungan_saksi2}
													</td>
												</tr>
											</table>
										</div>
									) : (
											// <div>{cetak.hubungan_saksi1}</div>
											<div>
												{' '}
												<table style={{ marginTop: '0px' }}>
													<tr style={{ textTransform: 'capitalize' }} >
														<td
															style={{ paddingLeft: '20px', textAlign: 'center' }}
														>
															{cetak.hubungan_saksi1}
														</td>
													</tr>
												</table>
											</div>
										)}
								</td>
								<td width="50%" style={{ paddingLeft: '20px', textAlign: 'center' }} />
							</tr>
							<tr>
								<td height="50" colspan="2">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td
									width="50%"
									align="center"
									style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
								>
									{cetak.nama_saksi2 != '' ? (
										<div>
											{' '}
											<table>
												<tr>
													<td
														width="50%"
														style={{
															paddingLeft: '20px',
															textAlign: 'center',
															fontWeight: 'bold',
															textTransform: 'uppercase'
														}}
													>
														<span
															style={{
																textTransform: 'uppercase',
																fontWeight: 'bold',
																borderBottom: '1px solid black'
															}}
														>
															{cetak.nama_saksi1}
														</span>
													</td>
													<td
														width="50%"
														style={{
															paddingLeft: '20px',
															textAlign: 'center',
															fontWeight: 'bold',
															textTransform: 'uppercase'
														}}
													>
														<span
															style={{
																textTransform: 'uppercase',
																fontWeight: 'bold',
																borderBottom: '1px solid black'
															}}
														>
															{cetak.nama_saksi2}
														</span>
													</td>
												</tr>
											</table>
										</div>
									) : (
											// <div>{cetak.nama_saksi1}</div>
											<div>
												{' '}
												<table>
													<tr>
														<td
															style={{
																paddingLeft: '20px',
																textAlign: 'center',
																fontWeight: 'bold',
																textTransform: 'uppercase'
															}}
														>
															<span
																style={{
																	textTransform: 'uppercase',
																	fontWeight: 'bold',
																	borderBottom: '1px solid black'
																}}
															>
																{cetak.nama_saksi1}
															</span>
														</td>
													</tr>
												</table>
											</div>
										)}
								</td>
								<td width="50%" align="center">
									<table>
										<tr>
											<td width="100%" style={{ textAlign: 'center', fontWeight: 'bold' }}>
												<span
													style={{
														textTransform: 'uppercase',
														fontWeight: 'bold',
														borderBottom: '1px solid black'
													}}
												>
													{cetak.nama_pemohon}
												</span>
												{/* {cetak.nama_pemohon} */}
											</td>
										</tr>
									</table>
									{/* <span
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      borderBottom: "1px solid black"
                    }}
                  >
                    {cetak.nama_pemohon}
                  </span> */}
								</td>
							</tr>
						</table>

						<table border="0" width="100%" style={{ marginTop: '20px' }}>
							<tr>
								<td width="100%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span>Mengetahui :</span>
								</td>
							</tr>
							<tr>
								<td width="100%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span style={{ fontWeight: 'bold' }}>
										{cetak.status_ttd}{' '}
										<span style={{ textTransform: 'uppercase' }}>
											{ttd()}
											{/* {cetak.jabatan_ttd.toLowerCase() != 'kepala ' + JENIS_DESA.toLowerCase() ? (
												cetak.jabatan_ttd + ' ' + cetak.nama_desa
											) : (
												'KEPALA ' + cetak.nama_desa
											)} */}
										</span>
										{cetak.jabatan_pengganti_ttd ? (
											<div>
												{/* <br /> */}
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.jabatan_pengganti_ttd}
												</span>
											</div>
										) : (
												''
											)}
									</span>
								</td>
							</tr>
							<tr>
								<td height="50" colspan="2">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td width="100%" align="center" style={{ paddingLeft: '20px' }}>
									<span
										style={{
											fontWeight: 'bold',
											borderBottom: '1px solid black',
											// textTransform: 'uppercase'
										}}
									>
										{cetak.nama_ttd}
									</span>
									<br />
									<span
										style={{
											textTransform: 'uppercase',
											// fontWeight: 'bold'
										}}
									>
										{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
									</span>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Pernyataan Tidak Menikah':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat pernyataan tidak menikah
									</span>
									{/* <br />
                {cetak.nomor_surat} */}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '0px' }}>Yang bertanda tangan dibawah ini yakni : </p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama_pemohon}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												{cetak.jenis_kelamin.toLowerCase() == 'laki-laki' ? 'Bin' : 'Binti'}
											</td>
											<td>:</td>
											<td style={{ textTransform: 'uppercase' }}>{cetak.nama_ayah_kandung}</td>
										</tr>

										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir}, {Tanggal(cetak.tanggal_lahir)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Status Perkawinan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.status_perkawinan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Dengan ini menyatakan sesungguhnya bahwa saya sampai saat ini berstatus{' '}
										{cetak.status_perkawinan} dan tercatat pada{' '}
										{cetak.status_perkawinan == 'Cerai hidup' ? (
											'Surat Akta Cerai'
										) : (
												'Surat Akta Kematian'
											)}{' '}
										{cetak.nomor_surat_akta}, pada {HariTanggal(cetak.tanggal_surat_akta)}
									</span>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Apabila dikemudian hari ternyata tidak benar atau palsu maka saya bersedia di
										tuntut secara hukum/undang-undang yang berlaku dan tidak melibatkan pejabat yang
										ikut bertanda tangan pada surat pernyataan ini.
									</span>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Demikianlah surat keterangan pernyataan ini saya buat dengan benar dan
										sungguh-sungguh dan dapat dipergunakan seperlunya.
									</span>
								</td>
							</tr>
						</table>
						<table border="0" width="100%">
							<tr>
								<td style={{ textAlign: 'justify' }} width="50%">
									&nbsp;
								</td>
								<td style={{ textAlign: 'center', textTransform: 'capitalize' }} width="50%">
									{cetak.nama_desa}, {Tanggal(new Date(cetak.tanggal_surat))}{' '}
								</td>
							</tr>
						</table>
						<table border="0" width="100%" style={{ marginTop: '20px' }}>
							<tr>
								<td width="50%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span style={{ fontWeight: 'bold' }}>Saksi keluarga,</span>
								</td>
								<td width="50%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span style={{ fontWeight: 'bold' }}>Saya yang menyatakan,</span>
								</td>
							</tr>
							<tr>
								<td
									width="50%"
									align="center"
									style={{
										paddingLeft: '20px',
										textAlign: 'center',
										textTransform: 'capitalize'
									}}
								>
									{cetak.hubungan_saksi}
								</td>
								<td width="50%" style={{ paddingLeft: '20px', textAlign: 'center' }} />
							</tr>
							<tr>
								<td height="50" colspan="2">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td width="50%" align="center" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											borderBottom: '1px solid black'
										}}
									>
										{cetak.nama_saksi}
									</span>
								</td>
								<td width="50%" align="center" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											borderBottom: '1px solid black'
										}}
									>
										{cetak.nama_pemohon}
									</span>
								</td>
							</tr>
						</table>

						<table border="0" width="100%" style={{ marginTop: '20px' }}>
							<tr>
								<td width="100%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span>Mengetahui :</span>
								</td>
							</tr>
							<tr>
								<td width="100%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span style={{ fontWeight: 'bold' }}>
										{cetak.status_ttd}{' '}
										<span style={{ textTransform: 'uppercase' }}>
											{ttd()}
											{/* {cetak.jabatan_ttd.toLowerCase() != 'kepala ' + JENIS_DESA.toLowerCase() ? (
												cetak.jabatan_ttd + ' ' + cetak.nama_desa
											) : (
												'KEPALA ' + cetak.nama_desa
											)} */}
										</span>
										{cetak.jabatan_pengganti_ttd ? (
											<div>
												{/* <br /> */}
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.jabatan_pengganti_ttd}
												</span>
											</div>
										) : (
												''
											)}
									</span>
								</td>
							</tr>
							<tr>
								<td height="50" colspan="2">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td
									width="100%"
									align="center"
									style={{
										// fontWeight: 'bold',
										paddingLeft: '20px',
										textAlign: 'center'
									}}
								>
									<span
										style={{
											fontWeight: 'bold',
											borderBottom: '1px solid black',
											// textTransform: 'uppercase'
										}}
									>
										{cetak.nama_ttd}
									</span>
									<br />
									{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Keterangan Kelahiran':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat keterangan kelahiran
									</span>
									<br />
									{cetak.nomor}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%" style={{ margin: '0px' }}>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pada Hari/Tanggal
											</td>
											<td>:</td>
											<td>{HariTanggal(cetak.tanggal_lahir_anak)}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Jam
											</td>
											<td>:</td>
											<td>
												{new Date(cetak.tanggal_lahir_anak).toLocaleTimeString('id-ID', { hour: '2-digit', minute: '2-digit' })}
												{' '}
												WIB
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat
											</td>
											<td>:</td>
											<td>{cetak.tempat_lahir_anak}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Telah lahir seorang anak
											</td>
											<td>:</td>
											<td>{cetak.jk_anak}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Diberi Nama
											</td>
											<td>:</td>
											<td
												style={{
													fontWeight: 'bold',
													textTransform: 'uppercase'
												}}
											>
												{cetak.nama_anak}
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" colspan="2" style={{ paddingLeft: '50px' }}>
												Dari Seorang Ibu
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama
											</td>
											<td>:</td>
											<td
												style={{
													fontWeight: 'bold',
													textTransform: 'uppercase'
												}}
											>
												{cetak.nama_ibu}
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td>
												{cetak.tempat_lahir_ibu}, {Tanggal(new Date(cetak.tanggal_lahir_ibu))}
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik_ibu}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td>{cetak.pekerjaan_ibu}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td>{cetak.alamat_ibu}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Istri Dari
											</td>
											<td>:</td>
											<td
												style={{
													fontWeight: 'bold',
													textTransform: 'uppercase'
												}}
											>
												{cetak.nama_ayah}
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td>
												{cetak.tempat_lahir_ayah}, {Tanggal(new Date(cetak.tanggal_lahir_ayah))}
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik_ayah}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td>{cetak.pekerjaan_ayah}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td>{cetak.alamat_ayah}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '52px' }}>
										Demikianlah surat keterangan ini dibuat berdasarkan laporan dari :
									</span>
									<table border="0" width="100%" style={{ marginTop: '0px' }}>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													fontWeight: 'bold',
													textTransform: 'uppercase'
												}}
											>
												{cetak.nama_pelapor}
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td>
												{cetak.tempat_lahir_pelapor}, {Tanggal(cetak.tanggal_lahir_pelapor)}
											</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik_pelapor}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td>{cetak.agama_pelapor}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td>{cetak.pekerjaan_pelapor}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td>{cetak.alamat_pelapor}</td>
										</tr>
										<tr style={{ textTransform: 'capitalize' }}>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Hubungan Pelapor
											</td>
											<td>:</td>
											<td>{cetak.hubungan_pelapor}</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
						<table border="0" width="100%" style={{ marginTop: '10px' }}>
							<tr>
								<td width="55%">&nbsp;</td>
								<td width="45%">
									<div style={{ width: 'fit-content' }}>
										<div style={{ marginBottom: '20px' }}>
											<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
												<tr>
													<td width="auto">Dikeluarkan di</td>
													<td style={{ width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.nama_desa}
													</td>
												</tr>
												<tr>
													<td width="auto" style={{ borderBottom: '1px solid black' }}>
														Pada tanggal
													</td>
													<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ borderBottom: '1px solid black' }}>
														{Tanggal(new Date(cetak.tanggal_surat))}
													</td>
												</tr>
											</table>
										</div>
										<div style={{ textAlign: 'center' }}>
											<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
												{cetak.status_ttd}{' '}
												<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
													{ttd()}
												</span>
											</div>
											{cetak.jabatan_pengganti_ttd ? (
												<div style={{ textAlign: 'center' }}>
													{/* <br /> */}
													<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
														{cetak.jabatan_pengganti_ttd}
													</span>
												</div>
											) : (
													''
												)}
											<div style={{ height: '50px' }}>{''}</div>
											<div style={{ textAlign: 'center' }}>
												<span
													style={{
														// textTransform: 'uppercase',
														fontWeight: 'bold',
														borderBottom: '1px solid black'
													}}
												>
													{cetak.nama_ttd}
												</span>
												<br />
												{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
											</div>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Kuasa SKGR':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat kuasa
									</span>
									{/* <br />
                {cetak.nomor_surat} */}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '0px' }}>Yang bertanda tangan dibawah ini yakni : </p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%" style={{ marginTop: '0px' }}>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td style={{ width: '11px' }}>:</td>
											<td
												style={{
													fontWeight: 'bold',
													textTransform: 'uppercase'
												}}
											>
												{cetak.nama}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir}, {Tanggal(cetak.tanggal_lahir)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '0px' }}>Dengan ini memberi kuasa kepada : </p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%" style={{ marginTop: '0px' }}>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td style={{ width: '11px' }}>:</td>
											<td
												style={{
													fontWeight: 'bold',
													textTransform: 'uppercase'
												}}
											>
												{cetak.nama2}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir2.toLowerCase()}, {Tanggal(cetak.tanggal_lahir2)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.nik2}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan2}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat2}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '0px' }}>
										Untuk {cetak.kuasa_untuk} {cetak.kuasa_atas} {cetak.tujuan_kuasa}. Adapun data
										SKGR tersebut adalah :
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%" style={{ marginTop: '0px' }}>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Atas Nama
											</td>
											<td style={{ width: '11px' }}>:</td>
											<td
												style={{
													textTransform: 'capitalize',
													fontWeight: 'bold'
												}}
											>
												{cetak.atas_nama}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												No. Reg
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.no_reg}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tanggal
											</td>
											<td>:</td>
											<td>{Tanggal(cetak.tanggal_skgr)}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Lokasi Tanah
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.lokasi_tanah}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Luas Tanah
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.luas_tanah}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '0px' }}>
										Demikianlah surat kuasa ini dibuat dengan sebenarnya untuk dapat dipergunakan
										seperlunya.
									</span>
								</td>
							</tr>
						</table>
						<table border="0" width="100%">
							<tr>
								<td style={{ textAlign: 'justify' }} width="50%">
									&nbsp;
								</td>
								<td style={{ textAlign: 'center', textTransform: 'capitalize' }} width="50%">
									{cetak.nama_desa}, {Tanggal(new Date(cetak.tanggal_surat))}{' '}
								</td>
							</tr>
						</table>
						<table border="0" width="100%" style={{ marginTop: '20px' }}>
							<tr>
								<td width="50%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span style={{ fontWeight: 'bold' }}>Yang diberi kuasa,</span>
								</td>
								<td width="50%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span style={{ fontWeight: 'bold' }}>Yang memberi kuasa,</span>
								</td>
							</tr>
							<tr>
								<td height="50" colspan="2">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td width="50%" align="center" style={{ paddingLeft: '20px' }}>
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											borderBottom: '1px solid black'
										}}
									>
										{cetak.nama2}
									</span>
								</td>
								<td width="50%" align="center" style={{ paddingLeft: '20px' }}>
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											borderBottom: '1px solid black'
										}}
									>
										{cetak.nama}
									</span>
								</td>
							</tr>
						</table>

						<table border="0" width="100%" style={{ marginTop: '20px' }}>
							<tr>
								<td width="100%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span>Mengetahui :</span>
								</td>
							</tr>
							<tr>
								<td width="100%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span style={{ fontWeight: 'bold' }}>
										{cetak.status_ttd}{' '}
										<span style={{ textTransform: 'uppercase' }}>
											{ttd()}
											{/* {cetak.jabatan_ttd.toLowerCase() != 'kepala ' + JENIS_DESA.toLowerCase() ? (
												cetak.jabatan_ttd + ' ' + cetak.nama_desa
											) : (
												'KEPALA ' + cetak.nama_desa
											)} */}
										</span>
										{cetak.jabatan_pengganti_ttd ? (
											<div>
												{/* <br /> */}
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.jabatan_pengganti_ttd}
												</span>
											</div>
										) : (
												''
											)}
									</span>
								</td>
							</tr>
							<tr>
								<td height="50" colspan="2">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td width="100%" align="center" style={{
									paddingLeft: '20px',
									// fontWeight: 'bold' 
								}}>
									<span
										style={{
											fontWeight: 'bold',
											borderBottom: '1px solid black',
											// textTransform: 'uppercase'
										}}
									>
										{cetak.nama_ttd}
									</span>
									<br />
									{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Keterangan Ahli Waris':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat keterangan ahli waris
									</span>
									<br />
									{cetak.nomor}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td rowspan="10" style={{ width: '2%' }}>
												1
											</td>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir}, {Tanggal(cetak.tanggal_lahir)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.nik.split(',')[0]}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
										<tr>
											<td colspan="3" width="35%" style={{ paddingLeft: '50px' }}>
												Telah Meninggal Dunia Pada
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Hari/Tanggal
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{HariTanggal(new Date(cetak.waktu_meninggal))}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Jam
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{new Date(cetak.waktu_meninggal)
													.toLocaleTimeString('id-ID')
													.replace(/\./g, ':')}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.tempat_meninggal}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Disebabkan karena
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.sebab_meninggal}</td>
										</tr>
									</table>
									{cetak.nama2 ? (
										<table border="0" width="100%">
											<tr>
												<td rowspan="10" style={{ width: '2%' }}>
													2
												</td>
												<td width="35%" style={{ paddingLeft: '50px' }}>
													Nama Lengkap
												</td>
												<td>:</td>
												<td>{cetak.nama2}</td>
											</tr>
											<tr>
												<td width="35%" style={{ paddingLeft: '50px' }}>
													Tempat/Tanggal Lahir
												</td>
												<td>:</td>
												<td>
													{cetak.tempat_lahir2}, {Tanggal(cetak.tanggal_lahir2)}
												</td>
											</tr>
											<tr>
												<td width="35%" style={{ paddingLeft: '50px' }}>
													Pekerjaan
												</td>
												<td>:</td>
												<td>{cetak.pekerjaan2}</td>
											</tr>
											<tr>
												<td width="35%" style={{ paddingLeft: '50px' }}>
													NIK
												</td>
												<td>:</td>
												<td>{cetak.nik.split(',')[1]}</td>
											</tr>
											<tr>
												<td width="35%" style={{ paddingLeft: '50px' }}>
													Alamat
												</td>
												<td>:</td>
												<td>{cetak.alamat2}</td>
											</tr>
											<tr>
												<td colspan="3" width="35%" style={{ paddingLeft: '50px' }}>
													Telah Meninggal Dunia Pada
												</td>
											</tr>
											<tr>
												<td width="35%" style={{ paddingLeft: '50px' }}>
													Hari/Tanggal
												</td>
												<td>:</td>
												<td>{HariTanggal(new Date(cetak.waktu_meninggal2))}</td>
											</tr>
											<tr>
												<td width="35%" style={{ paddingLeft: '50px' }}>
													Jam
												</td>
												<td>:</td>
												<td>
													{new Date(cetak.waktu_meninggal2)
														.toLocaleTimeString('id-ID')
														.replace(/\./g, ':')}
												</td>
											</tr>
											<tr>
												<td width="35%" style={{ paddingLeft: '50px' }}>
													Tempat
												</td>
												<td>:</td>
												<td>{cetak.tempat_meninggal2}</td>
											</tr>
											<tr>
												<td width="35%" style={{ paddingLeft: '50px' }}>
													Disebabkan karena
												</td>
												<td>:</td>
												<td>{cetak.sebab_meninggal2}</td>
											</tr>
										</table>
									) : (
											''
										)}
								</td>
							</tr>
							<tr>
								<td
									style={{
										textAlign: 'justify'
									}}
								>
									<span style={{ paddingLeft: '50px' }}>
										Selanjutnya kami terangkan bahwa {cetak.alm} meninggalkan {cetak.anak} orang
										ahli waris yaitu :
									</span>
									<table
										border="1"
										width="100%"
										cellpadding="0"
										cellspacing="0"
										style={{ fontSize: '14px', pageBreakAfter: 'auto' }}
									>
										<thead>
											<tr>
												<th>No</th>
												<th>Nama</th>
												<th>Tempat/Tanggal Lahir</th>
												<th>Pekerjaan</th>
												<th>SHDK</th>
											</tr>
										</thead>
										<tbody>
											{cetak.detail.map((dt, i) => {
												return (
													<tr
														style={{
															textTransform: 'capitalize',
															pageBreakInside: 'avoid',
															pageBreakAfter: 'auto'
														}}
													>
														<td
															style={{
																padding: '5px 5px 5px 5px',
																pageBreakInside: 'avoid',
																pageBreakAfter: 'auto'
															}}
														>
															{i + 1}
														</td>
														<td
															style={{
																padding: '5px 5px 5px 5px',
																pageBreakInside: 'avoid',
																pageBreakAfter: 'auto'
															}}
														>
															{dt.nama.toUpperCase()}
														</td>
														<td
															style={{
																padding: '5px 5px 5px 5px',
																pageBreakInside: 'avoid',
																pageBreakAfter: 'auto'
															}}
														>
															{dt.tempat_lahir.toLowerCase()},{' '}
															{Tanggal(new Date(dt.tanggal_lahir))}
														</td>
														<td
															style={{
																padding: '5px 5px 5px 5px',
																pageBreakInside: 'avoid',
																pageBreakAfter: 'auto'
															}}
														>
															{dt.pekerjaan.toLowerCase()}
														</td>
														<td
															style={{
																padding: '5px 5px 5px 5px',
																pageBreakInside: 'avoid',
																pageBreakAfter: 'auto'
															}}
														>
															{dt.shdk.toLowerCase()}
														</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</td>
							</tr>
							<tr style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
								<td
									style={{
										textAlign: 'justify',
										pageBreakInside: 'avoid',
										pageBreakAfter: 'auto'
									}}
								>
									<p style={{ textIndent: '50px' }}>
										Demikianlah surat keterangan ahli waris ini kami buat dengan sebenarnya untuk
										dapat dipergunakan seperlunya.
									</p>
									<table border="0" width="100%" style={{ marginTop: '10px', borderCollapse: 'collapse' }}>
										<tr>
											<td width="55%" style={{ padding: '0', margin: '0', verticalAlign: 'bottom' }}>
												<div style={{ width: 'fit-content', verticalAlign: 'bottom' }}>
													<div style={{ height: '70px' }}></div>
													<div style={{ textAlign: 'center' }}>
														<span style={{ fontWeight: 'bold', textAlign: 'center' }}>
															Diketahui oleh,
														</span>
														<div style={{ height: '64px' }}>{''}</div>
														<div style={{ textAlign: 'left' }}>
															<span
																style={{
																	textTransform: 'uppercase',
																	fontWeight: 'bold',
																	borderBottom: '1px solid black'
																}}
															>
																{'__________________'}
															</span>
															<br />
															{'NIP.: '}
														</div>
													</div>
												</div>
											</td>
											<td width="45%" style={{ padding: '0', margin: '0' }}>
												<div style={{ width: 'fit-content' }}>
													<div style={{ marginBottom: '20px' }}>
														<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
															<tr>
																<td width="auto">Dikeluarkan di</td>
																<td style={{ width: '21px', textAlign: 'center' }}>:</td>
																<td style={{ textTransform: 'capitalize' }}>
																	{cetak.nama_desa}
																</td>
															</tr>
															<tr>
																<td width="auto" style={{ borderBottom: '1px solid black' }}>
																	Pada tanggal
																</td>
																<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
																<td style={{ borderBottom: '1px solid black' }}>
																	{Tanggal(new Date(cetak.tanggal_surat))}
																</td>
															</tr>
														</table>
													</div>
													<div style={{ textAlign: 'center' }}>
														<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
															{cetak.status_ttd}{' '}
															<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
																{ttd()}
															</span>
														</div>
														{cetak.jabatan_pengganti_ttd ? (
															<div style={{ textAlign: 'center' }}>
																{/* <br /> */}
																<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
																	{cetak.jabatan_pengganti_ttd}
																</span>
															</div>
														) : (
																''
															)}
														<div style={{ height: '50px' }}>{''}</div>
														<div style={{ textAlign: 'center' }}>
															<span
																style={{
																	// textTransform: 'uppercase',
																	fontWeight: 'bold',
																	borderBottom: '1px solid black'
																}}
															>
																{cetak.nama_ttd}
															</span>
															<br />
															{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
														</div>
													</div>
												</div>
											</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Kuasa':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat kuasa
									</span>
									{/* <br />
                {cetak.nomor_surat} */}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '0px' }}>Yang bertanda tangan dibawah ini yakni : </p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir}, {Tanggal(cetak.tanggal_lahir)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '0px' }}>Dengan ini memberi kuasa kepada : </p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama2}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir2}, {Tanggal(cetak.tanggal_lahir2)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.nik2}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan2}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat2}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '0px' }}>
										Untuk {cetak.kuasa_untuk} {cetak.kuasa_atas}
										{cetak.tujuan_kuasa == '' ? '. ' : ''}{' '}
										{cetak.tujuan_kuasa != '' ? cetak.tujuan_kuasa + '. ' : ''}
										Adapun rincian data tersebut adalah :
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										{cetak.usaha.map((dt) => {
											return (
												<tr>
													<td
														width="35%"
														style={{ paddingLeft: '50px' }}
														style={{ textTransform: 'capitalize' }}
													>
														{dt.nama_rincian.toLowerCase()}
													</td>
													<td>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{dt.isi_rincian.toLowerCase()}
													</td>
												</tr>
											);
										})}
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '0px' }}>
										Demikianlah surat kuasa ini dibuat dengan sebenarnya untuk dapat dipergunakan
										seperlunya.
									</span>
								</td>
							</tr>
						</table>
						<table border="0" width="100%">
							<tr>
								<td style={{ textAlign: 'justify' }} width="50%">
									&nbsp;
								</td>
								<td style={{ textAlign: 'center' }} width="50%">
									{cetak.nama_desa}, {Tanggal(new Date(cetak.tanggal_surat))}{' '}
								</td>
							</tr>
						</table>
						<table border="0" width="100%" style={{ marginTop: '20px' }}>
							<tr>
								<td width="50%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span style={{ fontWeight: 'bold' }}>Yang diberi kuasa,</span>
								</td>
								<td width="50%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span style={{ fontWeight: 'bold' }}>Yang memberi kuasa,</span>
								</td>
							</tr>
							<tr>
								<td height="50" colspan="2">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td width="50%" align="center">
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											borderBottom: '1px solid black'
										}}
									>
										{cetak.nama2}
									</span>
								</td>
								<td width="50%" align="center">
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											borderBottom: '1px solid black'
										}}
									>
										{cetak.nama}
									</span>
								</td>
							</tr>
						</table>

						<table border="0" width="100%" style={{ marginTop: '20px' }}>
							<tr>
								<td width="100%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span>Mengetahui :</span>
								</td>
							</tr>
							<tr>
								<td width="100%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span style={{ fontWeight: 'bold' }}>
										{cetak.status_ttd}{' '}
										<span style={{ textTransform: 'uppercase' }}>
											{/* {cetak.jabatan_ttd} */}
											{ttd()}
											{/* {cetak.jabatan_ttd.toLowerCase() != 'kepala ' + JENIS_DESA.toLowerCase() ? (
												cetak.jabatan_ttd + ' ' + cetak.nama_desa
											) : (
												'KEPALA ' + cetak.nama_desa
											)} */}
										</span>
										{cetak.jabatan_pengganti_ttd ? (
											<div>
												{/* <br /> */}
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.jabatan_pengganti_ttd}
												</span>
												{/* {cetak.jabatan_pengganti_ttd} */}
											</div>
										) : (
												''
											)}
									</span>
								</td>
							</tr>
							<tr>
								<td height="50" colspan="2">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td width="100%" align="center" >
									<span
										style={{
											fontWeight: 'bold',
											borderBottom: '1px solid black',
											// textTransform: 'uppercase'
										}}
									>
										{cetak.nama_ttd}
									</span>
									<br />
									{/* {cetak.nip_ttd} */}
									{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Undangan':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '5px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td style={{ paddingBottom: '20px' }}>
									<table border="0" width="100%">
										<tr>
											<td width="10%">&nbsp;</td>
											<td width="1%">&nbsp;</td>
											<td width="45%">&nbsp;</td>
											<td width="34%">{Tanggal(new Date(cetak.tanggal_surat))}</td>
											<td width="10%">&nbsp;</td>
										</tr>
										<tr>
											<td>Nomor</td>
											<td>:</td>
											<td>{cetak.nomor}</td>
											<td>&nbsp;</td>
											<td>&nbsp;</td>
										</tr>
										<tr>
											<td>Sifat</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.sifat}</td>
											<td>Kepada Yth:</td>
											<td>&nbsp;</td>
										</tr>
										<tr>
											<td>Perihal</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.perihal}</td>
											<td style={{ textTransform: 'capitalize' }}>
												Bapak/Ibu/Sdr {cetak.kepada}
											</td>
											<td>&nbsp;</td>
										</tr>
										<tr>
											<td>&nbsp;</td>
											<td>&nbsp;</td>
											<td>&nbsp;</td>
											<td colspan="2">
												Di -
												{/* <u style={{ paddingLeft: "30px" }}> TEMPAT</u> */}{' '}
												<span
													style={{
														textTransform: 'uppercase',
														fontWeight: 'bold',
														textDecoration: 'underline'
													}}
												>
													TEMPAT
												</span>
											</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<i>
										Bissmillahirrahmanirrahim.
										<br />
										Assalamu'alaikum Wr. Wb.
									</i>
									<p style={{ textIndent: '50px' }}>
										Dengan mengucapkan puji syukur ke hadirat Allah SWT, semoga Bapak/Ibu, Saudara
										kami jumpai senantiasa dalam lindungan-NYA. Sholawat dan Salam semoga tetap
										tercurahkan kepada Nabi Muhammad SAW dengan harapan semoga kelak di Yaumil Akhir
										kita mendapatkan syafa’atnya. Aamiin ya Robbal Alaamiin.
									</p>
									<p style={{ textIndent: '50px' }}>
										Bersama ini kami mengharapkan kehadiran Bapak/Ibu/Sdr/Sdi pada :
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Hari/Tanggal
											</td>
											<td>:</td>
											<td>{HariTanggal(new Date(cetak.tanggal_acara))}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Jam
											</td>
											<td>:</td>
											<td>{cetak.jamStat.value}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.tempat}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Acara
											</td>
											<td>:</td>
											<td>
												<table
													border="0"
													style={{
														margin: '0px 0px 0px 0px',
														padding: '0px 0px 0px 0px'
													}}
												>
													{cetak.acara}
												</table>
											</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Sehubungan dengan hal tersebut di atas diharapkan untuk hadir tempat waktu.
									</p>
									<p style={{ textIndent: '50px' }}>
										Demikianlah undangan ini kami sampaikan, atas perhatian dan kehadiran
										Bapak/Ibu/Sdr/Sdi kami ucapkan terima kasih
									</p>
									<i>Wassalamu'alaikum Wr. Wb.</i>
								</td>
							</tr>
						</table>
						<table border="0" width="100%" style={{ marginTop: '10px' }}>
							<tr>
								<td width="55%">&nbsp;</td>
								<td width="45%">
									<div style={{ width: 'fit-content' }}>
										<div style={{ marginBottom: '20px' }}>
											<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
												<tr>
													<td width="auto">Dikeluarkan di</td>
													<td style={{ width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.nama_desa}
													</td>
												</tr>
												<tr>
													<td width="auto" style={{ borderBottom: '1px solid black' }}>
														Pada tanggal
													</td>
													<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ borderBottom: '1px solid black' }}>
														{Tanggal(new Date(cetak.tanggal_surat))}
													</td>
												</tr>
											</table>
										</div>
										<div style={{ textAlign: 'center' }}>
											<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
												{cetak.status_ttd}{' '}
												<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
													{ttd()}
												</span>
											</div>
											{cetak.jabatan_pengganti_ttd ? (
												<div style={{ textAlign: 'center' }}>
													{/* <br /> */}
													<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
														{cetak.jabatan_pengganti_ttd}
													</span>
												</div>
											) : (
													''
												)}
											<div style={{ height: '50px' }}>{''}</div>
											<div style={{ textAlign: 'center' }}>
												<span
													style={{
														// textTransform: 'uppercase',
														fontWeight: 'bold',
														borderBottom: '1px solid black'
													}}
												>
													{cetak.nama_ttd}
												</span>
												<br />
												{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
											</div>
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Izin Keramaian':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>

						<table
							border="0"
							width="90%"
							style={{
								marginTop: '60px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="right" style={{ paddingBottom: '20px' }}>
									{' '}
									<span>
										{DESA}, {Tanggal(cetak.tanggal_surat)}
									</span>
									<br />
									{/* {cetak.nomor_surat} */}
								</td>
							</tr>
							<tr>
								<td align="left" style={{ paddingBottom: '20px' }}>
									<table border="0" width="100%">
										<tr>
											<td>Hal : Permohonan Izin Keramaian</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td align="right" style={{ paddingLeft: '65%' }}>
									<table border="0" width="100%">
										<tr>
											<td>Kepada Yth.:</td>
										</tr>
										<tr>
											<td>
												Bapak Kapolsek{' '}
												<span style={{ textTransform: 'uppercase' }}>{KECAMATAN}</span>{' '}
											</td>
										</tr>
										<tr>
											<td>
												Di –{' '}
												<span
													style={{
														textTransform: 'uppercase',
														fontWeight: 'bold',
														borderBottom: '1px solid black'
													}}
												>
													{DESA}
												</span>
											</td>
										</tr>
										{/* <tr>
                      <td align="right">
                        
                      </td>
                    </tr> */}
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p>Assalamu'alaikum Wr. Wb.</p>

									<p>Dengan hormat,</p>
									<p style={{ textIndent: '50px' }}>Yang bertanda tangan dibawah ini : </p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama_pemohon}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat / Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir},{Tanggal(cetak.tanggal_lahir)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>

							<tr>
								<td style={{ textAlign: 'justify' }}>
									<span style={{ paddingLeft: '50px' }}>
										Dengan ini menyampaikan permohonan izin keramaian untuk acara{' '}
										{cetak.tujuan_pembuatan} yang Insya Allah akan kami laksanakan pada :
									</span>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Hari/Tanggal
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tanggal_mulai ? (
													HariTanggal(new Date(cetak.tanggal_mulai))
												) : (
														cetak.tanggal_mulai
													)}
												{/* s/d {" "} */}
												{cetak.tanggal_selesai ? (
													' s/d ' + HariTanggal(new Date(cetak.tanggal_selesai))
												) : (
														cetak.tanggal_selesai
													)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Waktu
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{/* {cetak.waktu_mulai
                          ? HariTanggal(new Date(cetak.waktu_mulai))
                          : cetak.waktu_mulai} */}
												{cetak.tanggal_mulai ? (
													cetak.tanggal_mulai.substr(11, 5)
												) : (
														cetak.tanggal_mulai
													)}
												{/* {" s/d "} */}
												{cetak.tanggal_selesai ? (
													' s/d ' + cetak.tanggal_selesai.substr(11, 5)
												) : (
														' selesai.'
													)}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.tempat}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Hiburan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.hiburan}</td>
										</tr>
									</table>
								</td>
							</tr>
						</table>
						<table border="0" width="100%" style={{ marginTop: '20px' }}>
							<tr>
								<td width="50%">&nbsp;</td>
								<td width="50%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									<span>Hormat Kami,</span>
								</td>
							</tr>
							<tr>
								<td height="50" colspan="2">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td align="center">&nbsp;</td>
								<td align="center" style={{ paddingLeft: '20px' }}>
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											borderBottom: '1px solid black'
											// paddingLeft: '20px'
										}}
									>
										{cetak.nama_pemohon}
									</span>
								</td>
							</tr>
						</table>

						<table border="0" width="100%" style={{ marginTop: '20px' }}>
							<tr>
								<td width="50%">&nbsp;</td>
								<td width="50%" style={{ paddingLeft: '20px', textAlign: 'center' }}>
									Mengetahui
									<span style={{ fontWeight: 'bold' }}>
										<br />
										{cetak.status_ttd}{' '}
										<span style={{ textTransform: 'uppercase' }}>
											{/* {cetak.jabatan_ttd} */}
											{ttd()}
											{/* {cetak.jabatan_ttd.toLowerCase() != 'kepala ' + JENIS_DESA.toLowerCase() ? (
												cetak.jabatan_ttd + ' ' + cetak.nama_desa
											) : (
												'KEPALA ' + cetak.nama_desa
											)} */}
										</span>
										{cetak.jabatan_pengganti_ttd ? (
											<div>
												{/* <br /> */}
												<span style={{ textTransform: 'capitalize' }}>
													{cetak.jabatan_pengganti_ttd}
												</span>
												{/* {cetak.jabatan_pengganti_ttd} */}
											</div>
										) : (
												''
											)}
									</span>
								</td>
							</tr>
							<tr>
								<td height="50" colspan="2">
									&nbsp;
								</td>
							</tr>
							<tr>
								<td align="center">&nbsp;</td>
								<td align="center" style={{
									// fontWeight: 'bold', 
									paddingLeft: '20px'
								}}>
									<span
										style={{
											fontWeight: 'bold',
											borderBottom: '1px solid black',
											// textTransform: 'uppercase'
										}}
									>
										{cetak.nama_ttd}
									</span>
									<br />
									{/* {cetak.nip_ttd} */}
									{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		case 'Surat Keterangan':
			return (
				<div>
					<div id="cetak-surat" style={{ display: 'none' }}>
						<style
							dangerouslySetInnerHTML={{
								__html:
									'\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    '
							}}
						/>
						<table width="100%" border="0">
							<tr>
								<td width="100" align="center">
									<img src={logo} width="80px" />
								</td>
								<td align="center">
									<div style={{ fontWeight: 'bold' }}>
										<span style={{ fontSize: '24px', textTransform: 'uppercase' }}>
											pemerintah {NAMA_KABUPATEN}
										</span>
										<br />
										<span style={{ fontSize: '22px', textTransform: 'uppercase' }}>
											kecamatan {KECAMATAN}
										</span>
										<br />
										<span style={{ fontSize: '28px', textTransform: 'uppercase' }}>
											kantor {JABATAN_TERTINGGI} {DESA}
										</span>
										<br />
										<span style={{ fontSize: '16px', textTransform: 'capitalize' }}>
											{ALAMAT_KANTOR_DESA}
										</span>
									</div>
								</td>
							</tr>
						</table>
						<hr style={{ border: '2px solid black' }} />
						<table
							border="0"
							width="90%"
							style={{
								marginTop: '30px',
								marginLeft: 'auto',
								marginRight: 'auto',
								verticalAlign: 'top',
								pageBreakAfter: 'auto'
							}}
						>
							<tr>
								<td align="center" style={{ paddingBottom: '20px' }}>
									{' '}
									<span
										style={{
											textTransform: 'uppercase',
											fontWeight: 'bold',
											textDecoration: 'underline'
										}}
									>
										surat keterangan
									</span>
									<br />
									{cetak.nomor_surat}
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Yang bertanda tangan dibawah ini {JABATAN_TERTINGGI} {DESA}, Kecamatan {KECAMATAN},{' '}
										{NAMA_KABUPATEN}, Provinsi {PROVINSI}, dengan ini menerangkan bahwa :{' '}
									</p>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<table border="0" width="100%">
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Nama Lengkap
											</td>
											<td>:</td>
											<td
												style={{
													textTransform: 'uppercase',
													fontWeight: 'bold'
												}}
											>
												{cetak.nama_pemohon}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Tempat/Tanggal Lahir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>
												{cetak.tempat_lahir}, {Tanggal(new Date(cetak.tanggal_lahir))}
											</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												NIK
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.nik}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												No Kartu Keluarga
											</td>
											<td>:</td>
											<td>{cetak.kk}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pekerjaan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pekerjaan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Status Perkawinan
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.status_perkawinan}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Agama
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.agama}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Pendidikan Terakhir
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.pendidikan_terakhir}</td>
										</tr>
										<tr>
											<td width="35%" style={{ paddingLeft: '50px' }}>
												Alamat
											</td>
											<td>:</td>
											<td style={{ textTransform: 'capitalize' }}>{cetak.alamat}</td>
										</tr>
									</table>
								</td>
							</tr>
							<tr>
								<td style={{ textAlign: 'justify' }}>
									<p style={{ textIndent: '50px' }}>
										Adalah benar nama tersebut di atas adalah warga {cetak.nama_desa}. Selanjutnya
										kami terangkan bahwa {cetak.tujuan_pembuatan}
									</p>
									<table
										border="0"
										width="70%"
										cellpadding="0"
										cellspacing="0"
										style={{
											fontSize: '14px',
											marginLeft: 'auto',
											pageBreakAfter: 'auto',
											marginRight: 'auto'
										}}
									>
										<tbody>
											{cetak.detail.map((dt, i) => {
												return (
													<tr
														style={{
															textTransform: 'capitalize',
															pageBreakInside: 'avoid',
															pageBreakAfter: 'auto'
														}}
													>
														<td
															style={{
																padding: '5px 5px 5px 5px',
																pageBreakInside: 'avoid',
																pageBreakAfter: 'auto'
															}}
														>
															{i + 1}. {dt.nama_item}
														</td>
														<td style={{ padding: '5px 5px 5px 5px' }}>:</td>
														<td style={{ padding: '5px 5px 5px 5px' }}>{dt.isi_item}</td>
													</tr>
												);
											})}
										</tbody>
									</table>
								</td>
							</tr>
							<tr style={{ pageBreakInside: 'avoid', pageBreakAfter: 'auto' }}>
								<td
									style={{
										textAlign: 'justify',
										pageBreakInside: 'avoid',
										pageBreakAfter: 'auto'
									}}
								>
									<span style={{ textTransform: 'capitalize' }}>{cetak.lokasi}</span>
									<p style={{ textIndent: '50px' }}>
										Demikianlah surat keterangan suket ini dibuat dengan sebenarnya untuk dapat
										dipergunakan seperlunya
									</p>
								</td>
							</tr>
						</table>
						<table border="0" width="100%" style={{ marginTop: '10px' }}>
							<tr>
								<td width="55%">&nbsp;</td>
								<td width="45%">
									<div style={{ width: 'fit-content' }}>
										<div style={{ marginBottom: '20px' }}>
											<table border="0" width="fit-content" cellpadding="0" cellspacing="0">
												<tr>
													<td width="auto">Dikeluarkan di</td>
													<td style={{ width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ textTransform: 'capitalize' }}>
														{cetak.nama_desa}
													</td>
												</tr>
												<tr>
													<td width="auto" style={{ borderBottom: '1px solid black' }}>
														Pada tanggal
													</td>
													<td style={{ borderBottom: '1px solid black', width: '21px', textAlign: 'center' }}>:</td>
													<td style={{ borderBottom: '1px solid black' }}>
														{Tanggal(new Date(cetak.tanggal_surat))}
													</td>
												</tr>
											</table>
										</div>
										<div style={{ textAlign: 'center' }}>
											<div style={{ textAlign: 'justify', textAlignLast: 'center', fontWeight: 'bold' }}>
												{cetak.status_ttd}{' '}
												<span style={{ textTransform: 'uppercase', textAlign: 'center' }}>
													{ttd()}
												</span>
											</div>
											{cetak.jabatan_pengganti_ttd ? (
												<div style={{ textAlign: 'center' }}>
													{/* <br /> */}
													<span style={{ textTransform: 'capitalize', fontWeight: 'bold' }}>
														{cetak.jabatan_pengganti_ttd}
													</span>
												</div>
											) : (
													''
												)}
											<div style={{ height: '50px' }}>{''}</div>
											<div style={{ textAlign: 'center' }}>
												<span
													style={{
														// textTransform: 'uppercase',
														fontWeight: 'bold',
														borderBottom: '1px solid black'
													}}
												>
													{cetak.nama_ttd}
												</span>
												<br />
												{cetak.nip_ttd != '' ? 'NIP.: ' + cetak.nip_ttd : ''}
											</div> 
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			);
			break;

		default:
			return <div />;
			break;
	}
}

export default CetakSurat;
