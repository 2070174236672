import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Col, FormGroup, Label, Row, Form } from 'reactstrap';
import { Get, JENIS_DESA, JENIS_DUSUN, Post } from '../function/Koneksi';
import Select from 'react-select';
import Keyboard from 'react-simple-keyboard';

export default class SuratBelumMenikah extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: {
				form: false,
				btnForm: true,
				btnAksi: []
			},
			// keyboard
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: {},
			inputPattern: /^\d+$/,
			display: 'block',
			focus: false,
			hasFocus: false,

			Basic: false,
			formSaksi: 'none',

			data:[],

			// List Dropdown
			frmShdk: [],
			shdkStat: null,
			shdkStat2: null,
		};
		this.showKeyboard = this.showKeyboard.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
	}

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	onChangeAll = (inputObj) => {
		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputObj[this.state.inputName]
		};
		this.setState({
			input: updatedInputObj
		});
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				console.log('Active input', inputName);
			}
		);
	};

	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		console.log(inputVal);

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);
	};

	onKeyPress = (button) => {
		// console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: 'block'
			});
		}
		this.forceUpdate();
	};

	onOpenModal = (key) => {
		console.log(key);
		this.setState({ [key]: true });
	};

	onCloseModal = (key) => {
		this.state.input['nama_saksi1'] = ""
		this.state.input['nama_saksi2'] = ""
		this.state.shdkStat = null;
		this.state.shdkStat2 = null;
		this.setState({ [key]: false });
		this.forceUpdate();
	};

	handleClick = (event) => {
		alert(event);
	};

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'shdk1':
					this.state.data.shdk1 = e.label;
					this.setState({ shdkStat: e });
					break;
				case 'shdk2':
					this.state.data.shdk2 = e.label;
					this.setState({ shdkStat2: e });
					break;
			}
		} else {
			switch (sel) {
				case 'shdk1':
					this.state.data.shdk1 = 0;
					this.setState({ shdkStat: e });
					break;
				case 'shdk2':
					this.state.data.shdk2 = 0;
					this.setState({ shdkStat2: e });
					break;
			}
		}

		this.forceUpdate();
	};

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();

		let alamat = '';
		
		if(this.state.shdkStat == null || this.state.shdkStat == ""){
			this.props.getAntrian('Surat Pernyataan Belum Menikah', 'cekDataShdk');
		}
		else{
			if(this.state.formSaksi!="none" && this.state.shdkStat2==null){
				this.props.getAntrian('Surat Pernyataan Belum Menikah', 'cekDataShdk');
			}
			else if(this.state.formSaksi!="none" && this.state.shdkStat2==""){
				this.props.getAntrian('Surat Pernyataan Belum Menikah', 'cekDataShdk');
			}
			else{
				if (JENIS_DESA.toLowerCase() == 'kelurahan') {
					alamat =
						' RT. ' +
						this.props.data.results.data_kk.data_rt.nama +
						'/RW. ' +
						this.props.data.results.data_kk.data_rt.data_rw.nama +
						', ' +
						this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
						', Kec. ' +
						this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
						', ' +
						this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
				} else {
					// alamat =
					// 	' RT. ' +
					// 	this.props.data.results.data_kk.data_rt.nama +
					// 	'/RW. ' +
					// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
					// 	', ' +
					// 	JENIS_DUSUN +
					// 	' ' +
					// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
					// 	', ' +
					// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
					// 	', Kec. ' +
					// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
					// 	', ' +
					// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;
		
						if (this.props.data.results.data_kk.id_rt == '0') {
							alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
							", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
							", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
							", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
						} else {
							alamat =
							" RT. " + 
							this.props.data.results.data_kk.data_rt.nama +
							"/RW. " +
							this.props.data.results.data_kk.data_rt.data_rw.nama +
							", " + JENIS_DUSUN + " " +
							this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
							", " + JENIS_DESA + " " +
							this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
							", Kec. " +
							this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
								.data_kecamatan.nama +
							", " +
							this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
								.data_kecamatan.data_kabkota.nama; 
						}
				}
		
				let addData = {
					id_jenis: this.props.id_jenis,
					nik: this.props.data.results.id,
					nama_pemohon: this.props.data.results.nama,
					tempat_lahir: this.props.data.results.tempat_lahir,
					tanggal_lahir: this.props.data.results.tanggal_lahir,
					pendidikan_terakhir: this.props.data.results.data_pendidikan_terakhir.nama,
					pekerjaan: this.props.data.results.data_pekerjaan.nama,
					agama: this.props.data.results.data_agama.nama,
					status_perkawinan: this.props.data.results.status_perkawinan,
					alamat: alamat,
					jenis_kelamin: this.props.data.results.jk,
		
					kewarganegaraan: 'Indonesia',
					tujuan_pembuatan: 'Surat Belum Pernah Menikah',
		
					nama_saksi1: document.getElementById('nama_saksi1').value,
					hubungan_saksi1: this.state.data.shdk1,
					nama_saksi2: document.getElementById('nama_saksi2').value,
					hubungan_saksi2: this.state.data.shdk2,
					nama_ayah: this.props.data.results.nama_ayah_kandung
				};
				// console.log(this.props.data.results);
				// console.log(addData);
		
				let psn = '';
				let resstat = 201;
				let metode = 'create';
		
				Post('surat-keterangan/grup/' + metode + '/belum-menikah', null, addData, (res) => {
					this.onCloseModal('Basic');
		
					let dataAntrian = {
						nama: this.props.data.results.nama,
						nik: this.props.data.results.id,
						keterangan: '',
						uuid_surat:res.data.results,
					};
		
					if (res.status === resstat) {
						Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
							console.log("Hasil Update Status")
							console.log(res2)
							if (res2.code === 201) {
								console.log("Berhasil Ganti Status Menjadi Selesai")
	
								this.props.getAntrian('Surat Pernyataan Belum Menikah', 'berhasil', false, dataAntrian);
							}
							else{
								console.log("Gagal Ganti Status Menjadi Selesai")
	
								this.props.getAntrian('Surat Pernyataan Belum Menikah', 'gagal');
							}
						});
						this.state.data.shdk1 = null;
						this.state.data.shdk2 = null;
						// this.props.getAntrian('Surat Pernyataan Belum Menikah', 'berhasil', false, dataAntrian);
						this.forceUpdate();
					} else {
						this.props.getAntrian('Surat Pernyataan Belum Menikah', 'gagal');
					}
					this.componentDidMount();
					// reset cache
					document.getElementById('nama_saksi1').value = '';
					// document.getElementById('hubungan_saksi1').value = '';
					document.getElementById('nama_saksi2').value = '';
					// document.getElementById('hubungan_saksi2').value = '';
					this.state.formSaksi = 'none';
					this.state.input = {};
					this.forceUpdate();
				});
			}
		}
	};
	// ========================================================================
	componentDidMount() {
		// const context = useContext(this.props.context);
		// context.getNomorAntrian();
		// console.log(this.props.location);

		// List Dropdown
		Get('shdk/list', null, (data) => {
			let frmShdk = [];
			console.log("data shdk belum menikah")
			console.log(data.results)
			if(data.results){
				data.results.map((dt) => {
					frmShdk.push({ value: dt.uuid, label: dt.nama });
				});
				this.setState({ frmShdk });
			}
		});
	}

	render() {
		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const { Basic } = this.state;

		return (
			<div>
				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div
							// style={{ marginBottom: this.state.display == 'block' ? '200px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								// console.log(e);
								this.state.isDown = true;
								this.state.startY = e.pageY - slider.offsetTop;
								this.state.scrollUp = slider.scrollTop;
								this.forceUpdate();
								// console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title" align="center">
									Surat Pernyataan Belum Menikah
								</h5>
							</div>

							<div className="modal-body">
								<input
									className="form-control"
									id="uuid"
									type="hidden"
									placeholder=""
									// defaultValue={this.state.dt.uuid}
								/>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											placeholder="NIK"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Lengkap Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pemohon"
											disabled
											type="text"
											placeholder=" Nama Lengkap"
											value={this.props.data.results.nama}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Saksi 1
									</Label>
									<Col sm="9">
										<input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												console.log(e.target);
												this.setActiveInput('nama_saksi1');
											}}
											value={this.state.input['nama_saksi1'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="nama_saksi1"
											required
											type="text"
											placeholder="Nama Saksi"
											autoComplete="off"
											// defaultValue={this.state.dt.nama_saksi1}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Hubungan Saksi 1
									</Label>
									<Col sm="9">
										<Select
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'shdk1');
											}}
											defaultValue={this.state.shdkStat}
											value={this.state.shdkStat}
											id="shdk"
											name="SHDK"
											options={this.state.frmShdk}
											placeholder="Hubungan Saksi 1"
										/>

										{/* <input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												console.log(e.target);
												this.setActiveInput('hubungan_saksi1');
											}}
											value={this.state.input['hubungan_saksi1'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="hubungan_saksi1"
											type="text"
											required
											placeholder="Hubungan Saksi"
											// defaultValue={this.state.dt.hubungan_saksi1}
										/> */}
									</Col>
								</FormGroup>

								<div style={{ display: this.state.formSaksi }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama Saksi 2
										</Label>
										<Col sm="9">
											<input
												onFocus={(e) => {
													this.setState({ display: 'block' });
													console.log(e.target);
													this.setActiveInput('nama_saksi2');
												}}
												value={this.state.input['nama_saksi2'] || ''}
												onChange={(e) => this.onChangeInput(e)}
												className="form-control"
												id="nama_saksi2"
												type="text"
												required={this.state.formSaksi == 'none' ? false : true}
												placeholder="Nama Saksi"
												autoComplete="off"
												// defaultValue={this.state.dt.nama_saksi2}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Hubungan Saksi 2
										</Label>
										<Col sm="9">
											<Select
												classNamePrefix="select"
												onChange={(e) => {
													this.changeSelectValue(e, 'shdk2');
												}}
												defaultValue={this.state.shdkStat2}
												value={this.state.shdkStat2}
												id="shdk"
												name="SHDK"
												options={this.state.frmShdk}
												placeholder="Hubungan Saksi 2"
											/>
											{/* <input
												onFocus={(e) => {
													this.setState({ display: 'block' });
													console.log(e.target);
													this.setActiveInput('hubungan_saksi2');
												}}
												value={this.state.input['hubungan_saksi2'] || ''}
												onChange={(e) => this.onChangeInput(e)}
												className="form-control"
												id="hubungan_saksi2"
												required={this.state.formSaksi == 'none' ? false : true}
												type="text"
												placeholder="Hubungan Saksi"
												// defaultValue={this.state.dt.hubungan_saksi2}
											/> */}
										</Col>
									</FormGroup>
								</div>
								<button
									type="button"
									className={this.state.formSaksi == 'none' ? 'btn btn-success' : 'btn btn-danger'}
									onClick={() => {
										if (this.state.formSaksi == 'none') {
											console.log('disana');

											this.setState({
												formSaksi: ''
											});
										} else {
											console.log('disnii');
											document.getElementById('nama_saksi2').value = '';
											// document.getElementById('hubungan_saksi2').value = '';

											this.state.input['nama_saksi2'] = '';
											this.state.shdkStat2 = null;
											this.state.data.shdk2 = null
											// this.state.input['hubungan_saksi2'] = '';

											this.state.formSaksi = 'none';
											this.forceUpdate();
										}
									}}
								>
									{this.state.formSaksi == 'none' ? 'Tambah Saksi 2 ' : 'Hapus Saksi 2'}
								</button>
							</div>

							<div
								className="modal-footer"
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
							>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-primary">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>
					{/* <div
						style={{
							position: 'fixed',
							bottom: 0,
							left: 0,
							width: '100%',
							zIndex: 4,
							display: this.state.display
						}}
					>
						<Keyboard
							style={{ marginTop: '40px', zIndex: 3 }}
							keyboardRef={(r) => (this.keyboard = r)}
							inputName={this.state.inputName}
							layoutName={this.state.layoutName}
							onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
							onKeyPress={(button) => this.onKeyPress(button)}
						/>
					</div> */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/belum-menikah.png')}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p
								className="mb-0"
								style={{
									fontSize: '20px',
									fontWeight: '600',
									color: '#000000'
								}}
							>
								Pernyataan Belum Menikah
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
