import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { Col, FormGroup, Label, Row, Form, Button } from 'reactstrap';
import { Tanggal, HariTanggal } from '../function/Format';
import Datepicker from 'react-datepicker';
import Datetime from 'react-datetime';
import { Get, JENIS_DESA, JENIS_DUSUN, Post } from '../function/Koneksi';
import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';

import Keyboard from 'react-simple-keyboard';

import 'react-simple-keyboard/build/css/index.css';

const frmDef = {
	uuid: '',
	id_jenis: '',
	nomor: '',
	tanggal_surat: new Date(),

	nik: '',
	nama: '',
	tempat_lahir: '',
	tanggal_lahir: '',
	jenis_kelamin: '',
	pekerjaan: '',
	kewarganegaraan: '',
	agama: '',
	alamat: '',
	status_perkawinan: '',
	waktu_meninggal: new Date(),
	tempat_meninggal: '',
	sebab_meninggal: '',

	nik2: '',
	nama2: '',
	tempat_lahir2: '',
	tanggal_lahir2: '',
	jenis_kelamin2: '',
	pekerjaan2: '',
	kewarganegaraan2: '',
	agama2: '',
	alamat2: '',
	status_perkawinan2: '',
	waktu_meninggal2: new Date(),
	tempat_meninggal2: '',
	sebab_meninggal2: '',

	status_ttd: '',
	jabatan_ttd: '',
	jabatan_pengganti_ttd: '',
	nama_ttd: '',
	nip_ttd: '',
	status_ttd2: '',
	jabatan_ttd2: '',
	jabatan_pengganti_ttd2: '',
	nama_ttd2: '',
	nip_ttd2: ''
};

let detail = [];
let detail_simpan = [];

let aw = {
	nama: '',
	nik: '',
	nama: '',
	tempat_lahir: '',
	tanggal_lahir: '',
	jenis_kelamin: '',
	pekerjaan: ''
};

export default class SuratKeteranganAhliWaris extends Component {
	constructor(props) {
		super(props);
		this.state = {
			focus: false,
			display: 'none',
			layoutName: 'default',
			inputName: 'input1',
			input: {},
			inputPattern: /^\d+$/,
			dt: frmDef,
			// aw: aw,
			aw: null,
			data: [],
			detail: [],
			detail_simpan: [],
			status: {
				form: false,
				btnForm: true,
				btnAksi: [],
				dataPenduduk: 'none',
				dataPenduduk2: 'none',
				dataPenduduk3: 'none'
			},
			Basic: false,
			waktu_meninggal: null,

			waktu_meninggal2: null,
			tempat_meninggal2: '',
			sebab_meninggal2: '',

			// ================== Select NIK
			frmNik: [],
			nikStat: null,
			frmNik2: [],
			nik2Stat: null,
			frmNik3: [],
			nik3Stat: null,

			shdk1: null,
			shdk_aw: null,

			// =======================
			meninggal2: 'none',

			// =========== CARI NIK ============
			cari_nik1: '',

			// Alert
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			successAlert: false
		};
	}

	// ====================keyboard=============================

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}

		this.forceUpdate();
	};

	onChange = (input) => {
		this.setState({ input });
		console.log('Input changed', input);
	};

	onKeyPress = (button) => {
		console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	// onChangeInput = (event) => {
	// 	console.log('===onchange===');
	// 	let inputVal = event.target.value;

	// 	let updatedInputObj = {
	// 		...this.state.input,
	// 		[this.state.inputName]: inputVal
	// 	};

	// 	this.setState(
	// 		{
	// 			input: updatedInputObj
	// 		},
	// 		() => {
	// 			this.keyboard.setInput(inputVal);
	// 		},
	// 		() => {
	// 			if (typeof this.keyboard != 'undefined') {
	// 				this.keyboard.setInput(inputVal);
	// 			}
	// 		}
	// 	);
	// };

	// Input keyboard
	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				} else {
				}
			}
		);
	};
	
	onInputChanged = (data) => {
		this.setState({ input: data });
	};

	onInputSubmitted = (data) => {
		console.log('Input submitted:', data);
	};

	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		console.log('Input changed', inputObj);
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				console.log('Active input', inputName);
			}
		);
	};

	// end keyboard

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_surat':
				this.setState({ tanggal_surat: date });
				break;
			case 'waktu_meninggal':
				// this.setState({ waktu_meninggal: date });
				if (typeof date._isValid != 'undefined') {
					let tgl = date.format('YYYY-MM-DD HH:mm:ss');
					this.setState({ waktu_meninggal: tgl });
				}
				break;
			case 'waktu_meninggal2':
				// this.setState({ waktu_meninggal2: date });
				if (typeof date._isValid != 'undefined') {
					let tgl = date.format('YYYY-MM-DD HH:mm:ss');
					this.setState({ waktu_meninggal2: tgl });
				}
				break;
			// case 'tanggal_lahir': this.setState({tanggal_lahir:date});break;
		}
	};

	onOpenModal = (key) => {
		console.log(key);
		this.setState({ [key]: true });
	};

	onCloseModal = (key) => {
		this.setState({ [key]: false, input: '' });
	};

	handleClick = (event) => {
		alert(event);
	};

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld == 'nik') {
				let nikStat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik = [];
					datanik.data.results.data.map((dt) => {
						frmNik.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmNik, nikStat });
				});
			} else if (fld == 'nik2') {
				let nik2Stat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik2 = [];
					datanik.data.results.data.map((dt) => {
						frmNik2.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmNik2, nik2Stat });
				});
			} else {
				let nik3Stat = { value: e, label: e };
				let param = {
					nik: e
				};
				Post('penduduk', null, param, (datanik) => {
					let frmNik3 = [];
					datanik.data.results.data.map((dt) => {
						frmNik3.push({ value: dt.id, label: dt.id });
					});
					this.setState({ frmNik3, nik3Stat });
				});
			}
		}
	};

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'jam':
					this.state.dt.jam = e.value;
					this.setState({ jamStat: e });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = e.label;
					this.setState({ pkrStat: e });
					break;
				case 'agama':
					this.state.dt.agama = e.label;
					this.setState({ agmStat: e });
					break;
				case 'nik':
					this.state.dt.nik = e.value;
					this.setState({ nikStat: e });
					break;
				case 'nik2':
					this.state.dt.nik2 = e.value;
					this.setState({ nik2Stat: e });
					break;
				case 'nik3':
					this.state.dt.nik3 = e.value;
					this.setState({ nik3Stat: e });
					break;
				case 'shdk1':
					this.state.dt.shdk1 = e.value;
					this.setState({ shdk1: e });
					break;
				case 'shdk_aw':
					this.state.dt.shdk_aw = e.value;
					this.setState({ shdk_aw: e });
					break;
			}
		} else {
			switch (sel) {
				case 'jam':
					this.state.dt.jam = 0;
					this.setState({ jamStat: e });
					break;
				case 'pekerjaan':
					this.state.dt.pekerjaan = '';
					this.setState({ pkrStat: e });
					break;
				case 'agama':
					this.state.dt.agama = '';
					this.setState({ agmStat: e });
					break;
				case 'nik':
					this.state.dt.nik = 0;
					this.setState({ nikStat: e });
					break;
				case 'nik2':
					this.state.dt.nik2 = 0;
					this.setState({ nik2Stat: e });
					break;
				case 'nik3':
					this.state.dt.nik3 = 0;
					this.setState({ nik3Stat: e });
					break;
				case 'shdk1':
					this.state.dt.shdk1 = 0;
					this.setState({ shdk1: e });
					break;
				case 'shdk_aw':
					this.state.dt.shdk_aw = 0;
					this.setState({ shdk_aw: e });
					break;
			}
		}

		this.forceUpdate();
	};

	getPenduduk = (e, sel) => {
		this.state.status.btnForm = true;
		this.forceUpdate();
		if (e) {
			this.changeSelectValue(e, sel);
			Get('penduduk/find', e.value, (data) => {
				const data_rt = data.results.data_kk.data_rt;
				const data_rw = data_rt.data_rw;
				const data_dusun = data_rw.data_dusun;
				const data_desa = data_dusun.data_keldes;
				const data_kecamatan = data_desa.data_kecamatan;
				const data_kabkota = data_kecamatan.data_kabkota;
				let alamat = '';
				if (JENIS_DESA.toLowerCase() == 'kelurahan') {
					alamat =
						' RT. ' +
						data.results.data_kk.data_rt.nama +
						'/RW. ' +
						data.results.data_kk.data_rt.data_rw.nama +
						', ' +
						data.results.data_kk.data_rt.data_rw.data_keldes.nama +
						', Kec. ' +
						data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
						', ' +
						data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
				} else {
					if (data.results.data_kk.id_rt == '0') {
            alamat = JENIS_DESA + " " + data.results.data_keldes.nama + 
            ", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
          } else {
            alamat =
            " RT. " + 
            data.results.data_kk.data_rt.nama +
            "/RW. " +
            data.results.data_kk.data_rt.data_rw.nama +
            ", " + JENIS_DUSUN + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.nama +
            ", " + JENIS_DESA + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
            ", Kec. " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.data_kabkota.nama; 
          }
				}

				if (sel == 'nik2') {
					this.state.tanggal_lahir2 = data.results.tanggal_lahir;
					this.state.status.btnForm = false;

					this.state.dt.nama2 = data.results.nama;
					this.state.dt.tanggal_lahir2 = new Date(data.results.tanggal_lahir).toLocaleDateString('id-ID');
					this.state.dt.tempat_lahir2 = data.results.tempat_lahir;
					this.state.dt.jenis_kelamin2 = data.results.jk;
					this.state.dt.status_perkawinan2 = data.results.status_perkawinan;
					this.state.dt.agama2 = data.results.data_agama.nama;
					this.state.dt.pekerjaan2 = data.results.data_pekerjaan.nama;
					this.state.dt.kewarganegaraan2 = 'Indonesia';
					this.state.dt.alamat2 = alamat;
					this.state.status.dataPenduduk2 = 'block';
				} else {
					if (sel == 'nik3') {
						aw.nik = e.value;
						aw.nama = data.results.nama;
						aw.jenis_kelamin = data.results.jenis_kelamin;
						aw.tanggal_lahir = data.results.tanggal_lahir;
						aw.tempat_lahir = data.results.tempat_lahir;
						aw.pekerjaan = data.results.data_pekerjaan.nama;
					} else {
						Get('catatan-penduduk/nik', data.results.id, (dpddk) => {
							let pctt = 0;
							let catatan = '';
							if (dpddk.results.data) {
								dpddk.results.data.map((dtp) => {
									if (dtp.status == 0 && dtp.penalti == 1) {
										pctt = 1;
										catatan = dtp.catatan;
									}
								});
							}
							if (pctt == 0) {
								this.state.tanggal_lahir = data.results.tanggal_lahir;
								this.state.dt.nama = data.results.nama;
								this.state.dt.tanggal_lahir = new Date(data.results.tanggal_lahir).toLocaleDateString(
									'id-ID'
								);
								this.state.dt.tempat_lahir = data.results.tempat_lahir;
								this.state.dt.jenis_kelamin = data.results.jk;
								this.state.dt.status_perkawinan = data.results.status_perkawinan;
								this.state.dt.agama = data.results.data_agama.nama;
								this.state.dt.pekerjaan = data.results.data_pekerjaan.nama;
								this.state.dt.kewarganegaraan = 'Indonesia';
								this.state.dt.alamat = alamat;
								this.state.status.dataPenduduk = 'block';
								this.state.status.btnForm = false;
								this.forceUpdate();
							} else {
								this.state.nikStat = null;
								this.state.frmNik = [];
								this.state.dt.nama = '';
								this.state.dt.tanggal_lahir = '';
								this.state.dt.tempat_lahir = '';
								this.state.dt.agama = '';
								this.state.dt.pekerjaan = '';
								this.state.dt.kewarganegaraan = '';
								this.state.dt.alamat = '';
								this.state.dt.jenis_kelamin = '';
								this.state.dt.status_perkawinan = '';
								this.state.dt.tempat_meninggal = '';
								this.state.dt.sebab_meninggal = '';
								this.state.status.dataPenduduk = 'none';
								this.state.status.btnForm = true;
								this.forceUpdate();
								this.setState({
									show: true,
									basicType: 'danger',
									basicTitle: 'Peringatan Catatan Warga',
									pesanAlert:
										'Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r' +
										catatan
								});
							}
						});
					}
				}
				this.forceUpdate();
			});
		} else {
			if (sel == 'nik2') {
				this.state.nik2Stat = null;
				this.state.dt.nama2 = '';
				this.state.dt.tanggal_lahir2 = '';
				this.state.dt.tempat_lahir2 = '';
				this.state.dt.agama2 = '';
				this.state.dt.pekerjaan2 = '';
				this.state.dt.kewarganegaraan2 = '';
				this.state.dt.alamat2 = '';
				this.state.dt.jenis_kelamin2 = '';
				this.state.dt.status_perkawinan2 = '';
				this.state.dt.tempat_meninggal2 = '';
				this.state.dt.sebab_meninggal2 = '';
				this.state.status.dataPenduduk2 = 'none';
				this.state.status.btnForm = false;
			} else {
				this.state.nikStat = null;
				this.state.dt.nama = '';
				this.state.dt.tanggal_lahir = '';
				this.state.dt.tempat_lahir = '';
				this.state.dt.agama = '';
				this.state.dt.pekerjaan = '';
				this.state.dt.kewarganegaraan = '';
				this.state.dt.alamat = '';
				this.state.dt.jenis_kelamin = '';
				this.state.dt.status_perkawinan = '';
				this.state.dt.tempat_meninggal = '';
				this.state.dt.sebab_meninggal = '';
				this.state.status.dataPenduduk = 'none';
			}
			this.forceUpdate();
		}
	};

	tambahMeninggal = (e) => {
		e.preventDefault();
		if (this.state.meninggal2 == 'none') {
			this.setState({ meninggal2: 'inherit' });
		} else {
			// this.setState({ meninggal2: 'none' });
			this.state.meninggal2 = 'none';
			// this.state.input['cari_nik2'] = null;
			this.state.nik2Stat = null;
			this.forceUpdate();
		}
	};

	componentDidMount() {
		Get('penduduk', null, (data) => {
			let frmNik = [];
			let frmNik2 = [];
			let frmNik3 = [];
			if(data.results){
				data.results.data.map((dt) => {
					frmNik.push({ value: dt.id, label: dt.id });
					frmNik2.push({ value: dt.id, label: dt.id });
					frmNik3.push({ value: dt.id, label: dt.id });
				});
				this.setState({ frmNik, frmNik2, frmNik3 });
			}
		});
	}

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();

		const nikTerlapor = document.getElementById("nikStat").value;
		const nikTerlapor2 = document.getElementById("nik2Stat").value;

		let cekTerlapor2 = true;

		if (this.state.meninggal2 != "none") {
			cekTerlapor2 = cekTerlapor2 && nikTerlapor2.trim() != '' && this.state.nik2Stat
		}

		if (this.state.shdk1 && 
			nikTerlapor.trim() != '' && this.state.nikStat &&
			cekTerlapor2
		) {
			// console.log(this.state.input['cari_nik1']);

			let alamat = '';
			if (JENIS_DESA.toLowerCase() == 'kelurahan') {
				alamat =
					' RT. ' +
					this.props.data.results.data_kk.data_rt.nama +
					'/RW. ' +
					this.props.data.results.data_kk.data_rt.data_rw.nama +
					', ' +
					this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
					', Kec. ' +
					this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
					', ' +
					this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
			} else {
				// alamat =
				// 	' RT. ' +
				// 	this.props.data.results.data_kk.data_rt.nama +
				// 	'/RW. ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
				// 	', ' +
				// 	JENIS_DUSUN +
				// 	' ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
				// 	', ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
				// 	', Kec. ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
				// 	', ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;
	
					if (this.props.data.results.data_kk.id_rt == '0') {
						alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
						", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
						", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
						", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
					} else {
						alamat =
						" RT. " + 
						this.props.data.results.data_kk.data_rt.nama +
						"/RW. " +
						this.props.data.results.data_kk.data_rt.data_rw.nama +
						", " + JENIS_DUSUN + " " +
						this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
						", " + JENIS_DESA + " " +
						this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
						", Kec. " +
						this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
							.data_kecamatan.nama +
						", " +
						this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
							.data_kecamatan.data_kabkota.nama; 
					}
			}

			detail_simpan.push({
				nik: this.props.data.results.id,
				nama: this.props.data.results.nama,
				jenis_kelamin: this.props.data.results.jk,
				tanggal_lahir: this.props.data.results.tanggal_lahir,
				tempat_lahir: this.props.data.results.tempat_lahir,
				pekerjaan: this.props.data.results.data_pekerjaan.nama,
				shdk: this.state.shdk1.value,
				keterangan: '',
				alamat: alamat
			});

			let detailnya = ''
			if (detail) {
				detailnya = [...detail, ...detail_simpan];
			}
			else {
				detailnya = detail_simpan
			}
			
			this.state.status.btnForm = true;
			this.forceUpdate();
			let tgl_lhr = this.state.dt.tanggal_lahir.split('/');
			let tanggal_lahir = tgl_lhr[2] + '-' + tgl_lhr[1] + '-' + tgl_lhr[0];
			let tanggal_lahir2 = '';
			if (this.state.dt.tanggal_lahir2) {
				let tgl_lhr_pl = this.state.dt.tanggal_lahir2.split('/');
				tanggal_lahir2 = tgl_lhr_pl[2] + '-' + tgl_lhr_pl[1] + '-' + tgl_lhr_pl[0];
			}
			// let nik = this.state.nikStat;
			let nik = document.getElementById('nikStat').value;
			let nama = this.state.dt.nama;
			let tempat_lahir = this.state.dt.tempat_lahir;
			let jenis_kelamin = this.state.dt.jenis_kelamin;
			let pekerjaan = this.state.dt.pekerjaan;
			let kewarganegaraan = this.state.dt.kewarganegaraan;
			let agama = this.state.dt.agama;
			// let alamat = this.state.dt.alamat;
			let waktu_meninggal =
				new Date(this.state.waktu_meninggal).toISOString().slice(0, 10) +
				' ' +
				new Date(this.state.waktu_meninggal).toLocaleTimeString('id-ID').replace(/\./g, ':');
			let tempat_meninggal = document.getElementById('tempat_meninggal').value;
			let sebab_meninggal = document.getElementById('sebab_meninggal').value;
			// if (this.state.nik2Stat) {
			// if (this.state.input['cari_nik2'] && this.state.input['cari_nik2'] != null) {
			if (this.state.nik2Stat && this.state.nik2Stat != null) {
				if (this.state.waktu_meninggal2) {
					waktu_meninggal +=
						',' +
						new Date(this.state.waktu_meninggal2).toISOString().slice(0, 10) +
						' ' +
						new Date(this.state.waktu_meninggal2).toLocaleTimeString('id-ID').replace(/\./g, ':');
				}
				nik += ',' + this.state.nik2Stat;
				// nik += ',' + this.state.input['cari_nik2'];
				if (this.state.dt.nama2) {
					nama += ',' + this.state.dt.nama2;
				}
				if (this.state.dt.jenis_kelamin) {
					jenis_kelamin += ',' + this.state.dt.jenis_kelamin2;
				}
				if (this.state.dt.tempat_lahir2) {
					tempat_lahir += ',' + this.state.dt.tempat_lahir2;
				}
				if (this.state.dt.tanggal_lahir2) {
					tanggal_lahir += ',' + tanggal_lahir2;
				}
				if (this.state.dt.jenis_kelamin2) {
					jenis_kelamin += ',' + this.state.dt.jenis_kelamin2;
				}
				if (this.state.dt.pekerjaan2) {
					pekerjaan += ',' + this.state.dt.pekerjaan2;
				}
				if (this.state.dt.kewarganegaraan2) {
					kewarganegaraan += ',' + this.state.dt.kewarganegaraan2;
				}
				if (this.state.dt.agama2) {
					agama += ',' + this.state.dt.agama2;
				}
				if (this.state.dt.alamat2) {
					alamat += '|' + this.state.dt.alamat2;
				}

				if (document.getElementById('tempat_meninggal2').value) {
					tempat_meninggal += ',' + document.getElementById('tempat_meninggal2').value;
				}
				if (document.getElementById('sebab_meninggal2').value) {
					sebab_meninggal += ',' + document.getElementById('sebab_meninggal2').value;
				}
			}

			let addSuratKeteranganAhliWaris = {
				id_jenis: this.props.id_jenis,
				nik: nik,
				nama: nama,
				tempat_lahir: tempat_lahir,
				tanggal_lahir: tanggal_lahir,
				jenis_kelamin: jenis_kelamin,
				pekerjaan: pekerjaan,
				kewarganegaraan: kewarganegaraan,
				agama: agama,
				alamat: alamat,
				waktu_meninggal: waktu_meninggal,
				tempat_meninggal: tempat_meninggal,
				sebab_meninggal: sebab_meninggal,

				// detail: [...this.state.data_produk, ...this.state.detail]
				detail: detailnya
			};
			let psn = '';
			let resstat = 201;
			let metode = 'create';

			console.log('Data Tersimpan: ');
			console.log(addSuratKeteranganAhliWaris);

			Post('surat-keterangan/ahli-waris/' + metode, null, addSuratKeteranganAhliWaris, (res) => {
				this.tutupForm();
				this.onCloseModal('Basic');

				let dataAntrian = {
					nama: this.props.data.results.nama,
					nik: this.props.data.results.id,
					keterangan: '',
					uuid_surat:res.data.results,
				};
	
				if (res.status === resstat) {
					Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
						console.log("Hasil Update Status")
						console.log(res2)
						if (res2.code === 201) {
							console.log("Berhasil Ganti Status Menjadi Selesai")
	
							this.props.getAntrian('Surat Keterangan Ahli Waris', 'berhasil', false, dataAntrian);
						}
						else{
							console.log("Gagal Ganti Status Menjadi Selesai")
	
							this.props.getAntrian('Surat Keterangan Ahli Waris', 'gagal');
						}
					});
					// this.props.getAntrian('Surat Keterangan Ahli Waris', 'berhasil', false, dataAntrian);
				} else {
					this.props.getAntrian('Surat Keterangan Ahli Waris', 'gagal');
				}
				this.componentDidMount();
			});
		} else {
			this.props.getAntrian('Surat Keterangan Ahli Waris', 'gagal', true);
		}
	};
	// ========================================================================

	hapus = (i) => {
		this.state.detail.splice(i, 1);
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.nikStat = null;
		this.state.dt.nama = '';
		this.state.dt.tanggal_lahir = '';
		this.state.dt.tempat_lahir = '';
		this.state.dt.agama = '';
		this.state.dt.pekerjaan = '';
		this.state.dt.kewarganegaraan = '';
		this.state.dt.alamat = '';
		this.state.dt.jenis_kelamin = '';
		this.state.dt.status_perkawinan = '';
		this.state.dt.tempat_meninggal = '';
		this.state.dt.sebab_meninggal = '';
		this.state.status.dataPenduduk = 'none';
		this.state.nik2Stat = null;
		this.state.nik3Stat = null;
		this.state.shdk1 = null;
		this.state.shdk_aw = null;
		this.state.dt.nama2 = '';
		this.state.dt.tanggal_lahir2 = '';
		this.state.dt.tempat_lahir2 = '';
		this.state.dt.agama2 = '';
		this.state.dt.pekerjaan2 = '';
		this.state.dt.kewarganegaraan2 = '';
		this.state.dt.alamat2 = '';
		this.state.dt.jenis_kelamin2 = '';
		this.state.dt.status_perkawinan2 = '';
		this.state.dt.tempat_meninggal2 = '';
		this.state.dt.sebab_meninggal2 = '';
		this.state.status.dataPenduduk2 = 'none';
		this.state.meninggal2 = 'none';
		this.state.status.btnForm = false;
		this.state.detail = [];
		this.state.detail_simpan = [];
		detail = []
		detail_simpan = []

		this.state.waktu_meninggal = null;
		this.state.waktu_meninggal2 = null;

		this.state.accStat = null;
		this.state.tableAcc = 'none';
		this.state.formAcc = false;
		this.state.status.form = false;
		this.state.formDisposisi = false;

		this.forceUpdate();
	};

	// Cari NIK
	handleChange = (e, cek) => {
		// console.log("E")
		// console.log(e.target.value)
		// console.log("Parameter")
		// console.log(cek)
		switch (cek) {
			case 'cari_nik1':
				this.setState({
					nikStat: e.target.value
				});
				// this.state.input['cari_nik1'] = e.target.value;
				this.forceUpdate();
				break;
			case 'cari_nik2':
				this.setState({
					nik2Stat: e.target.value
				});
				// this.state.input['cari_nik2'] = e.target.value;
				this.forceUpdate();
				break;
			case 'cari_nik3':
				this.setState({
					nik3Stat: e.target.value
				});
				// this.state.input['cari_nik3'] = e.target.value;
				this.forceUpdate();
				break;
		}
	};

	cariNIK = (e, sel) => {
		e.preventDefault();

		let id_cari = '';
		if (sel == 'cari_nik1') {
			id_cari = this.state.nikStat;
			// id_cari = this.state.input['cari_nik1'];
		} else if (sel == 'cari_nik2') {
			id_cari = this.state.nik2Stat;
			// id_cari = this.state.input['cari_nik2'];
		} else if (sel == 'cari_nik3') {
			id_cari = this.state.nik3Stat;
			// id_cari = this.state.input['cari_nik3'];
		}

		if (id_cari.length != 16) {
			this.props.getAntrian('Surat Keterangan Ahli Waris', 'cek digit nik');
		} else {

			Get('penduduk/find', id_cari, (data) => {
				if (data.results) {
					console.log(data.results);
					const data_rt = data.results.data_kk.data_rt;
					const data_rw = data_rt.data_rw;
					const data_dusun = data_rw.data_dusun;
					const data_desa = data_dusun.data_keldes;
					const data_kecamatan = data_desa.data_kecamatan;
					const data_kabkota = data_kecamatan.data_kabkota;
					let alamat = '';
					if (JENIS_DESA.toLowerCase() == 'kelurahan') {
						alamat =
							' RT. ' +
							data.results.data_kk.data_rt.nama +
							'/RW. ' +
							data.results.data_kk.data_rt.data_rw.nama +
							', ' +
							data.results.data_kk.data_rt.data_rw.data_keldes.nama +
							', Kec. ' +
							data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
							', ' +
							data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
					} else {
						if (data.results.data_kk.id_rt == '0') {
							alamat = JENIS_DESA + " " + data.results.data_keldes.nama + 
							", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
							", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
						} else {
							alamat =
							" RT. " + 
							data.results.data_kk.data_rt.nama +
							"/RW. " +
							data.results.data_kk.data_rt.data_rw.nama +
							", " + JENIS_DUSUN + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.nama +
							", " + JENIS_DESA + " " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
							", Kec. " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
								.data_kecamatan.nama +
							", " +
							data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
								.data_kecamatan.data_kabkota.nama; 
						}
					}

					if (sel == 'cari_nik2') {
						// this.state.nik2Stat = {value: data.results.id, label: data.results.id}
						this.state.nik2Stat = data.results.id
						this.state.tanggal_lahir2 = data.results.tanggal_lahir;
						this.state.status.btnForm = false;

						this.state.dt.nama2 = data.results.nama;
						this.state.dt.tanggal_lahir2 = new Date(data.results.tanggal_lahir).toLocaleDateString('id-ID');
						this.state.dt.tempat_lahir2 = data.results.tempat_lahir;
						this.state.dt.jenis_kelamin2 = data.results.jk;
						this.state.dt.status_perkawinan2 = data.results.status_perkawinan;
						this.state.dt.agama2 = data.results.data_agama.nama;
						this.state.dt.pekerjaan2 = data.results.data_pekerjaan.nama;
						this.state.dt.kewarganegaraan2 = 'Indonesia';
						this.state.dt.alamat2 = alamat;
						this.state.status.dataPenduduk2 = 'block';
					} else {
						if (sel == 'cari_nik3') {
							// aw.nik = data.results.nik;
							// aw.nama = data.results.nama;
							// aw.jenis_kelamin = data.results.jenis_kelamin;
							// aw.tanggal_lahir = data.results.tanggal_lahir;
							// aw.tempat_lahir = data.results.tempat_lahir;
							// aw.pekerjaan = data.results.data_pekerjaan.nama;
							
							let dataAw = {
								nik : data.results.id,
								nama : data.results.nama,
								jenis_kelamin : data.results.jenis_kelamin,
								tanggal_lahir : data.results.tanggal_lahir,
								tempat_lahir : data.results.tempat_lahir,
								pekerjaan : data.results.pekerjaan,
							}

							this.state.aw = dataAw;

							// this.state.aw.nik = data.results.id;
							// this.state.aw.nama = data.results.nama;
							// this.state.aw.jenis_kelamin = data.results.jenis_kelamin;
							// this.state.aw.tanggal_lahir = data.results.tanggal_lahir;
							// this.state.aw.tempat_lahir = data.results.tempat_lahir;
							// this.state.aw.pekerjaan = data.results.data_pekerjaan.nama;
							this.state.status.dataPenduduk3 = 'block';
						} else {
							Get('catatan-penduduk/nik', data.results.id, (dpddk) => {
								let pctt = 0;
								let catatan = '';
								if (dpddk.results.data) {
									dpddk.results.data.map((dtp) => {
										if (dtp.status == 0 && dtp.penalti == 1) {
											pctt = 1;
											catatan = dtp.catatan;
										}
									});
								}
								if (pctt == 0) {
									// this.state.nikStat = {value: data.results.id, label: data.results.id}
									this.state.nikStat = data.results.id
									this.state.tanggal_lahir = data.results.tanggal_lahir;
									this.state.dt.nama = data.results.nama;
									this.state.dt.tanggal_lahir = new Date(
										data.results.tanggal_lahir
									).toLocaleDateString('id-ID');
									this.state.dt.tempat_lahir = data.results.tempat_lahir;
									this.state.dt.jenis_kelamin = data.results.jk;
									this.state.dt.status_perkawinan = data.results.status_perkawinan;
									this.state.dt.agama = data.results.data_agama.nama;
									this.state.dt.pekerjaan = data.results.data_pekerjaan.nama;
									this.state.dt.kewarganegaraan = 'Indonesia';
									this.state.dt.alamat = alamat;
									this.state.status.dataPenduduk = 'block';
									this.state.status.btnForm = false;
									this.forceUpdate();
								} else {
									this.state.nikStat = null;
									this.state.frmNik = [];
									this.state.dt.nama = '';
									this.state.dt.tanggal_lahir = '';
									this.state.dt.tempat_lahir = '';
									this.state.dt.agama = '';
									this.state.dt.pekerjaan = '';
									this.state.dt.kewarganegaraan = '';
									this.state.dt.alamat = '';
									this.state.dt.jenis_kelamin = '';
									this.state.dt.status_perkawinan = '';
									this.state.dt.tempat_meninggal = '';
									this.state.dt.sebab_meninggal = '';
									this.state.status.dataPenduduk = 'none';
									this.state.status.btnForm = true;
									this.forceUpdate();
									this.setState({
										show: true,
										basicType: 'danger',
										basicTitle: 'Peringatan Catatan Warga',
										pesanAlert:
											'Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r' +
											catatan
									});
								}
							});
						}
					}
					this.forceUpdate();
				} else {	// Jika nik tidak ditemukan
					if (sel == 'cari_nik2') {
						this.state.nik2Stat = null;
						this.state.frmNik2 = [];
						this.state.dt.nama2 = '';
						this.state.dt.tanggal_lahir2 = '';
						this.state.dt.tempat_lahir2 = '';
						this.state.dt.agama2 = '';
						this.state.dt.pekerjaan2 = '';
						this.state.dt.kewarganegaraan2 = '';
						this.state.dt.alamat2 = '';
						this.state.dt.jenis_kelamin2 = '';
						this.state.dt.status_perkawinan2 = '';
						this.state.dt.tempat_meninggal2 = '';
						this.state.dt.sebab_meninggal2 = '';
						this.state.status.dataPenduduk2 = 'none';
						this.state.status.btnForm = true;
						this.forceUpdate();
						this.props.getAntrian('Surat Keterangan Ahli Waris', 'cari nik');
					} else if (sel == 'cari_nik1') {
						this.state.nikStat = null;
						this.state.frmNik = [];
						this.state.dt.nama = '';
						this.state.dt.tanggal_lahir = '';
						this.state.dt.tempat_lahir = '';
						this.state.dt.agama = '';
						this.state.dt.pekerjaan = '';
						this.state.dt.kewarganegaraan = '';
						this.state.dt.alamat = '';
						this.state.dt.jenis_kelamin = '';
						this.state.dt.status_perkawinan = '';
						this.state.dt.tempat_meninggal = '';
						this.state.dt.sebab_meninggal = '';
						this.state.status.dataPenduduk = 'none';
						this.state.status.btnForm = true;
						this.forceUpdate();
						this.props.getAntrian('Surat Keterangan Ahli Waris', 'cari nik');
					} else if (sel == "cari_nik3") {
						this.state.aw = null;
						this.forceUpdate()
						this.props.getAntrian('Surat Keterangan Ahli Waris', 'cari nik');
					}
				}
			});
		}
	};

	tambahAw = (e) => {
		// if (this.state.nik3Stat != null) {
		// if (this.state.input['cari_nik3'] != null) {
		if (this.state.aw != null && this.state.shdk_aw) {
			detail = this.state.detail;
			// Get('penduduk/find', this.state.input['cari_nik3'], (data) => {
			Get('penduduk/find', this.state.nik3Stat, (data) => {
				if (data.results) {
					detail.push({
						nik: data.results.id,
						nama: data.results.nama,
						jenis_kelamin: data.results.jenis_kelamin,
						tanggal_lahir: data.results.tanggal_lahir,
						tempat_lahir: data.results.tempat_lahir,
						pekerjaan: data.results.data_pekerjaan.nama,
						shdk: this.state.shdk_aw.value,
						keterangan: ''
					});
					this.setState({
						detail
					});
					this.state.status.dataPenduduk3 = 'none';
					
					// this.state.aw.nama = '';
					// this.state.shdk_aw = '';
					this.state.nik3Stat = "";
					// this.state.nik3Stat = null;
					this.state.aw = null
					this.state.shdk_aw = null;

					this.forceUpdate();
				}
			});
		} else {
			this.props.getAntrian('Surat Keterangan Ahli Waris', 'cekDataPewaris');
		}
	};

	changeInputValue = (e, sel) => {
		switch (sel) {
			case 'shdk':
				aw.shdk = e.target.value;
				this.forceUpdate();
				break;
		}
	};

	render() {
		let no = 0;

		const ahliwaris = this.state.detail.map((dt, i) => {
			no++;
			return (
				<tr key={i}>
					<td>{no}</td>
					<td>{dt.nama}</td>
					<td>
						{dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
					</td>
					<td>{dt.pekerjaan}</td>
					<td>{dt.shdk}</td>
					<td>
						<Button size="xs" onClick={() => this.hapus(i)} color="danger" className="btn-icon">
							<i className="fa fa-trash" />
						</Button>
					</td>
				</tr>
			);
		});

		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const { Basic } = this.state;

		const shdk = [
			{ value: 'Suami', label: 'Suami' },
			{ value: 'Isteri', label: 'Isteri' },
			{ value: 'Orang Tua', label: 'Orang Tua' },
			{ value: 'Anak', label: 'Anak' },
			{ value: 'Kakak', label: 'Kakak' },
			{ value: 'Adik', label: 'Adik' },
			{ value: 'Mertua', label: 'Mertua' },
			{ value: 'Menantu', label: 'Menantu' },
			{ value: 'Cucu', label: 'Cucu' },
			{ value: 'Pembantu', label: 'Pembantu' },
			{ value: 'Famili Lain', label: 'Famili Lain' }
		];

		return (
			<div>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					cancelBtnBsStyle="info"
					onCancel={this.closeAlert}
					showConfirm={false}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => {
						this.tutupForm();
						this.onCloseModal('Basic');
					}}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div
							// style={{ marginBottom: this.state.display == '' ? '230px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								// console.log(e);
								this.state.isDown = true;
								this.state.startY = e.pageY - slider.offsetTop;
								this.state.scrollUp = slider.scrollTop;
								this.forceUpdate();
								// console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title" align="center">
									Surat Keterangan Ahli Waris
								</h5>
							</div>

							<div className="modal-body">
								{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label">No Antrian Anda</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="no_antrian_anda"
										disabled
										type="text"
										defaultValue="0010"
										//   value={this.state.dt.nama_pemohon}
									/>
								</Col>
							</FormGroup> */}

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											placeholder="NIK"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Lengkap Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pemohon"
											disabled
											type="text"
											placeholder=" Nama Lengkap Pemohon"
											value={this.props.data.results.nama}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Hubungan Keluarga dengan Pewaris</Label>
									<Col sm="9">
										<Select
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'shdk1');
											}}
											value={this.state.shdk1}
											id="shdk"
											options={shdk}
											placeholder="HUBUNGAN KELUARGA"
										/>
									</Col>
								</FormGroup>
								<hr />

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										NIK
									</Label>
									<div className="col-sm-7">
										<input
											name="nikStat"
											id="nikStat"
											// value={this.state.nikStat}
											onChange={(e) => this.handleChange(e, 'cari_nik1')}
											style={{ marginBottom: '10px' }}
											className="form-control"
											placeholder="Masukkan NIK Terlapor"
											minLength="16"
											// onFocus={() => this.setActiveInput('cari_nik1')}
											value={this.state.nikStat}
											// onChange={(e) => this.onChangeInput(e)}
										/>
									</div>
									<div className="col-sm-2" style={{ textAlign: 'center' }}>
										<button
											type="button"
											className="btn btn-warning"
											onClick={(e) => this.cariNIK(e, 'cari_nik1')}
										>
											Cari
										</button>
									</div>
								</FormGroup>
								<div style={{ display: this.state.status.dataPenduduk }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama Terlapor
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="nama"
												type="text"
												placeholder="Nama Terlapor"
												value={this.state.dt.nama}
												readOnly
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tempat Lahir
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="tempat_lahir"
												type="text"
												placeholder="Tempat Lahir"
												value={this.state.dt.tempat_lahir}
												readOnly
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	console.log(e.target);
											// 	this.setActiveInput('tempat_lahir');
											// }}
											// value={this.state.input['tempat_lahir'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tanggal Lahir
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="tanggal_lahir"
												type="text"
												placeholder="Tanggal Lahir"
												value={this.state.dt.tanggal_lahir}
												readOnly
											/>
										</Col>
									</FormGroup>
								</div>
								<div style={{ display: 'none' }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Jenis Kelamin
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="jenis_kelamin"
												type="text"
												placeholder="Jenis Kelamin"
												value={this.state.dt.jenis_kelamin}
												readOnly
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Pekerjaan
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="pekerjaan"
												type="text"
												placeholder="Pekerjaan"
												value={this.state.dt.pekerjaan}
												readOnly
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Pekerjaan
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="kewarganegaraan"
												type="text"
												placeholder="Pekerjaan"
												value={this.state.dt.kewarganegaraan}
												readOnly
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Agama
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="agama"
												type="text"
												placeholder="Agama"
												value={this.state.dt.agama}
												readOnly
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Alamat
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="alamat"
												type="text"
												placeholder="Alamat"
												value={this.state.dt.alamat}
												readOnly
											/>
										</Col>
									</FormGroup>
								</div>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Waktu Meninggal</Label>
									<Col sm="9">
										<Datetime
											inputProps={{ required: true, placeholder: 'Waktu Meninggal' }}
											initialValue={this.state.waktu_meninggal}
											onChange={(e) => {
												this.gantiTgl(e, 'waktu_meninggal');
											}}
											locale="id-ID"
											timeFormat="HH:mm"
											dateFormat="DD/MM/yyyy"
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Tempat Meninggal</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tempat_meninggal"
											type="text"
											defaultValue=""
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	console.log(e.target);
											// 	this.setActiveInput('tempat_meninggal');
											// }}
											onFocus={() => this.setActiveInput('tempat_meninggal')}
											value={this.state.input['tempat_meninggal'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											required
											placeholder="Contoh : Rumah Sakit"
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Sebab Meninggal</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="sebab_meninggal"
											type="text"
											defaultValue=""
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	console.log(e.target);
											// 	this.setActiveInput('sebab_meninggal');
											// }}
											onFocus={() => this.setActiveInput('sebab_meninggal')}
											value={this.state.input['sebab_meninggal'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											required
											placeholder="Contoh : Sakit"
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Col sm="12">
										{/* <a href="#" onClick={this.tambahMeninggal} className="btn btn-sm btn-success">
											Tambah Data Meninggal
										</a> */}
										<a
											href="#"
											onClick={this.tambahMeninggal}
											className={
												this.state.meninggal2 == 'none' ? (
													'btn btn-sm btn-success'
												) : (
														'btn btn-sm btn-danger'
													)
											}
										>
											{this.state.meninggal2 == 'none' ? (
												'Tambah Data Meninggal'
											) : (
													'Hapus Data Meninggal'
												)}
										</a>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.meninggal2 }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											NIK
										</Label>
										{/* <Col sm="9">
												<Select
														classNamePrefix="select"
														onChange={(e) => {
																this.getPenduduk(e, 'nik2');
														}}
														defaultValue={this.state.nik2Stat}
														value={this.state.nik2Stat}
														name="NIK2"
														options={this.state.frmNik2}
														placeholder="Pilih NIK"
														onInputChange={(e) => this.handleInputChangeNIK(e, 'nik2')}
														isClearable
												/>
										</Col> */}

										<Col sm="6">
											<input
												name="nik2Stat"
												id="nik2Stat"
												// value={this.state.nikStat}
												onChange={(e) => this.handleChange(e, 'cari_nik2')}
												style={{ marginBottom: '10px' }}
												className="form-control"
												placeholder="Masukkan NIK Terlapor"
												minLength="16"
												// onFocus={() => this.setActiveInput('cari_nik1')}
												value={this.state.nik2Stat}
												// onChange={(e) => this.onChangeInput(e)}
											/>
											{/* <input
												name="nik2Stat"
												id="nik2Stat"
												// value={this.state.nik2Stat}
												onChange={(e) => this.handleChange(e, 'cari_nik2')}
												style={{ marginBottom: '10px' }}
												className="form-control"
												placeholder="Masukkan NIK Terlapor"
												minLength="16"
												onFocus={(e) => {
													this.setState({ display: '' });
													console.log(e.target);
													this.setActiveInput('cari_nik2');
												}}
												value={this.state.input['cari_nik2'] || ''}
												onChange={(e) => this.onChangeInput(e)}
											/> */}
										</Col>
										<Col sm="3">
											<button
												type="button"
												className="btn btn-warning"
												onClick={(e) => this.cariNIK(e, 'cari_nik2')}
											>
												Cari
											</button>
										</Col>
									</FormGroup>
									<div style={{ display: this.state.status.dataPenduduk2 }}>
										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
												Nama Terlapor
											</Label>
											<Col sm="9">
												<input
													className="form-control"
													id="nama2"
													type="text"
													placeholder="Nama Terlapor"
													value={this.state.dt.nama2}
													readOnly
												/>
											</Col>
										</FormGroup>
										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
												Tempat Lahir
											</Label>
											<Col sm="9">
												<input
													className="form-control"
													id="tempat_lahir2"
													type="text"
													placeholder="Tempat Lahir"
													value={this.state.dt.tempat_lahir2}
													readOnly
												/>
											</Col>
										</FormGroup>
										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
												Tanggal Lahir
											</Label>
											<Col sm="9">
												<input
													className="form-control"
													id="tanggal_lahir2"
													type="text"
													placeholder="Tanggal Lahir"
													value={this.state.dt.tanggal_lahir2}
													readOnly
												/>
											</Col>
										</FormGroup>
									</div>
									<div style={{ display: 'none' }}>
										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
												Jenis Kelamin
											</Label>
											<Col sm="9">
												<input
													className="form-control"
													id="jenis_kelamin2"
													type="text"
													placeholder="Jenis Kelamin"
													value={this.state.dt.jenis_kelamin2}
													readOnly
												/>
											</Col>
										</FormGroup>
										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
												Pekerjaan
											</Label>
											<Col sm="9">
												<input
													className="form-control"
													id="pekerjaan2"
													type="text"
													placeholder="Pekerjaan"
													value={this.state.dt.pekerjaan2}
													readOnly
												/>
											</Col>
										</FormGroup>
										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
												Kewarganegaraan
											</Label>
											<Col sm="9">
												<input
													className="form-control"
													id="kewarganegaraan2"
													type="text"
													placeholder="Pekerjaan"
													value={this.state.dt.kewarganegaraan2}
													readOnly
												/>
											</Col>
										</FormGroup>
										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
												Agama
											</Label>
											<Col sm="9">
												<input
													className="form-control"
													id="agama2"
													type="text"
													placeholder="Agama"
													value={this.state.dt.agama2}
													readOnly
												/>
											</Col>
										</FormGroup>
										<FormGroup className="row">
											<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
												Alamat
											</Label>
											<Col sm="9">
												<input
													className="form-control"
													id="alamat2"
													type="text"
													placeholder="Alamat"
													value={this.state.dt.alamat2}
													readOnly
												/>
											</Col>
										</FormGroup>
									</div>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Waktu Meninggal
										</Label>
										<Col sm="9">
											<Datetime
												inputProps={{ placeholder: 'Waktu Meninggal' }}
												initialValue={this.state.waktu_meninggal2}
												onChange={(e) => {
													this.gantiTgl(e, 'waktu_meninggal2');
												}}
												locale="id-ID"
												timeFormat="HH:mm"
												dateFormat="DD/MM/yyyy"
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tempat Meninggal
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="tempat_meninggal2"
												type="text"
												placeholder="Tempat Meninggal"
												defaultValue={this.state.dt.tempat_meninggal2}
												onFocus={(e) => {
													this.setState({ display: '' });
													console.log(e.target);
													this.setActiveInput('tempat_meninggal2');
												}}
												value={this.state.input['tempat_meninggal2'] || ''}
												onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Sebab Meninggal
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="sebab_meninggal2"
												type="text"
												placeholder="Sebab Meninggal"
												defaultValue={this.state.dt.sebab_meninggal2}
												onFocus={(e) => {
													this.setState({ display: '' });
													console.log(e.target);
													this.setActiveInput('sebab_meninggal2');
												}}
												value={this.state.input['sebab_meninggal2'] || ''}
												onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>
								</div>

								<hr />

								{/* ======= AHLI wARIS ============ */}

								<h5>Daftar Ahli Waris</h5>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										NIK Ahli Waris
									</Label>
									<div className="col-sm-7">
										<input
											name="nik3Stat"
											id="nik3Stat"
											// value={this.state.nikStat}
											onChange={(e) => this.handleChange(e, 'cari_nik3')}
											style={{ marginBottom: '10px' }}
											className="form-control"
											placeholder="Masukkan NIK Ahli Waris"
											minLength="16"
											// onFocus={() => this.setActiveInput('cari_nik1')}
											value={this.state.nik3Stat}
											// onChange={(e) => this.onChangeInput(e)}
										/>
										{/* <input
											name="nik3Stat"
											id="nik3Stat"
											// value={this.state.nik3Stat}
											onChange={(e) => this.handleChange(e, 'cari_nik3')}
											style={{ marginBottom: '10px' }}
											className="form-control"
											placeholder="Masukkan NIK Ahli Waris"
											minLength="16"
											onFocus={(e) => {
												this.setState({ display: '' });
												console.log(e.target);
												this.setActiveInput('cari_nik3');
											}}
											value={this.state.input['cari_nik3'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/> */}
									</div>
									<div className="col-sm-2" style={{ textAlign: 'center' }}>
										<button
											type="button"
											className="btn btn-warning"
											onClick={(e) => this.cariNIK(e, 'cari_nik3')}
										>
											Cari
										</button>
									</div>
								</FormGroup>
								<div style={{ display: this.state.status.dataPenduduk3 }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Nama Ahli Waris
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="nama"
												type="text"
												placeholder="Nama Ahli Waris"
												value={this.state.aw ? this.state.aw.nama : ''}
												readOnly
											/>
										</Col>
									</FormGroup>
								</div>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Hubungan Keluarga</Label>
									<Col sm="9">
										<Select
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'shdk_aw');
											}}
											value={this.state.shdk_aw}
											id="shdk"
											options={shdk}
											placeholder="Hubungan Keluarga"
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Col sm="12">
										<button type="button" className="btn btn-primary" onClick={this.tambahAw}>
											Tambah
										</button>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Col sm="12">
										<table className="table table-bordered table-stripes table-hovered">
											<thead>
												<tr>
													<th>No</th>
													<th>Nama</th>
													<th>Tempat/Tanggal Lahir</th>
													<th>Pekerjaan</th>
													<th>Hubungan Keluarga</th>
													<th>
														<i className="fa fa-trash" />
													</th>
												</tr>
											</thead>
											<tbody>{ahliwaris}</tbody>
										</table>
									</Col>
								</FormGroup>
							</div>

							<div
								className="modal-footer"
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
							>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => {
										this.tutupForm();
										this.onCloseModal('Basic');
									}}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-primary">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>

					{/* {this.state.display == '' ? (
						<div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 4 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
							''
						)} */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
						// background: 'linear-gradient(135deg, #484848, #000000)'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/ahli-waris.png')}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p className="mb-0" style={{ fontSize: '20px', fontWeight: '600', color: '#000000' }}>
								Ahli Waris
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
