import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Col, FormGroup, Label, Row, Form } from 'reactstrap';
import { JENIS_DESA, JENIS_DUSUN, Post, Get } from '../function/Koneksi';
import Keyboard from 'react-simple-keyboard';

export default class SuratSKCK extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Basic: false,
			// keyboard
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: {},
			inputPattern: /^\d+$/,
			display: 'block',
			focus: false,
			hasFocus: false
		};
		this.showKeyboard = this.showKeyboard.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
	}

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	onChangeAll = (inputObj) => {
		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputObj[this.state.inputName]
		};
		this.setState({
			input: updatedInputObj
		});
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				console.log('Active input', inputName);
			}
		);
	};

	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		console.log(inputVal);

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);
	};

	onKeyPress = (button) => {
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: 'block'
			});
		}
		this.forceUpdate();
	};

	onOpenModal = (key) => {
		console.log(key);
		this.setState({ [key]: true });
	};

	onCloseModal = (key) => {
		this.setState({ [key]: false, input: '' });
	};

	handleClick = (event) => {
		alert(event);
	};

	simpan = (e) => {
		e.preventDefault();
		// this.state.status.btnForm = true;
		// this.forceUpdate();

		// let uuid = document.getElementById('uuid').value;

		let alamat = '';
		if (JENIS_DESA.toLowerCase() == 'kelurahan') {
			alamat =
				' RT. ' +
				this.props.data.results.data_kk.data_rt.nama +
				'/RW. ' +
				this.props.data.results.data_kk.data_rt.data_rw.nama +
				', ' +
				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
				', Kec. ' +
				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
				', ' +
				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
		} else {
			// alamat =
			// 	' RT. ' +
			// 	this.props.data.results.data_kk.data_rt.nama +
			// 	'/RW. ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 	', ' +
			// 	JENIS_DUSUN +
			// 	' ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 	', ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 	', Kec. ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
			// 	', ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

				if (this.props.data.results.data_kk.id_rt == '0') {
					alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
					", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
					", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
					", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
				} else {
					alamat =
					" RT. " + 
					this.props.data.results.data_kk.data_rt.nama +
					"/RW. " +
					this.props.data.results.data_kk.data_rt.data_rw.nama +
					", " + JENIS_DUSUN + " " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
					", " + JENIS_DESA + " " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
					", Kec. " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.nama +
					", " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.data_kabkota.nama; 
				}
		}

		let addSkck = {
			id_jenis: this.props.id_jenis,

			nik: this.props.data.results.id,
			kk: this.props.data.results.id_kk,
			nama_pemohon: this.props.data.results.nama,
			tempat_lahir: this.props.data.results.tempat_lahir,
			tanggal_lahir: this.props.data.results.tanggal_lahir,
			pendidikan_terakhir: this.props.data.results.data_pendidikan_terakhir.nama,
			pekerjaan: this.props.data.results.data_pekerjaan.nama,
			agama: this.props.data.results.data_agama.nama,
			status_perkawinan: this.props.data.results.status_perkawinan,
			alamat: alamat,
			jenis_kelamin: this.props.data.results.jk,

			kewarganegaraan: 'Indonesia',
			tujuan_pembuatan: document.getElementById('tujuan_pembuatan').value
		};

		let psn = '';
		let resstat = 201;
		let metode = 'create';

		Post('surat-keterangan/grup/' + metode, null, addSkck, (res) => {
			this.onCloseModal('Basic');

			let dataAntrian = {
				nama: this.props.data.results.nama,
				nik: this.props.data.results.id,
				keterangan: '',
				uuid_surat:res.data.results,
			};

			if (res.status === resstat) {
				Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
					console.log("Hasil Update Status")
					console.log(res2)
					if (res2.code === 201) {
						console.log("Berhasil Ganti Status Menjadi Selesai")

						this.props.getAntrian('Surat Pengantar SKCK', 'berhasil', false, dataAntrian);
					}
					else{
						console.log("Gagal Ganti Status Menjadi Selesai")

						this.props.getAntrian('Surat Pengantar SKCK', 'gagal');
					}
				});
				// this.props.getAntrian('Surat Pengantar SKCK', 'berhasil', false, dataAntrian);
			} else {
				this.props.getAntrian('Surat Pengantar SKCK', 'gagal');
			}
			// reset cache
			this.state.input = {};
			document.getElementById('tujuan_pembuatan').value = '';
		});
	};

	render() {
		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const { Basic } = this.state;

		return (
			<div>
				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div
							// style={{ marginBottom: this.state.display == 'block' ? '100px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								// console.log(e);
								this.state.isDown = true;
								this.state.startY = e.pageY - slider.offsetTop;
								this.state.scrollUp = slider.scrollTop;
								this.forceUpdate();
								// console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title" align="center">
									Pengantar SKCK
								</h5>
							</div>

							<div className="modal-body">
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											placeholder="NIK"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Lengkap</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pemohon"
											disabled
											type="text"
											placeholder=" Nama Lengkap"
											value={this.props.data.results.nama}
										/>
									</Col>
								</FormGroup>

								<div style={{ display: 'none' }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tempat Lahir
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												readOnly
												id="tempat_lahir"
												type="text"
												placeholder="Tempat lahir"
												value={this.props.data.results.tempat_lahir}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
											Tanggal Lahir
										</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="tanggal_lahir"
												readOnly
												type="text"
												placeholder="Tanggal Lahir"
												value={this.props.data.results.tanggal_lahir}
											/>
										</Col>
									</FormGroup>

									<input
										className="form-control"
										id="jenis_kelamin"
										readOnly
										type="hidden"
										placeholder="jenis_kelamin"
										value={this.props.data.results.jk}
									/>

									<input
										className="form-control"
										readOnly
										id="pekerjaan"
										type="hidden"
										placeholder="Pekerjaan"
										value={this.props.data.results.pekerjaan}
									/>

									<input
										className="form-control"
										id="pendidikan_terakhir"
										readOnly
										type="hidden"
										placeholder="Pendidikan Terakhir"
										value={this.props.data.results.pendidikan_terakhir}
									/>

									<input
										className="form-control"
										id="agama"
										readOnly
										type="hidden"
										placeholder="Agama"
										value={this.props.data.results.agama}
									/>

									<input
										className="form-control"
										id="status_perkawinan"
										type="hidden"
										readOnly
										placeholder="Status Perkawinan"
										value={this.props.data.results.status_perkawinan}
									/>

									<input
										className="form-control"
										id="alamat"
										type="hidden"
										readOnly
										placeholder="Alamat"
										value={this.props.data.results.alamat}
									/>

									<input
										className="form-control"
										id="kewarganegaraan"
										type="hidden"
										readOnly={true}
										placeholder="Kewarganegaraan"
										value="Indonesia"
									/>
								</div>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Tujuan pembuatan</Label>
									<Col sm="9">
										<input
											onFocus={(e) => {
												this.setState({ display: 'block' });
												console.log(e.target);
												this.setActiveInput('tujuan_pembuatan');
											}}
											value={this.state.input['tujuan_pembuatan'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											className="form-control"
											id="tujuan_pembuatan"
											type="text"
											required
											placeholder="Tujuan pembuatan"
											autoComplete="off"
											// defaultValue={this.state.dt.tujuan_pembuatan}
										/>
									</Col>
								</FormGroup>
							</div>

							<div
								className="modal-footer"
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
							>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-primary">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>
					{/* <div
						style={{
							position: 'fixed',
							bottom: 0,
							left: 0,
							width: '100%',
							zIndex: 4,
							display: this.state.display
						}}
					>
						<Keyboard
							style={{ marginTop: '40px', zIndex: 3 }}
							keyboardRef={(r) => (this.keyboard = r)}
							inputName={this.state.inputName}
							layoutName={this.state.layoutName}
							onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
							onKeyPress={(button) => this.onKeyPress(button)}
						/>
					</div> */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/skck.png')}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p
								className="mb-0"
								style={{
									fontSize: '20px',
									fontWeight: '600',
									color: '#000000'
								}}
							>
								Pengantar SKCK
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
