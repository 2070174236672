import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";
import { DataToken } from "../../../function/Format";
import { JENIS_DESA } from "../../../function/Koneksi";

class MenuSuper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div
        className="mb-2 menuu"
        style={{
          display: "flex",
          justifyContent: "space-around",
          borderRadius: "10px",
          width: "100%"
        }}
      >
        <div className="dropdown-basic">
          {/* Dashboard */}
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-success"
                  style={{ borderRadius: "10px" }}
                >
                  <i className="fa fa-home"></i> Dashboard
                </button>
              </div>
            </Link>
          </div>
          {/* Kependudukan */}
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-success"
                style={{ borderRadius: "10px"}}
              >
                <i className="icofont icofont-users-alt-4"></i> Kependudukan
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                <Link to={process.env.PUBLIC_URL + "/admin/penduduk"}>
                  <i className="fa fa-user"></i> Penduduk
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/kartu-keluarga"}>
                  <i className="fa fa-users"></i> Kartu Keluarga
                </Link>
              </div>
            </div>
          </div>
          {/* Data Potensi */}
          <div className="dropdown">
            <Link to={process.env.PUBLIC_URL + "/admin/data-potensi"}>
              <div className="btn-group m-1">
                <button
                  type="button"
                  className="dropbtn btn-success"
                  style={{ borderRadius: "10px"}}
                >
                  <i className="fa fa-bar-chart-o"></i> Data Potensi
                </button>
              </div>
            </Link>
          </div>
          {/* User */}
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-success"
                style={{ borderRadius: "10px"}}
              >
                <i className="icofont icofont-users-alt-4"></i> User
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                <Link to={process.env.PUBLIC_URL + "/admin/pengguna"}>
                  <i className="fa fa-user"></i> Pengguna
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/operator"}>
                  <i className="fa fa-user"></i> Operator
                </Link>
              </div>
            </div>
          </div>
          {/* Wilayah */}
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-success"
                style={{ borderRadius: "10px" }}
              >
                <i className="fa fa-globe"></i> Wilayah
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                {(function() {
                  if (JENIS_DESA.toLowerCase() != "kelurahan") {
                    return (
                      <Link to={process.env.PUBLIC_URL + "/admin/dusun"}>
                        <i className="fa fa-globe"></i> Dusun
                      </Link>
                    );
                  }
                })()}
                <Link to={process.env.PUBLIC_URL + "/admin/rw"}>
                  <i className="fa fa-globe"></i> RW
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/rt"}>
                  <i className="fa fa-globe"></i> RT
                </Link>
              </div>
            </div>
          </div>
          {/* Perangkat */}
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-success"
                style={{ borderRadius: "10px"}}
              >
                <i className="fa fa-building"></i> Perangkat
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                <Link to={process.env.PUBLIC_URL + "/admin/jabatan-perangkat"}>
                  <i className="fa fa-building"></i> Jabatan Perangkat
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/perangkat"}>
                  <i className="fa fa-building"></i> Perangkat {JENIS_DESA}
                </Link>
              </div>
            </div>
          </div>
          {/* Pengaturan */}
          <div className="dropdown">
            <div className="btn-group m-1">
              <button
                type="button"
                className="dropbtn btn-success"
                style={{ borderRadius: "10px"}}
              >
                <i className="fa fa-wrench"></i> Pengaturan
                <span>
                  <i className="icofont icofont-arrow-down"></i>
                </span>
              </button>
              <div className="dropdown-content">
                <Link
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/jenis-surat",
                    state: "kode"
                  }}
                >
                  <i className="fa fa-wrench"></i> Kode Surat
                </Link>
                <Link
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/nomor-surat",
                    state: "kode"
                  }}
                >
                  <i className="fa fa-wrench"></i> Nomor Surat
                </Link>
                <Link
                  to={process.env.PUBLIC_URL + "/admin/profile-" + JENIS_DESA}
                >
                  <i className="fa fa-wrench"></i> Profile {JENIS_DESA}
                </Link>
                <Link to={process.env.PUBLIC_URL + "/admin/tanda-tangan"}>
                  <i className="fa fa-wrench"></i> Tanda Tangan
                </Link>
                <Link
                  to={process.env.PUBLIC_URL + "/admin/pengaturan-aplikasi"}
                >
                  <i className="fa fa-wrench"></i> Pengaturan Aplikasi
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MenuSuper;
