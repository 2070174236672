import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import { Col, FormGroup, Label, Row, Form } from 'reactstrap';
import Datepicker from 'react-datepicker';
import { Get, JENIS_DESA, JENIS_DUSUN, Post } from '../function/Koneksi';
import Datetime from 'react-datetime';
import Select from "react-select";

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const dateFormat = require('dateformat');

export default class SuratTidakMenikah extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: {
				form: false,
				btnForm: true,
				btnAksi: []
			},
			//keyboard
			focus: false,
			display: 'none',
			layoutName: 'default',
			inputName: 'input1',
			input: {},
			inputPattern: /^\d+$/,
			//end keyboard
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			successAlert: false,
			tanggal_surat: new Date(),
			// tanggal_surat_akta: new Date()
			tanggal_surat_akta: null,
			status_perkawinan: null,

			// Status Hubungan Dalam Keluarga
			shdk1: null,
			set_shdk:'',
		};
	}

	onOpenModal = (key) => {
		console.log(key);
		this.setState({ [key]: true });
	};

	onCloseModal = (key) => {
		this.setState({
			[key]: false,
			input: '',
			display: 'none',
			status_perkawinan: null,
			tanggal_surat_akta: null,
			set_shdk: '',
			shdk1:null,
		});
		this.forceUpdate();
	};

	handleClick = (event) => {
		alert(event);
	};

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_surat':
				this.setState({ tanggal_surat: date });
				break;
			case 'tanggal_surat_akta':
				if (typeof date._isValid != 'undefined') {
					this.setState({ tanggal_surat_akta: date.format('YYYY-MM-DD') });
				}
				break;
		}
	};

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		// this.state.status.btnForm = true;
		// this.forceUpdate();

		const namaSaksi = document.getElementById('nama_saksi').value;
		// const hubunganSaksi = document.getElementById('hubungan_saksi').value;

		// validasi
		// if (this.state.input.penghasilan != '' && this.state.tanggal_surat_akta && namaSaksi.trim() != '' && hubunganSaksi.trim() != '' && this.state.status_perkawinan && this.state.set_shdk != '') {
		if (this.state.input.penghasilan != '' && this.state.tanggal_surat_akta && namaSaksi.trim() != '' && this.state.status_perkawinan && this.state.set_shdk != '') {
			let alamat = '';
			if (JENIS_DESA.toLowerCase() == 'kelurahan') {
				alamat =
					' RT. ' +
					this.props.data.results.data_kk.data_rt.nama +
					'/RW. ' +
					this.props.data.results.data_kk.data_rt.data_rw.nama +
					', ' +
					this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
					', Kec. ' +
					this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
					', ' +
					this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
			} else {
				// alamat =
				// 	' RT. ' +
				// 	this.props.data.results.data_kk.data_rt.nama +
				// 	'/RW. ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
				// 	', ' +
				// 	JENIS_DUSUN +
				// 	' ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
				// 	', ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
				// 	', Kec. ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
				// 	', ' +
				// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;
	
					if (this.props.data.results.data_kk.id_rt == '0') {
						alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
						", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
						", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
						", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
					} else {
						alamat =
						" RT. " + 
						this.props.data.results.data_kk.data_rt.nama +
						"/RW. " +
						this.props.data.results.data_kk.data_rt.data_rw.nama +
						", " + JENIS_DUSUN + " " +
						this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
						", " + JENIS_DESA + " " +
						this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
						", Kec. " +
						this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
							.data_kecamatan.nama +
						", " +
						this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
							.data_kecamatan.data_kabkota.nama; 
					}
			}
	
			let addData = {
				id_jenis: this.props.id_jenis,
				nik: this.props.data.results.id,
				nama_pemohon: this.props.data.results.nama,
				tempat_lahir: this.props.data.results.tempat_lahir,
				tanggal_lahir: this.props.data.results.tanggal_lahir,
				pendidikan_terakhir: this.props.data.results.data_pendidikan_terakhir.nama,
				pekerjaan: this.props.data.results.data_pekerjaan.nama,
				agama: this.props.data.results.data_agama.nama,
				// status_perkawinan: this.props.data.results.status_perkawinan,
				status_perkawinan: this.state.status_perkawinan.value,
				alamat: alamat,
				jenis_kelamin: this.props.data.results.jk,
				kewarganegaraan: 'Indonesia',
				tujuan_pembuatan: 'Surat Tidak Pernah Menikah',
	
				nomor_surat_akta: document.getElementById('nomor_surat_akta').value,
				// tanggal_surat_akta: this.state.tanggal_surat_akta,
				tanggal_surat_akta: dateFormat(this.state.tanggal_surat_akta, 'yyyy-mm-dd'),
				nama_saksi: document.getElementById('nama_saksi').value,
				// hubungan_saksi: document.getElementById('hubungan_saksi').value,
				hubungan_saksi: this.state.set_shdk,
				nama_ayah: this.props.data.results.nama_ayah_kandung
			};
			let psn = '';
			let resstat = 201;
			let metode = 'create';
	
			// console.log(JSON.stringify(addData));
			Post('surat-keterangan/grup/' + metode + '/tidak-menikah', null, addData, (res) => {
				this.onCloseModal('Basic');
	
				let dataAntrian = {
					nama: this.props.data.results.nama,
					nik: this.props.data.results.id,
					keterangan: '',
					uuid_surat:res.data.results,
				};
	
				if (res.status === resstat) {
					Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
						console.log("Hasil Update Status")
						console.log(res2)
						if (res2.code === 201) {
							console.log("Berhasil Ganti Status Menjadi Selesai")
	
							this.props.getAntrian('Surat Keterangan Tidak Menikah', 'berhasil', false, dataAntrian);
						}
						else{
							console.log("Gagal Ganti Status Menjadi Selesai")
	
							this.props.getAntrian('Surat Keterangan Tidak Menikah', 'gagal');
						}
					});
					// this.props.getAntrian('Surat Keterangan Tidak Menikah', 'berhasil', false, dataAntrian);
				} else {
					this.props.getAntrian('Surat Keterangan Tidak Menikah', 'gagal');
				}
				this.componentDidMount();
				// reset cache
				document.getElementById('nomor_surat_akta').value = '';
				document.getElementById('nama_saksi').value = '';
				// document.getElementById('hubungan_saksi').value = '';
				this.state.set_shdk = '';
				this.state.tanggal_surat_akta = null;
				this.forceUpdate();
			});
		} else {
			this.props.getAntrian('Surat Pernyataan Tidak Menikah', 'gagal', true);
		}

	};
	// ========================================================================
	componentDidMount() {
		// const context = useContext(this.props.context);
		// context.getNomorAntrian();
		// console.log(this.props.location);
	}

	// ====================keyboard=============================

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}

		this.forceUpdate();
	};

	onChange = (input) => {
		this.setState({ input });
		console.log('Input changed', input);
	};

	onKeyPress = (button) => {
		console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				}
			}
		);
	};

	onInputChanged = (data) => {
		this.setState({ input: data });
	};

	onInputSubmitted = (data) => {
		console.log('Input submitted:', data);
	};

	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		console.log('Input changed', inputObj);
	};

	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				console.log('Active input', inputName);
			}
		);
	};

	//====================end keyboard========================

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'shdk1':
					this.state.set_shdk = e.value;
					this.setState({ shdk1: e });
					break;
			}
		} else {
			switch (sel) {
				case 'shdk1':
					this.state.set_shdk = 0;
					this.setState({ shdk1: e });
					break;
			}
		}

		this.forceUpdate();
	};

	render() {
		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const shdk = [
			{ value: 'Suami', label: 'Suami' },
			{ value: 'Isteri', label: 'Isteri' },
			{ value: 'Orang Tua', label: 'Orang Tua' },
			{ value: 'Anak', label: 'Anak' },
			{ value: 'Kakak', label: 'Kakak' },
			{ value: 'Adik', label: 'Adik' },
			{ value: 'Mertua', label: 'Mertua' },
			{ value: 'Menantu', label: 'Menantu' },
			{ value: 'Cucu', label: 'Cucu' },
			{ value: 'Pembantu', label: 'Pembantu' },
			{ value: 'Famili Lain', label: 'Famili Lain' }
		];

		const { Basic } = this.state;

		return (
			<div>
				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					styles={{
						modal: {
							width: '80%'
						}
					}}
				>
					<Form onSubmit={this.simpan}>
						<div
							// style={{ marginBottom: this.state.display == '' ? '200px' : '0px' }}
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								// console.log(e);
								this.state.isDown = true;
								this.state.startY = e.pageY - slider.offsetTop;
								this.state.scrollUp = slider.scrollTop;
								this.forceUpdate();
								// console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title" align="center">
									Surat Pernyataan Tidak Menikah
								</h5>
							</div>

							<div className="modal-body">
								<input
									className="form-control"
									id="uuid"
									type="hidden"
									placeholder=""
									// defaultValue={this.state.dt.uuid}
								/>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											placeholder="NIK"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Lengkap Pemohon</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pemohon"
											disabled
											type="text"
											placeholder=" Nama Lengkap"
											value={this.props.data.results.nama}
										/>
									</Col>
								</FormGroup>

								<div style={{ display: 'none' }}>
									<input
										className="form-control"
										readOnly
										id="tempat_lahir"
										type="text"
										placeholder="Tempat lahir"
										value={this.props.data.results.tempat_lahir}
									/>

									<input
										className="form-control"
										id="tanggal_lahir"
										readOnly
										type="text"
										placeholder="Tanggal Lahir"
										value={this.props.data.results.tanggal_lahir}
									/>

									<input
										className="form-control"
										id="jenis_kelamin"
										readOnly
										type="hidden"
										placeholder="jenis_kelamin"
										value={this.props.data.results.jk}
									/>

									<input
										id="pekerjaan"
										type="hidden"
										placeholder="Pekerjaan"
										value={this.props.data.results.pekerjaan}
									/>

									<input
										id="pendidikan_terakhir"
										readOnly
										type="hidden"
										placeholder="Pendidikan Terakhir"
										value={this.props.data.results.pendidikan_terakhir}
									/>
									<input
										id="agama"
										readOnly
										type="hidden"
										placeholder="Agama"
										value={this.props.data.results.agama}
									/>

									<input
										className="form-control"
										id="status_perkawinan"
										type="text"
										readOnly
										placeholder="Status Perkawinan"
										value={this.props.data.results.status_perkawinan}
									/>

									<input
										className="form-control"
										id="alamat"
										type="hidden"
										readOnly
										placeholder="Alamat"
										value={this.props.data.results.alamat}
									/>
									<input
										id="kewarganegaraan"
										type="hidden"
										placeholder="Kewarganegaraan"
										value="Indonesia"
									/>
								</div>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nomor Surat Akta
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nomor_surat_akta"
											type="text"
											placeholder="Nomor Surat Akta "
											required
											// onFocus={(e) => {
											// 	this.setState({ display: '' });
											// 	console.log(e.target);
											// 	this.setActiveInput('no_akta');
											// }}
											// value={this.state.input['no_akta'] || ''}
											// onChange={(e) => this.onChangeInput(e)}

											// onClick={() => this.showKeyboard()}
											// onFocus={() => this.setActiveInput('no_akta')}

											// onChange={(e) => this.onChangeInput(e)}
											// autoFocus
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tanggal Surat Akta
									</Label>
									<Col sm="9">
										<Datetime
											locale="id-ID"
											dateFormat="DD/MM/yyyy"
											closeOnSelect={true}
											input={true}
											inputProps={{ required: true, placeholder: 'Tanggal Surat Akta' }}
											closeOnTab={true}
											timeFormat={false}
											initialValue={this.state.tanggal_surat_akta}
											value={this.state.tanggal_surat_akta}
											onChange={(e) => {
												this.gantiTgl(e, 'tanggal_surat_akta');
											}}
										/>
										{/* <Datepicker
                                        id="tanggal_surat_akta"
                                        todayButton={'Today'}
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.tanggal_surat_akta}
                                        onChange={(e) => this.gantiTgl(e, 'tanggal_surat_akta')}
                                        className="form-control"
                                    /> */}
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Nama Saksi
									</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_saksi"
											type="text"
											placeholder="Nama Saksi"
											required
											// onClick={() => this.showKeyboard()}
											// onFocus={() => this.setActiveInput('nm_saksi')}
											// value={this.state.input['nm_saksi'] || ''}
											// onChange={(e) => this.onChangeInput(e)}
											// defaultValue={this.state.dt.nama_saksi}
										/>
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Hubungan Saksi
									</Label>
									<Col sm="9">
										<Select
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'shdk1');
											}}
											value={this.state.shdk1}
											id="shdk"
											options={shdk}
											placeholder="HUBUNGAN KELUARGA"
										/>
										{/* <input
											className="form-control"
											id="hubungan_saksi"
											type="text"
											placeholder="Hubungan Saksi"
											onClick={() => this.showKeyboard()}
											onFocus={() => this.setActiveInput('hub_saksi')}
											value={this.state.input['hub_saksi'] || ''}
											onChange={(e) => this.onChangeInput(e)}
											// defaultValue={this.state.dt.hubungan_saksi}
											required
										/> */}
									</Col>
								</FormGroup>
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Status Perkawinan
									</Label>
									<Col sm="9">
										<Select
											classNamePrefix="select"
											onChange={(e) => {
												console.log(e)
												this.setState({ status_perkawinan : e })
											}}
											// defaultValue={this.state.nik3Stat}

											value={this.state.status_perkawinan}
											name="NIK3"
											options={[
                        {value: 'Cerai Hidup', label: 'Cerai Hidup'},
                        {value: 'Cerai Mati', label: 'Cerai Mati'},
                      ]}
											placeholder="Pilih Status Perkawinan"
											isClearable
										/>
									</Col>
								</FormGroup>
							</div>

							<div
								className="modal-footer"
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center'
								}}
							>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-primary">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>

					{/* {this.state.display == '' ? (
						<div style={{ position: 'fixed', bottom: 0, left: 0, width: '100%', zIndex: 4 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
						''
					)} */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/tidak-menikah.png')}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p
								className="mb-0"
								style={{
									fontSize: '20px',
									fontWeight: '600',
									color: '#000000'
								}}
							>
								Pernyataan Tidak Menikah
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
