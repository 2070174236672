import React, { useContext } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	ButtonGroup,
	CustomInput,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';
import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';

function EditSkgr({ detail_surat_context }) {
	const detailSuratProps = useContext(detail_surat_context);

	const dateFormat = require('dateformat');

	return (
		<div>
			<Modal
				open={detailSuratProps.status.formUpdateSurat}
				onClose={detailSuratProps.tutupForm}
				styles={{ modal: { width: '80%' } }}
				closeOnEsc={false}
				closeOnOverlayClick={false}
			>
				<Form
					className="theme-form"
					onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}
				>
					<div className="modal-header">
						<h5 className="modal-title">Detail {detailSuratProps.detailSurat.jenis_surat}</h5>
					</div>
					<div className="modal-body">
						<input
							className="form-control"
							id="uuid"
							type="hidden"
							placeholder=""
							defaultValue={detailSuratProps.detailSurat.uuid}
						/>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Kuasa Dari{' '}
							</Label>
							<Col sm="9">
								<Select
									// isDisabled={this.state.status.select}
									classNamePrefix="select"
									onChange={(e) => {
										detailSuratProps.getPenduduk(
											e,
											detailSuratProps.detailSurat.jenis_surat,
											'nik'
										);
									}}
									defaultValue={detailSuratProps.detailSurat.pendStat}
									value={detailSuratProps.detailSurat.pendStat}
									name="penduduk"
									onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik')}
									options={detailSuratProps.dataNik.frmNik}
									placeholder="Pilih NIK"
									required={true}
									isClearable
								/>
							</Col>
						</FormGroup>
						<div style={{ display: detailSuratProps.status.showBio == false ? 'none' : '' }}>
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="nik"
								type="hidden"
								readOnly
								placeholder="NIK"
								value={detailSuratProps.detailSurat.nik}
							/>
							{/* </Col>
                </FormGroup> */}
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_pemohon"
										readOnly
										type="text"
										placeholder="nama_pemohon"
										value={detailSuratProps.detailSurat.nama}
										style={{ backgroundColor:"#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tempat Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										readOnly
										id="tempat_lahir"
										type="text"
										placeholder="Tempat lahir"
										value={detailSuratProps.detailSurat.tempat_lahir}
										style={{ backgroundColor:"#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tanggal Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tanggal_lahir"
										readOnly
										type="text"
										placeholder="Tanggal Lahir"
										value={dateFormat(detailSuratProps.detailSurat.tanggal_lahir, 'dd/mm/yyyy')}
										style={{ backgroundColor:"#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								readOnly
								id="pekerjaan"
								type="hidden"
								placeholder="Pekerjaan"
								value={detailSuratProps.detailSurat.pekerjaan}
							/>
							{/* </Col>
                </FormGroup> */}

							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="alamat"
								type="hidden"
								readOnly
								placeholder="Alamat"
								value={detailSuratProps.detailSurat.alamat}
							/>
							{/* </Col>
                </FormGroup> */}
						</div>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Yang Diberi Kuasa{' '}
							</Label>
							<Col sm="9">
								{detailSuratProps.detailSurat.formPenerimaKuasa == "none" ? (
									<Select
										// isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											detailSuratProps.getPenduduk(
												e,
												detailSuratProps.detailSurat.jenis_surat,
												'nik2'
											);
										}}
										defaultValue={detailSuratProps.detailSurat.pendStat2}
										value={detailSuratProps.detailSurat.pendStat2}
										name="penduduk"
										options={detailSuratProps.dataNik.frmNik2}
										placeholder="Pilih NIK"
										onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik2')}
										required={true}
										isClearable
									/>
								) : (
									<input
                    className="form-control"
                    id="nik2"
                    type={
                      detailSuratProps.detailSurat.formPenerimaKuasa == "none"
                        ? "hidden"
                        : "number"
                    }
                    placeholder="NIK"
                    defaultValue={detailSuratProps.detailSurat.nik2}
                    value={detailSuratProps.detailSurat.nik2}
                    required
                    onChange={e => detailSuratProps.changeInputText(e, "nik2")}
                  />
								)}
								<small id="emailHelp" className="form-text text-muted">
									Data NIK {detailSuratProps.detailSurat.formPenerimaKuasa == "none" ? "tidak" : ""}{" "}
									tersedia?{" "}
									<Button
										className="btn btn-xs"
										// to={{
										// 	pathname: this.props.myroute,
										// 	state: this.props.location.state
										// }}
										onClick={detailSuratProps.dataPenerimaKuasa}
									>
										{" "}
										Klik disini
									</Button>
								</small>
							</Col>
						</FormGroup>
						<div style={{ display: detailSuratProps.status.showBio2  }}>
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="nik2"
								readOnly
								type="hidden"
								required
								placeholder="NIK"
								defaultValue={detailSuratProps.detailSurat.nik2}
							/>
							{/* </Col>
                </FormGroup> */}
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama2"
										required
										type="text"
										readOnly={
											detailSuratProps.detailSurat.formPenerimaKuasa == "" ? false : true
										}
										onChange={e => detailSuratProps.changeInputText(e, "nama2")}
										placeholder="Nama"
										defaultValue={detailSuratProps.detailSurat.nama2}
										value={detailSuratProps.detailSurat.nama2}
										style={detailSuratProps.detailSurat.formPenerimaKuasa == "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
                    disabled={detailSuratProps.detailSurat.formPenerimaKuasa == "" ? false : true}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tempat Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										required
										id="tempat_lahir2"
										readOnly={
											detailSuratProps.detailSurat.formPenerimaKuasa == "" ? false : true
										}
										onChange={e => detailSuratProps.changeInputText(e, "tempat_lahir2")}
										type="text"
										placeholder="Tempat lahir"
										defaultValue={detailSuratProps.detailSurat.tempat_lahir2}
										value={detailSuratProps.detailSurat.tempat_lahir2}
										style={detailSuratProps.detailSurat.formPenerimaKuasa == "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
                    disabled={detailSuratProps.detailSurat.formPenerimaKuasa == "" ? false : true}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tanggal Lahir
								</Label>
								<Col sm="9">
									{detailSuratProps.detailSurat.formPenerimaKuasa == "none" ? (
										<input
											className="form-control"
											readOnly
											id="tanggal_lahir2"
											readOnly
											type="text"
											placeholder="Tanggal Lahir"
											value={dateFormat(detailSuratProps.detailSurat.tanggal_lahir2, 'dd/mm/yyyy')}
											style={{ backgroundColor:"#c9d1d8" }}
										/>
									) : (
										<Datetime
                      locale="id-ID"
                      input={true}
                      timeFormat={false}
                      closeOnTab={true}
                      closeOnSelect={true}
                      dateFormat="DD/MM/yyyy"
                      // initialValue={dateFormat(this.state.dt.tanggal_lahir2, 'dd/mm/yyyy')}
                      value={detailSuratProps.detailSurat.tanggal_lahir2}
                      onChange={(e) => {
                        detailSuratProps.gantiTgl(e, 'tanggal_lahir_penerima_kuasa');
                      }}
                      inputProps={{
                        required: true,
                        placeholder: 'Tanggal Lahir',
                      }}
                    />
									)}
									
								</Col>
							</FormGroup>
							<div style={{ display: detailSuratProps.detailSurat.formPenerimaKuasa }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9">
										<Select
											classNamePrefix="select"
											onChange={e => {
												detailSuratProps.changeSelectValue2(e, "pekerjaan2");
											}}
											value={detailSuratProps.detailSurat.pekerjaan2}
											name="pekerjaan2"
											options={detailSuratProps.listPekerjaan}
											placeholder="Pilih Pekerjaan"
											isClearable
										/>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="alamat2"
                      // readOnly
                      type="text"
                      required
                      placeholder="Alamat"
                      // defaultValue={this.state.dt.alamat2}
                      onChange={e => detailSuratProps.changeInputText(e, "alamat2")}
                      defaultValue={detailSuratProps.detailSurat.alamat2}
                      value={detailSuratProps.detailSurat.alamat2}
                      readOnly={
                        detailSuratProps.detailSurat.formPenerimaKuasa == "" ? false : true
                      }
                      required
                    />
                  </Col>
                </FormGroup>
              </div>
						</div>{' '}
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Kuasa Untuk
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="kuasa_untuk"
									type="text"
									required
									placeholder="Kuasa Untuk"
									defaultValue={detailSuratProps.detailSurat.kuasa_untuk}
								/>
							</Col>
						</FormGroup>{' '}
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Kuasa Atas
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="kuasa_atas"
									type="text"
									required
									placeholder="Kuasa Atas"
									defaultValue={detailSuratProps.detailSurat.kuasa_atas}
								/>
							</Col>
						</FormGroup>{' '}
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Tujuan Kuasa
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tujuan_kuasa"
									type="text"
									required
									placeholder="Contoh : sebagai pinjaman bank"
									defaultValue={detailSuratProps.detailSurat.tujuan_kuasa}
								/>
							</Col>
						</FormGroup>{' '}
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Detail Data SKGR
							</Label>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Atas nama
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="atas_nama"
									type="text"
									required
									placeholder="Atas Nama"
									defaultValue={detailSuratProps.detailSurat.atas_nama}
								/>
							</Col>
						</FormGroup>{' '}
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								No. Reg
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="no_reg"
									type="text"
									required
									placeholder="No. Reg"
									defaultValue={detailSuratProps.detailSurat.no_reg}
								/>
							</Col>
						</FormGroup>{' '}
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Tanggal
							</Label>
							<Col sm="9">
								{/* <Datepicker
                  id="tanggal_skgr"
                  todayButton={"Today"}
                  dateFormat="dd/MM/yyyy"
                  selected={detailSuratProps.detailSurat.tanggal_skgr}
                  onChange={e => detailSuratProps.gantiTgl(e, "tanggal_skgr")}
                  className="form-control"
                /> */}
								<Datetime
									id="tanggal_skgr"
									locale="id-ID"
									input={true}
									closeOnTab={true}
									closeOnSelect={true}
									timeFormat={false}
									dateFormat="DD/MM/yyyy"
									// initialValue={detailSuratProps.detailSurat.tanggal_skgr}
									value={detailSuratProps.detailSurat.tanggal_skgr}
									onChange={(e) => {
										detailSuratProps.gantiTgl(e, 'tanggal_skgr');
									}}
								/>
							</Col>
						</FormGroup>{' '}
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Lokasi Tanah
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="lokasi_tanah"
									type="text"
									required
									placeholder="Lokasi Tanah"
									defaultValue={detailSuratProps.detailSurat.lokasi_tanah}
								/>
							</Col>
						</FormGroup>{' '}
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Luas Tanah
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="luas_tanah"
									type="text"
									required
									placeholder="Luas Tanah"
									defaultValue={detailSuratProps.detailSurat.luas_tanah}
								/>
							</Col>
						</FormGroup>{' '}
						{/* {usaha}
              <button
                type="button"
                onClick={this.handleAddShareholder}
                className="btn btn-success small"
              >
                Tambah Usaha
              </button> */}
						<hr />
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-warning"
							// disabled={detailSuratProps.status.btnForm}
							onClick={detailSuratProps.tutupForm}
						>
							Tutup
						</button>
						<button
							type="submit"
							className="btn btn-success"
							// disabled={detailSuratProps.status.btnForm}
						>
							Simpan
						</button>
					</div>
				</Form>
			</Modal>
		</div>
	);
}

export default EditSkgr;
