import React, { Component } from 'react';
import HeaderBaru from '../components/common/header/header.baru';
import SidebarAdmin from './common/sidebar/sidebar.admin';
import SidebarSuperAdmin from './common/sidebar/sidebar.superadmin';
import Header from '../components/common/header/header.component';
// import { borderRadius } from "react-select/src/theme";

import { Link, Redirect } from 'react-router-dom';
import { DataToken } from '../function/Format';
import { JENIS_DESA } from '../function/Koneksi';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			layanan: [],
			perizinan: [],
			menuUtama: 'inherit',
			menuWilayah: 'none',
			menuPengaturan: 'none',
			menuPerangkat: 'none',
			menuLaporan: 'none',
			menuAdministrasi: 'none',
			menuSuku: 'none',
			menuDaftarLayanan: 'none',
			menuPerubahanData: 'none',
			akun: DataToken(sessionStorage.getItem('access_token'))
		};
	}

	bukaMenu = (wil) => {
		switch (wil) {
			case 'laporan':
				this.setState({ menuUtama: 'none', menuLaporan: 'inherit' });
				break;
			case 'wilayah':
				this.setState({ menuUtama: 'none', menuWilayah: 'inherit' });
				break;
			case 'perangkat':
				this.setState({ menuUtama: 'none', menuPerangkat: 'inherit' });
				break;
			case 'pengaturan':
				this.setState({ menuUtama: 'none', menuPengaturan: 'inherit' });
				break;

			case 'perubahan_data':
				this.setState({
					menuUtama: 'none',
					menuAdministrasi: 'none',
					menuSuku: 'none',
					menuDaftarLayanan: 'none',
					menuPerubahanData: 'inherit'
				});
				break;
			case 'administrasi':
				this.setState({
					menuUtama: 'none',
					menuPerubahanData: 'none',
					menuSuku: 'none',
					menuDaftarLayanan: 'none',
					menuAdministrasi: 'inherit'
				});
				break;
			case 'suku':
				this.setState({
					menuUtama: 'none',
					menuAdministrasi: 'none',
					menuPerubahanData: 'none',
					menuSuku: 'inherit',
					menuDaftarLayanan: 'none'
				});
				break;
			case 'sub-daftar-layanan':
				this.setState({
					menuUtama: 'none',
					menuAdministrasi: 'none',
					menuPerubahanData: 'none',
					menuSuku: 'none',
					menuDaftarLayanan: 'inherit'
				});
				break;
			case 'utama':
				this.setState({
					menuPengaturan: 'none',
					menuPerangkat: 'none',
					menuWilayah: 'none',
					menuLaporan: 'none',
					menuAdministrasi: 'none',
					menuSuku: 'none',
					menuDaftarLayanan: 'none',
					menuPerubahanData: 'none',
					menuUtama: 'inherit'
				});
				break;
		}
	};

	render() {
		console.log(this.state.akun);
		if (this.state.akun == null) {
			return <Redirect to="/" push={true} />;
		}

		let title = '';
		if (this.props.location.pathname == '/admin/dashboard') {
			title = 'Dashboard';
		} else if (this.props.location.pathname == '/admin/role') {
			title = 'Role';
		} else if (this.props.location.pathname == '/admin/provinsi') {
			title = 'Provinsi';
		} else if (this.props.location.pathname == '/admin/kabkota') {
			title = 'Kabupaten/Kota';
		} else if (this.props.location.pathname == '/admin/kecamatan') {
			title = 'Kecamatan';
		} else if (this.props.location.pathname == '/admin/keldes') {
			title = 'Kelurahan/' + JENIS_DESA;
		} else if (this.props.location.pathname == '/admin/pekerjaan') {
			title = 'Pekerjaan';
		} else if (this.props.location.pathname == '/admin/agama') {
			title = 'Agama';
		} else if (this.props.location.pathname == '/admin/shdk') {
			title = 'shdk';
		} else if (this.props.location.pathname == '/admin/jenjang-pendidikan') {
			title = 'Jenjang Pendidikan';
		} else if (this.props.location.pathname == '/admin/surat-keterangan') {
			title = 'Surat Keterangan Umum';
		} else if (this.props.location.pathname == '/admin/jabatan-perangkat') {
			title = 'Jabatan Perangkat';
		} else if (this.props.location.pathname == '/admin/dusun') {
			title = 'Dusun';
		} else if (this.props.location.pathname == '/admin/rw') {
			title = 'RW';
		} else if (this.props.location.pathname == '/admin/rt') {
			title = 'RT';
		} else if (this.props.location.pathname == '/admin/verifikasi') {
			title = 'Verifikasi Data';
		} else if (this.props.location.pathname == '/admin/persyaratan-surat') {
			title = 'Persyaratan Surat';
		} else if (this.props.location.pathname == '/admin/persyaratan-surat') {
			title = 'Verifikasi Data';
		} else if (this.props.location.pathname == '/admin/pejabat-penandatangan') {
			title = 'Pejabat Penandatangan';
		} else if (this.props.location.pathname == '/admin/daftar-aplikasi') {
			title = 'Daftar Aplikasi';
		} else if (this.props.location.pathname == '/admin/nomor-surat') {
			title = 'Nomor Surat';
		} else if (this.props.location.pathname == '/admin/jenis-surat') {
			title = 'Jenis Surat';
		} else if (this.props.location.pathname == '/admin/surat-pernyataan-belum-menikah') {
			title = 'Surat Belum Menikah';
		} else if (this.props.location.pathname == '/admin/surat-pernyataan-tidak-menikah') {
			title = 'Surat Pernyataan Tidak Menikah';
		} else if (this.props.location.pathname == '/admin/kartu-keluarga') {
			title = 'Kartu Keluarga';
		} else if (this.props.location.pathname == '/admin/verifikasi') {
			title = 'Verifikasi Data';
		} else if (this.props.location.pathname == '/admin/data-potensi') {
			title = 'Data Potensi Masyarakat';
		} else if (this.props.location.pathname == '/admin/perangkat') {
			title = 'Perangkat ' + JENIS_DESA;
		} else if (this.props.location.pathname == '/admin/operator') {
			title = 'Operator';
		} else if (this.props.location.pathname == '/admin/pengguna') {
			title = 'Pengguna';
		} else if (this.props.location.pathname == '/admin/tanda-tangan') {
			title = 'Tanda Tangan';
		} else if (this.props.location.pathname == '/admin/surat-undangan') {
			title = 'Surat undangan';
		} else if (this.props.location.pathname == '/admin/surat-pengantar-skck') {
			title = 'Surat Pengantar SKCK';
		} else if (this.props.location.pathname == '/admin/surat-pernyataan-kehilangan') {
			title = 'Surat Pernyataan Kehilangan';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-belum-memiliki-rumah') {
			title = 'Surat Belum Memiliki rumah';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-jalan') {
			title = 'Surat Keterangan Jalan';
		} else if (this.props.location.pathname == '/admin/surat-izin-keramaian') {
			title = 'Surat Izin Keramaian';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-usaha') {
			title = 'Surat Keterangan Usaha';
		} else if (this.props.location.pathname == '/admin/surat-kuasa-skgr') {
			title = 'Surat Kuasa SKGR';
		} else if (this.props.location.pathname == '/admin/surat-kuasa') {
			title = 'Surat Kuasa';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-penghasilan') {
			title = 'Surat Keterangan Penghasilan';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-kematian') {
			title = 'Surat Keterangan Kematian';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-pernah-menikah') {
			title = 'Surat Keterangan Pernah Menikah';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-kelahiran') {
			title = 'Surat Keterangan Kelahiran';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-ahli-waris') {
			title = 'Surat Keterangan Ahli Waris';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-domisili') {
			title = 'Surat Keterangan Domisili';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-domisili-usaha') {
			title = 'Surat Keterangan Domisili Usaha';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-tidak-mampu') {
			title = 'Surat Keterangan Tidak Mampu';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-kepemilikan-rumah') {
			title = 'Surat Keterangan Kepemilikan Rumah';
		} else if (this.props.location.pathname == '/admin/surat-keterangan-tidak-mampu') {
			title = 'Surat Keterangan Tidak Mampu';
		} else if (this.props.location.pathname == '/admin/profile-' + JENIS_DESA) {
			title = 'Profile ' + JENIS_DESA;
		} else if (this.props.location.pathname == '/admin/laporan') {
			title = 'Data laporan';
		} else if (this.props.location.pathname == '/admin/catatan') {
			title = 'Catatan Penduduk';
		} else if (this.props.location.pathname == '/admin/banner') {
			title = 'Banner';
		} else if (this.props.location.pathname == '/admin/suku') {
			title = 'Data Suku';
		} else if (this.props.location.pathname == '/admin/datuak') {
			title = 'Data Datuak';
		} else if (this.props.location.pathname == '/admin/penduduksuku') {
			title = 'Data Penduduk Suku';
		} else if (this.props.location.pathname == '/admin/layanan/kua/kua') {
			title = 'Data Pelayanan KUA';
		} else if (this.props.location.pathname == '/admin/layanan/kua/pernikahan') {
			title = 'Data Pelayanan Pernikahan KUA';
		} else if (this.props.location.pathname == '/admin/layanan/capil/disdukcapil') {
			title = 'Data Pelayanan Disdukcapil';
		} else if (this.props.location.pathname == '/admin/layanan/capil/kelahiran') {
			title = 'Data Pelayanan Kelahiran Disdukcapil';
		} else if (this.props.location.pathname == '/admin/layanan/capil/kematian') {
			title = 'Data Pelayanan Kematian Disdukcapil';
		} else if (this.props.location.pathname == '/admin/layanan/capil/pindah-datang') {
			title = 'Data Pelayanan Pindah Datang Disdukcapil';
		} else if (this.props.location.pathname == '/admin/layanan/capil/kartu-keluarga') {
			title = 'Data Pelayanan Kartu Keluarga Disdukcapil';
		} else if (this.props.location.pathname == '/admin/penduduk') {
			title = 'Data Penduduk';
		} else if (this.props.location.pathname.indexOf('/admin/alur-disposisi') !== -1) {
			title = 'Alur Disposisi';
		} else if (this.props.location.pathname == '/admin/disposisi') {
			title = 'Disposisi Surat';
		} else if (this.props.location.pathname == '/admin/berita') {
			title = 'Info dan Berita';
		} else if (this.props.location.pathname == '/admin/perubahan-data/nik-kk') {
			title = 'Perubahan Data Nik & KK';
		} else if (this.props.location.pathname == '/admin/perubahan-data/pindah-kk') {
			title = 'Pindah KK';
		} else if (this.props.location.pathname == '/admin/perubahan-data/pengajuan') {
			title = 'Pengajuan Perubahan Data';
		} else if (this.props.location.pathname == '/admin/pengaturan-aplikasi') {
			title = 'Pengaturan Aplikasi';
		} else if (this.props.location.pathname == '/admin/data-masyarakat') {
			title = 'Data Potensi Masyarakat';
		} else if (this.props.location.pathname == '/admin/notifikasi') {
			title = 'Notifikasi';
		} else if (this.props.location.pathname == '/admin/wisata') {
			title = 'Wisata';
		} else if (this.props.location.pathname == '/admin/laporan-potensi') {
			title = 'Laporan Potensi Masyarakat';
		} else if (this.props.location.pathname == '/admin/data-master-potensi') {
			title = 'Data Master Potensi Desa';
		}
		console.log(title);
		return (
			<div>
				<div className="page-wrapper box-layout" style={{ overflow: 'auto' }}>
					<HeaderBaru />
					<div className="page-body-wrapper">
						{this.state.akun.sub.data_role.nama_role == 'Admin' ? <SidebarAdmin /> : <SidebarSuperAdmin />}
						<div className="bayu-body">
							<div className="container-fluid">
								{title != 'Dashboard' ? (
									<div className="page-header">
										<div className="row">
											<div className="col-lg-6">
												<h3>
													{title}
													<small>
														{JENIS_DESA.toLowerCase() == 'nagari' ? (
															'Aplikasi Manajemen Pelayanan Masyarakat Nagari'
														) : (
															'Sistem Informasi Layanan ' + JENIS_DESA
														)}
													</small>
												</h3>
											</div>
											<div className="col-lg-6">
												<ol className="breadcrumb pull-right">
													<li className="breadcrumb-item">
														<Link to="/admin/dashboard">
															<i className="fa fa-home" />
														</Link>
													</li>
													<li className="breadcrumb-item">Dashboard</li>
													{title != 'Dashboard' ? (
														<li className="breadcrumb-item active">{title}</li>
													) : (
														<div />
													)}
												</ol>
											</div>
										</div>
									</div>
								) : (
									<div />
								)}
							</div>
							{this.props.children}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default App;
