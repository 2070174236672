import React, { Component, createContext } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Slider from 'react-slick';
import { FormControl } from 'react-bootstrap';

import SuratIzinKeramainan from './SuratIzinKeramainan';
import SuratKeteranganUsaha from './SuratKeteranganUsaha';
import SuratKeteranganDomisili from './SuratKeteranganDomisili';
import SuratJalan from './SuratJalan';
import SuratKeteranganAhliWaris from './SuratKeteranganAhliWaris';
import SuratKelahiran from './SuratKelahiran';
import SuratBelumMemilikiRumah from './SuratBelumMemilikiRumah';
import SuratKematian from './SuratKematian';
import SuratKepemilikanRumah from './SuratKepemilikanRumah';
import SuratPernahMenikah from './SuratPernahMenikah';
import SuratPenghasilan from './SuratPenghasilan';
import SuratTidakMampu from './SuratTidakMampu';
import SuratKuasaSKGR from './SuratKuasaSKGR';
import SuratPindahNikah from './SuratPindahNikah';
import SuratSKCK from './SuratSKCK';
import SuratBelumMenikah from './SuratBelumMenikah';
import SuratKehilangan from './SuratKehilangan';
import SuratTidakMenikah from './SuratTidakMenikah';

import API_URL, { Get, JENIS_DESA, KECAMATAN, Nama_DESA, NAMA_KABUPATEN, Post, PROVINSI } from '../function/Koneksi';
import Modal from 'react-responsive-modal';
// import SuratPenghasilan from './SuratPenghasilan';
// import SuratTidakMampu from './SuratTidakMampu';
// import SuratKuasaSKGR from './SuratKuasaSKGR';
// import SuratSKCK from './SuratSKCK';
import '../assets/css/site.css';

import SweetAlert from 'react-bootstrap-sweetalert/lib/dist/SweetAlert';
// import SuratKehilangan from './SuratKehilangan';
// import SuratPernahMenikah from './SuratPernahMenikah';
// import SuratPindahNikah from './SuratPindahNikah';
import { Redirect } from 'react-router-dom';
import Iframe from 'react-iframe';

export default class Layanan extends Component {
	constructor(props) {
		super(props);
		this.nextSlide = this.nextSlide.bind(this);
		this.prevSlide = this.prevSlide.bind(this);
		this.state = {
			navigate: false,
			status: {
				frmNomorAntrian: false,
				modalKonfirmasi: false,
				modalKonfirmasi2: false,
				messageAntrian: '',
				messageAntrian2: true,
				jenisSurat: '',
				statusSurat: '',
				frmPrintKonfirmasi: false,
				frmPrint: false,
			},
			layanan: [],
			perizinan: [],
			show: false,
			basicType: 'info',
			basicTitle: '',
			pesanAlert: '',
			nomorAntrian: 0,
			
			// Loading
			loading: true,

			
			get_data_surat:[],
			get_jenis_surat: "",
		};
	}

	logOut(e) {
		e.preventDefault();
		// sessionStorage.removeItem('access_token');
		this.setState({ navigate: true });
	}

	componentDidMount() {
		if (navigator.onLine) {
			this.getData();
			console.log(this.props.location.state.results);
		} else {
			this.setState({
				show: true,
				basicType: 'info',
				basicTitle: 'Login',
				pesanAlert: 'Jaringan internet tidak aktif...'
			});
		}
	}

	getData = (fil = null) => {
		// console.log(fil);
		if (fil) {
			let filJenisSurat = {
				nama: fil
			};
			Post('jenis-surat/list', null, filJenisSurat, (data) => {
				console.log(data);
				let layanan = [];
				let perizinan = [];
				data.data.results.map((dt) => {
					if (dt.kategori == 'Layanan Masyarakat') {
						layanan.push(dt);
					}
					if (dt.kategori == 'Perizinan Masyarakat') {
						perizinan.push(dt);
					}
				});
				this.setState({ layanan, perizinan, loading: false, });
			});
		} else {
			Get('jenis-surat/list', null, (data) => {
				let layanan = [];
				let perizinan = [];
				console.log(data);
				if (typeof data != 'undefined') {
					data.results.map((dt) => {
						if (dt.kategori == 'Layanan Masyarakat') {
							layanan.push(dt);
						}
						if (dt.kategori == 'Perizinan Masyarakat') {
							perizinan.push(dt);
						}
					});
					this.setState({ layanan, perizinan, loading: false, });
				}

				// console.log(this.state.layanan);
			});
		}
	};

	isComplete = (stat, noted = null) => {
		if (stat == false) {
			if (noted != null) {
				this.setState({
					show: true,
					basicType: 'info',
					basicTitle: 'Data Surat',
					pesanAlert:
						'Pemohon memiliki catatan yang belum selesai, hubungi petugas kantor ' + JENIS_DESA.toLowerCase() + ' untuk info lebih lanjut'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'info',
					basicTitle: 'Data Surat',
					pesanAlert: 'Data Surat Belum Lengkap. Silahkan lengkapi seluruh data yang dibutuhkan pada kolom yang tersedia'
				});
			}
		}
	};

	getNomorAntrian = (jenis_surat, status, uncomplete = false, data) => {
		if (status == 'berhasil') {
			console.log("Mari Kita Coba Print")
			console.log("Data Get Nomor Buskuqoweqwe")
			console.log(data)

			let set_jenis = '';
			switch (jenis_surat) {
				case 'Surat Keterangan Belum Memiliki Rumah':
					set_jenis = 'belum-memiliki-rumah';
					break;
				case 'Surat Pernyataan Belum Menikah':
					set_jenis = 'belum-menikah';
					break;
				case 'Surat Izin Keramaian':
					set_jenis = 'keramaian';
					break;
				case 'Surat Keterangan Jalan':
					set_jenis = 'surat-jalan';
					break;
				case 'Surat Pernyataan Kehilangan':
					set_jenis = 'kehilangan';
					break;
				case 'Surat Keterangan Kelahiran':
					set_jenis = 'kelahiran';
					break;
				case 'Surat Keterangan Kematian':
					set_jenis = 'kematian';
					break;
				case 'Surat Keterangan Kepemilikan Rumah':
					set_jenis = 'kepemilikan-rumah';
					break;
				case 'Surat Keterangan Ahli Waris':
					set_jenis = 'ahli-waris';
					break;
				case 'Surat Keterangan Domisili':
					set_jenis = 'domisili';
					break;
				case 'Surat Keterangan Usaha':
					set_jenis = 'usaha';
					break;
				case 'Surat Kuasa SKGR':
					set_jenis = 'skgr';
					break;
				case 'Surat Keterangan Penghasilan':
					set_jenis = 'penghasilan';
					break;
				case 'Surat Keterangan Pernah Menikah':
					set_jenis = 'pernah-menikah';
					break;
				case 'Surat Pengantar Pindah Nikah':
					set_jenis = 'pindah-nikah';
					break;
				case 'Surat Pengantar SKCK':
					set_jenis = 'skck';
					break;
				case 'Surat Keterangan Tidak Mampu':
					set_jenis = 'tidak-mampu';
					break;
				case 'Surat Keterangan Tidak Menikah':
					set_jenis = 'tidak-menikah';
					break;
				default:
					set_jenis = '';
					break;
				// ==============================
				// case 'Surat Keterangan Tidak Mampu':
				// 	set_jenis = 'tidak-mampu';
				// 	break;
				// case 'Surat Keterangan Domisili':
				// 	set_jenis = 'domisili';
				// 	break;
				// case 'Surat Keterangan Ahli Waris':
				// 	set_jenis = 'ahli-waris';
				// 	break;
				// case 'Surat Keterangan Kelahiran':
				// 	set_jenis = 'kelahiran';
				// 	break;
				// case 'Surat Keterangan Kematian':
				// 	set_jenis = 'kematian';
				// 	break;
				// case 'Surat Keterangan Penghasilan':
				// 	set_jenis = 'penghasilan';
				// 	break;
				// case 'Surat Keterangan Pernah Menikah':
				// 	set_jenis = 'pernah-menikah';
				// 	break;
				// case 'Surat Kuasa':
				// 	set_jenis = 'kuasa';
				// 	break;
				// case 'Surat Kuasa SKGR':
				// 	set_jenis = 'kuasa';
				// 	break;
				// case 'Surat Undangan':
				// 	kop_surat = 'surat-undangan';
				// 	set_jenis = '';
				// 	break;

				
			}

			this.state.status.jenisSurat = jenis_surat+' Berhasil Dibuat';
			this.state.status.messageAntrian =
				'Cetak Surat Anda Sekarang?';
			this.state.status.frmPrintKonfirmasi = true;
			this.state.status.statusSurat = status;
			this.state.get_data_surat = data;
			this.state.get_jenis_surat = set_jenis;
			this.forceUpdate();
			// Post('antrian/create', null, data, (res) => {
			// 	// console.log(data);
			// 	console.log(res);
			// 	if (res.status === 201) {
			// 		this.state.nomorAntrian = res.data.results.nomor;
			// 		this.state.status.jenisSurat = jenis_surat;
			// 		this.state.status.messageAntrian =
			// 			'Silahkan menunggu nomor antrian anda dipanggil untuk mengambil surat. Terima kasih';
			// 		this.state.status.frmNomorAntrian = true;
			// 		this.state.status.statusSurat = status;
			// 		this.forceUpdate();
			// 	} else {
			// 		this.state.show = true;
			// 		this.state.basicType = 'info';
			// 		this.state.basicTitle = 'Data Surat';
			// 		this.state.pesanAlert =
			// 			'Mohon maaf, surat anda tidak berhasil diajukan. Mohon ajukan surat lagi atau hubungi pegawai di kantor ' + JENIS_DESA.toLowerCase() + '.';
			// 		this.state.status.statusSurat = status;
			// 		this.forceUpdate();
			// 	}
			// });
		} else if (status == 'cari nik') {
			this.state.show = true;
			this.state.basicType = 'info';
			this.state.basicTitle = 'Data Tidak Ditemukan';
			this.state.pesanAlert = 'Silahkan Periksa Kembali NIK';
		} else if (status == 'cek digit nik') {
			this.state.show = true;
			this.state.basicType = 'warning';
			this.state.basicTitle = 'NIK yang anda masukkan Salah';
			this.state.pesanAlert = 'Jumlah angka NIK kurang 16 angka. Silahkan cek NIK kembali';
		} else if (status == 'cekDataPewaris') {
			this.state.show = true;
			this.state.basicType = 'info';
			this.state.basicTitle = 'Data Ahli Waris';
			this.state.pesanAlert = 'Silahkan pilih NIK dan hubungan keluarga sebelum menambahkan data pewaris';
		} else if (status == 'cekDataPengikut') {
			this.state.show = true;
			this.state.basicType = 'info';
			this.state.basicTitle = 'Data ' + jenis_surat;
			this.state.pesanAlert = 'Silahkan pilih NIK dan hubungan keluarga sebelum menambahkan data pengikut';
		} else if (status == 'cekDataShdk') {
			this.state.show = true;
			this.state.basicType = 'info';
			this.state.basicTitle = 'Data Status Hubungan Dalam Keluarga';
			this.state.pesanAlert = 'Silahkan pilih status hubungan dalam keluarga sebelum menambahkan data';
		} else {
			if (uncomplete) {
				this.state.show = true;
				this.state.basicType = 'info';
				this.state.basicTitle = 'Data Surat';
				this.state.pesanAlert = 'Data Belum Lengkap. Silahkan Lengkapi Seluruh Data yang diperlukan';
			} else {
				this.state.show = true;
				this.state.basicType = 'info';
				this.state.basicTitle = 'Data Surat';
				this.state.pesanAlert =
					'Mohon maaf, surat anda tidak berhasil diajukan. Mohon ajukan surat lagi atau hubungi pegawai di kantor ' + JENIS_DESA.toLowerCase() + '.';
			}
		}

		this.state.status.statusSurat = status;
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.status.frmNomorAntrian = false;
		this.state.status.frmPrintKonfirmasi = false;
		// this.state.status.modalKonfirmasi = true;
		// this.state.status.messageAntrian2 = true;
		this.forceUpdate();
	};

	cetakAntrian = () => {
		// let halaman_antrian = document.getElementById('halaman_print_antrian');
		// let antrian = document.getElementById('kertas_antrian').contentWindow;

		// antrian.document.open();
		// antrian.document.write(halaman_antrian.innerHTML);
		// antrian.document.close();
		// antrian.focus();
		// antrian.print();
		// window.print();
		// const printContents = document.getElementById('kertas_antrian').innerHTML;
		// const originalContents = document.body.innerHTML;

		// document.body.innerHTML = printContents;

		window.print();

		// document.body.innerHTML = originalContents;
	};

	nextSlide() {
		this.slider.slickNext();
	}

	prevSlide() {
		this.slider.slickPrev();
	}

	render() {
		console.log("ID SURAT IFREAMENYA")
		console.log(this.state.get_data_surat.uuid_surat)
		if (this.state.navigate || typeof this.props.location.state == 'undefined') {
			return <Redirect to="/" push={true} />;
		}

		// console.log('Data yang diparsing');
		// console.log(this.props.location.state);

		var Merge_settings = {
			className: 'center',
			// centerMode: true,
			dots: true,
			infinite: true,
			swipe: false,
			// speed: 100,
			slidesToShow: 4,
			slidesToScroll: 4,
			rows: 2,
			margin: 2,
			// initialSlide: 0,
			//   slidesPerRow: 2,
			// centerMode: true,
			// variableWidth: true,
			adaptiveHeight: true,

			responsive: [
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 3,
						dots: true,
						infinite: true
						// speed: 100
					}
				},
				{
					breakpoint: 980,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2,
						initialSlide: 2

						// dots: true,
						// infinite: true,
						// speed: 100
					}
				},
				{
					breakpoint: 680,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		};

		const background = require('../assets/images/auth-layer.png');

		const dataPenduduk = this.props.location.state;
		// console.log(dataPenduduk);

		const tampilLayanan = this.state.layanan.map((dt) => {
			let link = dt.nama.toLowerCase().replace(/ /g, '-');
			let nama = dt.nama.toLowerCase();
			// console.log(nama);

			switch (nama) {
				case 'surat izin keramaian':
					return (
						<SuratIzinKeramainan
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat pengantar pindah nikah':
					return (
						<SuratPindahNikah
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan domisili':
					return (
						<SuratKeteranganDomisili
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan usaha':
					return (
						<SuratKeteranganUsaha
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan jalan':
					return (
						<SuratJalan
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan ahli waris':
					return (
						<SuratKeteranganAhliWaris
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan kelahiran':
					return (
						<SuratKelahiran
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan belum memiliki rumah':
					return (
						<SuratBelumMemilikiRumah
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan kematian':
					return (
						<SuratKematian
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan penghasilan':
					return (
						<SuratPenghasilan
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan tidak mampu':
					return (
						<SuratTidakMampu
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat kuasa skgr':
					return (
						<SuratKuasaSKGR
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat pengantar skck':
					return (
						<SuratSKCK
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat pernyataan kehilangan':
					return (
						<SuratKehilangan
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan pernah menikah':
					return (
						<SuratPernahMenikah
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat, noted) => this.isComplete(stat, noted)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan kepemilikan rumah':
					return (
						<SuratKepemilikanRumah
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan penghasilan':
					return (
						<SuratPenghasilan
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat keterangan tidak mampu':
					return (
						<SuratTidakMampu
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat kuasa skgr':
					return (
						<SuratKuasaSKGR
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat pengantar pindah nikah':
					return (
						<SuratPindahNikah
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat pernyataan belum menikah':
					return (
						<SuratBelumMenikah
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				case 'surat pernyataan tidak menikah':
					return (
						<SuratTidakMenikah
							data={dataPenduduk}
							id_jenis={dt.uuid}
							isComplete={(stat) => this.isComplete(stat)}
							getAntrian={(jenis_surat, status, uncomplete, data) =>
								this.getNomorAntrian(jenis_surat, status, uncomplete, data)}
						/>
					);
					break;

				default:
					break;
			}
		});

		let logoAplikasi = (
			<img
				src={require('../assets/images/digdes.png')}
				alt="logo"
				style={{
					height: '2.7em'
					// width: '45px',
					// marginTop: '-10px'
				}}
			/>
    )

    switch (localStorage.getItem('JENIS_APLIKASI').toLowerCase()) {
      case 'halaman kampung':
        logoAplikasi = (
					<img
						src={require('../assets/images/hk_icon.png')}
						alt="logo"
						style={{
							height: '2.7em'
							// width: '45px',
							// marginTop: '-10px'
						}}
					/>
        )
        break;
    }

		return (
			<>
				<div
					id="bg-kiosk"
					style={{
						// background: 'linear-gradient(135deg, #07057f, #09759e)',
						// background: 'linear-gradient(135deg, #59dc23, #0b9e09)',
						// background: 'linear-gradient(135deg, #a75c1d, #262aad)',
						// background: 'linear-gradient(135deg, #a5d434, #67e00e)',
						// background: 'linear-gradient(135deg, #46ecc3, #017cff)',
						// background: 'linear-gradient(135deg, #3F51B5, #017cff)',
						// background: 'linear-gradient(135deg, #607D8B, #28a745)',
						// background: 'linear-gradient(135deg, #aff1eb, #17a2b8)',
						// height: '100vh',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-evenly'
					}}
				>
					<div
						className="row"
						style={{
							// padding: '20px 20px 0px 20px',
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							justifyContent: 'center',
							position: 'relative',
							zIndex: 1
						}}
					>
						<div
							// className="card-body"
							style={{
								// backgroundColor: '#ffffffc7',
								// background: 'linear-gradient(45deg, #e4e4e4, #a8b6c3)',
								width: 'max-content',
								// width: '33%',
								height: 'max-content',
								borderRadius: '45px',
								padding: '1em 2em',
								boxShadow: '0 4px 8px 0 #0000003d, 0 6px 20px 0 #0000003d',
								zIndex: 2
							}}
						>
							<div
								className="media"
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center'
								}}
							>
								{logoAplikasi}

								<div
									className="media-body align-self-left"
									style={{
										padding: '0px 10px',
										fontWeight: '600'
									}}
								>
									<span
										style={{
											fontSize: '1.7em',
											// color: '#464646',
											color: '#ffffff',
											fontWeight: '600'
										}}
									>
										Kios Pelayanan Masyarakat
									</span>
								</div>
							</div>
						</div>
						<div
							id="logout-kiosk"
							className="logout-kiosk"
							style={{
								// width: '33%',
								position: 'absolute',
								top: 15,
								right: 40,
								width: 'max-content',
								height: 'max-content',
								borderRadius: '45px',
								padding: '0.5em 1em',
								// backgroundColor: '#ffffff',
								backgroundColor: '#ff0000',
								boxShadow: '0 4px 8px 0 #0000003d, 0 6px 20px 0 #0000003d',
								// boxShadow: '0 0 30px 0 #ffffffd1 , 0 0 20px 0 #ffffffd1 ',
								cursor: 'pointer',
								zIndex: 100
							}}
							onClick={() => {
								this.state.status.modalKonfirmasi2 = true;
								this.forceUpdate();
							}}
						>
							<h5
								style={{
									fontSize: '1.2em',
									color: '#ffffff',
									fontWeight: '500',
									marginBottom: '0px'
								}}
							>
								Keluar
							</h5>
						</div>
					</div>


				{this.state.loading ? (
					<div className="row">
						<div className="col-sm-12" align="center">
							<img 
							src={require("../assets/images/loading-bos.gif")} 
							style={{
								width: "100%",
								borderRadius: "10px",
								width: '70px'
							}}/> 
						</div>
					</div>
				) : 
				<>
					<div style={{ padding: '0px 15px' }}>
						<Slider ref={(c) => (this.slider = c)} {...Merge_settings}>
							{tampilLayanan}
						</Slider>
						<div style={{ textAlign: 'center', marginTop: '10px' }}>
							<button className="btn btn-info" style={{ margin: '0px 5px' }} onClick={this.prevSlide}>
								Sebelumnya
							</button>
							<button className="btn btn-info" style={{ margin: '0px 5px' }} onClick={this.nextSlide}>
								Selanjutnya
							</button>
						</div>
					</div>
				</>
				}

					<div
						className="row"
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<h5 style={{ fontSize: '1em', color: '#ffffff', textShadow: '1px 0px 5px #000000' }}>
							Kantor {Nama_DESA}, {KECAMATAN}, {NAMA_KABUPATEN}, {PROVINSI}
						</h5>
						<h5 style={{ fontSize: '1em', color: '#ffffff', textShadow: '1px 0px 5px #000000' }}>
							© PT. Solusi Data Madani, Pekanbaru, Riau.
						</h5>
					</div>
				</div>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					cancelBtnBsStyle="info"
					onCancel={() => {
						this.setState({
							show: false
						});
					}}
					showConfirm={false}
				>
					{this.state.pesanAlert}
				</SweetAlert>
				
				<Modal
					open={this.state.status.frmNomorAntrian}
					// open={true}
					// open={true}
					// onClose={false}
					styles={{ modal: { width: '400px' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						id="kertas_antrian"
						className="modal-body"
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<div className="row" style={{ textAlign: 'center' }}>
							<h4>{this.state.status.jenisSurat}</h4>
						</div>
						<div
							style={{
								// width: 'fit-content',
								padding: '10px 25px',
								margin: '10px 30px',
								borderRadius: '35px',
								border: '5px solid'
								// display: this.state.status.messageAntrian2 ? '' : 'none'
							}}
						>
							<div className="row" style={{ textAlign: 'center' }}>
								<div className="col-sm-12">
									<h5 style={{ fontSize: '25px' }}>No. Antrian Anda</h5>
								</div>
							</div>
							<div className="row" style={{ textAlign: 'center' }}>
								<div className="col-sm-12">
									<h1 style={{ fontSize: '60px' }}>{this.state.nomorAntrian}</h1>
								</div>
							</div>
						</div>
						<div className="row" style={{ textAlign: 'center' }}>
							<h5 style={{ fontSize: '22px' }}>
								Silahkan menunggu nomor antrian anda dipanggil untuk mengambil surat. Terima kasih
							</h5>
							{/* <h5 style={{ fontSize: '22px' }}>{this.state.status.messageAntrian}</h5> */}
						</div>
					</div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<button
							onClick={() => {
								// this.tutupForm();
								this.cetakAntrian();
								this.state.status.frmNomorAntrian = false;
								this.state.status.modalKonfirmasi = true;
								this.forceUpdate();
							}}
							className="btn btn-primary"
						>
							Cetak Nomor Antrian
						</button>
						<button
							onClick={() => {
								this.state.status.frmNomorAntrian = false;
								this.state.status.modalKonfirmasi = true;
								this.forceUpdate();
							}}
							className="btn btn-success"
						>
							Tutup
						</button>
					</div>
				</Modal>

				<Modal
					open={this.state.status.frmPrintKonfirmasi}
					// open={true}
					// open={true}
					// onClose={false}
					styles={{ modal: { width: '800px' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						id="kertas_antrian"
						className="modal-body"
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<div className="row" style={{ textAlign: 'center' }}>
							<h4>{this.state.status.jenisSurat}</h4>
						</div>
						
						<div className="row" style={{ textAlign: 'center' }}>
							<h5 style={{ fontSize: '22px' }}>
								Apakah Anda Ingin Mencetak Surat Yang Telah Anda Buat?
							</h5>
						</div>
					</div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>

						<button
							onClick={() => {
								this.state.status.frmPrintKonfirmasi = false;
								this.state.status.modalKonfirmasi = true;
								this.forceUpdate();
							}}
							className="btn btn-success"
						>
							Tutup
						</button>

						<button
							onClick={() => {
								// this.tutupForm();
								// this.cetakAntrian();
								this.state.status.frmPrintKonfirmasi = false;
								// this.state.status.modalKonfirmasi = true;
								this.state.status.frmPrint = true;
								// this.cetakSurat()
								this.forceUpdate();
							}}
							className="btn btn-primary"
						>
							Cetak Sekarang
						</button>
						
					</div>
				</Modal>

				<Modal
					className="custom-map-modal"
					open={this.state.status.frmPrint}
					// open={true}
					// open={true}
					onClose={false}
					styles={{ modal: { width: '100%', maxWidth: 'none'} }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						className="modal-body"
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<span><h4><b>Pilih gambar <i class="fa fa-print"></i> untuk mencetak surat yang telah anda buat</b></h4></span><br/>
						{/* <div className="row" style={{ textAlign: 'center' }}> */}
							{/* <h5>Silahkan menunggu nomor antrian anda dipanggil untuk mengambil surat. Terima kasih</h5> */}
							{/* <h3>Apakah anda ingin membuat surat lagi ?</h3> */}

							<Iframe 
								id="myFrame" 
								// src={"http://localhost:8000/api/v1/cetak-surat/skck/0bc1ea83-e7f7-4131-a369-9d8c9d03d90d?status_barcode=tidak"}
								src={
									API_URL +
									'cetak-surat/' +
									this.state.get_jenis_surat +
									'/' +
									this.state.get_data_surat.uuid_surat +
									'?show=Ya'
								}
								width="100%"
								height="450px"
								className="myClassname"
								display="initial"
								position="relative"
							/>
						{/* </div> */}
					</div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<button
							onClick={() => {
								this.state.status.modalKonfirmasi = true;
								this.state.status.frmPrint = false;
								this.forceUpdate();
							}}
							className="btn btn-success"
						>
							Tutup
						</button>
						{/* <button
							onClick={() => {
								this.state.status.modalKonfirmasi = false;
								this.forceUpdate();
							}}
							className="btn btn-primary"
						>
							Buat surat lagi
						</button>
						<button onClick={this.logOut.bind(this)} className="btn btn-success">
							Tidak
						</button> */}
					</div>
				</Modal>

				<Modal
					open={this.state.status.modalKonfirmasi}
					// open={true}
					// open={true}
					onClose={false}
					styles={{ modal: { width: '400px' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						className="modal-body"
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<div className="row" style={{ textAlign: 'center' }}>
							{/* <h5>Silahkan menunggu nomor antrian anda dipanggil untuk mengambil surat. Terima kasih</h5> */}
							<h3>Apakah anda ingin membuat surat lagi ?</h3>
						</div>
					</div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<button
							onClick={() => {
								this.state.status.modalKonfirmasi = false;
								this.forceUpdate();
							}}
							className="btn btn-primary"
						>
							Buat surat lagi
						</button>
						<button onClick={this.logOut.bind(this)} className="btn btn-success">
							Tidak
						</button>
					</div>
				</Modal>




				<Modal
					open={this.state.status.modalKonfirmasi2}
					// open={true}
					// open={true}
					onClose={false}
					styles={{ modal: { width: '400px' } }}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<div
						className="modal-body"
						style={{
							display: 'flex',
							justifyContent: 'center',
							flexDirection: 'column',
							alignItems: 'center'
						}}
					>
						<div className="row" style={{ textAlign: 'center' }}>
							<h3>Apakah anda yakin ingin keluar ?</h3>
						</div>
					</div>
					<div
						className="modal-footer"
						style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
					>
						<button onClick={this.logOut.bind(this)} className="btn btn-success">
							Ya
						</button>
						<button
							onClick={() => {
								this.state.status.modalKonfirmasi2 = false;
								this.forceUpdate();
							}}
							className="btn btn-primary"
						>
							Tidak
						</button>
					</div>
				</Modal>
			</>
		);
	}
}