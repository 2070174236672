import React, { useEffect, useState, useContext } from 'react';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Input,
	FormText,
	ButtonGroup,
	CustomInput,
	Row,
	Col,
	Nav,
	NavItem,
	NavLink,
	TabContent,
	TabPane
} from 'reactstrap';
import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { Link } from 'react-router-dom';

function EditKelahiran({ detail_surat_context }) {
	const detailSuratProps = useContext(detail_surat_context);

	const dateFormat = require('dateformat');

	// console.log(detailSuratProps.detailSurat);

	return (
		<div>
			<Modal
				open={detailSuratProps.status.formUpdateSurat}
				onClose={detailSuratProps.tutupForm}
				closeOnEsc={false}
				closeOnOverlayClick={false}
			>
				<Form
					className="theme-form"
					onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}
				>
					<div className="modal-header">
						<h5 className="modal-title">Detail {detailSuratProps.detailSurat.jenis_surat}</h5>
					</div>
					<div className="modal-body">
						<p>
							Data identitas akan terisi secara otomatis saat NIK dipilih. Jika ada ketidak sesuaian data,
							silakan ubah di data master penduduk.
						</p>
						<input
							className="form-control"
							id="uuid"
							type="hidden"
							placeholder=""
							defaultValue={detailSuratProps.detailSurat.uuid}
						/>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Tanggal Lahir Anak
							</Label>
							<Col sm="9">
								{/* <Datepicker
                  className="form-control"
                  selected={detailSuratProps.detailSurat.tanggal_lahir_anak}
                  onChange={(e) => detailSuratProps.gantiTgl(e, 'tanggal_lahir_anak')}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="tanggal"
                  dateFormat="dd/MM/yyyy HH:mm "
                /> */}
								<Datetime
									locale="id-ID"
									timeFormat="HH:mm"
									dateFormat="DD/MM/yyyy"
									input={true}
									closeOnTab={true}
									initialValue={detailSuratProps.detailSurat.tanggal_lahir_anak}
									value={detailSuratProps.detailSurat.tanggal_lahir_anak}
									onChange={(e) => {
										detailSuratProps.gantiTgl(e, 'tanggal_lahir_anak');
										// this.setState({ tanggal_lahirkk:})
										// console.log(e.format('YYYY-MM-DD'))
									}}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Tempat Lahir Anak
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="tempat_lahir_anak"
									type="text"
									placeholder="Tempat Lahir Anak"
									defaultValue={detailSuratProps.detailSurat.tempat_lahir_anak}
									required
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Nama Anak
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="nama_anak"
									type="text"
									placeholder="Nama Anak"
									defaultValue={detailSuratProps.detailSurat.nama_anak}
									required
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Jenis Kelamin Anak
							</Label>
							<Col sm="9">
								<select
									className="form-control"
									id="jk_anak"
									placeholder="jk_anak"
									value={detailSuratProps.detailSurat.jk_anak}
									onChange={(e) => {
										detailSuratProps.changeSelectValue2(e, "jk_anak")}
									}
								>
									<option value="" disabled selected>Pilih Jenis Kelamin</option>
									<option value="Laki-laki">Laki-Laki</option>
									<option value="Perempuan">Perempuan</option>
								</select>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								NIK Ayah
							</Label>
							<Col sm="9">
								{detailSuratProps.detailSurat.formAyah == "none" ? (
									<Select
										classNamePrefix="select"
										onChange={(e) => {
											detailSuratProps.getPenduduk(
												e,
												detailSuratProps.detailSurat.jenis_surat,
												'pemohon'
											);
										}}
										defaultValue={detailSuratProps.dataNikStat.nikStat}
										value={detailSuratProps.dataNikStat.nikStat}
										name="NIK"
										options={detailSuratProps.dataNik.frmNik}
										placeholder="Pilih NIK"
										onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik')}
										required={true}
										isClearable
									/>
								) : (
									<input
                    className="form-control"
                    id="nik_ayah"
                    type={detailSuratProps.detailSurat.formAyah == "none" ? "hidden" : "number"}
                    placeholder="Nik ayah"
                    defaultValue={detailSuratProps.detailSurat.nik_ayah}
                    value={detailSuratProps.detailSurat.nik_ayah}
                    required
                    onChange={e => detailSuratProps.changeInputText(e, "nik_ayah")}
                  />
								)}
								<small id="emailHelp" className="form-text text-muted">
									Data NIK ayah {detailSuratProps.detailSurat.formAyah == "none" ? "tidak" : ""}{" "}
									tersedia?{" "}
									<Button
										className="btn btn-xs"
										// to={{
										// 	pathname: this.props.myroute,
										// 	state: this.props.location.state
										// }}
										onClick={detailSuratProps.dataAyah}
									>
										{" "}
										Klik disini
									</Button>
								</small>
								{/* <Select
									classNamePrefix="select"
									onChange={(e) => {
										detailSuratProps.getPenduduk(
											e,
											detailSuratProps.detailSurat.jenis_surat,
											'pemohon'
										);
									}}
									defaultValue={detailSuratProps.dataNikStat.nikStat}
									value={detailSuratProps.dataNikStat.nikStat}
									name="NIK"
									options={detailSuratProps.dataNik.frmNik}
									placeholder="Pilih NIK"
									onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik')}
									required={true}
									isClearable
								/> */}
							</Col>
						</FormGroup>
						<div style={{ display: detailSuratProps.status.dataPenduduk }}>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Ayah
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_ayah"
										type="text"
										placeholder="Nama Ayah"
										value={detailSuratProps.detailSurat.nama_ayah}
										onChange={e => detailSuratProps.changeInputText(e, "nama_ayah")}
										readOnly={detailSuratProps.detailSurat.formAyah == "" ? false : true}
										disabled={detailSuratProps.detailSurat.formAyah == "" ? false : true}
										style={detailSuratProps.detailSurat.formAyah == "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
										required
									/>
									{/* <span>{detailSuratProps.detailSurat.nama_ayah}</span> */}
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tempat Lahir Ayah
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tempat_lahir_ayah"
										type="text"
										placeholder="Tempat Lahir Ayah"
										value={detailSuratProps.detailSurat.tempat_lahir_ayah}
										onChange={e =>
											detailSuratProps.changeInputText(e, "tempat_lahir_ayah")
										}
										readOnly={detailSuratProps.detailSurat.formAyah == "" ? false : true}
										required
										style={detailSuratProps.detailSurat.formAyah == "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
										disabled={detailSuratProps.detailSurat.formAyah == "" ? false : true}
									/>
									{/* <span>{detailSuratProps.detailSurat.tempat_lahir_ayah}</span> */}
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tanggal Lahir Ayah
								</Label>
								<Col sm="9">
									{detailSuratProps.detailSurat.formAyah == '' ? 
										(<Datetime
                      locale="id-ID"
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      input={true}
                      closeOnTab={true}
                      closeOnSelect={true}
                      initialValue={detailSuratProps.detailSurat.tanggal_lahir_ayah}
                      value={detailSuratProps.detailSurat.tanggal_lahir_ayah}
                      onChange={e => {
                        detailSuratProps.gantiTgl(e,'tanggal_lahir_ayah')
                      }}
                      inputProps={{
                        required: true,
                        placeholder: 'Tanggal Lahir Ayah',
                      }}
                    />)
										:
										(<input
                      className="form-control"
                      id="tanggal_lahir_ayah"
                      //   type="text"
                      type={detailSuratProps.detailSurat.formAyah == "" ? "date" : "text"}
                      placeholder="Tanggal Lahir Ayah"
                      value={dateFormat(detailSuratProps.detailSurat.tanggal_lahir_ayah, 'dd/mm/yyyy')}
                      //   readOnly
                      readOnly={detailSuratProps.detailSurat.formAyah == "" ? false : true}
                      required
                      style={detailSuratProps.detailSurat.formAyah == "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
                      disabled={detailSuratProps.detailSurat.formAyah == "" ? false : true}
                    />)
									}
									{/* <input
										className="form-control"
										id="tanggal_lahir_ayah"
										type="text"
										placeholder="Tanggal Lahir"
										value={detailSuratProps.detailSurat.tanggal_lahir_ayah}
										readOnly
									/> */}
									{/* <span>{detailSuratProps.detailSurat.tanggal_lahir_ayah.toLocaleDateString('id-ID')}</span> */}
								</Col>
							</FormGroup>
						</div>
						<div style={{ display: detailSuratProps.detailSurat.formAyah }}>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Pekerjaan Ayah
								</Label>
								<Col sm="9">
									{/* <input className="form-control" id="pekerjaan_ayah" type="text" placeholder="Pekerjaan Ayah" value={detailSurat.pekerjaan_ayah} readOnly /> */}
									{/* <span>{detailSuratProps.detailSurat.pekerjaan_ayah}</span> */}
									{/* <input
										className="form-control"
										id="pekerjaan_ayah"
										type="text"
										placeholder="Pekerjaan Ayah"
										defaultValue={detailSuratProps.detailSurat.pekerjaan_ayah}
										value={detailSuratProps.detailSurat.pekerjaan_ayah}
										readOnly={detailSuratProps.detailSurat.formAyah == "" ? false : true}
										onChange={e => detailSuratProps.changeInputText(e, "pekerjaan_ayah")}
										required
										//   readOnly
									/> */}
									<Select
										classNamePrefix="select"
										onChange={e => {
											detailSuratProps.changeSelectValue2(e, "pekerjaan_ayah");
										}}
										value={detailSuratProps.detailSurat.pekerjaan_ayah}
										name="pekerjaan_ayah"
										options={detailSuratProps.listPekerjaan}
										placeholder="Pilih Pekerjaan Ayah"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Alamat Ayah
								</Label>
								<Col sm="9">
									{/* <input className="form-control" id="alamat_ayah" type="text" placeholder="Alamat Ayah" value={detailSurat.alamat_ayah} readOnly /> */}
									<input
										className="form-control"
										id="alamat_ayah"
										type="text"
										placeholder="Alamat Ayah"
										defaultValue={detailSuratProps.detailSurat.alamat_ayah}
										value={detailSuratProps.detailSurat.alamat_ayah}
										readOnly={detailSuratProps.detailSurat.formAyah == "" ? false : true}
										onChange={e => detailSuratProps.changeInputText(e, "alamat_ayah")}
										required
										//   readOnly
									/>
								</Col>
							</FormGroup>
						</div>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								NIK Ibu
							</Label>
							<Col sm="9">
								{detailSuratProps.detailSurat.formIbu == "none" ? (
									<Select
										classNamePrefix="select"
										onChange={(e) => {
											detailSuratProps.getPenduduk(
												e,
												detailSuratProps.detailSurat.jenis_surat,
												'ibu'
											);
										}}
										defaultValue={detailSuratProps.dataNikStat.nik3Stat}
										value={detailSuratProps.dataNikStat.nik3Stat}
										name="NIK3"
										options={detailSuratProps.dataNik.frmNik3}
										placeholder="Pilih NIK IBU"
										onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik3')}
										required={true}
										isClearable
									/>
								) : (
									<input
										className="form-control"
										id="nik_ibu"
										type={detailSuratProps.detailSurat.formIbu == "none" ? "hidden" : "number"}
										placeholder="Nik Ibu"
										defaultValue={detailSuratProps.detailSurat.nik_ibu}
										value={detailSuratProps.detailSurat.nik_ibu}
										onChange={e => detailSuratProps.changeInputText(e, "nik_ibu")}
										required
									/>
								)}
								<small id="emailHelp2" className="form-text text-muted">
									Data NIK ibu {detailSuratProps.detailSurat.formIbu == "none" ? "tidak" : ""}{" "}
									tersedia?{" "}
									<Button
										className="btn btn-xs"
										// to={{
										// 	pathname: this.props.myroute,
										// 	state: this.props.location.state
										// }}
										onClick={detailSuratProps.dataIbu}
									>
										{" "}
										Klik disini
									</Button>
								</small>
							</Col>
						</FormGroup>
						<div style={{ display: detailSuratProps.status.dataPenduduk3 }}>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Ibu
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_ibu"
										type="text"
										placeholder="Nama Ibu"
										value={detailSuratProps.detailSurat.nama_ibu}
										onChange={e => detailSuratProps.changeInputText(e, "nama_ibu")}
										readOnly={detailSuratProps.detailSurat.formIbu == "" ? false : true}
										required
										style={detailSuratProps.detailSurat.formIbu == "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
										disabled={detailSuratProps.detailSurat.formIbu == "" ? false : true}
									/>
									{/* <span>{detailSuratProps.detailSurat.nama_ibu}</span> */}
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tempat Lahir Ibu
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tempat_lahir_ibu"
										type="text"
										placeholder="Tempat Lahir Ibu"
										value={detailSuratProps.detailSurat.tempat_lahir_ibu}
										onChange={e =>
											detailSuratProps.changeInputText(e, "tempat_lahir_ibu")
										}
										readOnly={detailSuratProps.detailSurat.formIbu == "" ? false : true}
										required
										style={detailSuratProps.detailSurat.formIbu == "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
										disabled={detailSuratProps.detailSurat.formIbu == "" ? false : true}
									/>
									{/* <span>{detailSuratProps.detailSurat.tempat_lahir_ibu}</span> */}
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tanggal Lahir Ibu
								</Label>
								<Col sm="9">
									{detailSuratProps.detailSurat.formIbu == '' ? (
										<Datetime
											locale="id-ID"
											timeFormat={false}
											dateFormat="DD/MM/yyyy"
											input={true}
											closeOnTab={true}
											closeOnSelect={true}
											initialValue={detailSuratProps.detailSurat.tanggal_lahir_ibu}
											value={detailSuratProps.detailSurat.tanggal_lahir_ibu}
											onChange={e => {
												detailSuratProps.gantiTgl(e,'tanggal_lahir_ibu')
											}}
											inputProps={{
												required: true,
												placeholder: 'Tanggal Lahir Ibu',
											}}
										/>
									) : (
										<input
											className="form-control"
											id="tanggal_lahir_ibu"
											type={detailSuratProps.detailSurat.formIbu == "" ? "date" : "text"}
											placeholder="Tanggal Lahir"
											// value={this.state.dt.tanggal_lahir_ibu}
											value={dateFormat(detailSuratProps.detailSurat.tanggal_lahir_ibu, 'dd/mm/yyyy')}
											//   readOnly
											onChange={e =>
												detailSuratProps.changeInputText(e, "tanggal_lahir_ibu")
											}
											defaultValue={detailSuratProps.detailSurat.tanggal_lahir_ibu}
											readOnly={detailSuratProps.detailSurat.formIbu == "" ? false : true}
											required
											style={detailSuratProps.detailSurat.formIbu == "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
											disabled={detailSuratProps.detailSurat.formIbu == "" ? false : true}
										/>
									)}
									{/* <span>{detailSuratProps.detailSurat.tanggal_lahir_ibu.toLocaleDateString('id-ID')}</span> */}
								</Col>
							</FormGroup>
						</div>
						<div style={{ display: detailSuratProps.detailSurat.formIbu }}>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Pekerjaan Ibu
								</Label>
								<Col sm="9">
									{/* <input className="form-control" id="pekerjaan_ibu" type="text" placeholder="Pekerjaan Ibu" value={detailSurat.pekerjaan_ibu} readOnly /> */}
									{/* <input
										className="form-control"
										id="pekerjaan_ibu"
										type="text"
										placeholder="Pekerjaan Ibu"
										onChange={e => detailSuratProps.changeInputText(e, "pekerjaan_ibu")}
										defaultValue={detailSuratProps.detailSurat.pekerjaan_ibu}
										value={detailSuratProps.detailSurat.pekerjaan_ibu}
										readOnly={detailSuratProps.detailSurat.formIbu == "" ? false : true}
										required
									/> */}
									<Select
										classNamePrefix="select"
										onChange={e => {
											detailSuratProps.changeSelectValue2(e, "pekerjaan_ibu");
										}}
										value={detailSuratProps.detailSurat.pekerjaan_ibu}
										name="pekerjaan_ibu"
										options={detailSuratProps.listPekerjaan}
										placeholder="Pilih Pekerjaan Ibu"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Alamat Ibu
								</Label>
								<Col sm="9">
									{/* <input className="form-control" id="alamat_ibu" type="text" placeholder="Alamat Ibu" value={detailSurat.alamat_ibu} readOnly /> */}
									<input
										className="form-control"
										id="alamat_ibu"
										type="text"
										placeholder="Alamat Ibu"
										value={detailSuratProps.detailSurat.alamat_ibu}
										//   readOnly
										onChange={e => detailSuratProps.changeInputText(e, "alamat_ibu")}
										defaultValue={detailSuratProps.detailSurat.alamat_ibu}
										value={detailSuratProps.detailSurat.alamat_ibu}
										readOnly={detailSuratProps.detailSurat.formIbu == "" ? false : true}
										required
									/>
								</Col>
							</FormGroup>
						</div>

						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								NIK Pelapor
							</Label>
							<Col sm="9">
								<Select
									classNamePrefix="select"
									onChange={(e) => {
										detailSuratProps.getPenduduk(
											e,
											detailSuratProps.detailSurat.jenis_surat,
											'pelapor'
										);
									}}
									defaultValue={detailSuratProps.dataNikStat.nik2Stat}
									value={detailSuratProps.dataNikStat.nik2Stat}
									name="NIK2"
									options={detailSuratProps.dataNik.frmNik2}
									placeholder="Pilih NIK Pelapor"
									onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik2')}
									required={true}
									isClearable
								/>
							</Col>
						</FormGroup>
						<div style={{ display: detailSuratProps.status.dataPenduduk2 }}>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Pelapor
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_pelapor"
										type="text"
										placeholder="Nama Pemohon"
										value={detailSuratProps.detailSurat.nama_pelapor}
										readOnly
										style={{ backgroundColor:"#c9d1d8" }}
									/>
									{/* <span>{detailSuratProps.detailSurat.nama_pelapor}</span> */}
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tempat Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tempat_lahir_pelapor"
										type="text"
										placeholder="Tempat Lahir"
										value={detailSuratProps.detailSurat.tempat_lahir_pelapor}
										readOnly
										style={{ backgroundColor:"#c9d1d8" }}
									/>
									{/* <span>{detailSuratProps.detailSurat.tempat_lahir_pelapor}</span> */}
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tanggal Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tanggal_lahir_pelapor"
										type="text"
										placeholder="Tanggal Lahir"
										value={dateFormat(detailSuratProps.detailSurat.tanggal_lahir_pelapor, 'dd/mm/yyyy')}
										readOnly
										style={{ backgroundColor:"#c9d1d8" }}
									/>
									{/* <span>{detailSuratProps.detailSurat.tanggal_lahir_pelapor.toLocaleDateString('id-ID')}</span> */}
								</Col>
							</FormGroup>
						</div>
						<div style={{ display: 'none' }}>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Pekerjaan
								</Label>
								<Col sm="9">
									{/* <input className="form-control" id="pekerjaan_pelapor" type="text" placeholder="Pekerjaan" value={detailSurat.pekerjaan_pelapor} readOnly /> */}
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Agama
								</Label>
								<Col sm="9">
									{/* <input className="form-control" id="agama_pelapor" type="text" placeholder="Agama" value={detailSurat.agama_pelapor} readOnly /> */}
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Alamat
								</Label>
								<Col sm="9">
									{/* <input className="form-control" id="alamat_pelapor" type="text" placeholder="Alamat" value={detailSurat.alamat_pelapor} readOnly /> */}
								</Col>
							</FormGroup>
						</div>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Hubungan Pelapor
							</Label>
							<Col sm="9">
								{/* <input
									className="form-control"
									id="hubungan_pelapor"
									type="text"
									placeholder="Hubungan Pelapor"
									defaultValue={detailSuratProps.detailSurat.hubungan_pelapor}
									required
								/> */}
								<Select
                  classNamePrefix="select"
                  onChange={(e) => {
                    detailSuratProps.changeSelectValue2(e, 'shdk_aw');
                  }}
                  value={detailSuratProps.detailSurat.shdk}
                  id="shdk"
                  options={[
                    { value: 'Suami', label: 'Suami' },
                    { value: 'Isteri', label: 'Isteri' },
                    { value: 'Orang Tua', label: 'Orang Tua' },
                    { value: 'Anak', label: 'Anak' },
                    { value: 'Kakak', label: 'Kakak' },
                    { value: 'Adik', label: 'Adik' },
                    { value: 'Mertua', label: 'Mertua' },
                    { value: 'Menantu', label: 'Menantu' },
                    { value: 'Cucu', label: 'Cucu' },
                    { value: 'Pembantu', label: 'Pembantu' },
                    { value: 'Famili Lain', label: 'Famili Lain' }
                  ]}
                  placeholder="Hubungan Keluarga"
                />
							</Col>
						</FormGroup>
					</div>
					<div className="modal-footer">
						<button type="button" className="btn btn-warning" onClick={detailSuratProps.tutupForm}>
							Tutup
						</button>
						<button
							type="submit"
							className="btn btn-success"
							// disabled={detailSuratProps.status.btnForm}
						>
							Simpan
						</button>
					</div>
				</Form>
			</Modal>
		</div>
	);
}

export default EditKelahiran;
