import axios from "axios";

// API Desa Build
// const API_URL = "https://ws-dgd-spt0761.digidesa.com/api/v1/";

//Dev Sungai Petai
const API_URL = "https://ws-dev-kelurahan.digidesa.com/api/v1/";

// prod marketplace
const API_URL2 = 'https://ws-marketplace-desa-prod.digidesa.com/api/v1/web/';

// ID Desa Build
export const ID_DESA = 1471011007;

// ID Desa Demo
// export const ID_DESA = 1471011003;

// id desa dev sungai petai
// export const ID_DESA = 1401082006;

// Set Konfig ke local storage
axios.get(API_URL2+'list-layanan-desa/'+ID_DESA)
	.then(response => {
		let data_layanan = JSON.parse(response.data.result.koneksi_web)


		let jenis_desa = data_layanan.jenis_desa
		let jenis_dusun = data_layanan.jenis_dusun
		let desa = response.data.result.data_api.data_desa.nama
		let nama_desa = jenis_desa+ " " + desa
		let kecamatan = response.data.result.data_api.data_desa.data_kecamatan.nama
		let ex = response.data.result.data_api.data_desa.data_kecamatan.data_kabkota.nama.split(' ');
		let jenis_kabupaten = ex[0];
		let jenis_kab = ""

		if(jenis_kabupaten.toLowerCase()=='kab.'){
			jenis_kab = jenis_kabupaten.toLowerCase().replace('kab.', 'Kabupaten')
			console.log(jenis_kab)
		}
		else if(jenis_kabupaten.toLowerCase()=='kota'){
			jenis_kab = jenis_kabupaten.toLowerCase().replace('kota', 'Kota')
			console.log(jenis_kab)
		}
		
		let kabupaten = ex[1].charAt(0).toUpperCase() + ex[1].slice(1).toLowerCase();
		let nama_kabupaten = jenis_kab+ " " +kabupaten
		let provinsi = response.data.result.data_api.data_desa.data_kecamatan.data_kabkota.data_provinsi.nama
		let jabatan_tertinggi = data_layanan.jenis_dusun
		let jenis_aplikasi = response.data.result.data_api.jenis_app
		let layanan_desa = response.data.result.list_layanan_desa_web
		let layanan_kua = response.data.result.list_layanan_kua_web
		let status_layanan_kua = response.data.result.layanan_kua
		let layanan_capil = response.data.result.list_layanan_capil_web
		let status_layanan_capil = response.data.result.layanan_capil
		
		let kantor_polisi_provinsi = data_layanan.kantor_polisi_provinsi
		let kantor_polisi_kabupaten = data_layanan.kantor_polisi_kabupaten
		let kantor_polisi_kecamatan = data_layanan.kantor_polisi_kecamatan
		let kepala_polisi_provinsi = data_layanan.kepala_polisi_provinsi
		let kepala_polisi_kabupaten = data_layanan.kepala_polisi_kabupaten
		let kepala_polisi_kecamatan = data_layanan.kepala_polisi_kecamatan

		localStorage.setItem('JENIS_DESA', jenis_desa);
		localStorage.setItem('JENIS_DUSUN', jenis_dusun);
		localStorage.setItem('DESA', desa);
		localStorage.setItem('Nama_DESA', nama_desa);
		localStorage.setItem('KECAMATAN', kecamatan);
		localStorage.setItem('JENIS_KABUPATEN', jenis_kab);
		localStorage.setItem('KABUPATEN', kabupaten);
		localStorage.setItem('NAMA_KABUPATEN', nama_kabupaten);
		localStorage.setItem('PROVINSI', provinsi);
		localStorage.setItem('JABATAN_TERTINGGI', jabatan_tertinggi);
		localStorage.setItem('JENIS_APLIKASI', jenis_aplikasi);
		localStorage.setItem('LAYANAN_DESA', layanan_desa);
		localStorage.setItem('LAYANAN_KUA', layanan_kua);
		localStorage.setItem('STATUS_LAYANAN_KUA', status_layanan_kua);
		localStorage.setItem('LAYANAN_CAPIL', layanan_capil);
		localStorage.setItem('STATUS_LAYANAN_CAPIL', status_layanan_capil);
		localStorage.setItem('KANTOR_POLISI_PROVINSI', kantor_polisi_provinsi);
		localStorage.setItem('KANTOR_POLISI_KABUPATEN', kantor_polisi_kabupaten);
		localStorage.setItem('KANTOR_POLISI_KECAMATAN', kantor_polisi_kecamatan);
		localStorage.setItem('KEPALA_POLISI_PROVINSI', kepala_polisi_provinsi);
		localStorage.setItem('KEPALA_POLISI_KABUPATEN', kepala_polisi_kabupaten);
		localStorage.setItem('KEPALA_POLISI_KECAMATAN', kepala_polisi_kecamatan);
	});

// Konfigurasi Desa
export const JENIS_DESA = localStorage.getItem('JENIS_DESA');
export const JENIS_DUSUN = localStorage.getItem('JENIS_DUSUN');
export const DESA = localStorage.getItem('DESA');
export const Nama_DESA = JENIS_DESA + " " + DESA;

export const KECAMATAN = localStorage.getItem('KECAMATAN');

export const JENIS_KABUPATEN = localStorage.getItem('JENIS_KABUPATEN');
export const KABUPATEN = localStorage.getItem('KABUPATEN');
export const NAMA_KABUPATEN = JENIS_KABUPATEN + " " + KABUPATEN;

export const PROVINSI = localStorage.getItem('PROVINSI');

export const JENIS_APLIKASI = localStorage.getItem('JENIS_APLIKASI');

export const LAYANAN_DESA = localStorage.getItem('LAYANAN_DESA');
export const LAYANAN_KUA = localStorage.getItem('LAYANAN_KUA');
export const STATUS_LAYANAN_KUA = localStorage.getItem('STATUS_LAYANAN_KUA');
export const LAYANAN_CAPIL = localStorage.getItem('LAYANAN_CAPIL');
export const STATUS_LAYANAN_CAPIL = localStorage.getItem('STATUS_LAYANAN_CAPIL');

// OTORITAS POLISI: TINGKAT KEPOLISIAN KECAMATAN, TINGKAT KEPOLISIAN KABUPATEN, TINGKAT KEPOLISIAN PROVINSI 
export const KEPALA_POLISI_KECAMATAN = localStorage.getItem('KEPALA_POLISI_KECAMATAN');
export const KANTOR_POLISI_KECAMATAN = localStorage.getItem('KANTOR_POLISI_KECAMATAN');

export const KEPALA_POLISI_KABUPATEN = localStorage.getItem('KEPALA_POLISI_KABUPATEN');
export const KANTOR_POLISI_KABUPATEN = localStorage.getItem('KANTOR_POLISI_KABUPATEN');

export const KEPALA_POLISI_PROVINSI = localStorage.getItem('KEPALA_POLISI_PROVINSI');
export const KANTOR_POLISI_PROVINSI = localStorage.getItem('KANTOR_POLISI_PROVINSI');

export const OTORITAS_POLISI = 	[
	{ 
		value: JSON.stringify({
				jabatan: KEPALA_POLISI_KECAMATAN,
				wilayah: KECAMATAN,
				lokasi_kantor: KANTOR_POLISI_KECAMATAN
			}), 
		label:  KEPALA_POLISI_KECAMATAN +' '+ KECAMATAN + " di " + KANTOR_POLISI_KECAMATAN
	},
	{ 
		value: JSON.stringify({
				jabatan: KEPALA_POLISI_KABUPATEN,
				wilayah: KABUPATEN,
				lokasi_kantor: KANTOR_POLISI_KABUPATEN
			}), 
		label:  KEPALA_POLISI_KABUPATEN +' '+ KABUPATEN + " di " + KANTOR_POLISI_KABUPATEN
	},
	{ 
		value: JSON.stringify({
				jabatan: KEPALA_POLISI_PROVINSI,
				wilayah: PROVINSI,
				lokasi_kantor: KANTOR_POLISI_PROVINSI
			}), 
		label:  KEPALA_POLISI_PROVINSI +' '+ PROVINSI + " di " + KANTOR_POLISI_PROVINSI
	},
];


// Tidak Terpakai, tapi masih ada pengaruh ke file lainnya
export const ALAMAT_KANTOR_DESA = "";
export const JABATAN_TERTINGGI = "Kepala";

export function Get(table, id, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "public";

	// console.log(token);
	if (id === null) {
		axios
			.get(`${API_URL + table}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				if (err.response) {
					cb(err.response.data);
					// cb(err.response.headers);
					// cb(err.response.status);
					// console.log(error.response.status);
					// console.log(error.response.headers);
				}
				// cb(err);
			});
	} else {
		axios
			.get(`${API_URL + table}/${id}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function GetMarketplace(table, id, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "";

	// console.log(token);
	if (id === null) {
		axios
			.get(`${API_URL2 + table}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				cb(err);
			});
	} else {
		axios
			.get(`${API_URL2 + table}/${id}`, {
				headers: { Authorization: `Bearer ${token}` }
			})
			.then(response => response.data)
			.then(data => {
				cb(data);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function Delete(table, id, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "";

	axios
		.delete(`${API_URL + table}/${id}`, {
			headers: { Authorization: `Bearer ${token}` }
		})
		.then(response => response.status)
		.then(status => {
			cb(status);
		})
		.catch(err => {
			cb(err);
		});
}

export function Post(table, id, data, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "";
	// console.log(token)
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => {
				cb(response);
			})
			.catch(err => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => {
				cb(response);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function Put(table, id, data, cb) {
	axios
		.put(`${API_URL + table}/${id}`, data, { validateStatus: false })
		.then(response => {
			cb(response);
		})
		.catch(err => {
			cb(err);
		});
}

export function Patch(table, id, data, cb) {
	axios
		.patch(`${API_URL + table}/${id}`, data, { validateStatus: false })
		.then(response => {
			cb(response);
		})
		.catch(err => {
			cb(err);
		});
}

export function cekLogin(table, data, cb) {
	axios
		.post(`${API_URL + table}`, data, { validateStatus: false })
		.then(response => {
			cb(response);
		})
		.catch(response => {
			cb(response);
		});
}
export function PostStatus(table, id, data, cb) {
	let token = sessionStorage.getItem("access_token")
		? sessionStorage.getItem("access_token")
		: "";
	if (id === null) {
		axios
			.post(`${API_URL + table}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => response.status)
			.then(status => {
				cb(status);
			})
			.catch(err => {
				cb(err);
			});
	} else {
		axios
			.patch(`${API_URL + table}/${id}`, data, {
				headers: { Authorization: `Bearer ${token}` },
				validateStatus: false
			})
			.then(response => response.status)
			.then(status => {
				cb(status);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export function PostUser(table, id, data, cb) {
	if (id === null) {
		axios
			.post("http://localhost/ppdx/web/" + table, data, {
				headers: { "Content-Type": "multipart/form-data" }
			})
			.then(response => response.status)
			.then(status => {
				cb(status);
			})
			.catch(err => {
				cb(err);
			});
	}
}

export default API_URL;