import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { Col, FormGroup, Label, Row, Form } from 'reactstrap';
import { Tanggal, HariTanggal } from '../function/Format';
import Datepicker from 'react-datepicker';
import { JENIS_DESA, JENIS_DUSUN, Post, Get } from '../function/Koneksi';
import Datetime from 'react-datetime';
// import Keyboard from 'react-simple-keyboard';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const dateFormat = require('dateformat');

export default class SuratJalan extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tanggal_surat: new Date(),
			tanggal_lahir_supir: null,
			rencana_berangkat: null,
			Basic: false,
			status: {
				form: false,
				btnForm: true,
				btnAksi: [],
				keyboard: false
			},
			scrollUp: null,

			//keyboard
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: {},
			inputPattern: /^\d+$/,
			display: 'none',
			focus: false,
			hasFocus: false,
			style: {}
		};
	}

	handleClick = (event) => {
		alert(event);
	};

	gantiTgl = (date, fld) => {
		switch (fld) {
			case 'tanggal_surat':
				this.setState({ tanggal_surat: date });
				break;
			case 'tanggal_acara':
				this.setState({ tanggal_acara: date });
				break;
			case 'tanggal_lahir_supir':
				// this.setState({ tanggal_lahir_supir: date });
				if (typeof date._isValid != 'undefined') {
					let tgl = date.format('YYYY-MM-DD HH:mm:ss');
					this.setState({ tanggal_lahir_supir: new Date(tgl) });
				}
				break;
			case 'rencana_berangkat':
				// this.setState({ rencana_berangkat: date });
				if (typeof date._isValid != 'undefined') {
					let tgl = date.format('YYYY-MM-DD HH:mm:ss');
					this.setState({ rencana_berangkat: new Date(tgl) });
				}
				break;
		}
	};

	simpan = (e) => {
		e.preventDefault();
		// this.state.status.btnForm = true;
		// this.forceUpdate();

		// let uuid = document.getElementById('uuid').value;
		let alamat = '';
		if (JENIS_DESA.toLowerCase() == 'kelurahan') {
			alamat =
				' RT. ' +
				this.props.data.results.data_kk.data_rt.nama +
				'/RW. ' +
				this.props.data.results.data_kk.data_rt.data_rw.nama +
				', ' +
				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
				', Kec. ' +
				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
				', ' +
				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
		} else {
			// alamat =
			// 	' RT. ' +
			// 	this.props.data.results.data_kk.data_rt.nama +
			// 	'/RW. ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 	', ' +
			// 	JENIS_DUSUN +
			// 	' ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 	', ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 	', Kec. ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
			// 	', ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

				if (this.props.data.results.data_kk.id_rt == '0') {
					alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
					", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
					", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
					", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
				} else {
					alamat =
					" RT. " + 
					this.props.data.results.data_kk.data_rt.nama +
					"/RW. " +
					this.props.data.results.data_kk.data_rt.data_rw.nama +
					", " + JENIS_DUSUN + " " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
					", " + JENIS_DESA + " " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
					", Kec. " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.nama +
					", " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.data_kabkota.nama; 
				}
		}

		let formDetail = [
			{
				nama_item: 'rencana_berangkat',
				isi_item: dateFormat(this.state.rencana_berangkat, 'yyyy-mm-dd HH:MM:ss')
			},
			{
				nama_item: 'transportasi',
				isi_item: document.getElementById('transportasi').value
			},
			{
				nama_item: 'tujuan_perjalanan',
				isi_item: document.getElementById('tujuanPerjalanan').value
			},
			{
				nama_item: 'no_pol',
				isi_item: document.getElementById('no_pol').value
			},
			{
				nama_item: 'nama_supir',
				isi_item: document.getElementById('nama_supir').value
			},
			{
				nama_item: 'tempat_lahir_supir',
				isi_item: document.getElementById('tempat_lahir_supir').value
			},
			{
				nama_item: 'tanggal_lahir_supir',
				isi_item: dateFormat(this.state.tanggal_lahir_supir, 'yyyy-mm-dd HH:MM:ss')
			},
			{
				nama_item: 'barang',
				isi_item: document.getElementById('barang_bawaan').value
			},
			{
				nama_item: 'pengikut',
				isi_item: document.getElementById('pengikut').value
			}
		];

		let addData = {
			id_jenis: this.props.id_jenis,

			nik: this.props.data.results.id,
			nama_pemohon: this.props.data.results.nama,
			tempat_lahir: this.props.data.results.tempat_lahir,
			tanggal_lahir: this.props.data.results.tanggal_lahir,
			pekerjaan: this.props.data.results.data_pekerjaan.nama,
			alamat: alamat,

			pendidikan_terakhir: this.props.data.results.pendidikan_terakhir,
			agama: this.props.data.results.data_agama.nama,
			status_perkawinan: this.props.data.results.status_perkawinan,
			jenis_kelamin: this.props.data.results.jk,

			kewarganegaraan: 'Indonesia',
			tujuan_pembuatan: document.getElementById('maksudTujuan').value,
			kk: this.props.data.results.id_kk,

			detail: formDetail
		};

		let psn = '';
		let resstat = 201;
		let metode = 'create';

		Post('surat-keterangan/grup/' + metode + '/with-detail', null, addData, (res) => {
			this.onCloseModal('Basic');

			let dataAntrian = {
				nama: this.props.data.results.nama,
				nik: this.props.data.results.id,
				keterangan: '',
				uuid_surat:res.data.results,
			};

			if (res.status === resstat) {
				Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
					console.log("Hasil Update Status")
					console.log(res2)
					if (res2.code === 201) {
						console.log("Berhasil Ganti Status Menjadi Selesai")

						this.props.getAntrian('Surat Keterangan Jalan', 'berhasil', false, dataAntrian);
					}
					else{
						console.log("Gagal Ganti Status Menjadi Selesai")

						this.props.getAntrian('Surat Keterangan Jalan', 'gagal');
					}
				});
				// this.props.getAntrian('Surat Keterangan Jalan', 'berhasil', false, dataAntrian);
			} else {
				this.props.getAntrian('Surat Keterangan Jalan', 'gagal');
			}
			// this.componentDidMount();
			// reset cache
			document.getElementById('maksudTujuan').value = '';
			document.getElementById('transportasi').value = '';
			document.getElementById('tujuanPerjalanan').value = '';
			document.getElementById('no_pol').value = '';
			document.getElementById('nama_supir').value = '';
			document.getElementById('tempat_lahir_supir').value = '';
			document.getElementById('barang_bawaan').value = '';
			document.getElementById('pengikut').value = '';
			this.state.rencana_berangkat = null;
			this.state.tanggal_lahir_supir = null;
			this.forceUpdate();
		});
	};

	// EMBED KEyBOARD

	onOpenModal = (key) => {
		console.log(key);
		console.log(this.props.data.results);
		this.setState({
			[key]: true,
			regis: true
		});
	};

	onCloseModal = (key) => {
		this.setState({
			[key]: false,
			display: 'none',
			regis: 'false',
			input: {}
		});
		this.state.tanggal_lahir_supir = null;
		this.state.rencana_berangkat = null;
		this.forceUpdate();
	};

	// on focus
	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				console.log('Active input', inputName);
			}
		);
	};

	// end on focus

	// ketika Klik Keyboard atau mengisi data
	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		console.log('Input changed', inputObj);
	};

	onKeyPress = (button) => {
		console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	// End ketika Klik Keyboard atau mengisi data

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}
		// this.setState({
		// 	display: '',
		// 	// onFocus:true

		//   });
		this.forceUpdate();
	};

	showKeyboard1 = () => {
		this.setState({
			display: 'none',
			regis: 'false'
		});
		this.forceUpdate();
	};

	// Input keyboard
	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				} else {
				}
			}
		);
	};

	onChangeInput1 = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			}
		);
	};

	// end input keyboard

	// END EMBED KEYBOARD

	render() {
		const footerStyle = {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		};

		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const { Basic } = this.state;

		return (
			<div>
				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					// styles={{ modal: { marginBottom: '200px', width: '80%' } }}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div
							// className="card-scrollable"
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								// console.log(e);
								this.state.isDown = true;
								this.state.startY = e.pageY - slider.offsetTop;
								this.state.scrollUp = slider.scrollTop;
								this.forceUpdate();
								// console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								// console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title" align="center">
									Surat Jalan
								</h5>
							</div>

							<div className="modal-body">
								<input id="jenis_kelamin" type="hidden" value={this.props.data.results.jk} />

								<input
									id="pekerjaan"
									type="hidden"
									value={this.props.data.results.pekerjaan}
									readOnly
								/>

								<input
									id="pendidikan_terakhir"
									type="hidden"
									value={this.props.data.results.pendidikan_terakhir}
									readOnly
								/>

								<input id="agama" type="hidden" value={this.props.data.results.agama} readOnly />

								<input
									id="status_perkawinan"
									type="hidden"
									value={this.props.data.results.status_perkawinan}
									readOnly
								/>

								<input id="alamat" type="hidden" value={this.props.data.results.alamat} readOnly />

								<input
									id="kewarganegaraan"
									type="hidden"
									readOnly
									// value={this.state.dt.kewarganegaraan}
								/>
								<input id="kk" type="hidden" value={this.props.data.results.id_kk} readOnly />

								<input
									id="tempat_lahir"
									type="hidden"
									value={this.props.data.results.tempat_lahir}
									readOnly
								/>

								<input id="tanggal_lahir" type="hidden" value={this.props.data.results.tanggal_lahir} />

								{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label">No Antrian Anda</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="no_antrian_anda"
										disabled
										type="text"
										placeholder="No Antrian Anda"
										defaultValue="12"
									/>
								</Col>
							</FormGroup> */}

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											placeholder="NIK"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Lengkap</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pemohon"
											disabled
											type="text"
											placeholder=" Nama Lengkap"
											value={this.props.data.results.nama}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Maksud dan Tujuan</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="maksudTujuan"
											type="text"
											placeholder="Contoh: Membawa hewan ternak"
											defaultValue=""
											required
											autoFocus
											onFocus={() => this.setActiveInput('maksudTujuan')}
											value={this.state.input['maksudTujuan'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Tujuan Perjalanan</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tujuanPerjalanan"
											type="text"
											placeholder="Contoh: Kota Bukit Tinggi"
											defaultValue=""
											required
											onFocus={() => this.setActiveInput('tujuanPerjalanan')}
											value={this.state.input['tujuanPerjalanan'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Rencana Berangkat</Label>
									<Col sm="9">
										{/* <Datepicker
										id="rencana_berangkat"
										todayButton={'Hari ini'}
										dateFormat="dd/MM/yyyy"
										placeholderText="Tanggal Berangkat"
										selected={this.state.rencana_berangkat}
										onChange={(e) => this.gantiTgl(e, 'rencana_berangkat')}
										className="form-control"
										required
									/> */}
										<Datetime
											inputProps={{ required: true, placeholder: 'Tanggal Berangkat' }}
											initialValue={this.state.rencana_berangkat}
											onChange={(e) => {
												this.gantiTgl(e, 'rencana_berangkat');
											}}
											locale="id-ID"
											timeFormat={false}
											dateFormat="DD/MM/yyyy"
											closeOnSelect={true}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Transportasi</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="transportasi"
											type="text"
											placeholder="Contoh: Mobil Pribadi"
											defaultValue=""
											required
											onFocus={() => this.setActiveInput('transportasi')}
											value={this.state.input['transportasi'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">No. Pol</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="no_pol"
											type="text"
											defaultValue=""
											placeholder="Contoh : BM 4454 KJ"
											required
											onFocus={() => this.setActiveInput('no_pol')}
											value={this.state.input['no_pol'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Supir</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_supir"
											type="text"
											defaultValue=""
											placeholder="Contoh: Norman"
											required
											onFocus={() => this.setActiveInput('nama_supir')}
											value={this.state.input['nama_supir'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Tempat Lahir Supir</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="tempat_lahir_supir"
											type="text"
											defaultValue=""
											placeholder="Contoh : Pekanbaru"
											required
											onFocus={() => this.setActiveInput('tempat_lahir_supir')}
											value={this.state.input['tempat_lahir_supir'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Tanggal Lahir Supir
									</Label>
									<Col sm="9">
										{/* <Datepicker
										id="tanggal_lahir_supir"
										todayButton={'Hari ini'}
										showYearDropdown
										// showMonthDropdown
										dateFormat="dd/MM/yyyy"
										selected={this.state.tanggal_lahir_supir}
										placeholderText="Tanggal lahir supir"
										onChange={(e) => this.gantiTgl(e, 'tanggal_lahir_supir')}
										className="form-control"
										required
									/> */}

										<Datetime
											inputProps={{ required: true, placeholder: 'Tanggal lahir supir' }}
											initialValue={this.state.tanggal_lahir_supir}
											onChange={(e) => {
												this.gantiTgl(e, 'tanggal_lahir_supir');
											}}
											closeOnSelect={true}
											locale="id-ID"
											timeFormat={false}
											dateFormat="DD/MM/yyyy"
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Pengikut</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="pengikut"
											type="text"
											defaultValue=""
											placeholder="Contoh : ridho / alief / tidak ada"
											required
											onFocus={() => this.setActiveInput('pengikut')}
											value={this.state.input['pengikut'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Barang Bawaan</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="barang_bawaan"
											type="text"
											defaultValue=""
											placeholder="Contoh : laptop / uang cash 400 juta / tidak ada"
											required
											onFocus={() => this.setActiveInput('barang_bawaan')}
											value={this.state.input['barang_bawaan'] || ''}
											onChange={(e) => this.onChangeInput(e)}
										/>
									</Col>
								</FormGroup>
							</div>

							<div className="modal-footer" style={footerStyle}>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-primary">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>

					{/* {this.state.regis ? (
						<div style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 4, left: 0 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
						<div />
					)} */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
						// background: 'linear-gradient(135deg, #72ec76, #86ff01)'
						// background: 'linear-gradient(135deg, #212529, #007bff)'
						// background: 'linear-gradient(135deg, #339ce8, #4ec573)'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/surat-jalan.png')}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p
								className="mb-0"
								style={{
									fontSize: '20px',
									fontWeight: '600',
									color: '#000000'
								}}
							>
								Surat Jalan
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
