import React, { useEffect, useState, useContext } from 'react'
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import Datepicker from 'react-datepicker';
import Select from 'react-select';

import { Post, Get, Delete, cekLogin } from '../../function/Koneksi';
import EditKelahiran from './kelahiran';
import EditKeteranganJalan from './jalan';
import EditKematian from './kematian';
import EditSkgr from './skgr';
import EditTidakMampu from './tidak-mampu';
import EditPernahMenikah from './pernah-menikah';
import EditTidakMenikah from './tidak-menikah';
import EditKehilangan from './kehilangan';
import EditBelumMenikah from './belum-menikah';
import EditPindahNikah from './pindah-nikah';
import EditUsaha from './usaha';
import EditDomisiliUsaha from './domisili-usaha';
import EditPenghasilan from './penghasilan';
import EditKepemilikanRumah from './kepemilikan-rumah';
import EditSkck from './skck';
import EditDomisili from './domisili';
import EditBelumMemilikiRumah from './belum-memiliki-rumah';
import EditAhliWaris from './ahli-waris';
import EditUmum from './umum';
import EditKeramaian from './keramaian';
import EditKuasa from './kuasa';
import EditUndangan from './undangan';

function ModalUpdate({ jenis_surat, detail_surat_context }) {

  switch (jenis_surat) {
    case 'Surat Keterangan Kelahiran':
      return (
        <div>
          <EditKelahiran detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Keterangan Jalan':
      return (
        <div>
          <EditKeteranganJalan detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Keterangan Kematian':
      return (
        <div>
          <EditKematian detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Kuasa SKGR':
      return (
        <div>
          <EditSkgr detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Keterangan Tidak Mampu':
      return (
        <div>
          <EditTidakMampu detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Keterangan Pernah Menikah':
      return (
        <div>
          <EditPernahMenikah detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Pernyataan Tidak Menikah':
      return (
        <div>
          <EditTidakMenikah detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Pernyataan Kehilangan':
      return (
        <div>
          <EditKehilangan detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Pernyataan Belum Menikah':
      return (
        <div>
          <EditBelumMenikah detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Pengantar Pindah Nikah':
      return (
        <div>
          <EditPindahNikah detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Keterangan Usaha':
      return (
        <div>
          <EditUsaha detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Keterangan Penghasilan':
      return (
        <div>
          <EditPenghasilan detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Keterangan Kepemilikan Rumah':
      return (
        <div>
          <EditKepemilikanRumah detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Pengantar SKCK':
      return (
        <div>
          <EditSkck detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Keterangan Domisili':
      return (
        <div>
          <EditDomisili detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Keterangan Domisili Usaha':
      return (
        <div>
          <EditDomisiliUsaha detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Keterangan Belum Memiliki Rumah':
      return (
        <div>
          <EditBelumMemilikiRumah detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Keterangan Ahli Waris':
      return (
        <div>
          <EditAhliWaris detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Keterangan':
      return (
        <div>
          <EditUmum detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Izin Keramaian':
      return (
        <div>
          <EditKeramaian detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Kuasa':
      return (
        <div>
          <EditKuasa detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    case 'Surat Undangan':
      return (
        <div>
          <EditUndangan detail_surat_context={detail_surat_context} />
        </div>
      )
      break;

    default:
      return (
        <div>

        </div>
      )
      break;
  }

}

export default ModalUpdate