import React, { Component } from "react";
import { Link } from "react-router-dom";
import "../assets/css/site.css";

import SweetAlert from "react-bootstrap-sweetalert";
// import {DESA} from "../function/Koneksi"
import {
  Nama_DESA,
  DESA,
  NAMA_KABUPATEN,
  PROVINSI,
  KECAMATAN
} from "../function/Koneksi";

// koneksi
import { Post, Get, Delete, cekLogin, LAYANAN_DESA } from "../function/Koneksi";
import { DataToken } from "../function/Format";

let LIST_LAYANAN = JSON.parse(LAYANAN_DESA);
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      layanan: [],
      perizinan: [],
      jumlahMenungguEksekusi: 0,
      jumlahSelesai: 0,
      jumlahCatatanMenungguEksekusi: 0,
      jumlahVerifikasiMenungguEksekusi: 0,
      jumlahPengaduanDiajukan: 0,
      menuUtama: "inherit",
      menuWilayah: "none",
      menuPengaturan: "none",
      menuPerangkat: "none",
      menuLaporan: "none",
      menuAdministrasi: "none",
      menuSuku: "none",
      menuLayanan: "none",
      menuPerubahanData: "none",
      akun: DataToken(sessionStorage.getItem("access_token"))
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  getData = (fil = null) => {
    if (fil) {
      let filJenisSurat = {
        nama: fil
      };
      Post("jenis-surat/list", null, filJenisSurat, data => {
        console.log(data);
        let layanan = [];
        let perizinan = [];
        data.data.results.map(dt => {
          if (dt.kategori == "Layanan Masyarakat") {
            layanan.push(dt);
          }
          if (dt.kategori == "Perizinan Masyarakat") {
            perizinan.push(dt);
          }
        });
        this.setState({ layanan, perizinan });
      });
    } else {
      Get("jenis-surat/list", null, data => {
        let layanan = [];
        let perizinan = [];
        data.results.map(dt => {
          if (dt.kategori == "Layanan Masyarakat") {
            layanan.push(dt);
          }
          if (dt.kategori == "Perizinan Masyarakat") {
            perizinan.push(dt);
          }
        });
        this.setState({ layanan, perizinan });
      });
    }
  };

  componentDidMount() {
    LIST_LAYANAN = JSON.parse(localStorage.getItem("LAYANAN_DESA"));
    this.getData();

    Get("list-pengajuan-layanan/menunggu-eksekusi", null, dtkat => {
      console.log(dtkat);
      this.setState({ jumlahMenungguEksekusi: dtkat.results.total });
    });

    Get("list-pengajuan-layanan/selesai", null, dtkat => {
      // console.log(dtkat);
      this.setState({ jumlahSelesai: dtkat.results.total });
    });

    Get("pengaduan?status=Diajukan", null, dtkat => {
      this.setState({ jumlahPengaduanDiajukan: dtkat.results.total });
    });

    Get("catatan-penduduk?status=0", null, dtkat => {
      console.log(dtkat);
      this.setState({ jumlahCatatanMenungguEksekusi: dtkat.results.total });
    });

    Get("pengguna?status=1", null, dtkat => {
      // console.log(dtkat);
      this.setState({
        jumlahVerifikasiMenungguEksekusi: dtkat.results.total
      });
    });

    // console.log(this.state.akun);
  }

  render() {
    console.log("List Layanan");
    console.log(LIST_LAYANAN);
    const tampilLayanan = this.state.layanan.map(dt => {
      let symbol = "";
      let judul = "";
      let condition = false;

      switch (dt.nama.toLowerCase()) {
        case "surat keterangan tidak mampu":
          symbol = "TM";
          judul = "Tidak Mampu";

          if (LIST_LAYANAN != null && LIST_LAYANAN.tidak_mampu) {
            condition = true;
          }
          break;

        case "surat keterangan domisili":
          symbol = "D";
          judul = "Domisili";

          if (LIST_LAYANAN != null && LIST_LAYANAN.domisili) {
            condition = true;
          }
          break;

        case "surat keterangan domisili usaha":
          symbol = "DU";
          judul = "Domisili Usaha";

          if (LIST_LAYANAN != null && LIST_LAYANAN.domisili_usaha) {
            condition = true;
          }
          break;

        case "surat keterangan penghasilan":
          symbol = "P";
          judul = "Penghasilan";

          if (LIST_LAYANAN != null && LIST_LAYANAN.penghasilan) {
            condition = true;
          }
          break;

        case "surat keterangan kelahiran":
          symbol = "KL";
          judul = "Kelahiran";

          if (LIST_LAYANAN != null && LIST_LAYANAN.kelahiran) {
            condition = true;
          }
          break;

        case "surat keterangan kematian":
          symbol = "KM";
          judul = "Kematian";

          if (LIST_LAYANAN != null && LIST_LAYANAN.kematian) {
            condition = true;
          }
          break;

        case "surat keterangan ahli waris":
          symbol = "AW";
          judul = "Ahli Waris";

          if (LIST_LAYANAN != null && LIST_LAYANAN.ahli_waris) {
            condition = true;
          }
          break;

        case "surat keterangan kepemilikan rumah":
          symbol = "KPR";
          judul = "Kepemilikan Rumah";

          if (LIST_LAYANAN != null && LIST_LAYANAN.kepemilikan_rumah) {
            condition = true;
          }
          break;

        case "surat keterangan belum memiliki rumah":
          symbol = "BMR";
          judul = "Belum Memiliki Rumah";

          if (LIST_LAYANAN != null && LIST_LAYANAN.belum_memiliki_rumah) {
            condition = true;
          }
          break;

        case "surat keterangan usaha":
          symbol = "U";
          judul = "Usaha";

          if (LIST_LAYANAN != null && LIST_LAYANAN.usaha) {
            condition = true;
          }
          break;

        case "surat kuasa":
          symbol = "SK";
          judul = "Surat Kuasa";

          if (LIST_LAYANAN != null && LIST_LAYANAN.kuasa) {
            condition = true;
          }
          break;

        case "surat kuasa skgr":
          symbol = "SKGR";
          judul = "Surat SKGR";

          if (LIST_LAYANAN != null && LIST_LAYANAN.skgr) {
            condition = true;
          }
          break;

        case "surat pengantar skck":
          symbol = "SKCK";
          judul = "Pengantar SKCK";

          if (LIST_LAYANAN != null && LIST_LAYANAN.skck) {
            condition = true;
          }
          break;

        case "surat pernyataan kehilangan":
          symbol = "PH";
          judul = "Pernyataan Kehilangan";

          if (LIST_LAYANAN != null && LIST_LAYANAN.kehilangan) {
            condition = true;
          }
          break;

        case "surat keterangan jalan":
          symbol = "SJ";
          judul = "Surat Jalan";

          if (LIST_LAYANAN != null && LIST_LAYANAN.jalan) {
            condition = true;
          }
          break;

        case "surat pernyataan tidak menikah":
          symbol = "TN";
          judul = "Tidak Nikah";

          if (LIST_LAYANAN != null && LIST_LAYANAN.tidak_menikah) {
            condition = true;
          }
          break;

        case "surat keterangan pernah menikah":
          symbol = "PN";
          judul = "Pernah Nikah";

          if (LIST_LAYANAN != null && LIST_LAYANAN.pernah_menikah) {
            condition = true;
          }
          break;

        case "surat pernyataan belum menikah":
          symbol = "BN";
          judul = "Belum Nikah";

          if (LIST_LAYANAN != null && LIST_LAYANAN.belum_menikah) {
            condition = true;
          }
          break;

        case "surat pengantar pindah nikah":
          symbol = "PIN";
          judul = "Pindah Nikah";

          if (LIST_LAYANAN != null && LIST_LAYANAN.pindah_menikah) {
            condition = true;
          }
          break;

        case "surat izin keramaian":
          symbol = "IK";
          judul = "Izin Keramaian";

          if (LIST_LAYANAN != null && LIST_LAYANAN.keramaian) {
            condition = true;
          }
          break;

        case "surat keterangan":
          symbol = "UM";
          judul = "Umum";

          if (LIST_LAYANAN != null && LIST_LAYANAN.umum) {
            condition = true;
          }
          break;

        case "surat undangan":
          symbol = "UN";
          judul = "Undangan";

          if (LIST_LAYANAN != null && LIST_LAYANAN.undangan) {
            condition = true;
          }
          break;
        default:
          break;
      }

      // console.log(dt);
      // console.log(dt.nama);
      let link = dt.nama.toLowerCase().replace(/ /g, "-");

      if (condition) {
        return (
          <div className="col-sm-6 col-md-12 col-lg-4" style={{ padding: 5 }}>
            <Link to={{ pathname: "/admin/" + link, state: dt }}>
              <div className="dashboard-over">
                <b>
                  <span>{symbol}</span>
                  <h6>{judul}</h6>
                </b>
              </div>
            </Link>
          </div>
        );
      } else {
        return "";
      }
    });

    let bannerAplikasi = (
      <img
        src={require("../assets/images/banner/kampung-tengah.png")}
        alt=""
        style={{
          maxHeight: "250px",
          width: "100%",
          borderRadius: "10px"
        }}
      />
    )

    switch (localStorage.getItem('JENIS_APLIKASI').toLowerCase()) {
      case 'halaman kampung':
        bannerAplikasi = (
          <img
            src={require("../assets/images/banner/sungai-petai.png")}
            alt=""
            style={{
              maxHeight: "250px",
              width: "100%",
              borderRadius: "10px"
            }}
          />
        )
        break;
    }

    return (
      <div>
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>
        {this.state.alert}
        <div className="container-fluid">
          {/* <div className="row"> */}
          {this.state.akun.sub.data_role.nama_role == "Admin" ? (
            <div className="row">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-12 col-md-6 col-lg-8">
                    <div className="card layanan-baru">
                      <div className="card-body">
                        <div className="row">{tampilLayanan}</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <div className="row">
                      <div className="col-sm-12 col-md-12">
                        <div className="card" style={{ borderRadius: "10px" }}>
                          <div className="text-center">
                            {bannerAplikasi}
                          </div>
                        </div>
                      </div>
                      {/* pengajuan selesai */}
                      <div className="col-sm-12 col-md-12">
                        <Link
                          to={{
                            pathname:
                              process.env.PUBLIC_URL + "/admin/disposisi",
                            tabProps: "5"
                          }}
                        >
                          <div className="pengajuan-selesai">
                            <b>
                              <span>
                                {/* <b>24%</b> */}
                                {this.state.jumlahSelesai}
                              </span>
                              <h4>Pengajuan Selesai</h4>
                              {/* <span>100 Pengajuan</span> */}
                            </b>
                          </div>
                        </Link>
                      </div>
                      {/* pengajuan menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <Link
                          to={{
                            pathname:
                              process.env.PUBLIC_URL + "/admin/disposisi",
                            tabProps: "2"
                          }}
                        >
                          <div className="pengajuan-menunggu-eksekusi">
                            <b>
                              <span>{this.state.jumlahMenungguEksekusi}</span>
                              <h4>Pengajuan</h4>
                              <h6>Menunggu Eksekusi</h6>
                            </b>
                          </div>
                        </Link>
                      </div>
                      {/* pengaduan menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <div className="pengaduan-menunggu-eksekusi">
                          <b>
                            <span>{this.state.jumlahPengaduanDiajukan}</span>
                            <h4>Pengaduan</h4>
                            <h6>Diajukan</h6>
                          </b>
                        </div>
                      </div>
                      {/* catatan menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <Link
                          to={{
                            pathname: process.env.PUBLIC_URL + "/admin/catatan",
                            tabProps: "1"
                          }}
                        >
                          <div className="catatan-menunggu-eksekusi">
                            <b>
                              <span>
                                {this.state.jumlahCatatanMenungguEksekusi}
                              </span>
                              <h4>Catatan</h4>
                              <h6>Belum Selesai</h6>
                            </b>
                          </div>
                        </Link>
                      </div>
                      {/* verifikasi menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <Link
                          to={{
                            pathname:
                              process.env.PUBLIC_URL + "/admin/verifikasi",
                            tabProps: "2"
                          }}
                        >
                          <div className="verifikasi-menunggu-eksekusi">
                            <b>
                              <span>
                                {this.state.jumlahVerifikasiMenungguEksekusi}
                              </span>
                              <h4>Verifikasi</h4>
                              <h6>Menunggu Verifikasi</h6>
                            </b>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="row" style={{ marginTop: "50px" }}>
                  <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                    <a
                      href="https://solusidatamadani.co.id/web"
                      target="_blank"
                    >
                      <div style={{ color: "#707070", textAlign: "center" }}>
                        <img
                          src={require("../assets/images/esdeem.png")}
                          alt=""
                          style={{ maxWidth: "70%", maxHeight: "60px" }}
                        />
                      </div>
                    </a>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                    <div
                      style={{
                        color: "#707070",
                        textAlign: "center",
                        wordWrap: "break-word"
                      }}
                    >
                      <h5>
                        <b>PT. Solusi Data Madani</b>
                      </h5>
                      <h6>We Make IT Easier</h6>
                      <h7>2021 All Rights Reserved</h7>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                    <div
                      style={{
                        color: "#707070",
                        textAlign: "center",
                        wordWrap: "break-word"
                      }}
                    >
                      <h5>
                        <b>Hubungi Kami</b>
                      </h5>
                      <a
                        href="tel: (0761) 849 861"
                        target="_blank"
                        style={{ color: "#707070" }}
                      >
                        <h6>(0761) 849 861</h6>
                      </a>
                      <a
                        href="mailto: info@solusidatamadani.co.id"
                        target="_blank"
                        style={{ color: "#707070" }}
                      >
                        <h7>info@solusidatamadani.co.id</h7>
                      </a>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3 mb-4">
                    <div
                      style={{
                        color: "#707070",
                        textAlign: "center",
                        wordWrap: "break-word"
                      }}
                    >
                      <span style={{ fontSize: "30px" }}>
                        <a
                          href="https://wa.me/6282392462636"
                          target="_blank"
                          style={{ color: "#707070" }}
                        >
                          <i className="icofont icofont-brand-whatsapp"></i>{" "}
                        </a>
                        <a
                          href="https://www.instagram.com/digidesa"
                          target="_blank"
                          style={{ color: "#707070" }}
                        >
                          <i className="fa fa-instagram"></i>
                        </a>
                      </span>
                      <a
                        href="https://www.digidesa.com"
                        target="_blank"
                        style={{ color: "#707070" }}
                      >
                        <h6>www.digidesa.com</h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-sm-12">
                <div
                  className="card"
                  style={{
                    backgroundColor: "#f6f7fb"
                  }}
                >
                  <div className="card-body">
                    <div className="row">
                      {/* pengajuan selesai */}
                      <div className="col-sm-12 col-md-12">
                        <div className="pengajuan-selesai">
                          <b>
                            <span>
                              {/* <b>24%</b> */}
                              {this.state.jumlahSelesai}
                            </span>
                            <h4>Pengajuan Selesai</h4>
                            {/* <span>100 Pengajuan</span> */}
                          </b>
                        </div>
                      </div>
                      {/* pengajuan menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <div className="pengajuan-menunggu-eksekusi">
                          <b>
                            <span>{this.state.jumlahMenungguEksekusi}</span>
                            <h4>Pengajuan</h4>
                            <h6>Menunggu Eksekusi</h6>
                          </b>
                        </div>
                      </div>
                      {/* pengaduan menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <div className="pengaduan-menunggu-eksekusi">
                          <b>
                            <span>{this.state.jumlahPengaduanDiajukan}</span>
                            <h4>Pengaduan</h4>
                            <h6>Diajukan</h6>
                          </b>
                        </div>
                      </div>
                      {/* catatan menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <div className="catatan-menunggu-eksekusi">
                          <b>
                            <span>
                              {this.state.jumlahCatatanMenungguEksekusi}
                            </span>
                            <h4>Catatan</h4>
                            <h6>Belum Selesai</h6>
                          </b>
                        </div>
                      </div>
                      {/* verifikasi menunggu eksekusi */}
                      <div className="col-sm-6 col-md-6">
                        <div className="verifikasi-menunggu-eksekusi">
                          <b>
                            <span>
                              {this.state.jumlahVerifikasiMenungguEksekusi}
                            </span>
                            <h4>Verifikasi</h4>
                            <h6>Menunggu Verifikasi</h6>
                          </b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    );
  }
}

export default Dashboard;