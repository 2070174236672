import React, { Component } from 'react';
import { Col, FormGroup, Label, Row, Form } from 'reactstrap';
import Modal from 'react-responsive-modal';
import { Get, JENIS_DESA, JENIS_DUSUN, Post } from '../function/Koneksi';
// import Keyboard from 'react-simple-keyboard';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

export default class SuratKepemilikanRumah extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: {
				form: false,
				btnForm: true,
				btnAksi: [],
				keyboard: false
			},
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			successAlert: false,
			// tanggal_mulai: new Date(),
			// tanggal_selesai: null

			//keyboard
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: {},
			inputPattern: /^\d+$/,
			display: 'none',
			focus: false,
			hasFocus: false,
			style: {}
		};

		this.showKeyboard = this.showKeyboard.bind(this);
	}

	handleClick = (event) => {
		alert(event);
	};

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		this.state.status.btnForm = true;
		this.forceUpdate();

		let alamat = '';
		if (JENIS_DESA.toLowerCase() == 'kelurahan') {
			alamat =
				' RT. ' +
				this.props.data.results.data_kk.data_rt.nama +
				'/RW. ' +
				this.props.data.results.data_kk.data_rt.data_rw.nama +
				', ' +
				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
				', Kec. ' +
				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
				', ' +
				this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
		} else {
			// alamat =
			// 	' RT. ' +
			// 	this.props.data.results.data_kk.data_rt.nama +
			// 	'/RW. ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 	', ' +
			// 	JENIS_DUSUN +
			// 	' ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 	', ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 	', Kec. ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
			// 	', ' +
			// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

				if (this.props.data.results.data_kk.id_rt == '0') {
					alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
					", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
					", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
					", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
				} else {
					alamat =
					" RT. " + 
					this.props.data.results.data_kk.data_rt.nama +
					"/RW. " +
					this.props.data.results.data_kk.data_rt.data_rw.nama +
					", " + JENIS_DUSUN + " " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
					", " + JENIS_DESA + " " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
					", Kec. " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.nama +
					", " +
					this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
						.data_kecamatan.data_kabkota.nama; 
				}
		}

		let addData = {
			id_jenis: this.props.id_jenis,
			// nomor : document.getElementById("nomor").value,
			nik: this.props.data.results.id,
			kk: this.props.data.results.id_kk,
			nama_pemohon: this.props.data.results.nama,
			tempat_lahir: this.props.data.results.tempat_lahir,
			tanggal_lahir: this.props.data.results.tanggal_lahir,
			jenis_kelamin: this.props.data.results.jk,
			pekerjaan: this.props.data.results.data_pekerjaan.nama,
			pendidikan_terakhir: this.props.data.results.data_pendidikan_terakhir.nama,
			kewarganegaraan: 'Indonesia',
			agama: this.props.data.results.data_agama.nama,
			alamat: alamat,
			status_perkawinan: this.props.data.results.status_perkawinan,
			tujuan_pembuatan: document.getElementById('alamat_rumah').value,
			// tujuan_pembuatan : "-",
			status_ttd: null
		};

		let psn = 'Tambah';
		let resstat = 201;
		let metode = 'create';

		Post('surat-keterangan/grup/' + metode, null, addData, (res) => {
			this.onCloseModal('Basic');
			console.log(res);

			let dataAntrian = {
				nama: this.props.data.results.nama,
				nik: this.props.data.results.id,
				keterangan: '',
				uuid_surat:res.data.results,
			};

			if (res.status === resstat) {
				Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
					console.log("Hasil Update Status")
					console.log(res2)
					if (res2.code === 201) {
						console.log("Berhasil Ganti Status Menjadi Selesai")

						this.props.getAntrian('Surat Keterangan Kepemilikan Rumah', 'berhasil', false, dataAntrian);
					}
					else{
						console.log("Gagal Ganti Status Menjadi Selesai")

						this.props.getAntrian('Surat Keterangan Kepemilikan Rumah', 'gagal');
					}
				});
				// this.props.getAntrian('Surat Keterangan Kepemilikan Rumah', 'berhasil', false, dataAntrian);
			} else {
				this.props.getAntrian('Surat Keterangan Kepemilikan Rumah', 'gagal');
			}
			this.componentDidMount();
			// reset cache
			document.getElementById('alamat_rumah').value = '';
		});
	};

	componentDidMount() {
		// const context = useContext(this.props.context);
		// context.getNomorAntrian();
		// console.log(this.props.location);
	}

	// EMBED KEyBOARD

	onOpenModal = (key) => {
		console.log(key);
		console.log(this.props.data.results);
		this.setState({
			[key]: true,
			regis: true
		});
	};

	onCloseModal = (key) => {
		this.setState({
			[key]: false,
			display: 'none',
			regis: 'false',
			input: {}
		});
	};

	// on focus
	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				console.log('Active input', inputName);
			}
		);
	};

	// end on focus

	// ketika Klik Keyboard atau mengisi data
	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		console.log('Input changed', inputObj);
	};

	onKeyPress = (button) => {
		console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	// End ketika Klik Keyboard atau mengisi data

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}
		// this.setState({
		// 	display: '',
		// 	// onFocus:true

		//   });
		this.forceUpdate();
	};

	showKeyboard1 = () => {
		this.setState({
			display: 'none',
			regis: 'false'
		});
		this.forceUpdate();
	};

	// Input keyboard
	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				} else {
				}
			}
		);
	};

	onChangeInput1 = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			}
		);
	};

	// end input keyboard

	// END EMBED KEYBOARD

	render() {
		const footerStyle = {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		};

		// console.log('Data Parsing');
		// console.log(this.props.data.results);
		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const { Basic } = this.state;

		return (
			<div>
				<Modal
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					// styles={{ modal: { marginBottom: '200px', width: '80%' } }}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title" align="center">
								Surat Keterangan Kepemilikan Rumah
							</h5>
						</div>

						<div className="modal-body">
							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label">No Antrian Anda</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="no_antrian_anda"
										disabled
										type="text"
										defaulValue="0010"
									/>
								</Col>
							</FormGroup> */}

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label">NIK Pemohon</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nik"
										disabled
										type="text"
										placeholder="NIK"
										value={this.props.data.results.id}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label">Nama Lengkap Pemohon</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_pemohon"
										disabled
										type="text"
										placeholder=" Nama Lengkap"
										value={this.props.data.results.nama}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label">Alamat Rumah</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="alamat_rumah"
										type="text"
										placeholder="Alamat Rumah"
										defaultValue=""
										required
										autoFocus
										onFocus={() => this.setActiveInput('input4')}
										value={this.state.input['input4'] || ''}
										onChange={(e) => this.onChangeInput(e)}
									/>
								</Col>
							</FormGroup>
						</div>

						<div className="modal-footer" style={footerStyle}>
							<button
								type="button"
								className="btn btn-warning"
								onClick={() => this.onCloseModal('Basic')}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-primary">
								Buat Surat
							</button>
						</div>
					</Form>

					{/* {this.state.regis ? (
						<div style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 4, left: 0 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
						<div />
					)} */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
						// background: 'linear-gradient(135deg, #339ce8, #4ec573)'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/kepemilikan-rumah.png')}
								height="65"
								alt="logo"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p className="mb-0" style={{ fontSize: '20px', fontWeight: '600', color: '#000000' }}>
								Kepemilikan Rumah
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
