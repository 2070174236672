import React, { Component } from 'react';

// import Custom Componenets
import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
	PaginationProvider,
	PaginationTotalStandalone,
	PaginationListStandalone
} from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Input, FormText, ButtonGroup, CustomInput, Row, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { Post, Get, Delete, cekLogin, JENIS_DESA, JENIS_DUSUN, ID_DESA } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');

const frmDef = {
	id: 0,
	idbaru: '',
	id_keldes: '',
	id_dusun: '',
	id_rw: '',
	nama: ''
};
class BasicTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false,
				select: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: frmDef,
			judul: 'Tambah RW',
			// =========== Select 2 ============
			frmPrv: [],
			prvStat: [],
			frmKbkt: [],
			kbktStat: [],
			frmKec: [],
			kecStat: [],
			frmKlds: [],
			kldsStat: [],
			frmDsn: [],
			dsnStat: [],
			isKelurahan: false,
			// =================================
			// =========== Alert ============
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: ''
			// ==============================
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== Ambil data dari db untuk table ==================
	fetch = (params = {}) => {
		let link = '';
		let where = [];
		if (params.where) {
			where = params.where;
		}
		if (params.page) {
			switch (params.page) {
				case '>>':
					link = '?page=' + Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
					break;
				case '>':
					link = '?page=' + (parseInt(this.state.pagination.current_page) + 1);
					break;
				case '<':
					link = '?page=' + (parseInt(this.state.pagination.current_page) - 1);
					break;
				case '<<':
					link = '?page=1';
					break;
				default:
					link = '?page=' + params.page;
					break;
			}
		}
		Get('rw' + link, null, (dtkat) => {
			this.setState({ data: dtkat.results.data, pagination: dtkat.results });
			this.pagination(dtkat.results);
		});
	};

	// Pagination
	pagination = (data) => {
		// const data = this.state.pagination;
		console.log(data);
		let awal = '';
		let hal = [];
		let sebelum = '';
		let setelah = '';
		let akhir = '';
		if (data.total > 0) {
			let start = 1;
			let end = 5;
			let n = 0;
			let p = 0;
			if (data.current_page <= 3) {
				start = 1;
				end = 5;
				if (data.last_page > data.current_page) {
					n = data.current_page + 1;
				}
			} else {
				p = data.current_page - 1;
				n = data.current_page + 1;
				start = data.current_page - 2;
				end = data.current_page + 2;
			}
			if (end >= data.last_page - 2) {
				p = data.current_page - 1;
				if (start >= 5) {
					start = data.last_page - 4;
				}
				end = data.last_page;
			}

			for (let i = start; i <= end; i++) {
				let warna = 'success';
				if (i == data.current_page) {
					warna = 'danger';
				}
				hal.push(
					<a
						onClick={() => this.fetch({ page: i })}
						className={'btn btn-' + warna + ' btn-square btn-sm pr-3 pl-3 pt-2 pb-2'}
					>
						{i}
					</a>
				);
			}
			if (p > 0) {
				sebelum = (
					<a
						onClick={() => this.fetch({ page: p })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'<'}
					</a>
				);
			}
			if (n > 0) {
				setelah = (
					<a
						onClick={() => this.fetch({ page: n })}
						className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
					>
						{'>'}
					</a>
				);
			}
			awal = (
				<a
					onClick={() => this.fetch({ page: 1 })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'<<'}
				</a>
			);
			akhir = (
				<a
					onClick={() => this.fetch({ page: data.last_page })}
					className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
				>
					{'>>'}
				</a>
			);
		}
		this.setState({ awal, sebelum, hal, setelah, akhir });
	};
	// Cari Data Table
	cariData = (e) => {
		if (e.key == 'Enter') {
			let addRW = {
				nama: e.target.value
			};
			Post('rw', null, addRW, (data) => {
				this.setState({ data: data.data.results.data, pagination: data.data.results });
			});
		}
	};

	// ========================================================================

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		let validation = false
		
			validation = true
			this.state.status.btnForm = true;
			this.forceUpdate();

			let id_keldes = ID_DESA

			let id = parseInt(document.getElementById('id').value);
			let addRW = {
				id: this.state.dt.id_dusun + document.getElementById('idbaru').value,
				id_keldes,
				nama: document.getElementById('nama').value
			};

			// Jika bukan kelurahan, tambahkan id dusun
			if (!this.state.isKelurahan) {
				if (this.state.dt.id_dusun != '') {
					addRW.id_dusun = this.state.dt.id_dusun
					validation = true
				} else {
					validation = false
				}
			} else {
				addRW.id = id_keldes + document.getElementById('idbaru').value
			}

			let psn = '';
			let resstat = 204;
			let metode = 'create';
			if (id === 0) {
				psn = 'Tambah';
				resstat = 201;
				id = null;
			} else {
				psn = 'Ubah';
				resstat = 200;
				metode = 'update';
			}

			if (validation) {
				Post('rw/' + metode, id, addRW, (res) => {
					this.state.status.btnForm = false;
					this.state.status.form = false;
					this.forceUpdate();
					if (res.status === resstat) {
						this.setState({
							show: true,
							basicType: 'success',
							basicTitle: 'Data RW',
							pesanAlert: 'Berhasil ' + psn + ' Data'
						});
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Data RW',
							pesanAlert: 'Gagal ' + psn + ' Data'
						});
					}
					this.componentDidMount();
				});
			} else {
				this.setState({
					show: true,
					basicType: 'warning',
					basicTitle: 'Data RW',
					pesanAlert: 'Isi seluruh data yg diperlukan dengan benar'
				});
			}
	};
	// ========================================================================

	// ============================== Ubah Data ===============================

	ubahData = (id) => {
		this.state.status.btnAksi = true;
		this.state.judul = 'Ubah RW';
		this.forceUpdate();
		Get('rw/find', id, (data) => {
			this.state.dt.id = data.results.id;
			if (!this.state.isKelurahan) {
				this.state.dt.idbaru = data.results.id
				.toString()
				.substring(
					data.results.id.toString().length -
						(data.results.id.toString().length - data.results.id_dusun.toString().length)
				);
			} else {
				this.state.dt.idbaru = data.results.id
					.toString()
					.substring(
						data.results.id.toString().length -
							(data.results.id.toString().length - ID_DESA.toString().length)
					);
			}
				
			this.state.dt.id_keldes = data.results.id_keldes;
			
			if (!this.state.isKelurahan) {
				this.state.dt.id_dusun = data.results.id_dusun;
			}

			this.state.dt.nama = data.results.nama;
			this.state.status.btnAksi = false;
			this.state.status.form = true
			this.forceUpdate();
			console.log(this.state.dt.idbaru);

			if (!this.state.isKelurahan) {
				this.ubahPilihan('dusun', data.results.data_dusun);
			}
			
		});
	};
	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		Delete('rw/delete', id, (res) => {
			if (res == 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data RW',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data RW',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.state.status.form = true;
		this.forceUpdate();
	};

	tutupForm = () => {
		this.state.status.form = false;
		this.forceUpdate();
	};

	tombolAksi = (cell, row) => {
		return (
			<>
				<Button
					size="xs"
					color="success"
					className="btn-icon"
					onClick={() => this.ubahData(row.id)}
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-pencil" />
				</Button>{' '}
				&nbsp;&nbsp;
				<Button
					size="xs"
					color="danger"
					className="btn-icon"
					onClick={() => this.konfirmHapus(row.id)}
					disabled={this.state.status.btnAksi}
				>
					<i className="fa fa-trash" />
				</Button>
			</>
		);
	};

	getChild = (e, self, parent, child) => {
		this.state.status.select = true;
		this.forceUpdate();
		this.changeSelectValue(e, self);
		if (e) {
			Get(child + '/' + parent + '/' + e.value + '/list', null, (data) => {
				let frm = [];
				data.results.map((dt) => {
					frm.push({ value: dt.id, label: dt.nama });
				});
				switch (child) {
					case 'kab-kota':
						this.setState({ frmKbkt: frm, frmKec: [], frmKlds: [], frmDsn: [] });
						break;
					case 'kecamatan':
						this.setState({ frmKec: frm, frmKlds: [], frmDsn: [] });
						break;
					case 'keldes':
						this.setState({ frmKlds: frm, frmDsn: [] });
						break;
					case 'dusun':
						this.setState({ frmDsn: frm });
						break;
				}
				this.state.status.select = false;
				this.forceUpdate();
			});
		}
	};

	ubahPilihan = (table, data) => {
		switch (table) {
			case 'dusun':
				this.getChild(
					{
						value: data.data_keldes.data_kecamatan.data_kabkota.data_provinsi.id,
						label: data.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama
					},
					'provinsi',
					'prov',
					'kab-kota'
				);
				this.getChild(
					{
						value: data.data_keldes.data_kecamatan.data_kabkota.id,
						label: data.data_keldes.data_kecamatan.data_kabkota.nama
					},
					'kab-kota',
					'kab',
					'kecamatan'
				);
				this.getChild(
					{ value: data.data_keldes.data_kecamatan.id, label: data.data_keldes.data_kecamatan.nama },
					'kecamatan',
					'kec',
					'keldes'
				);
				this.getChild({ value: data.data_keldes.id, label: data.data_keldes.nama }, 'keldes', 'kel', 'dusun');
				this.getChild({ value: data.id, label: data.nama }, 'dusun', 'dus', 'rw');
				this.setState({
					prvStat: {
						value: data.data_keldes.data_kecamatan.data_kabkota.data_provinsi.id,
						label: data.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama
					},
					kbktStat: {
						value: data.data_keldes.data_kecamatan.data_kabkota.id,
						label: data.data_keldes.data_kecamatan.data_kabkota.nama
					},
					kecStat: { value: data.data_keldes.data_kecamatan.id, label: data.data_keldes.data_kecamatan.nama },
					kldsStat: { value: data.data_keldes.id, label: data.data_keldes.nama },
					dsnStat: { value: data.id, label: data.nama }
				});
				this.bukaForm();
				break;
		}
	};

	changeSelectValue = (e, sel) => {
		if (e) {
			switch (sel) {
				case 'provinsi':
					this.setState({ prvStat: e, kbktStat: [], kecStat: [], kldsStat: [], dsnStat: [] });
					break;
				case 'kab-kota':
					this.setState({ kbktStat: e, kecStat: [], kldsStat: [], dsnStat: [] });
					break;
				case 'kecamatan':
					this.setState({ kecStat: e, kldsStat: [], dsnStat: [] });
					break;
				case 'keldes':
					this.state.dt.id_keldes = e.value;
					this.setState({ kldsStat: e, dsnStat: [] });
					break;
				case 'dusun':
					this.state.dt.id_dusun = e.value;
					this.setState({ dsnStat: e });
					break;
			}
		} else {
			switch (sel) {
				case 'provinsi':
					this.setState({
						prvStat: [],
						kbktStat: [],
						kecStat: [],
						kldsStat: [],
						dsnStat: [],
						frmKbkt: [],
						frmKec: [],
						frmKlds: [],
						frmDsn: []
					});
					break;
				case 'kab-kota':
					this.setState({
						kbktStat: [],
						kecStat: [],
						kldsStat: [],
						dsnStat: [],
						frmKec: [],
						frmKlds: [],
						frmDsn: []
					});
					break;
				case 'kecamatan':
					this.setState({ kecStat: [], kldsStat: [], dsnStat: [], frmKlds: [], frmDsn: [] });
					break;
				case 'keldes':
					this.state.dt.id_keldes = 0;
					this.setState({ kldsStat: [], dsnStat: [], frmDsn: [] });
					break;
				case 'dusun':
					this.state.dt.id_dusun = 0;
					this.setState({ dsnStat: [] });
					break;
			}
		}

		this.state.status.select = false;
		this.forceUpdate();
	};

	componentDidMount() {
		if (JENIS_DESA.toLowerCase() == 'kelurahan') {
			this.setState({ isKelurahan: true });
		} else {
			this.getChild({
				value: ID_DESA,
				label: ID_DESA,
			}, 'keldes', 'kel', 'dusun');
		}
		
		this.fetch({ page: null, where: [] });
		Get('provinsi/list', null, (data) => {
			let frmPrv = [];
			data.results.map((dt) => {
				frmPrv.push({ value: dt.id, label: dt.nama });
			});
			this.setState({ frmPrv });
		});
	}

	render() {
		var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		var from = this.state.pagination.from;
		var page = this.state.pagination.current;
		var to = this.state.pagination.to;
		var total = this.state.pagination.total;
		var data = this.state.data;
		const frmDef = {
			id: 0,
			idbaru: '',
			id_keldes: '',
			id_dusun: '',
			id_rw: '',
			nama: ''
		};
		// const { SearchBar } = Search;

		let columns = []

		// Jika bukan kelurahan
		if (!this.state.isKelurahan) {
			columns = [
				{
					dataField: 'id',
					text: 'ID',
					sort: false
				},
				{
					dataField: 'nama_keldes',
					text: 'Kelurahan/' + JENIS_DESA,
					isDummyField: true,
					formatter: (cell, obj) => {
						return obj.data_keldes.nama;
					},
					sort: true
				},
				{
					dataField: 'nama_dusun',
					text: JENIS_DUSUN,
					isDummyField: true,
					formatter: (cell, obj) => {
						return obj.data_dusun.nama;
					},
					sort: true
				},
				{
					dataField: 'nama',
					text: 'RW',
					sort: true
				},
				{
					dataField: 'aksi',
					text: 'Aksi',
					isDummyField: true,
					csvExport: false,
					formatter: this.tombolAksi
				}
			];
		} else {
			columns = [
				{
					dataField: 'id',
					text: 'ID',
					sort: false
				},
				{
					dataField: 'nama_keldes',
					text: 'Kelurahan/' + JENIS_DESA,
					isDummyField: true,
					formatter: (cell, obj) => {
						return obj.data_keldes.nama;
					},
					sort: true
				},
				{
					dataField: 'nama',
					text: 'RW',
					sort: true
				},
				{
					dataField: 'aksi',
					text: 'Aksi',
					isDummyField: true,
					csvExport: false,
					formatter: this.tombolAksi
				}
			];
		}

		const defaultSorted = [
			{
				dataField: 'nama',
				order: 'asc'
			}
		];

		const pageButtonRenderer = ({ page, active, disable, title, onPageChange }) => {
			const handleClick = (e) => {
				e.preventDefault();
				this.fetch({ where: [], page: page });
			};
			var classname = '';
			classname = 'btn btn-success';
			return (
				<li className="page-item pl-1" key={page}>
					<a href="#" onClick={handleClick} className={classname}>
						{page}
					</a>
				</li>
			);
		};

		const options = {
			alwaysShowAllBtns: true,
			pageButtonRenderer,
			// custom: true,
			paginationTotalRenderer: () => (
				<span className="react-bootstrap-table-pagination-total">
					Showing {from} to {to} of {total} Results
				</span>
			),
			paginationSize: size,
			totalSize: total
		};

		return (
			<div>
				{/* <Breadcrumb title="Data RW" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6">
											{/* <h5>Data RW</h5> */}
											{/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({
														judul: 'Tambah RW',
														dt: frmDef,
														prvStat: [],
														kbktStat: [],
														kecStat: []
													});
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div>
									</div>
								</div>
								<div className="card-body datatable-react">
									<BootstrapTable keyField="id" data={data} columns={columns} />
									<div className="pull-right text-white">
										{this.state.awal}
										{this.state.sebelum}
										{this.state.hal.map((dt) => {
											return dt;
										})}
										{this.state.setelah}
										{this.state.akhir}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{
						modal: {width: '80%'}
					}}
				>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input
								className="form-control"
								id="id"
								type="hidden"
								placeholder="RW"
								defaultValue={this.state.dt.id}
							/>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									ID RW
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="idbaru"
										type="text"
										placeholder="ID RW"
										defaultValue={this.state.dt.idbaru}
										required
									/>
								</Col>
							</FormGroup>
							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Provinsi
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getChild(e, 'provinsi', 'prov', 'kab-kota');
										}}
										defaultValue={this.state.prvStat}
										value={this.state.prvStat}
										name="provinsi"
										options={this.state.frmPrv}
										placeholder="Pilih Provinsi"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Kabupaten / Kota
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getChild(e, 'kab-kota', 'kab', 'kecamatan');
										}}
										defaultValue={this.state.kbktStat}
										value={this.state.kbktStat}
										name="kabkota"
										options={this.state.frmKbkt}
										placeholder="Pilih Kabupaten/Kota"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Kecamatan
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											this.getChild(e, 'kecamatan', 'kec', 'keldes');
										}}
										defaultValue={this.state.kecStat}
										value={this.state.kecStat}
										name="kecamatan"
										options={this.state.frmKec}
										placeholder="Pilih Kecamatan"
										isClearable
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									{JENIS_DESA}
								</Label>
								<Col sm="9">
									<Select
										isDisabled={this.state.status.select}
										classNamePrefix="select"
										onChange={(e) => {
											if (!this.state.isKelurahan) {
												this.getChild(e, 'keldes', 'kel', 'dusun');
											} else {
												this.changeSelectValue(e, 'keldes');
											}
										}}
										defaultValue={this.state.kldsStat}
										value={this.state.kldsStat}
										name="keldes"
										options={this.state.frmKlds}
										placeholder={'Pilih ' + JENIS_DESA}
										isClearable
									/>
								</Col>
							</FormGroup> */}
							{!this.state.isKelurahan ? (
								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
										Dusun
									</Label>
									<Col sm="9">
										<Select
											isDisabled={this.state.status.select}
											classNamePrefix="select"
											onChange={(e) => {
												this.changeSelectValue(e, 'dusun');
											}}
											defaultValue={this.state.dsnStat}
											value={this.state.dsnStat}
											name="dusun"
											options={this.state.frmDsn}
											placeholder={'Pilih ' + JENIS_DUSUN}
											isClearable
										/>
									</Col>
								</FormGroup>
							) : ''}
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama RW
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama RW"
										defaultValue={this.state.dt.nama}
										required
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								disabled={this.state.status.btnForm}
								onClick={this.simpan}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default BasicTable;
