import React, { Component, createContext } from "react";

// import Custom Componenets
import Breadcrumb from "./../../../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
import Datetime from 'react-datetime';
// import paginationFactory from 'react-bootstrap-table2-paginator';
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  ButtonGroup,
  CustomInput,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";

import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import API_URL, { Post, Get, Delete, cekLogin, Nama_DESA, JENIS_DESA, JENIS_DUSUN, DESA } from "./../../../function/Koneksi";
import { Tanggal, Hari, HariTanggal, HariTanggalJam } from "./../../../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import ReactDatePicker from "react-datepicker";
import { Link } from "react-router-dom";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  id: 0,
  idbaru: "",
  id_keldes: "",
  nama: ""
};


const dateFormat = require('dateformat');

class DisdukcapilKematian extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Data table
      data: [],
      dataVerifikasi: [],
      dataDisetujui: [],
      dataDitolak: [],
      dataSelesai: [],

      data_detail: [],
      data_download: [],

      // Pagination data table
      pagination: [],
      paginationDitolak: [],
      paginationDiverifikasi: [],
      paginationDisetujui: [],
      paginationSelesai: [],

      status: {
        form: false,
        formTambahVerifikasi: false,
        formKeterangan: false,
        formDetailGambar: false,
        formShowDownload: false,
        btnForm: false,
        btnAksi: false,
        btnAksiTidakGelar: false,
        select: false,
      },

      // Diajukan
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      // Diverifikasi
      awal2: "",
      sebelum2: "",
      hal2: [],
      setelah2: "",
      akhir2: "",
      // Disetujui
      awal3: "",
      sebelum3: "",
      hal3: [],
      setelah3: "",
      akhir3: "",
      // Ditolak
      awal4: "",
      sebelum4: "",
      hal4: [],
      setelah4: "",
      akhir4: "",
      // Selesai
      awal5: "",
      sebelum5: "",
      hal5: [],
      setelah5: "",
      akhir5: "",

      dt: frmDef,
      judul: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: [],
      frmKbkt: [],
      kbktStat: [],
      frmKec: [],
      kecStat: [],
      frmKlds: [],
      kldsStat: [],
      frmAgm: [],
      // =================================
      // =========== Alert ============
      alert: null,
      show: false,
      showCetakSurat: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // ==============================
      active_tab_icon: "1",

      // Cari Data Gelar
      dataCariGelar: {
				nama: ''
			},

      // Detail Layanan
      detailLayanan: [],

      cek_posisi:'Pertama'
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      showCetakSurat: false,
      alert: null
    });
  };

  // Bootstrap tabs function
  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      if(tab == 1){
        this.state.cek_posisi = 'Pertama'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab == 2){
        this.state.cek_posisi = 'Kedua'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab == 3){
        this.state.cek_posisi = 'Ketiga'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab == 4){
        this.state.cek_posisi = 'Keempat'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab == 5){
        this.state.cek_posisi = 'Kelima'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      
      this.setState({
        active_tab_icon: tab,
      });
    }
    
    this.forceUpdate();
    this.componentDidMount();
  }

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    let where = [];
    if (params.where) {
      where = params.where;
    }
    if (params.page) {
      switch (params.page) {
        case ">>":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" +
                Math.ceil(
                  this.state.pagination.total / this.state.pagination.per_page
                );
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDiverifikasi.total /
                  this.state.paginationDiverifikasi.per_page
                );
              break;

            case "Disetujui":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDisetujui.total /
                  this.state.paginationDisetujui.per_page
                );
              break;

            case "Ditolak":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDitolak.total /
                  this.state.paginationDitolak.per_page
                );
              break;
            case "Selesai":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationSelesai.total /
                  this.state.paginationSelesai.per_page
                );
              break;
          }
          break;
        case ">":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) + 1);
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                (parseInt(this.state.paginationDiverifikasi.current_page) +
                  1);
              break;

            case "Disetujui":
              link =
                "&page=" +
                (parseInt(this.state.paginationDisetujui.current_page) + 1);
              break;

            case "Ditolak":
              link =
                "&page=" +
                (parseInt(this.state.paginationDitolak.current_page) + 1);
              break;
            case "Selesai":
              link =
                "&page=" +
                (parseInt(this.state.paginationSelesai.current_page) + 1);
              break;

          }
          break;
        case "<":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) - 1);
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                (parseInt(this.state.paginationDiverifikasi.current_page) -
                  1);
              break;

            case "Disetujui":
              link =
                "&page=" +
                (parseInt(this.state.paginationDisetujui.current_page) - 1);
              break;

            case "Ditolak":
              link =
                "&page=" +
                (parseInt(this.state.paginationDitolak.current_page) - 1);
              break;
            case "Selesai":
              link =
                "&page=" +
                (parseInt(this.state.paginationSelesai.current_page) - 1);
              break;
          }
          break;
        case "<<":
          link = "&page=1";
          break;
        default:
          link = "&page=" + params.page;
          break;
      }
    }

    // Get data from db
    if(this.state.cek_posisi == "Pertama"){
      params.tab = "Diajukan"
    }
    else if(this.state.cek_posisi == "Kedua"){
      params.tab = "Diverifikasi"
    }
    else if(this.state.cek_posisi == "Ketiga"){
      params.tab = "Disetujui"
    }
    else if(this.state.cek_posisi == "Keempat"){
      params.tab = "Ditolak"
    }
    else if(this.state.cek_posisi == "Kelima"){
      params.tab = "Selesai"
    }

    Get("pelayanan-external?jenis_layanan=Penerbitan Akta Kematian&status=" + params.tab + link, null, (dtkat) => {
      console.log("COba tampil bos")
      console.log(dtkat)
      switch (params.tab) {
        case "Diajukan":
          this.setState({
            data: dtkat.results.data,
            pagination: dtkat.results
          });
          break;

        case "Diverifikasi":
          this.setState({
            dataVerifikasi: dtkat.results.data,
            paginationDiverifikasi: dtkat.results
          });
          break;
        
        case "Disetujui":
          this.setState({
            dataDisetujui: dtkat.results.data,
            paginationDisetujui: dtkat.results
          });
          break;
        case "Ditolak":
          this.setState({
            dataDitolak: dtkat.results.data,
            paginationDitolak: dtkat.results
          });
          break;
        case "Selesai":
          this.setState({
            dataSelesai: dtkat.results.data,
            paginationSelesai: dtkat.results
          });
          break;
      }
      // console.log(dtkat.results);
      this.pagination(dtkat.results, params.tab);
    });
  };

  // Pagination
  pagination = (data, tab) => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "success";
        if (i == data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            key={i}
            onClick={() => this.fetch({ page: i, tab: tab })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            onClick={() => this.fetch({ page: p, tab: tab })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            onClick={() => this.fetch({ page: n, tab: tab })}
            className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }

      awal = (
        <a
          onClick={() => this.fetch({ page: 1, tab: tab })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          onClick={() => this.fetch({ page: data.last_page, tab: tab })}
          className="btn btn-success btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }

    switch (tab) {
      case "Diajukan":
        this.setState({ awal, sebelum, hal, setelah, akhir });
        break;
      case "Diverifikasi":
        this.setState({
          awal2: awal,
          sebelum2: sebelum,
          hal2: hal,
          setelah2: setelah,
          akhir2: akhir
        });
        break;
      case "Disetujui":
        this.setState({
          awal3: awal,
          sebelum3: sebelum,
          hal3: hal,
          setelah3: setelah,
          akhir3: akhir
        });
        break;
      case "Ditolak":
        this.setState({
          awal4: awal,
          sebelum4: sebelum,
          hal4: hal,
          setelah4: setelah,
          akhir4: akhir
        });
        break;
      case "Selesai":
        this.setState({
          awal5: awal,
          sebelum5: sebelum,
          hal5: hal,
          setelah5: setelah,
          akhir5: akhir
        });
        break;
    }
  };


  // Tambah Verifikasi
  tambahDataVerifikasi = (data) => {
    this.state.status.formTambahVerifikasi = true;
    this.state.judul = "Verifikasi Pendaftaran Layanan";
    
    console.log("PARSING DATA DETAIL Verifikasi")
    console.log(data)
    
    // data layanan
    this.state.data_detail.uuid= data.uuid;
    this.state.data_detail.tgl_layanan = data.tgl_melapor;
    this.state.data_detail.jenis_layanan = data.jenis_layanan;
    this.state.data_detail.status_layanan = data.data_status_baru.status;
    let kett = ""
    if(data.data_status_baru.status=="Ditolak"){
      kett = data.data_status_baru.keterangan
    }
    this.state.data_detail.keterangan_layanan = kett;
    
    // Data Lampiran
    let lampiran = JSON.parse(data.dokumen_input)
    this.state.data_detail.kk_bersangkutan=lampiran.kk_bersangkutan
    this.state.data_detail.surat_kematian_desa=lampiran.surat_kematian_desa

    // Data Download 
    let dokumen_download = JSON.parse(data.dokumen_output)

    console.log("Data DOwnload")
    console.log(dokumen_download)


    let detailLayanan = null
    detailLayanan = JSON.parse(data.data_pelayanan)
    let detailPelapor = null
    detailPelapor = JSON.parse(data.data_pelapor)
    
    // data ayah
    this.state.data_detail.alamat_ayah = detailLayanan. alamat_ayah
    this.state.data_detail.deskel_ayah = detailLayanan.deskel_ayah
    this.state.data_detail.kabkota_ayah = detailLayanan.kabkota_ayah
    this.state.data_detail.kecamatan_ayah = detailLayanan.kecamatan_ayah
    this.state.data_detail.nama_ayah = detailLayanan.nama_ayah
    this.state.data_detail.nik_ayah = detailLayanan.nik_ayah
    this.state.data_detail.pekerjaan_ayah = detailLayanan.pekerjaan_ayah
    this.state.data_detail.provinsi_ayah = detailLayanan.provinsi_ayah
    this.state.data_detail.tgl_lahir_ayah = detailLayanan.tgl_lahir_ayah
    this.state.data_detail.umur_ayah = detailLayanan.umur_ayah
    // data ibu
    this.state.data_detail.alamat_ibu = detailLayanan.alamat_ibu
    this.state.data_detail.deskel_ibu = detailLayanan.deskel_ibu
    this.state.data_detail.kabkota_ibu = detailLayanan.kabkota_ibu
    this.state.data_detail.kecamatan_ibu = detailLayanan.kecamatan_ibu
    this.state.data_detail.nama_ibu = detailLayanan.nama_ibu
    this.state.data_detail.nik_ibu = detailLayanan.nik_ibu
    this.state.data_detail.pekerjaan_ibu = detailLayanan.pekerjaan_ibu
    this.state.data_detail.provinsi_ibu = detailLayanan.provinsi_ibu
    this.state.data_detail.tgl_lahir_ibu = detailLayanan.tgl_lahir_ibu
    this.state.data_detail.umur_ibu = detailLayanan.umur_ibu
    // data terlapor
    this.state.data_detail.agama = detailLayanan.agama
    this.state.data_detail.alamat_jenazah = detailLayanan.alamat_jenazah
    this.state.data_detail.anak_ke = detailLayanan.anak_ke
    this.state.data_detail.deskel_jenazah = detailLayanan.deskel_jenazah
    this.state.data_detail.jk_jenazah = detailLayanan.jk_jenazah
    this.state.data_detail.kabkota_jenazah = detailLayanan.kabkota_jenazah
    this.state.data_detail.kecamatan_jenazah = detailLayanan.kecamatan_jenazah
    this.state.data_detail.nama_jenazah = detailLayanan.nama_jenazah
    this.state.data_detail.nama_kepala_keluarga = detailLayanan.nama_kepala_keluarga
    this.state.data_detail.nik_jenazah = detailLayanan.nik_jenazah
    this.state.data_detail.no_kk = detailLayanan.no_kk
    this.state.data_detail.pekerjaan_jenazah = detailLayanan.pekerjaan_jenazah
    this.state.data_detail.provinsi_jenazah = detailLayanan.provinsi_jenazah
    this.state.data_detail.pukul_kematian = detailLayanan.pukul_kematian
    this.state.data_detail.sebab_kematian = detailLayanan.sebab_kematian
    this.state.data_detail.tempat_kematian = detailLayanan.tempat_kematian
    this.state.data_detail.tgl_kematian = detailLayanan.tgl_kematian
    this.state.data_detail.tgl_lahir_jenazah = detailLayanan.tgl_lahir_jenazah
    this.state.data_detail.tmpt_lahir_jenazah = detailLayanan.tmpt_lahir_jenazah
    this.state.data_detail.umur_jenazah = detailLayanan.umur_jenazah
    this.state.data_detail.yang_menerangkan = detailLayanan.yang_menerangkan
    // data saksi
    this.state.data_detail.alamat_saksi_1 = detailLayanan.alamat_saksi_1
    this.state.data_detail.alamat_saksi_2 = detailLayanan.alamat_saksi_2
    this.state.data_detail.deskel_saksi_1 = detailLayanan.deskel_saksi_1
    this.state.data_detail.deskel_saksi_2 = detailLayanan.deskel_saksi_2
    this.state.data_detail.kabkota_saksi_1 = detailLayanan.kabkota_saksi_1
    this.state.data_detail.kabkota_saksi_2 = detailLayanan.kabkota_saksi_2
    this.state.data_detail.kecamatan_saksi_1 = detailLayanan.kecamatan_saksi_1
    this.state.data_detail.kecamatan_saksi_2 = detailLayanan.kecamatan_saksi_2
    this.state.data_detail.nama_saksi_1 = detailLayanan.nama_saksi_1
    this.state.data_detail.nama_saksi_2 = detailLayanan.nama_saksi_2
    this.state.data_detail.nik_saksi_1 = detailLayanan.nik_saksi_1
    this.state.data_detail.nik_saksi_2 = detailLayanan.nik_saksi_2
    this.state.data_detail.pekerjaan_saksi_1 = detailLayanan.pekerjaan_saksi_1
    this.state.data_detail.pekerjaan_saksi_2 = detailLayanan.pekerjaan_saksi_2
    this.state.data_detail.provinsi_saksi_1 = detailLayanan.provinsi_saksi_1
    this.state.data_detail.provinsi_saksi_2 = detailLayanan.provinsi_saksi_2
    this.state.data_detail.tgl_lahir_saksi_1 = detailLayanan.tgl_lahir_saksi_1
    this.state.data_detail.tgl_lahir_saksi_2 = detailLayanan.tgl_lahir_saksi_2
    this.state.data_detail.umur_saksi_1 = detailLayanan.umur_saksi_1
    this.state.data_detail.umur_saksi_2 = detailLayanan.umur_saksi_2
    // date pelapor
    this.state.data_detail.nik_pelapor = data.nik_pelapor;
    this.state.data_detail.nama_pelapor = data.nama_pelapor;
    this.state.data_detail.alamat_pelapor = data.alamat_pelapor;
    this.state.data_detail.no_hp_pelapor = data.no_hp_pelapor;
    this.state.data_detail.pekerjaan_pelapor = data.pekerjaan_pelapor;
    this.state.data_detail.deskel_pelapor = detailPelapor.deskel_pelapor
    this.state.data_detail.kabkota_pelapor = detailPelapor.kabkota_pelapor
    this.state.data_detail.kecamatan_pelapor = detailPelapor.kecamatan_pelapor
    this.state.data_detail.provinsi_pelapor = detailPelapor.provinsi_pelapor
    this.state.data_detail.tgl_lahir_pelapor = detailPelapor.tgl_lahir_pelapor
    this.state.data_detail.umur_pelapor = detailPelapor.umur_pelapor


    console.log("Lampiran")
    console.log(lampiran)
    console.log("Detail Pelayanan")
    console.log(detailLayanan)
    console.log("Detail Pelapor")
    console.log(detailPelapor)
    this.state.detailLayanan.push(detailLayanan);
    this.forceUpdate();
    
  }
 

  // Simpan Verifikasi
  simpanVerifikasi = (e) => {
		e.preventDefault();
      this.forceUpdate();
      let id = this.state.data_detail.uuid;
      let addData = {
          status: "Diverifikasi",
          keterangan: "-",
      };

          let psn = "Verifikasi";
          let resstat = 200;
          
      Post('pelayanan-external/verifikasi/'+ id, null , addData, (res) => {
          this.forceUpdate();
          console.log(res.status);
          if (res.status === resstat) {
              this.setState({ show: true, basicType: 'success', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Berhasil ' + psn + ' Data' });
              this.state.status.formTambahVerifikasi = false;
              this.forceUpdate()
          }
          else {
              this.setState({ show: true, basicType: 'danger', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Gagal ' + psn + ' Data' });
          }
          this.componentDidMount();
      });
	};
 
  // ========================================================================

  // ====================== Hapus Data =============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("kesukuan/delete", id, res => {
      if (res == 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Suku",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Suku",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ====================== Hapus Data =============================

  bukaForm = jenis => {
    switch (jenis) {
      case "catatan":
        this.state.status.form = true;
        break;

      case "update":
        this.state.status.formUpdateSurat = true;
        break;

      default:
        break;
    }
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.formTambahVerifikasi = false;
    this.state.status.form = false;

    this.state.data_detail.uuid = "";
    this.state.data_detail.nik = "";
    this.state.data_detail.nama = "";
    this.state.id_suku= null
    this.state.id_datuak= null
    this.state.data_detail.nama_gelar = "";



    this.state.status.accStat = false;
    this.state.status.tableAcc = "none";

    this.forceUpdate();
  };


  tombolAksiVerifikasi = (cell, row) => {
    return (
      <>
      {this.state.cek_posisi=="Pertama"
        ?
        <div>
            <button onClick={() => this.tambahDataVerifikasi(row)} color="primary" className="btn btn-success"><i className="fa fa-check"></i> Verifikasi</button>
        </div>
        :this.state.cek_posisi=="Kedua"
        ?
        <div>
            <button onClick={() => this.tambahDataVerifikasi(row)} color="primary" className="btn btn-success"><i className="fa fa-eye"></i> Detail</button>
        </div>
         :this.state.cek_posisi=="Ketiga"
         ?
         <div>
             <button onClick={() => this.tambahDataVerifikasi(row)} color="primary" className="btn btn-success"><i className="fa fa-eye"></i> Detail</button>
         </div>
         :this.state.cek_posisi=="Keempat"
         ?
         <div>
             <button onClick={() => this.tambahDataVerifikasi(row)} color="primary" className="btn btn-success"><i className="fa fa-eye"></i>  Detail</button>
         </div>
         :this.state.cek_posisi=="Kelima"
         ?
         <div>
             <button onClick={() => this.tambahDataVerifikasi(row)} color="primary" className="btn btn-success"><i className="fa fa-eye"></i>  Detail</button>{"   "}
             <button onClick={() => this.showDownload(row)} color="primary" className="btn btn-primary"><i className="fa fa-download"></i> Download</button>
         </div>
         :
         <>
         </>
      }
     
    </>
    )
}

  componentDidMount() {
    if (typeof this.props.location.tabProps != "undefined") {
      this.state.active_tab_icon = this.props.location.tabProps;
    }
    if (typeof this.props.location.state != "undefined") {
      this.state.active_tab_icon = this.props.location.state.tab;

    }

    let tabnya = ""
    if(this.state.cek_posisi=="Pertama"){
      tabnya="Diajukan"
    }
    else if(this.state.cek_posisi=="Kedua"){
      tabnya="Diverifikasi"
    }
    else if(this.state.cek_posisi=="Ketiga"){
      tabnya="Disetujui"
    }
    else if(this.state.cek_posisi=="Keempat"){
      tabnya="Ditolak"
    }
    else if(this.state.cek_posisi=="Kelima"){
      tabnya="Selesai"
    }
    // Fetching data diajukan
    this.fetch({ page: null, where: [], tab: tabnya });
    // Fetcing data diverifikasi
    // this.fetch({ page: null, where: [], tab: "Diverifikasi" });
    // // Fetcing data Disetujui
    // this.fetch({ page: null, where: [], tab: "Disetujui" });
    // // Fetcing data Ditolak
    // this.fetch({ page: null, where: [], tab: "Ditolak" });
    
    
    this.forceUpdate();
  }


  // Cari Data penduduk gelar
	cariDataGelar = (e) => {
    
    console.log("Tab Keberapa")
    console.log(this.state.active_tab_icon)

    if(this.state.active_tab_icon==1){
      this.state.cek_posisi = "Pertama"
    }
    else{
      this.state.cek_posisi = "Non-Gelar"
    }

		if (e.key == 'Enter') {
      console.log("Search")
			console.log(e.target.value)
			let dataCariGelar = {
        q: e.target.value
			};
			this.setState({ dataCariGelar });
			setTimeout(
        function() {
          // mywindow.close();
					this.fetch();
				}.bind(this),
				500
        );
      }
      else{
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      this.forceUpdate();
	};


  // ============================== Ubah Data ===============================
    
//   ubahData = (id) => {
//     this.state.status.btnAksi = true;
//     this.state.status.formTambahVerifikasi = true;
//     this.state.judul = "Ubah Suku";
//     this.forceUpdate();
//     Get('kesukuan/find',id, (data) => {
//       console.log("Data Ubah")
//       console.log(data)
//       this.state.data_detail.uuid = data.results.uuid;
//       this.state.data_detail.nik = data.results.nik;
//       this.state.data_detail.nama = data.results.data_penduduk.nama;
//       this.state.id_suku= { value: data.results.nama_suku, label: data.results.nama_suku }
//       this.state.id_datuak= { value: data.results.nama_datuak, label: data.results.nama_datuak }
//       this.state.data_detail.nama_gelar = data.results.nama_gelar;

//           this.forceUpdate();
//         this.state.status.btnAksi = false;
//         this.forceUpdate();
//         this.bukaForm();
//     });
// }


  formKeterangan = (e) => {
		e.preventDefault();
			this.state.status.formKeterangan = true;
      this.state.data_detail.keterangan_layanan = "";
			this.forceUpdate();
	};

  detailGambar = (id, judul) => {
		// e.preventDefault();
			this.state.status.formDetailGambar = true;
      this.state.data_detail.show_gambar = id
      this.state.data_detail.judul_gambar = judul
      console.log("Detail Link Gambar")
      console.log(id)
			this.forceUpdate();
	};


  tutupKeterangan = (e) => {
		e.preventDefault();
		this.state.status.formKeterangan = false;
    document.getElementById("keterangan_tolak").value = ""
		this.forceUpdate();
	};

  tutupDetailGambar = (e) => {
		e.preventDefault();
		this.state.status.formDetailGambar = false;
		this.forceUpdate();
	};

  tutupShowDownload = (e) => {
		e.preventDefault();
		this.state.status.formShowDownload = false;
		this.forceUpdate();
	};

  tambahKeterangan = (e) => {
		e.preventDefault();
      this.forceUpdate();
      let id = this.state.data_detail.uuid;
      let addData = {
          status: "Ditolak",
          keterangan: document.getElementById("keterangan_tolak").value,
      };

          let psn = "Tolak";
          let resstat = 200;

          console.log("Data Tambah Keterangan")
          console.log(id)
          console.log(addData)
          
      Post('pelayanan-external/verifikasi/'+ id, null , addData, (res) => {
          this.forceUpdate();
          console.log(res.status);
          if (res.status === resstat) {
              this.setState({ show: true, basicType: 'success', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Berhasil ' + psn + ' Verifikasi' });
		          this.state.status.formKeterangan = false;
              this.state.status.formTambahVerifikasi = false;
              document.getElementById("keterangan_tolak").value = ""
              this.forceUpdate()
          }
          else {
              this.setState({ show: true, basicType: 'danger', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Gagal ' + psn + ' Verifikasi' });
          }
          this.componentDidMount();
      });
	};

   // Download File Kematian
   downloadDokumen = (data) => {
    this.state.status.formShowDownload = true;

    console.log("Gass Download Linknya")
    console.log(data)
       
      const response = {
        file: data,
      };
      // server sent the url to the file!
      // now, let's download:
      window.open(response.file);
      // you could also do:
      // window.location.href = response.file;
  
    this.forceUpdate();
    
  }
 
   // Show Download Modal
   showDownload = (data) => {
    this.state.status.formShowDownload = true;
    this.state.judul = "Download Dokumen";
    
    console.log("Data Download")
    console.log(data.dokumen_output)

    let dokumen_download = JSON.parse(data.dokumen_output)

    console.log("Data DOwnload")
    console.log(dokumen_download)

    let data_download = [];

    this.state.data_download=[
      {
        label: 'Akta Kematian',
        link: dokumen_download.akta_kematian,
      },
      {
        label: 'Kartu Keluarga',
        link: dokumen_download.kartu_keluarga,
      },
    ]
    
    
    console.log("View Download List")
    console.log(data_download)
    
    this.forceUpdate();
    
  }
  
  render() {
    console.log("Lihat Data Download Bos")
    console.log(this.state.data_download)

    console.log("Cek Data Tab Pane")
    console.log(this.state.data)
    console.log(this.state.dataVerifikasi)
    console.log(this.state.dataDisetujui)
    console.log(this.state.dataDitolak)
    console.log(this.state.dataSelesai)
    // console.log("Detail Layanan")
    // console.log(this.state.detailLayanan[0])
    // var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
    // var size = this.state.pagination.total;
    // console.log("Tab Keberapa?")
    // console.log(this.state.active_tab_icon)
    // console.log(this.state.cek_posisi)

    // console.log("Datanya")
    // console.log(this.state.data)
    // console.log(this.state.dataVerifikasi)
    // console.log(this.state.dataDisetujui)
    // console.log(this.state.dataDitolak)

    var from = this.state.pagination.from;
    var page = this.state.pagination.current;
    var to = this.state.pagination.to;
    var total = this.state.pagination.total;
    var data = this.state.data;
    var dataVerifikasi = this.state.dataVerifikasi;
    var dataDisetujui = this.state.dataDisetujui;
    var dataDitolak = this.state.dataDitolak;
    var dataSelesai = this.state.dataSelesai;

    var no = 0;
    const frmDef = {
      id: 0,
      idbaru: "",
      id_keldes: "",
      nama: ""
    };
    // const { SearchBar } = Search;

    // Data diproses
    const columns = [

      {
        dataField: "tgl_melapor",
        text: "Tanggal Melapor"
        // sort: true
      },

      {
        dataField: "nik_pelapor",
        text: "NIK"
        // sort: true
      },

      {
        dataField: "nama_pelapor",
        text: "Nama"
        // sort: true
      },

      {
        dataField: "jenis_layanan",
        text: "Layanan"
        // sort: true
      },

      {
        dataField: "data_status_baru.status",
        text: "Status"
        // sort: true
      },

      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiVerifikasi
      }
    ];

    // Data ditolak
    const columns2 = [

      {
        dataField: "nik_pelapor",
        text: "NIK"
        // sort: true
      },

      {
        dataField: "nik_pelapor",
        text: "NIK"
        // sort: true
      },

      {
        dataField: "nama_pelapor",
        text: "Nama"
        // sort: true
      },

      {
        dataField: "tgl_melapor",
        text: "Tanggal Melapor"
        // sort: true
      },

      {
        dataField: "data_status.status",
        text: "Status"
        // sort: true
      },

      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiVerifikasi
      }
    ];


    const defaultSorted = [
      {
        dataField: "nama",
        order: "asc"
      }
    ];

  

    return (
      <div>
        {/* <Breadcrumb title="Disposisi Surat" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Disposisi Surat</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      {/* <Button size="sm" color="success" className="btn-square" onClick={() => {
                        this.setState({ judul: "Tambah Dusun", dt: frmDef, prvStat: [], kbktStat: [], kecStat: [], kldsStat: [] }); this.bukaForm();
                      }}>Tambah Data</Button> */}
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <div className="row">
										<div className="col-sm-4">
											&nbsp;
										</div>
										<div className="col-sm-8 text-right">
											<button onClick={this.props.history.goBack} color="primary" className="btn btn-secondary"><i className="fa fa-arrow-left"></i> Kembali</button>
										</div>
									</div>

                  <Nav tabs className="tabs-color">
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "1"
                        })}
                        onClick={() => {
                          this.toggle_icon("1");
                        }}
                      >
                        <i className="fa fa-hourglass-half"></i>
                        
                        Diajukan
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "2"
                        })}
                        onClick={() => {
                          this.toggle_icon("2");
                        }}
                      >
                        <i className="fa fa-tasks"></i>

                        Diverifikasi
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "3"
                        })}
                        onClick={() => {
                          this.toggle_icon("3");
                        }}
                      >
                        <i className="fa fa-check"></i>

                        Disetujui
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "4"
                        })}
                        onClick={() => {
                          this.toggle_icon("4");
                        }}
                      >
                        <i className="fa fa-times"></i>

                        Ditolak
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "5"
                        })}
                        onClick={() => {
                          this.toggle_icon("5");
                        }}
                      >
                        <i className="fa fa-check"></i>

                        Selesai
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.active_tab_icon}>
                    <TabPane tabId="1">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                          {/* <div className="row">
                            <div className="col-lg-12">
                              <input
                                type="text"
                                className="form-control"
                                id="cariTable"
                                placeholder="Cari Data berdasarkan nama penduduk"
                                onKeyPress={this.cariDataGelar}
                              />
                            </div>
                          </div> */}
                            <BootstrapTable
                              keyField="uuid"
                              data={data}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal}
                              {this.state.sebelum}
                              {this.state.hal.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah}
                              {this.state.akhir}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                          <div className="row">
                            <div className="col-lg-12">
                                  <div>
                                    {/* <input
                                      type="text"
                                      className="form-control"
                                      id="cariTable"
                                      placeholder="Cari Data berdasarkan nama penduduk"
                                      onKeyPress={this.cariDataGelar}
                                    /> */}
                                    <BootstrapTable
                                    keyField="uuid_surat"
                                    data={dataVerifikasi}
                                    columns={columns}
                                    />
                                    <div className="pull-right text-white">
                                      {this.state.awal2}
                                      {this.state.sebelum2}
                                      {this.state.hal2.map(dt => {
                                        return dt;
                                      })}
                                      {this.state.setelah2}
                                      {this.state.akhir2}
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">

                          {/* <div className="row">
                            <div className="col-lg-12">
                              <input
                                type="text"
                                className="form-control"
                                id="cariTable"
                                placeholder="Cari Data berdasarkan nama penduduk"
                                onKeyPress={this.cariDataGelar}
                              />
                            </div>
                          </div> */}
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={dataDisetujui}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal3}
                              {this.state.sebelum3}
                              {this.state.hal3.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah3}
                              {this.state.akhir3}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">

                          {/* <div className="row">
                            <div className="col-lg-12">
                              <input
                                type="text"
                                className="form-control"
                                id="cariTable"
                                placeholder="Cari Data berdasarkan nama penduduk"
                                onKeyPress={this.cariDataGelar}
                              />
                            </div>
                          </div> */}
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={dataDitolak}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal4}
                              {this.state.sebelum4}
                              {this.state.hal4.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah4}
                              {this.state.akhir4}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">

                          {/* <div className="row">
                            <div className="col-lg-12">
                              <input
                                type="text"
                                className="form-control"
                                id="cariTable"
                                placeholder="Cari Data berdasarkan nama penduduk"
                                onKeyPress={this.cariDataGelar}
                              />
                            </div>
                          </div> */}
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={dataSelesai}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal5}
                              {this.state.sebelum5}
                              {this.state.hal5.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah5}
                              {this.state.akhir5}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />


        {/* Modal Tambah */}
        
        <Modal
          open={this.state.status.formTambahVerifikasi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanVerifikasi}>
            <div className="modal-header">
              <h4 className="modal-title">{this.state.judul}</h4>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.data_detail.uuid}
              />
              
              <div style={{ border: '3px outset #e3641b', borderRadius: '25px', padding:'20px'}}>
              
                  <h5>
                    <b>Data Jenazah</b>
                  </h5>
          
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>No. KK</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.no_kk? this.state.data_detail.no_kk:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>NIK</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.nik_jenazah? this.state.data_detail.nik_jenazah:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Nama</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.data_detail.nama_jenazah? this.state.data_detail.nama_jenazah:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Tempat Lahir</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.data_detail.tmpt_lahir_jenazah? this.state.data_detail.tmpt_lahir_jenazah:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Tanggal Lahir</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.data_detail.tgl_lahir_jenazah? this.state.data_detail.tgl_lahir_jenazah:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Jenis Kelamin</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.jk_jenazah? this.state.data_detail.jk_jenazah:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Agama</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.agama? this.state.data_detail.agama:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Anak Ke</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.anak_ke? this.state.data_detail.anak_ke:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Pekerjaan</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.pekerjaan_jenazah? this.state.data_detail.pekerjaan_jenazah:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Umur</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.umur_jenazah? this.state.data_detail.umur_jenazah:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Alamat</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.alamat_jenazah? this.state.data_detail.alamat_jenazah:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Desa/Kelurahan</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.deskel_jenazah? this.state.data_detail.deskel_jenazah:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Kecamatan</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.kecamatan_jenazah? this.state.data_detail.kecamatan_jenazah:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Kabupaten/Kota</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.kabkota_jenazah? this.state.data_detail.kabkota_jenazah:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Provinsi</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.provinsi_jenazah? this.state.data_detail.provinsi_jenazah:'-'}
								</h6>
											</div>
							</FormGroup>

              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Tempat Meninggal</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.tempat_kematian? this.state.data_detail.tempat_kematian:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Tanggal Meninggal</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.tgl_kematian? this.state.data_detail.tgl_kematian:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Waktu Meninggal</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.pukul_kematian? this.state.data_detail.pukul_kematian:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Sebab Meninggal</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.sebab_kematian? this.state.data_detail.sebab_kematian:'-'}
								</h6>
											</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Yang Menerangkan</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.yang_menerangkan? this.state.data_detail.yang_menerangkan:'-'}
								</h6>
											</div>
							</FormGroup>

              <FormGroup className="row" style={{ marginBottom: '0px' }}>
								<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Kepala Keluarga</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
												<h6
													style={{
														marginBottom: '0px',
														fontWeight: 400,
														fontSize: 'inherit'
													}}
												> {this.state.data_detail.nama_kepala_keluarga? this.state.data_detail.nama_kepala_keluarga:'-'}
								</h6>
											</div>
							</FormGroup>
              </div>

              <br/>
                          
              <div style={{ border: '3px outset #e3c21b', borderRadius: '25px', padding:'20px'}}>

                <h5>
                  <b>Data Ibu</b>
                </h5>
                        
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>NIK</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.nik_ibu? this.state.data_detail.nik_ibu:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Nama</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.nama_ibu? this.state.data_detail.nama_ibu:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Tanggal Lahir</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.tgl_lahir_ibu? this.state.data_detail.tgl_lahir_ibu:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Umur</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.umur_ibu? this.state.data_detail.umur_ibu:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Pekerjaan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.pekerjaan_ibu? this.state.data_detail.pekerjaan_ibu:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Alamat</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.alamat_ibu? this.state.data_detail.alamat_ibu:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Desa/Kelurahan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.deskel_ibu? this.state.data_detail.deskel_ibu:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Kecamatan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.kecamatan_ibu? this.state.data_detail.kecamatan_ibu:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Kabupaten/Kota</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.kabkota_ibu? this.state.data_detail.kabkota_ibu:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Provinsi</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.provinsi_ibu? this.state.data_detail.provinsi_ibu:'-'}
                  </h6>
                        </div>
                </FormGroup>
              </div>

               <br/>
                          
              <div style={{ border: '3px outset #b4e31b', borderRadius: '25px', padding:'20px'}}>
                
                <h5>
                  <b>Data Ayah</b>
                </h5>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>NIK</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.nik_ayah? this.state.data_detail.nik_ayah:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Nama</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.nama_ayah? this.state.data_detail.nama_ayah:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Tanggal Lahir</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.tgl_lahir_ayah? this.state.data_detail.tgl_lahir_ayah:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Umur</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.umur_ayah? this.state.data_detail.umur_ayah:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Pekerjaan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.pekerjaan_ayah? this.state.data_detail.pekerjaan_ayah:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Alamat</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.alamat_ayah? this.state.data_detail.alamat_ayah:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Desa/Kelurahan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.deskel_ayah? this.state.data_detail.deskel_ayah:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Kecamatan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.kecamatan_ayah? this.state.data_detail.kecamatan_ayah:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Kabupaten/Kota</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.kabkota_ayah? this.state.data_detail.kabkota_ayah:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Provinsi</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.provinsi_ayah? this.state.data_detail.provinsi_ayah:'-'}
                  </h6>
                        </div>
                </FormGroup>  
              </div>

              <br/>
                          
              <div style={{ border: '3px outset #1be31b', borderRadius: '25px', padding:'20px'}}>
               
                <h5>
                  <b>Data Pelapor</b>
                </h5>
                      
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>NIK</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.nik_pelapor? this.state.data_detail.nik_pelapor:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Nama</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.nama_pelapor? this.state.data_detail.nama_pelapor:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Tanggal Lahir</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.tgl_lahir_pelapor? this.state.data_detail.tgl_lahir_pelapor:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Umur</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.umur_pelapor? this.state.data_detail.umur_pelapor:'-'}
                  </h6>
                        </div>
                </FormGroup>
      

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Pekerjaan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.pekerjaan_pelapor? this.state.data_detail.pekerjaan_pelapor:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>No. HP</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.no_hp_pelapor? this.state.data_detail.no_hp_pelapor:'-'}
                  </h6>
                        </div>
                </FormGroup>
                
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Alamat</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.alamat_pelapor? this.state.data_detail.alamat_pelapor:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Desa/Kelurahan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.deskel_pelapor? this.state.data_detail.deskel_pelapor:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Kecamatan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.kecamatan_pelapor? this.state.data_detail.kecamatan_pelapor:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Kabupaten/Kota</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.kabkota_pelapor? this.state.data_detail.kabkota_pelapor:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Provinsi</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.provinsi_pelapor? this.state.data_detail.provinsi_pelapor:'-'}
                  </h6>
                        </div>
                </FormGroup>
              </div>

              <br/>
                          
              <div style={{ border: '3px outset #1be3e0', borderRadius: '25px', padding:'20px'}}>
                
                <h5>
                  <b>Data Saksi I</b>
                </h5>
                        

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>NIK </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.nik_saksi_1? this.state.data_detail.nik_saksi_1:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Nama </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.nama_saksi_1? this.state.data_detail.nama_saksi_1:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Tanggal Lahir </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.tgl_lahir_saksi_1? this.state.data_detail.tgl_lahir_saksi_1:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Umur </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.umur_saksi_1? this.state.data_detail.umur_saksi_1:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Pekerjaan </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.pekerjaan_saksi_1? this.state.data_detail.pekerjaan_saksi_1:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Alamat </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.alamat_saksi_1? this.state.data_detail.alamat_saksi_1:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Desa/Kelurahan </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.deskel_saksi_1? this.state.data_detail.deskel_saksi_1:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Kecamatan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.kecamatan_saksi_1? this.state.data_detail.kecamatan_saksi_1:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Kabupaten/Kota</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.kabkota_saksi_1? this.state.data_detail.kabkota_saksi_1:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Provinsi</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.provinsi_saksi_1? this.state.data_detail.provinsi_saksi_1:'-'}
                  </h6>
                        </div>
                </FormGroup>
              </div>

              <br/>
                          
              <div style={{ border: '3px outset #1b86e3', borderRadius: '25px', padding:'20px'}}>

               
                <h5>
                  <b>Data Saksi II</b>
                </h5>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>NIK </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.nik_saksi_2? this.state.data_detail.nik_saksi_2:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Nama </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.nama_saksi_2? this.state.data_detail.nama_saksi_2:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Tanggal Lahir </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.tgl_lahir_saksi_2? this.state.data_detail.tgl_lahir_saksi_2:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Umur </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.umur_saksi_2? this.state.data_detail.umur_saksi_2:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Pekerjaan </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.pekerjaan_saksi_2? this.state.data_detail.pekerjaan_saksi_2:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Alamat </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.alamat_saksi_2? this.state.data_detail.alamat_saksi_2:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Desa/Kelurahan </b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.deskel_saksi_2? this.state.data_detail.deskel_saksi_2:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Kecamatan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.kecamatan_saksi_2? this.state.data_detail.kecamatan_saksi_2:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Kabupaten/Kota</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.kabkota_saksi_2? this.state.data_detail.kabkota_saksi_2:'-'}
                  </h6>
                        </div>
                </FormGroup>
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Provinsi</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.provinsi_saksi_2? this.state.data_detail.provinsi_saksi_2:'-'}
                  </h6>
                        </div>
                </FormGroup>
              </div>

              <br/>
                          
              <div style={{ border: '3px outset #931be3', borderRadius: '25px', padding:'20px'}}>

                <h5>
                  <b>Data Layanan</b>
                </h5>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Jenis Layanan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.jenis_layanan? this.state.data_detail.jenis_layanan:'-'}
                  </h6>
                        </div>
                </FormGroup>

                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Tanggal Melapor</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.tgl_layanan? this.state.data_detail.tgl_layanan:'-'}
                  </h6>
                        </div>
                </FormGroup>
                
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Status</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          > {this.state.data_detail.status_layanan? this.state.data_detail.status_layanan:'-'}
                  </h6>
                        </div>
                </FormGroup>
              
              {this.state.data_detail.status_layanan == "Ditolak"
                ?
                <FormGroup className="row" style={{ marginBottom: '0px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Keterangan</b>
                  </Label>
                  <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <h6
                            style={{
                              marginBottom: '0px',
                              fontWeight: 400,
                              fontSize: 'inherit'
                            }}
                          >  {this.state.data_detail.keterangan_layanan? this.state.data_detail.keterangan_layanan:'-'}
                  </h6>
                        </div>
                </FormGroup>
                :
                <>
                </>
                }
              
              </div>

              <hr/>
              <br/>
             
              <h5 align="center" style={{ fontWeight: 1000 }}>Detail Data Lampiran</h5>
              <hr/>
              <br/>

              <div className="col-md-12" align="center">
                <div className="row">
                    <div className="col-md-6">
                    <br/>
                      <p>Foto KK</p>
                      {this.state.data_detail.kk_bersangkutan
                        ?
                        <div>
                          <img src={this.state.data_detail.kk_bersangkutan} style={{ width: 150, height: 250 }} alt="" />
                          <br/><br/>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => this.detailGambar(this.state.data_detail.kk_bersangkutan, "Kartu Keluarga")}
                          >
                            Lihat
                          </button>
                        </div>
                        :
                          <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                      }
                    </div>
                    
                    <div className="col-md-6">
                    <br/>
                      <p>Foto Surat Kematian</p>
                      {this.state.data_detail.surat_kematian_desa
                        ?
                        <div>
                          <img src={this.state.data_detail.surat_kematian_desa} style={{ width: 150, height: 250 }} alt="" />
                          <br/><br/>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => this.detailGambar(this.state.data_detail.surat_kematian_desa, "Surat Keterangan Kematian")}
                          >
                            Lihat
                          </button>
                        </div>
                        :
                          <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                      }   
                    </div>
                </div>
              </div>
            
            </div>
                 
            {this.state.cek_posisi=="Pertama"
              ?
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.formKeterangan}
                >
                  Tolak
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  // disabled={this.state.status.btnFormAcc}
                >
                  Setujui
                </button>
              </div>
              : this.state.cek_posisi=="Kedua"
              ?
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={this.tutupForm}
                >
                  Tutup
                </button>
              </div>
              : this.state.cek_posisi=="Ketiga"
              ?
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={this.tutupForm}
                >
                  Tutup
                </button>
              </div>
              : this.state.cek_posisi=="Keempat"
              ?
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={this.tutupForm}
                >
                  Tutup
                </button>
              </div>
              : this.state.cek_posisi=="Kelima"
              ?
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={this.tutupForm}
                >
                  Tutup
                </button>
              </div>
              :
              <>
              </>
            }
          </Form>
        
        </Modal>

        <Modal
					open={this.state.status.formDetailGambar}
					onClose={this.tutupDetailGambar}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: "100%" } }}
				>
          <div className="modal-header" align="center">
							<h5 className="modal-title">{this.state.data_detail.judul_gambar}</h5>
						</div>
					<Form className="theme-form">
            <div className="modal-body">	
              <div className="col-md-12" align="center">
                <div className="row">
                  <img src={this.state.data_detail.show_gambar} style={{width: "100%"}}alt=""/>
                </div>
              </div>
            </div>
						
						<div className="modal-footer" align="center">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupDetailGambar}
							>
								Tutup
							</button>
						</div>
					</Form>
				</Modal>
				
        <Modal
					open={this.state.status.formKeterangan}
					onClose={this.tutupKeterangan}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: "60%" } }}
				>
					<Form className="theme-form" onSubmit={this.tambahKeterangan}>
						<div className="modal-header">
							<h5 className="modal-title">Keterangan Tolak Verifikasi</h5>
						</div>

            <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Keterangan
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="keterangan_tolak"
										type="text"
										placeholder="Keterangan Tolak"
										defaultValue={this.state.data_detail.keterangan_layanan}
										required
									/>
								</Col>
							</FormGroup>
						
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								onClick={this.tutupKeterangan}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success">
								Kirim
							</button>
						</div>
					</Form>
				</Modal>


        <Modal
					open={this.state.status.formShowDownload}
					onClose={this.tutupShowDownload}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: "100%" } }}
				>
          <div className="modal-header" align="center">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
					<Form className="theme-form">
            
            <div className="row text-center">
              {this.state.data_download.map(e => {
                return <div className="col-md-4">
                  <a href={e.link} className="btn btn-primary"><i className="fa fa-download"></i> Download {e.label}
                  </a>
                </div>;
              })}
            </div>
            
						<div className="modal-footer" align="center">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupShowDownload}
							>
								Tutup
							</button>
						</div>
					</Form>
				</Modal>
				
      </div>
    );
  }
}

export default DisdukcapilKematian;